import { message } from 'antd'
import { set, toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { api } from '../../../api'
import Agreement from '../../../components/common/Agreement/agreement'
import Button from '../../../components/common/Button/button'
import ForgotPassButton from '../../../components/common/ForgotPassButton/forgotPassButton'
import Input from '../../../components/common/Input'
import {
  callbackGetCompany,
  getError,
  getReadStatuses,
  getReportsReadStatuses,
  redirectToUnfilledPage,
} from '../../../helpers/helpers'
import { useStore } from '../../../mobx-store/context'
import AgreementPopup from '../Agreement/agreement'
import { Bottom } from '../authForm.style'
import { HookForm } from './form.style'
import WarningModal from '../WarningModal'

const Form = observer(({ login }) => {
  const history = useHistory()
  const store = useStore()

  const checkForRegisterData =
    store.registerData.email && store.registerData.password

  const { control, handleSubmit, errors, setError, watch, reset } = useForm({
    mode:
      history.location.state && checkForRegisterData ? 'onChange' : 'onSubmit',
  })
  const [cookies, setCookie, removeCookie] = useCookies(['pbm_token', 'userId'])
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const [checked, setChecked] = useState(false)
  const [startedFromReportSource, setstartedFromReportSource] = useState(false)
  const [startedFromResource, setStartedFromResource] = useState(false)
  const [startedFromProposedSource, setStartedFromProposedSource] = useState(false)
  const [attemptsLeft, setAttemptsLeft] = useState(0)
  const [lockModalVisible, setLockModalVisible] = useState(false)
  const [inactiveAccountModal, setInactiveAccountModal] = useState(false)

  const watchAllFields = watch()


  useEffect(() => {
    setstartedFromReportSource(store.started_from_report_source)
  }, [])

  useEffect(() => {
    setStartedFromResource(store.started_from_resource)
  }, [])

  useEffect(() => {
    setStartedFromProposedSource(store.started_from_proposed_source)
  }, [])

  useEffect(() => {
    const locationHash = history.location.hash
    const locationState = history.location.state
    reset(store.registerData)

    if (locationHash === '#popup') setVisible(true)

    if (!checkForRegisterData) return

    if (locationState) {
      for (let key in locationState) {
        setError(key, {
          type: 'manual',
        })
      }
    }
  }, [])

  const logIn = (data) => {
    const { email, password } = data
    setLoading(true)
    removeCookie('pbm_token')
    store.reset()
    api(
      '/token/',
      {
        email,
        password,
        username: email,
      },
      'POST',
      true
    )
      .then((data) => {
        if (data.should_enter_otp) {
          localStorage.setItem('email', email)
          history.push('/otp-validation')
          return setLoading(false)
        }
        if (data.error === 'unconfirmed_device') {
          message.error('Unconfirmed device')
          localStorage.setItem(
            'email',
            email
          )
          history.push('/validate-account')
          return setLoading(false)
        }
        if (data.errors && data.errors.attempts_left && data.errors.attempts_left[0] > 0) {
          setAttemptsLeft(data.errors.attempts_left[0])
          setModalVisible(true)
          return setLoading(false)
        }
        if (data.errors && data.errors.non_field_errors[0] === 'Your account is locked. Contact your PBMSource administrator or support.') {
          setLockModalVisible(true)
          return setLoading(false)
        }
        if (data.errors && data.errors.non_field_errors[0] === 'Your account marked as inactive. Contact your PBMSource administrator or support.') {
          setInactiveAccountModal(true)
          return setLoading(false)
        }
        if (data.error === 'Only Dashboard access account') {
          message.error('Only Dashboard access account')
          return setLoading(false)
        }
        if (data.error === 'Only PBMSource access account') {
          message.error('Only PBMSource access account')
          return setLoading(false)
        }
        if (data.errors) {
          message.error(getError(data))
          return setLoading(false)
        }

        setCookie('pbm_token', data.access, { path: '/' })

        getReadStatuses()
        getReportsReadStatuses()
        sessionStorage.setItem('showDisclaimer', 'true');
        store.getCompany(() => {
          store.getUser(() => {
            localStorage.setItem('accountType', store.user[0].account_type)
            localStorage.setItem('required_to_change_temporary_password', store.user[0].required_to_change_temporary_password)
            localStorage.setItem('is_time_to_change_password', store.user[0].is_time_to_change_password)
            store.getResourceTariffInfo(() => {
              if (
                store.company[0].started_from_resource &&
                !store.company[0].have_access_to_tracking &&
                store.company[0].have_access_to_resource
              ) {
                history.push('/resource/table/guides')
              } else {
                redirectToUnfilledPage(history, store, true)
              }
              localStorage.removeItem('legislativeToGo')
              localStorage.removeItem('assignee')
              localStorage.removeItem('lastSessionPathname')
              setLoading(false)
            })
          })
        })
        store.loadSelect('companyOptions', () =>
          store.getSelectedJurisdictions()
        )
      })
      .catch((err) => console.log(err))
  }

  const handleRegister = (values) => {
    store.setRegisterData('email', values.email)
    store.setRegisterData('password', values.password)

    removeCookie('pbm_token')
    setVisible(true)
  }

  return (
    <HookForm onSubmit={handleSubmit(login ? logIn : handleRegister)}>
      {modalVisible && <WarningModal attemptsLeft={attemptsLeft} onClose={() => setModalVisible(false)} />}
      {lockModalVisible && <WarningModal onClose={() => setLockModalVisible(false)} locked={true} />}
      {inactiveAccountModal && <WarningModal onClose={() => setInactiveAccountModal(false)} inactive={true} locked={false} />}
      {watchAllFields && (
        <>
          <Input
            type={'email'}
            control={control}
            placeholder={'E-Mail'}
            name={'email'}
            label={'Email Address'}
            transparent
            validation={{
              required: true,
            }}
            style={{ marginBottom: !errors.email && '.5em' }}
            error={errors.email && 'Enter valid email'}
          />
          <Input
            type={'password'}
            control={control}
            placeholder={'Password'}
            name={'password'}
            label={login ? 'Password' : 'Create Password'}
            transparent
            validation={{
              required: true,
              minLength: 8,
            }}
            error={
              errors.password && 'Enter valid password. Min length - 8 symbols'
            }
          />
        </>
      )}

      {!login && (
        <Agreement
          checked={checked}
          onChange={(checked) => setChecked(checked)}
        />
      )}
      {!login && visible && (
        <AgreementPopup
          startedFromResource={startedFromResource}
          startedFromReportSource={startedFromReportSource}
          startedFromProposedSource={startedFromProposedSource}
          onClick={() => {
            setVisible(false)
          }}
          setError={setError}
        />
      )}
      <Bottom style={{ justifyContent: 'center' }}>
        <Button
          text={login ? 'Sign In' : 'Sign Up'}
          type={'main'}
          loading={loading}
          disabled={!login && !checked}
          style={{ width: '15.625em', marginBottom: '.5em' }}
        />
        {login && <ForgotPassButton />}
      </Bottom>
    </HookForm>
  )
})

export default Form

export const register = async (
  data,
  setLoading,
  setCookie,
  store,
  history,
  removeCookie,
  ga4React,
  startedFromResource,
  startedFromReportSource,
  startedFromProposedSource,
) => {
  removeCookie('pbm_token')
  setLoading(true)
  const { email, password } = data
  api(
    '/users/',
    {
      email,
      password,
      username: email,
      started_from_resource: startedFromResource,
      started_from_report_source: startedFromReportSource,
      started_from_proposed_source: startedFromProposedSource,
    },
    'POST',
    true
  )
    .then((data) => {
      if (data.errors) {
        removeCookie('pbm_token')
        store.reset()
        store.setSingleValue('user', data)
        message.error(getError(data))
        setLoading(false)
      } else {
        removeCookie('pbm_token')
        return api(
          '/token/',
          {
            email,
            password,
            username: email,
          },
          'POST',
          true
        )
      }
    })
    .then((data) => {
      if (data)
        if (!data?.errors) {
          setCookie('pbm_token', data.access, { path: '/' })
          ga4React?.gtag('event', 'registration')
          return api('/companies/', {}, 'GET')
        } else {
          message.error(getError(data))
        }
    })
    .then((data) => {
      if (data)
        if (!data?.errors) {
          callbackGetCompany(data, setLoading, store, history)
          store.reset()
        }
    })
    .catch((err) => {
      setLoading(false)
    })
}
