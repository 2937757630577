
import { CloseCircleFilled } from '@ant-design/icons';
import React from 'react';
import CalendarCustom from '../../Calendar';
import { CustomClose } from '../input.style';

const InputCustomCalendar = ({
  handleChange,
  onChange,
  value,
  name,
  placeholder,
  setValue,
  noLimit,
  showCross,
  handleReset,
  allowHandleChange = true,
}) => {

  const changeHandler = (value) => {
    if (handleChange && allowHandleChange) {
      handleChange(value);
    } else if (onChange) {
      onChange(value);
    }
  }

  const onReset = () => {
    setValue(name, null);
    changeHandler(null);
  }

  const handleOnClick = () => {
    if (handleReset) {
      handleReset();
    } else {
      onReset();
    }
  };

  return (
    <>
      <CalendarCustom
        handleChange={(value) => changeHandler(value)}
        incomeValue={value}
        placeholder={placeholder}
        noLimit={noLimit}
        noLabel
        noBorder
      />
      {showCross && (
        <CustomClose className={'ant-select-clear'} onClick={handleOnClick}>
          <CloseCircleFilled />
        </CustomClose>
      )}
    </>
  );
}

export default InputCustomCalendar;