import { useEffect, useState } from "react";
import { useStore } from "../../../../../../../mobx-store/context";
import { StyledSwitch } from "../../../FirstPage/style";
import { useHistory } from "react-router-dom";
import { SwitchTitle, SwitchText, Row, DetailsWrap, TableTitle, AllCheckboxContainer } from "../../style";
import { useForm } from "react-hook-form";
import Input from "../../../../../../../components/common/Input";
import { Divider, Checkbox } from "antd";
import Button from "../../../../../../../components/common/Button/button";
import { PrevBtn } from "../../../FirstPage/Form/style";
import CheckboxGroup from '../../CheckboxGroup'
import { api } from "../../../../../../../api";
import { formatCurrency } from "../../../../../helpers";
import EndDateConfirmModal from "../../../../../EditCompany/Tabs/Resource/GuideGroup/EndDateConfirmModal";
import moment from 'moment'

const ResourceTab = ({ handleTabChange }) => {
  const history = useHistory()
  const store = useStore()
  const { handleSubmit, control, getValues, watch, errors, setValue } = useForm({
    defaultValues: {
      resource_start_date: store?.cmsCompany[0]?.resource_start_date || null,
      resource_end_date: store?.cmsCompany[0]?.resource_end_date || null,
    }
  })

  const [hasAccesstoResource, setHasAccesstoResource] = useState(store?.cmsCompany[0]?.have_access_to_resource || false)
  const [hasCustomPrice, setHasCustomPrice] = useState(store?.cmsCompany[0]?.has_resource_custom_pricing || false)
  const [guidesCount, setGuidesCount] = useState(store?.cmsCompany[0]?.guides?.length || 0)
  const [jursCount, setJursCount] = useState(store?.cmsCompany[0]?.resource_jurisdictions?.length || 0)
  const [allJurisdictions, setAllJurisdictions] = useState(store?.cmsCompany[0]?.all_resource_jurisdictions)
  const [allGuides, setAllGuides] = useState(store?.cmsCompany[0]?.all_resource_guides)
  const [tempEndDate, setTempEndDate] = useState(null)
  const [endDateModalVisible, setEndDateModalVisible] = useState(false)
  const updateCompany = (values) => {
    const data = {
      ...values,
      resource_start_date: moment(values.resource_start_date).format('YYYY-MM-DD') || null,
      resource_end_date: moment(values.resource_end_date).format('YYYY-MM-DD') || null,
      have_access_to_resource: hasAccesstoResource,
      has_resource_custom_pricing: hasCustomPrice,
      resource_custom_price: hasCustomPrice && values.resource_custom_price
        ? values.resource_custom_price.replace(/[$,a-zA-Z]/g, '')
        : null,
      all_resource_jurisdictions: allJurisdictions,
      all_resource_guides: allGuides,
    }
    api(`/cms/companies/${store?.cmsCompany[0]?.id}/`, data, 'PATCH').then((res) => {
      if (!res.errors)
        store.setSingleValue('cmsCompany', [res])

    })
  }

  const onSubmit = (values) => {
    updateCompany(values)
    handleTabChange('report')
  }

  const watchValues = watch();

  useEffect(() => {
    setGuidesCount((getValues('guides') && getValues('guides').length) || 0)
    setJursCount((getValues('resource_jurisdictions') && getValues('resource_jurisdictions').length) || 0)
  }, [watchValues]);

  useEffect(() => {
    updateCompany(getValues())
  }, [watchValues])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {endDateModalVisible && <EndDateConfirmModal onClose={() => setEndDateModalVisible(false)} setValue={() => setValue('resource_end_date', tempEndDate)} />}
      <div style={{ display: 'flex', alignItems: 'center', marginTop: 25, marginBottom: 20 }}>
        <StyledSwitch
          checkedChildren="Yes"
          unCheckedChildren="No"
          onChange={(checked) => { setHasAccesstoResource(checked) }}
          defaultChecked={store?.cmsCompany[0]?.have_access_to_resource}
        />
        <div style={{ marginLeft: 10 }} >
          <SwitchTitle>Has Access to ReSource:</SwitchTitle>
          <SwitchText>Company will have access to ReSource, the date they can first log in.</SwitchText>
        </div>
      </div>
      {/* <Divider style={{ background: '#CCC' }} />
      <div style={{ display: 'flex', alignItems: 'center', marginTop: 10, marginBottom: 20 }}>
        <StyledSwitch
          checkedChildren="Yes"
          unCheckedChildren="No"
          onChange={(checked) => { setHasCustomPrice(checked) }}
          defaultChecked={store?.cmsCompany[0]?.has_resource_custom_pricing}
        />
        <div style={{ marginLeft: 10 }} >
          <SwitchTitle>Has Custom Pricing</SwitchTitle>
          <SwitchText>Show a custom price, if not selected no price will <br /> show in the admin’s settings page.</SwitchText>
        </div>
      </div >
      {hasCustomPrice &&
        <Input
          smallLabel
          label={'Custom Price'}
          disabled={!hasCustomPrice}
          name={'resource_custom_price'}
          defaultValue={formatCurrency(store?.cmsCompany[0]?.resource_custom_price) || ''}
          type={'text'}
          control={control}
          validation={{ required: true }}
          error={errors && errors.resource_custom_price && 'Enter valid custom price'}
          transparent
          placeholder={'Custom Price'}
          style={{ width: '48%' }}
        />
      } */}
      <Divider style={{ background: '#CCC' }} />
      <Row style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Input
          smallLabel
          showCross={false}
          name={'resource_start_date'}
          label={'Select Start Date'}
          type={'date'}
          control={control}
          validation={{ required: false }}
          transparent
          placeholder={'Select Start Date'}
          noLimit
        />
        <Input
          smallLabel
          name={'resource_end_date'}
          showCross={true}
          setValue={() => { }}
          handleChange={(e) => {
            setTempEndDate(e)
            setEndDateModalVisible(true)
          }}
          handleReset={() => setValue('resource_end_date', null)}
          label={'Select Group End Date'}
          type={'date'}
          control={control}
          validation={{ required: false }}
          transparent
          placeholder={'Select End Date'}
          noLimit
        />
      </Row >
      <Divider style={{ background: '#CCC' }} />
      <SwitchTitle>Select ReSource Guides To Provide Access To:</SwitchTitle>
      <DetailsWrap style={{ gridTemplateColumns: '1fr 1fr' }}>
        <TableTitle> Jurisdictions ({allJurisdictions ? 'All' : jursCount})</TableTitle>
        <TableTitle> Guides ({allGuides ? 'All' : guidesCount})</TableTitle>
      </DetailsWrap>
      <DetailsWrap style={{ gridTemplateColumns: '1fr 1fr' }}>
        <AllCheckboxContainer>
          <div> All Jurisdictions </div>
          <Checkbox
            onChange={(e) => setAllJurisdictions(e.target.checked)}
            name={'all_resource_jurisdictions'}
            style={{ marginLeft: 0, marginRight: 40 }}
            checked={allJurisdictions}
          >
          </Checkbox>
        </AllCheckboxContainer>
        <AllCheckboxContainer>
          <div> All Guides </div>
          <Checkbox
            onChange={(e) => setAllGuides(e.target.checked)}
            name={'all_topics'}
            style={{ marginLeft: 0, marginRight: 5 }}
            checked={allGuides}
          >
          </Checkbox>
        </AllCheckboxContainer>
      </DetailsWrap>
      {(!allJurisdictions || !allGuides) &&
        <DetailsWrap style={{ gridTemplateColumns: '1fr 1fr' }}>
          <CheckboxGroup
            defaultValue={store?.cmsCompany[0]?.resource_jurisdictions}
            control={control}
            name={'resource_jurisdictions'}
            disabled={allJurisdictions}
            options={store?.cms_options.jurisdictions} />
          <CheckboxGroup
            defaultValue={store?.cmsCompany[0]?.guides}
            control={control}
            name={'guides'}
            disabled={allGuides}
            options={store?.cms_options.guides} />
        </DetailsWrap>
      }
      <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
        <PrevBtn onClick={() => history.goBack()}>Previous</PrevBtn>
        <Button
          text={'Next'}
          htmlType="submit"
          type={'main'}
          style={{
            width: '10%',
            padding: 0,
            marginRight: 0,
            marginLeft: '.5em',
            marginTop: '2.3em',
          }}
        />
      </Row>
    </form>
  )
};

export default ResourceTab;