
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Divider } from "antd";
import { PageTitle, Row } from "../../CreateCompany/pages/ManageProducts/style";
import Input from "../../../../components/common/Input";
import MyEditor from "../../ReportEdit/ReportPage/CheckboxGroup/Editor";
import moment from 'moment'
import Document from "../../ComplianceEdit/document";

const Version = ({ edit, version, updateVersion, number }) => {
  const { control, getValues, watch, } = useForm({
    defaultValues: !edit ? {} : {
      name: version.reference_number,
      title: version.title,
      type: version.type,
      date: version.date,
      status: version.status,
    }
  })
  const watchDate = watch('date')

  console.log(version)

  useEffect(() => {
    if (watchDate) {
      handleInputChange('date', moment(watchDate).format('YYYY-MM-DD'))
    }
  }, [watchDate])

  const handleInputChange = (name, value) => {
    updateVersion(version.id, { [name]: value });
  };

  return (
    <>
      <form style={{ width: '50%' }}>
        <Row style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <PageTitle style={{ fontSize: 16 }}>Pending Bill Version: #{number}</PageTitle>
        </Row>
        <Row style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
          <Input
            smallLabel
            name={'name'}
            showCross={false}
            label={'Reference Number'}
            type={'text'}
            control={control}
            validation={{ required: false }}
            transparent
            placeholder={''}
            handleChange={e => handleInputChange('name', e.target.value)}
          />
        </Row >
        <Row style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
          <Input
            handleChange={e => handleInputChange('title', e.target.value)}
            smallLabel
            name={'title'}
            showCross={false}
            label={'Title'}
            type={'text'}
            control={control}
            validation={{ required: false }}
            transparent
            placeholder={''}
          />
          <Input
            handleChange={e => handleInputChange('type', e)}
            smallLabel
            name={'type'}
            showCross={false}
            label={'Type'}
            type={'select'}
            options={[
              { name: 'Bill', id: 'bill' },
              { name: 'Regulation', id: 'regulation' },
            ]}
            control={control}
            validation={{ required: false }}
            transparent
            placeholder={''}
            noLimit
          />
        </Row >
        <Row style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
          <Input
            type={'file'}
            name={'document'}
            label={'Upload Document'}
            control={control}
            styleRow={{ marginTop: 10 }}
            handleChange={e => handleInputChange('document', e.target.files[0])}
            showFile={true}
            multiple
            smallLabel
          />
        </Row >
        {version.file && <Document fileName={version.file} />}
        <Row style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
          <Input
            smallLabel
            name={'date'}
            showCross={false}
            label={'Date'}
            type={'date'}
            control={control}
            validation={{ required: false }}
            transparent
            placeholder={''}
            handleChange={e => handleInputChange('date', getValues('date'))}
          />
          <Input
            smallLabel
            name={'status'}
            showCross={false}
            label={'Status'}
            type={'text'}
            control={control}
            validation={{ required: false }}
            transparent
            placeholder={''}
            noLimit
            handleChange={e => handleInputChange('status', e.target.value)}
          />
        </Row >
        <Row>
          <MyEditor
            title={'Notes'}
            handleChange={(value) => handleInputChange('notes', value)}
            defaultValue={edit ? version.notes : ''} />
        </Row>
        <Divider style={{ background: '#CCC' }} />
      </form>
    </>
  )
};

export default Version;