
import moment from "moment";
import {
    Wrap,
    NotificationTitle,
    Text,
    TimeStamp,
    MenuItem
} from "./style";
import { EllipsisOutlined } from '@ant-design/icons';
import { Menu, Dropdown } from 'antd';
import NotificationsOff from '../../../assets/images/notifications_off.svg'
import DeleteBin from '../../../assets/images/delete_bin.svg'
import { api } from "../../../api";
import { message } from "antd";



const NotificationItem = ({ name, id, notification_type, text, created, item_url, clearNotifications, getNotifications, getNewNotifications }) => {

    const notifStatuses = {
        new_compliance_item: 'New Compliance Item',
        assigned_to_a_compliance_item: 'Compliance Item Assigned to You',
        compliance_item_status_changed: 'Compliance Item Status Updates',
        file_uploaded: 'Compliance Document Updates',
        file_removed: 'Compliance Document Removed',
        compliance_item_is_due_today: 'Compliance Item Is Due Today',
        compliance_item_is_past_due: 'Compliance Item Is Past Due Today',
        compliance_item_due_soon: 'Compliance Item Due Soon',
        task_completion: 'Completed Task',
        comment_mentions: 'New Comment on Compliance Item',
        updates_to_existing_compliance_items: 'Compliance Item Updated',
        assigned_new_task: 'Compliance Task Assigned to You',
        task_is_due_today: 'Compliance Task Is Due Today',
        task_is_past_due: 'Compliance Task Is Past Due Today',
        task_due_soon: 'Compliance Task Due Soon',
        new_report_item_in_tracking: 'New Report Item',
        assigned_to_a_report_item: 'Report Item Assigned to You',
        report_item_status_changed: 'Report Item Status Updated',
        report_file_uploaded: 'Report Document Uploaded',
        report_file_removed: 'Report Document Removed',
        report_task_completion: 'Completed Report Item Task',
        report_comments: 'New Comment on Report Item',
        new_report_task_assigned: 'Report Task Assigned to You',
        report_item_is_due_today: 'Report Item Is Due Today',
        report_item_are_past_due: 'Report Item Is Past Today',
        report_item_due_soon: 'Report Item Due Soon',
        report_task_are_past_due: 'Report Task Is Past Due',
        report_task_is_due_today: 'Report Task Is Due Today',
        report_task_due_soon: 'Report Item Due Soon',
        new_resource_guide: 'ReSource Guide Added to Your Tracking List',
        legislation_updates: 'Proposed Legislation Updates',
        new_pending_bill: 'Proposed Legislation Updates',
        mentions: 'Compliance Item',
        report_mentions: 'Report Item',
        report_comments_reply: 'New Report Comment Reply',
        comment_reply_site_notifications: 'New Compliance Comment Reply'
    }

    const turnOffFutureNotifications = () => {
        api('/notifications/turn_off_notification/', { notification_type: notification_type }, 'POST').then(() => {
            message.success('Notification turned off')
        })
    }

    const getTimeAgoString = (date_stamp) => {
        const currentDate = moment();
        const inputDate = moment(date_stamp, 'YYYY-MM-DD HH:mm:ss');

        const hoursDifference = currentDate.diff(inputDate, 'hours');
        const daysDifference = currentDate.diff(inputDate, 'days');
        const monthsDifference = currentDate.diff(inputDate, 'months');
        const yearsDifference = currentDate.diff(inputDate, 'years');

        if (yearsDifference === 1) {
            return '1 Year Ago';
        } else if (yearsDifference >= 2) {
            return `${yearsDifference} Years Ago`;
        } else if (monthsDifference === 1) {
            return '1 Month Ago';
        } else if (monthsDifference >= 2) {
            return `${monthsDifference} Months Ago`;
        } else if (daysDifference === 1) {
            return '1 Day Ago';
        } else if (daysDifference >= 2) {
            return `${daysDifference} Days Ago`;
        } else if (hoursDifference === 1) {
            return '1 Hour Ago';
        } else {
            return `${hoursDifference} Hours Ago`;
        }
    };

    const menu = (
        <Menu >
            <Menu.Item key="1">
                <MenuItem >
                    <div onClick={() => {
                        clearNotifications(false, id)
                        getNotifications()
                        getNewNotifications()
                    }}>
                        <img src={DeleteBin} alt={'#'} />
                        Delete Notification
                    </div>
                </MenuItem>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="2">
                <MenuItem>
                    <div onClick={() => {
                        turnOffFutureNotifications()
                    }}>
                        <img src={NotificationsOff} alt={'#'} />
                        Turn Off Future Notification Like This
                    </div>
                </MenuItem>
            </Menu.Item>
        </Menu>
    );

    const openNewPage = (url) => {
        window.open(url, '_blank')
    }

    function cutHTMLText(htmlText) {
        const maxLength = 90;
        if (htmlText === null) return ''
        if (htmlText.length <= maxLength) return htmlText;


        // Strip HTML tags using a temporary element
        const tempElement = document.createElement('div');
        tempElement.innerHTML = htmlText;
        let plainText = tempElement.textContent || tempElement.innerText || '';

        // Trim the text to the maximum length
        const trimmedText = plainText.trim().slice(0, maxLength);

        // Re-attach HTML tags
        tempElement.innerHTML = trimmedText;

        // Get the updated HTML content
        const truncatedHTML = tempElement.innerHTML;

        return truncatedHTML + '... ';
    }

    const htmlString = notification_type === 'new_resource_guide'
        || notification_type === 'assigned_to_a_compliance_item'
        || notification_type === 'task_is_due_today'
        || notification_type === 'task_is_past_due'
        || notification_type === 'task_due_soon'
        || notification_type === 'compliance_item_is_due_today'
        || notification_type === 'compliance_item_is_past_due'
        || notification_type === 'compliance_item_due_soon'
        || notification_type === 'new_compliance_item'
        || notification_type === 'assigned_new_task'
        || notification_type === 'new_report_task_assigned'
        || notification_type === 'comment_mentions'
        || notification_type === 'report_comments'
        || notification_type === 'report_task_is_due_today'
        || notification_type === 'report_task_due_soon'
        || notification_type === 'updates_to_existing_compliance_items'
        || notification_type === 'task_completion'
        || notification_type === 'report_task_completion'
        || notification_type === 'report_task_are_past_due'
        || notification_type === 'report_comments_reply'
        || notification_type === 'comment_reply_site_notifications'
        ? `${cutHTMLText(text)} <a href="${item_url}">${'View Details'}</a>`
        : `${text === null ? '' : text} <a href="${item_url}">${'View Details'}</a>`

    return (
        <Wrap>
            <div>
                <NotificationTitle>
                    <strong>
                        {notifStatuses[notification_type]}
                    </strong>: {name}
                </NotificationTitle>
                {notification_type === 'new_resource_guide' ?
                    <Text>
                        <div onClick={() => openNewPage(item_url)}>View Details</div>
                    </Text>
                    : <Text dangerouslySetInnerHTML={{
                        __html: htmlString,
                    }} />}

            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                <TimeStamp>
                    {getTimeAgoString(created)}
                </TimeStamp>

                <Dropdown overlay={menu} overlayStyle={{ zIndex: 9999999 }} trigger={['click']} >
                    <EllipsisOutlined style={{ fontSize: 48 }} />
                </Dropdown>

            </div>
        </Wrap>
    );
};

export default NotificationItem;