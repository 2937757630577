export const tabs = [
  {
    key: 'main',
    title: 'Tracking',
    disabledFor: ['member'],
  },
  { key: 'monitor', title: 'Monitor', disabledFor: ['member'] },
  { key: 'closed', title: 'Closed', disabledFor: ['member'] },
  { key: 'all', title: 'All', disabledFor: ['member'] },
  { key: 'myTasks', title: 'My Tasks' },
]

export const liteTabs = [
  {
    key: 'main',
    title: 'Tracking',
    disabledFor: ['member'],
  },
  { key: 'closed', title: 'Archive' },
]
export const reportTabs = [
  {
    key: 'main',
    title: 'General',
    disabledFor: ['member'],
  },
  { key: 'apcd', title: 'APCD', disabledFor: ['member'] },
  { key: 'closed', title: 'Closed', disabledFor: ['member'] },
  { key: 'all', title: 'All', disabledFor: ['member'] },
  { key: 'myTasks', title: 'My Tasks' },
  { key: 'calendar', title: 'Calendar', disabledFor: ['member'] },
]

export const reportLiteTabs = [
  {
    key: 'main',
    title: 'General',
    disabledFor: ['member'],
  },
  { key: 'apcd', title: 'APCD', disabledFor: ['member'] },
  { key: 'all', title: 'All', disabledFor: ['member'] },
  { key: 'closed', title: 'Archive' },
  { key: 'calendar', title: 'Calendar', disabledFor: ['member'] },
]