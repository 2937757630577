import { Select } from 'antd'
import React from 'react'
import { checkRole, getDate } from '../../../helpers/helpers'
import { statuses } from '../Table/table'

export const infoInputs = (store, data) => {
  return [
    {
      key: 0,
      label: 'Compliance Owner',
      name: 'assignee',
      placeholder: 'Unassigned',
      role: ['admin', 'compliance_owner', 'owner'],
      value: store.getMember(data?.assignee),
      customOptions: store.members
        .filter((item) => {
          return (
            (item.is_active || item.id === data?.assignee) &&
            item.have_access_to_tracking &&
            checkRole(
              [item.member_type],
              ['admin', 'compliance_owner', 'owner']
            )
          )
        })
        .map((item) => (
          <Select.Option value={item.id} key={item.id}>
            {item.first_name + ' ' + item.last_name}
          </Select.Option>
        )),
    },
    {
      key: 1,
      label: 'Current Status',
      name: 'status',
      placeholder: 'Placeholder',
      role: ['admin', 'compliance_owner', 'owner'],
      value: statuses[data.status]?.[0],
      options: store.statuses,
      selectVars: { value: 0, name: 1 },
    },
    {
      key: 2,
      label: 'Internal Due Date ',
      name: 'primary_due_date',
      placeholder: 'Placeholder',
      role: ['admin', 'compliance_owner', 'owner'],
      type: 'date',
      value: getDate(data.primary_due_date, '-'),
    },
  ]
}

export const reportInfoInputs = (store, data) => {
  return [
    {
      key: 0,
      label: 'Report Owner',
      name: 'assignee',
      placeholder: 'Unassigned',
      role: ['admin', 'compliance_owner', 'owner', 'report_owner'],
      value: store.getMember(data?.assignee),
      customOptions: store.members
        .filter((item) => {
          return (
            (item.is_active || item.id === data?.assignee) &&
            item.have_access_to_report_source &&
            checkRole(
              [item.report_source_role],
              ['admin', 'report_owner', 'owner']
            )
          )
        })
        .map((item) => (
          <Select.Option value={item.id} key={item.id}>
            {item.first_name + ' ' + item.last_name}
          </Select.Option>
        )),
    },
    {
      key: 1,
      label: 'Current Status',
      name: 'status',
      placeholder: 'Placeholder',
      role: ['admin', 'compliance_owner', 'owner', 'report_owner'],
      value: statuses[data.status]?.[0],
      options: store.report_source_statuses?.filter((item) => item[0] !== 'monitor'),
      selectVars: { value: 0, name: 1 },
    },
    {
      key: 2,
      label: 'Internal Due Date ',
      name: 'report_due_date',
      placeholder: 'Placeholder',
      role: ['admin', 'compliance_owner', 'owner', 'report_owner'],
      type: 'date',
      value: getDate(data?.report?.report_due_date, '-'),
    },
  ]
}
