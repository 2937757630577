import React from 'react'
import { data } from './data'
import { Description, Grid, Title, Wrap } from './style'
import { Tool } from './Tool'
import ProposedSourceTool from './ProposedSourceTool'
import resource_icon from '../../../../assets/images/resource_icon.png'
import proposed_icon from '../../../../assets/images/proposed_icon_new.png'
import report_icon from '../../../../assets/images/report_icon.png'
import compliance_icon from '../../../../assets/images/compliance_icon.png'

const Tools = () => {

  const services = [
    {
      key: 0,
      name: 'Compliance',
      subtitle: 'New Laws',
      backgroundColor: '#ECF0FB',
      color: 'text',
      description: 'Identifies new laws, regulations, and guidance when adopted.',
      image: compliance_icon,
      link: '/home-compliance',
      signUpText: 'Reach Out To Sales',
    },
    {
      key: 1,
      name: 'Re',
      subtitle: 'Existing Law Guides',
      backgroundColor: '#F2FDF5',
      color: 'text',
      description: 'Identifies existing laws and regulations.',
      image: resource_icon,
      link: '/home-resource',
      signUpText: 'Sign Up',
    },
    {
      key: 2,
      name: 'Report',
      subtitle: 'All PBM required reporting',
      backgroundColor: '#FBF6EC',
      color: 'text',
      description: 'Identifies regulatory reporting requirements and deadlines for PBMs.',
      image: report_icon,
      link: '/home-report-source',
      signUpText: 'Reach Out To Sales',
    },
    {
      key: 3,
      name: 'Proposed',
      subtitle: 'Proposed Legislation',
      backgroundColor: '#F3F3F3',
      color: 'text',
      description: 'Proposed bills and regulations.',
      image: proposed_icon,
      link: '/home-proposed-source',
      signUpText: 'Reach Out To Sales',
    },
  ]
  return (
    <Wrap>
      <Title>Tools to save time and money on regulatory compliance. </Title>
      <Description>
        Take advantage of business opportunities knowing what applies and, just
        as importantly, what doesn't apply. Avoid customer dissatisfaction and
        regulatory enforcement actions.{' '}
      </Description>
      <Grid>
        {services.map((item) => (
          <Tool key={item.key}
            number={item.key}
            name={item.name}
            description={item.description}
            subtitle={item.subtitle}
            image={item.image}
            backgroundColor={item.backgroundColor}
            color={item.color}
            link={item.link}
            disabled={item.disabled}
            signUpText={item.signUpText} />
        ))}
      </Grid>
    </Wrap>
  )
}

export default Tools
