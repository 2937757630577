import { Select } from 'antd'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { api } from '../../../../../../api'
import closeIcon from '../../../../../../assets/images/close-grey.svg'
import Button from '../../../../../../components/common/Button/button'
import Input from '../../../../../../components/common/Input'
import { Label } from '../../../../../../components/common/Input/input.style'
import { checkRole, getError } from '../../../../../../helpers/helpers'
import { useStore } from '../../../../../../mobx-store/context'
import { Text } from '../../../../../Tracking/Right/right.style'
import {
  Cancel,
  Overlay,
  Wrap,
} from '../../../../../Tracking/Right/Tasks/Modal/style'

const modalContent = ({
  store,
  handleSubmit,
  onSubmit,
  onClose,
  legislatives_assigned,
  tasks_assigned,
  report_source_tasks_assigned,
  reports_assigned,
  deactivate,
  params,
  activate,
  button,
  deleting,
}) => {
  const selectItem = (item) => (
    <Select.Option value={item.id} key={item.id}>
      {item.first_name + ' ' + item.last_name}
    </Select.Option>
  )

  const members = store.members
    .filter(
      (item) =>
        (params.type === 'reportSource' ? item.have_access_to_report_source : item.have_access_to_tracking)
        && item.id !== +params.id && item.is_active
    )
    .map((item) => selectItem(item))

  const membersAdmins = store.members
    .filter(
      (item) =>
        (params.type === 'reportSource' ? item.have_access_to_report_source : item.have_access_to_tracking) &&
        item.id !== +params.id &&
        item.is_active &&
        !checkRole([item.member_type], ['member'])
    )
    .map((item) => selectItem(item))
  return {
    at_least_one_admin: {
      title: 'There Must Be At Least 1 Admin',
      description:
        'In order to be delete this user another admin must be added first. There must be at least one admin per subscription.',
      button: 'I Understand',
      onClick: onClose,
    },
    are_you_sure: {
      title: 'Are You Sure You Want to Deactivate User?',
      description:
        'Deactivating a user will deactivate their account but the activity log of this user will remain. ',
      onClick: deactivate,
      button: button || 'Deactivate',
      name: true,
    },
    items_assigned: {
      title: 'This user has items assigned to them.',
      name: true,
      description: `In order to ${deleting ? 'be deactivate' : 'change role for'
        } this user, another user must be assigned their tasks`,
      selects: [
        {
          key: 0,
          label: params.type === 'reportSource' ? 'Select Report Owner' : 'Select Compliance Owner',
          customOptions: membersAdmins,
          name: 'complianceOwner',
          hidden: params.type === 'reportSource'
            ? !reports_assigned
            : !legislatives_assigned,
        },
        {
          key: 1,
          label: 'Select Task Owner',
          customOptions: members,
          name: 'taskOwner',
          hidden: params.type === 'reportSource'
            ? !report_source_tasks_assigned
            : !tasks_assigned,
        },
      ],
      button: button || 'Deactivate',
      onClick: handleSubmit(onSubmit),
    },
    reactivate: {
      title: 'Are You Sure You Want to Reactivate User?',
      name: 'true',
      button: "Yes, I'm Sure",
      onClick: activate,
    },
  }
}

const Modal = ({
  error,
  onClose,
  deactivate,
  activate,
  deleting,
  onEdit,
  isRoleChanging,
}) => {
  const store = useStore()
  const params = useParams()
  const { control, errors, handleSubmit } = useForm()

  const assignUser = (values, error) => {
    const getBody = () => {
      const { tasks_assigned, legislatives_assigned, report_source_tasks_assigned, reports_assigned } = error;
      const { type } = params;
      const { complianceOwner, taskOwner } = values;

      if (store.userDeactivation === true) {
        if (report_source_tasks_assigned && reports_assigned && tasks_assigned && legislatives_assigned) {
          return {
            report_assignee: complianceOwner,
            report_source_task_assignee: taskOwner, 
            legislative_assignee: complianceOwner, 
            task_assignee: taskOwner
          }
        }
      }

      if (type === 'reportSource') {
        if (report_source_tasks_assigned && reports_assigned) {
          return { report_assignee: complianceOwner, report_source_task_assignee: taskOwner }
        }

        if (report_source_tasks_assigned) {
          return { report_source_task_assignee: taskOwner }

        }
        return { report_assignee: complianceOwner }

      }
      if (tasks_assigned && legislatives_assigned) {
        return { legislative_assignee: complianceOwner, task_assignee: taskOwner };
      }

      if (tasks_assigned) {
        return { task_assignee: taskOwner };
      }

      return { legislative_assignee: complianceOwner };
    };

    api(
      `/members/${params.id}/reassign_legislatives_and_tasks/`,
      getBody(),
      'POST'
    ).then((data) => {
      if (!data.errors) {
        onClose()
        deactivate && deleting && deactivate()
        !deleting && onEdit()
      } else {
        getError(data)
      }
    })
  }

  const onSubmit = (values) => {
    assignUser(values, error)
  }

  const content = modalContent({
    store,
    handleSubmit,
    onSubmit,
    onClose,
    legislatives_assigned: error.legislatives_assigned,
    tasks_assigned: error.tasks_assigned,
    report_source_tasks_assigned: error.report_source_tasks_assigned,
    reports_assigned: error.reports_assigned,
    deactivate,
    params,
    activate,
    button: error.button,
    deleting,
  })[error.code]

  return (
    <>
      <Overlay onClick={onClose} />
      <Wrap id={'modal'}>
        <img src={closeIcon} onClick={onClose} alt='close-icon' id='close' />
        <Label style={{ fontSize: 24 }}>{content.title}</Label>
        <Text style={{ margin: '15px 0', fontSize: 18 }}>
          {content.name && (
            <div
              style={{
                fontFamily: 'var(--medium)',
                fontSize: 16,
                marginBottom: 15,
              }}
            >
              {store.getMember(+params.id)}{' '}
              {+params.id === store.user?.[0]?.id && '(Yourself)'}
            </div>
          )}
          {content.description}
        </Text>
        {content.selects &&
          content.selects.map(
            (item) =>
              !item.hidden && (
                <Input
                  key={item.key}
                  control={control}
                  name={item.name}
                  type={'select'}
                  customOptions={item.customOptions}
                  label={item.label}
                  validation={{ required: true }}
                  styleRow={{ marginBottom: 15 }}
                  error={
                    errors && errors[item.name] && 'This field is required'
                  }
                  popupContainer={'modal'}
                  getPopupContainer={true}
                  showSearch
                  smallLabel
                />
              )
          )}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {error.code === 'reactivate' && (
            <Cancel onClick={onClose}>Cancel</Cancel>
          )}
          <Button
            text={content.button}
            style={{ marginLeft: 'auto' }}
            onClick={() => content.onClick()}
          />
        </div>
      </Wrap>
    </>
  )
}

export default Modal
