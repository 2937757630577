
import { Tag } from 'antd';

function TagList({ tags, onTagClose }) {

  const tagsMap = {
    have_access_to_tracking: 'ComplianceSource',
    has_access_to_compliance_source: 'ComplianceSource',
    have_access_to_proposed_source: 'ProposedSource',
    has_access_to_proposed_source: 'ProposedSource',
    have_access_to_resource: 'ReSource',
    has_access_to_resource: 'ReSource',
    have_access_to_report_source: 'ReportSource',
    has_access_to_report_source: 'ReportSource',
    normal_access: 'Normal Access',
    admin_access: 'Admin Access',
    staff_access: 'Staff Access',
    only_admins: 'User Admin',
    only_compliance_owners: 'Compliance Owner',
    only_report_owners: 'Report Owner',
    only_active: 'Active Account',
    only_inactive: 'Inactive Account',
    only_locked: 'Locked Account',
    terms_and_conditions: 'Has Accepted Terms & Conditions',
    is_on_trial: 'On Trial',
    is_trial_is_over: 'Trial is Over',
    is_testing_account: 'Testing Account',
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div style={{ color: 'var(--text)', fontFamily: 'var(--regular)', fontSize: 12, marginRight: 5 }}>Filtering by: </div>
      {tags.map((tag) => (
        <Tag
          key={tag}
          closable
          bordered={true}
          onClose={() => onTagClose(tag)}
        >
          {tagsMap[tag]}
        </Tag>
      ))}
    </div>
  );
}

export default TagList;