import { SubTitle, CompanyDescription } from '../CreateCompany/pages/FirstPage/style'
import { useState, useEffect } from 'react';
import { NawPageWrap, } from '../CreateCompany/pages/ManageProducts/style';
import ComplianceTab from './Tabs/Compliance'
import ProposedTab from './Tabs/Proposed'
import ResourceTab from './Tabs/Resource'
import ReportTab from './Tabs/Report'
import UsersTab from './Tabs/Users';
import CompanyTab from './Tabs/CompanyTab';
import Tabs from '../../../components/common/Tabs/tabs'
import { useStore } from '../../../mobx-store/context';
import { api } from '../../../api';
import FullScreenLoader from '../../../components/common/FullScreenLoader';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Wraper from '../CMSContainer';
import PricingTab from './Tabs/PricingTab';
import ButtonOrange from '../../../components/common/ButtonOrange';

const EditingCompany = () => {
  const [loading, setLoading] = useState(true)
  const [activeTab, setActiveTab] = useState("company");
  const store = useStore()
  const params = useParams()
  const history = useHistory()

  const getCmsOptions = (id) => {
    setLoading(true)
    api(`/cms/dictionaries_data/all/`, {}, 'GET').then((data) => {
      if (!data.errors) {
        store.setSingleValue('cms_options', data)
      }
    }).then(() => {
      getCompanyInfo(id)
    })
  }

  const getCompanyInfo = (id) => {
    api(`/cms/companies/${id}/`, {}, 'GET').then((data) => {
      store.setSingleValue('cmsCompany', [data])
      localStorage.setItem('editedCmsCompanyId', data.id)
      setLoading(false)
    })
  }
  useEffect(() => {
    getCmsOptions(params.id)
  }, [])

  const showTabs = (obj) => {
    return {
      company: {
        render: () => <CompanyTab handleTabChange={obj.handleTabChange} loading={obj.loading} active={obj.activeTab} companyId={obj.id} />,
      },
      compliance: {
        render: () => <ComplianceTab handleTabChange={obj.handleTabChange} loading={obj.loading} active={obj.activeTab} companyId={obj.id} />,
      },
      proposed: {
        render: () => <ProposedTab handleTabChange={obj.handleTabChange} active={obj.activeTab} companyId={obj.id} />,
      },
      resource: {
        render: () => <ResourceTab handleTabChange={obj.handleTabChange} active={obj.activeTab} companyId={obj.id} />,
      },
      report: {
        render: () => <ReportTab handleTabChange={obj.handleTabChange} active={obj.activeTab} companyId={obj.id} />,
      },
      users: {
        render: () => <UsersTab handleTabChange={obj.handleTabChange} active={obj.activeTab} companyId={obj.id} allUsersPage={'companyUsers'} />,
      },
      pricing: {
        render: () => <PricingTab handleTabChange={obj.handleTabChange} active={obj.activeTab} companyId={obj.id} />
      },
    }
  }
  const handleTabChange = (key) => {
    setActiveTab(key);
  };
  return (
    <Wraper>
      <NawPageWrap style={{ width: 910 }}>
        {loading ? <FullScreenLoader />
          : <>
            <SubTitle style={{ fontSize: 24 }}>Edit {store?.cmsCompany[0]?.name}</SubTitle>
            <CompanyDescription style={{ marginBottom: 10 }}>
              <div>Company Name - {store?.cmsCompany[0]?.name || 'N/A'}</div>
              <div>{store?.cmsCompany[0]?.address1}</div>
            </CompanyDescription>
            <ButtonOrange
              text={'< Go Back'}
              onClick={() => history.goBack()}
              style={{ fontFamily: 'var(--bold)', color: '#4E74A9', fontSize: 12, textDecoration: 'none', marginBottom: 10 }}
            />
            <SubTitle>Manage:</SubTitle>
            <>
              <Tabs
                Reporting={false}
                tabs={[
                  {
                    key: 'company',
                    title: 'Company',
                  },
                  {
                    key: 'pricing',
                    title: 'Pricing',
                  },
                  {
                    key: 'users',
                    title: 'Users',
                  },
                  {
                    key: 'compliance',
                    title: 'ComplianceSource',
                  },
                  {
                    key: 'proposed',
                    title: 'ProposedSource',
                  },
                  {
                    key: 'resource',
                    title: 'ReSource',
                  },
                  {
                    key: 'report',
                    title: 'ReportSource',
                  },


                ]}
                onClick={(key) => {
                  setActiveTab(key)
                }}
                active={activeTab}
              />

              {showTabs({
                handleTabChange,
                loading,
                activeTab,
                id: params.id,
              })[activeTab].render()
              }
            </>
          </>
        }
      </NawPageWrap>
    </Wraper>
  );
};

export default EditingCompany;