import React, { useState } from 'react'
import { DocumentRight, DocumentWrap } from './right.style'
import download from '../../../assets/images/download.svg'
import deleteIcon from '../../../assets/images/delete.svg'
import Validation from '../../../components/common/Validation/validation'
import Button from '../../../components/common/Button/button'
import { useStore } from '../../../mobx-store/context'
import { observer } from 'mobx-react-lite'
import { toJS } from 'mobx'
import { api, downloadDoc } from '../../../api'
import { Dropdown, Menu } from 'antd'


const Document = observer(({ item, index, onDeleted, legislative, isReportSource, reportId, groupStatus }) => {
  const store = useStore()
  const [show, setShow] = useState(false)
  const file = item?.file_name?.split('/')[item?.file_name.split('/').length - 1]
  const docId = item?.id

  const changeDocumentStatus = (id, status) => {
    const url = `/report_company/${store?.currentLegislative?.id}/files/${id}/change_status/`
    const obj = { status: status }
    api(url, obj, 'PUT').then((data) => {
      store.loadDocuments(legislative, '1', isReportSource, groupStatus)
    })
  }

  const docStatusList = [
    {
      key: 1,
      value: 'working',
      label: 'Working',
    },
    {
      key: 2,
      value: 'finalized',
      label: 'Finalized',
    },
    {
      key: 3,
      value: 'submitted',
      label: 'Submitted',
    },
    {
      key: 3,
      value: 'deficiency',
      label: 'Deficiency',
    },
    {
      key: 4,
      value: 'other',
      label: 'Other',
    },
  ]

  const filteredDocStatusList = docStatusList.filter((item) => item.value !== groupStatus)


  const documentStatusMenu = (
    <>
      <Menu>
        {
          filteredDocStatusList.map((item) => (
            <Menu.Item key={item.key} onClick={() =>
              changeDocumentStatus(docId, item.value)
            }>
              {item.label}
            </Menu.Item>
          ))
        }
      </Menu>
    </>
  )

  return (
    <>
      <DocumentWrap grey={index % 2 === 0}>
        <div>
          {file}
          {item.task &&
            <div style={{
              fontFamily: 'var(--regular)',
              fontSize: 14,
              color: '',
            }}>
              Task - {isReportSource ? item.task_description : item.task.description}
            </div>
          }
        </div>
        <DocumentRight>
          {isReportSource &&
            <Dropdown placement="bottomRight" overlay={documentStatusMenu}>
              <div>Move ▼
              </div>
            </Dropdown>
          }
          <a
            onClick={() => downloadDoc(item?.id, file, isReportSource, store?.currentLegislative?.id, store?.currentLegislative?.report?.id)}
            target={'_blank'}
            download>
            Download
          </a>
          <div onClick={() => setShow(true)}>
            Delete
          </div>
        </DocumentRight>
      </DocumentWrap >
      {show && (
        <Validation
          text={
            <div style={{ display: 'flex' }}>
              <Button
                text={'Cancel'}
                onClick={() => setShow(false)}
                style={{ marginRight: '.5em' }}
              />
              <Button
                text={'Delete'}
                onClick={() => {
                  isReportSource
                    ? store.deleteReportDocument(item?.report_company, item?.id, () => {
                      onDeleted(item.file_name)
                      store.loadActivity(legislative, 1, null, isReportSource, reportId)
                    })
                    : store.deleteDocument(item?.id, () => {
                      onDeleted(item.file_name)
                      store.loadActivity(legislative, 1, null, isReportSource, reportId)
                    })
                  setShow(false)
                }}
                style={{ marginLeft: '.5em' }}
                border
              />
            </div>
          }
          upperText={
            <>
              Are You sure that you want to delete{' '}
              <span style={{ color: 'var(--blue)' }}>{file}</span>?
              This cannot be undone.
            </>
          }
          noLineThrough
        />
      )
      }
    </>
  )
})

export default Document
