
import Modal from "../../../../components/common/ModalNew"
import { Text, Title, GoBackButton } from "../RemoveuserModal/style";
import Button from "../../../../components/common/Button/button";



const TempPassModal = ({ onClose, onSubmit, openManualModal }) => {

  return (
    <Modal
      styleWrap={{ width: 'auto', height: 'auto', padding: 30, maxWidth: 580 }}
      onClose={onClose}>
      <Title>
        Send a New Temporary Password?
      </Title>
      <Text>
        This will send an email with a new temporary password (randomly generated), which they will use to set a new permanent one.<br /> If they are not getting the email,<div onClick={() => { openManualModal() }}>manually change the password here.</div>
      </Text>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '1.5em' }}>
        <GoBackButton onClick={() => onClose()}>
          GoBack
        </GoBackButton>
        <Button
          text={'Send Temporary Password'}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            onSubmit()
          }}

          type={'main'}
          style={{
            padding: 10,
            marginRight: 0,
          }}
        />
      </div>
    </Modal>
  )
}

export default TempPassModal