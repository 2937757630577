import { observer } from 'mobx-react-lite'
import { TableStyled } from '../../../../../components/common/style'
import { useStore } from '../../../../../mobx-store/context'
import { useEffect, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons'
import { toJS } from 'mobx';
import { TableHeaderTitle } from '../../../../Tracking/Table/table.style';
import { getDescription } from '../../../../../helpers/helpers';
import deleteIcon from '../../../../../assets/images/delete-icon.png';
import { api } from '../../../../../api';

const Table = observer(({ data, setData, serviceName, reset }) => {
    const [loading, setLoading] = useState(false)
    const [currentId, setCurrentId] = useState(null)
    const store = useStore()

    const onDeleteItem = (id) => {
        setLoading(true)
        setCurrentId(id)
        api(`/members/${id}/delete_from_auto_assign/`, { service_name: serviceName }, 'DELETE').then(() => {
            api(`/companies/auto_assign_members/?filter_for=${serviceName}`, {}, 'GET').then((data) => {
                setData(data)
                store.setSingleValue('autoAssignMembers', data)
            })
            api('/companies/auto_assign_lists/', {}, 'GET').then((data) => {
                if (!data.errors) {
                    store.setSingleValue('autoAssignLists', data)
                }
            })
        }).then(() => {
            serviceName === 'compliance_source'
                ? reset({
                    compliance_jurisdictions_auto_assign: '',
                    compliance_topics_auto_assign: '',
                })
                : reset({
                    report_source_jurisdictions_auto_assign: '',
                    report_source_topics_auto_assign: '',
                })
        }).then(() => {
            setLoading(false)
            setCurrentId(null)
        })
    }

    useEffect(() => {
        setData(store?.autoAssignMembers)
    }, [store?.autoAssignMembers])

    const trackingColumns = [
        {
            title: <TableHeaderTitle>Name</TableHeaderTitle>,
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => record.first_name + ' ' + record.last_name,
        },
        {
            title: <TableHeaderTitle>Jurisdictions</TableHeaderTitle>,
            dataIndex: 'jurisdiction',
            key: 'jurisdiction',
            render: (text, record) => <div dangerouslySetInnerHTML={{
                __html:
                    getDescription(store.jurs, record.compliance_jurisdictions_auto_assign, 'name')
                        .split(',')
                        .join(` <br/> `)
                    || 'Not Specified'
            }} />,
        },
        {
            title: <TableHeaderTitle>Topics</TableHeaderTitle>,
            dataIndex: 'topics',
            key: 'topics',
            render: (text, record) => <div dangerouslySetInnerHTML={{
                __html:
                    getDescription(store.topics, record.compliance_topics_auto_assign, 'name')
                        .split(',')
                        .join(` <br/> `)
                    || 'Not Specified'
            }} />
        },
        {
            dataIndex: 'delete',
            key: 'delete',
            align: 'center',
            render: (text, record) => {
                return (
                    loading && record.id === currentId ? <LoadingOutlined spin />
                        : <div onClick={() => onDeleteItem(record.id)}>
                            <img src={deleteIcon} alt='delete-icon' />
                        </div>

                )
            },
        },
    ]

    const reportColumns = [
        {
            title: <TableHeaderTitle>Name</TableHeaderTitle>,
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => record.first_name + ' ' + record.last_name,
        },
        {
            title: <TableHeaderTitle>Jurisdictions</TableHeaderTitle>,
            dataIndex: 'jurisdiction',
            key: 'jurisdiction',
            render: (text, record) => <div dangerouslySetInnerHTML={{
                __html:
                    getDescription(store.report_source_jurisdictions, record.report_source_jurisdictions_auto_assign, 'name')
                        .split(',')
                        .join(` <br/> `)
                    || 'Not Specified'
            }} />,
        },
        {
            title: <TableHeaderTitle>Types</TableHeaderTitle>,
            dataIndex: 'topics',
            key: 'topics',
            render: (text, record) => <div dangerouslySetInnerHTML={{
                __html:
                    getDescription(store.report_source_report_types, record.report_source_report_types_auto_assign, 'name')
                        .split(',')
                        .join(` <br/> `)
                    || 'Not Specified'
            }} />
        },
        {
            dataIndex: 'delete',
            key: 'delete',
            align: 'center',
            render: (text, record) => {
                return (
                    loading && record.id === currentId ? <LoadingOutlined spin />
                        : <div onClick={() => onDeleteItem(record.id)}>
                            <img src={deleteIcon} alt='delete-icon' />
                        </div>

                )
            },
        },
    ]

    return (
        <TableStyled
            columns={serviceName === 'compliance_source' ? trackingColumns : reportColumns}
            dataSource={toJS(data)}
            size='small'
            style={{ marginTop: 20 }}
            pagination={{
                hideOnSinglePage: true,
                pageSize: 25,
                position: ['bottomLeft'],
            }}
        />
    )
});

export default Table