import moment from 'moment'
import React, { useEffect, useState } from 'react'
import Calendar from 'react-calendar'
import Moment from 'react-moment'
import 'moment-timezone'
import close from '../../../assets/images/close-grey.svg'
import { CalendarWrap } from '../../../pages/Auth/StartDate/startDate.style'
import Button from '../Button/button'
import { ErrorText, Label } from '../Input/input.style'
import { Block, Overlay, Wrap } from './style'
import './style.css'

const CalendarCustom = ({
  incomeValue = null,
  handleChange,
  disabled,
  required,
  error,
  styleDropdown,
  placeholder,
  label,
  handleOpen,
  redCircleDate,
  noLabel,
  noBorder,
  noLimit,
}) => {
  const [visible, setVisible] = useState()
  const [value, setValue] = useState(null)

  const newValue = value ?
    new Date(moment(value).add(7, 'hours'))
    : new Date()

  const onClose = (isClosedWithoutFetch) => {
    if (isClosedWithoutFetch) setValue(null)
    setVisible(false)
  }

  useEffect(() => {
    if (incomeValue) {
      setValue(new Date(incomeValue))
    } else {
      setValue(null)
    }
  }, [incomeValue])


  return (
    <>
      {visible && <Overlay onClick={() => onClose(true)} />}
      <div
        style={{
          position: 'relative',
          width: '100%',
          opacity: disabled && '.5',
        }}
      >
        {!noLabel && (
          <Label smallLabel required={required}>
            {label || 'Due Date'}
            <span>*</span>
          </Label>
        )}

        <Block
          disabled={disabled}
          error={error}
          noBorder={noBorder}
          onClick={(e) => {
            if (disabled) return null
            if (
              !e.target
                .closest('span')
                ?.classList.contains('anticon-close-circle')
            ) {
              setVisible(!visible)
              handleOpen && handleOpen(true)
            }
          }}
        >
          {value ? (
            <Moment format={'MM/DD/YYYY'} add={{ hours: 7 }}>{value}</Moment>
          ) : incomeValue ? (
            <Moment format={'MM/DD/YYYY'} add={{ hours: 7 }}>{incomeValue}</Moment>
          ) : (
            <div
              style={{
                color: 'var(--text)',
                fontSize: '16px',
                fontFamily: 'var(--regular)',
                opacity: 0.6,
              }}
            >
              {placeholder || null}
            </div>
          )}
        </Block>
        {error && <ErrorText>{error}</ErrorText>}

        <Wrap visible={visible} style={styleDropdown}>
          <img src={close} alt='close-icon' onClick={() => onClose(true)} />
          <CalendarWrap noLightFill>
            <Calendar
              calendarType={'US'}
              locale={'en-EN'}
              onChange={(value) => {
                handleChange(value)
                onClose()
              }}
              value={newValue}
              minDate={noLimit ? null : new Date()}
              tileClassName={({ date }) => {
                if (redCircleDate === moment(date).format('MM/DD/YYYY'))
                  return 'red-circle'
              }}
            />
          </CalendarWrap>
        </Wrap>
      </div>
    </>
  )
}

CalendarCustom.propTypes = {}

export default CalendarCustom
