import { Select, Radio } from 'antd'
import moment from 'moment'
import React, { useEffect, useState, useRef } from 'react'
import { Controller } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'
import closeIcon from '../../../../../assets/images/close-grey.svg'
import deleteFileIcon from '../../../../../assets/images/delete-orange.svg'
import Button from '../../../../../components/common/Button/button'
import ButtonOrange from '../../../../../components/common/ButtonOrange'
import CalendarCustom from '../../../../../components/common/Calendar'
import Input from '../../../../../components/common/Input'
import { checkRole } from '../../../../../helpers/helpers'
import { useStore } from '../../../../../mobx-store/context'
import { Cancel, Overlay, Row, Title, Wrap, Subtitle } from './style'
import Draggable from "react-draggable";
import { api } from '../../../../../api'
import { is } from 'immutable'

const Modal = ({
  data,
  control,
  loadingDueDate,
  onClose,
  onSubmit,
  errors,
  complete,
  assigneNew,
  taskID,
  tasks,
  loadingFile,
  complianceItem,
  redCircleDate,
  setTemporaryFiles,
  temporaryFiles,
  loading,
  activeTab,
  isReportSource = false,
  getConsiderationData,
  receiveGetReadyData,
}) => {
  const history = useHistory()
  const params = useParams()
  const store = useStore()
  const [taskFiles, setFiles] = useState()
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [docStatus, setDocStatus] = useState('working')
  const docStatusList = [
    {
      key: 0,
      value: 'working',
      label: 'Working',
    },
    {
      key: 1,
      value: 'finalized',
      label: 'Finalized',
    },
    {
      key: 2,
      value: 'submitted',
      label: 'Submitted',
    },
    {
      key: 3,
      value: 'deficiency',
      label: 'Deficiency',
    },
    {
      key: 3,
      value: 'other',
      label: 'Other',
    },
  ]


  const patternSet = {
    update: 'Update Policy and Procedure:',
    create: 'Create Policy and Procedure:',
    customer_contracts_update_template: 'Customer Contracts: Update Template:',
    customer_contracts_create_amendment: 'Customer Contracts: Create Amendment:',
    pharmacy_contracts_update_template: 'Pharmacy Contracts: Update Template:',
    pharmacy_contracts_create_amendment: 'Pharmacy Contracts: Create Amendment:',
    rebate_contracts_update_template: 'Rebate Contracts: Update Template:',
    rebate_contracts_create_amendment: 'Rebate Contracts: Create Amendment:',
    vendor_contracts_update_template: 'Vendor Contracts: Update Template:',
    vendor_contracts_create_amendment: 'Vendor Contracts: Create Amendment:',
    other_contracts: 'Other Contracts:',
    internal: 'Create Internal Communication:',
    pharmacy: 'Create Pharmacy Communication:',
    customer: 'Create Customer Communication:',
    vendor: 'Create Vendor Communication:',
    other: 'Create Other Communication:',
    update_formulary: 'Update Formulary:',
    update_clinical_guidelines: 'Update Clinical Guidelines:',
  }

  const disabled = !complete && (isReportSource ? store.user?.[0]?.report_source_role === 'member' : store.user?.[0]?.member_type === 'member')

  const isAdminOrComplianceOwner = checkRole([store.user?.[0]?.member_type], ['member']);
  const isAdminOrReportOwner = checkRole([store.user?.[0]?.report_source_role], ['member']);

  const accessAllowed = isReportSource ? isAdminOrReportOwner : isAdminOrComplianceOwner;

  const nodeRef = useRef(null);
  const trackPos = (data) => {
    setPosition({ x: data.x, y: data.y });
  };
  const handleStart = () => { };
  const handleEnd = () => { };

  const toggleConsiderationsCheckbox = (name, value) => {
    const field = name === 'other_contracts'
      || name === 'vendor'
      || name === 'internal'
      || name === 'pharmacy'
      || name === 'other'
      || name === 'customer'
      || name === 'update'
      || name === 'create'
      || name === 'other_contracts'
      || name === 'update_formulary'
      || name === 'update_clinical_guidelines'
      ? name + '_tasks' : name

    api(`/legislative/${complianceItem}/toggle_considerations_checkboxes/`, { [field]: value }, 'PATCH').then(() => {
      getConsiderationData()
    })
  }

  const toggleGetReafyCheckbox = (name, value) => {
    api(`/report_company/${complianceItem}/get_ready_tasks_information/`, { checkbox_id: store.getReadyTask.id }, 'PATCH').then(() => {
      receiveGetReadyData()
    })
  }

  function truncateTextToFirstColon(text) {
    const index = text.indexOf(':');
    if (index !== -1) {
      return text.substring(0, index + 1); // +1 чтобы включить двоеточие
    }
    return text;
  }

  const handleClose = () => {
    if (isReportSource) {
      if (activeTab === 'myTasks') {
        history.push(`/report-source/myTasks?report=${complianceItem}`)
      } else if (activeTab === 'main') {
        history.push(`/report-source/main?report=${complianceItem}`)
      } else if (activeTab === 'apcd') {
        history.push(`/report-source/apcd?report=${complianceItem}`)
      } else if (activeTab === 'all') {
        history.push(`/report-source/all?report=${complianceItem}`)
      } else if (activeTab === 'closed') {
        history.push(`/report-source/closed?report=${complianceItem}`)
      }
    } else {
      if (activeTab === 'myTasks') {
        history.push(`/tracking/myTasks?compliance_item=${complianceItem}`)
      } else {
        history.push(`/tracking/main?compliance_item=${complianceItem}`)
      }
    }
    if (isReportSource) {
      store.getReadyTask && toggleGetReafyCheckbox(store.getReadyTask.id, false)
      store.setSingleValue('getReadyTask', null)
    }
    else {
      toggleConsiderationsCheckbox(store.considerationTask, false)
      store.setSingleValue('considerationTask', null)
    }

    onClose()
  }

  const addFile = (e) => {
    setTemporaryFiles([...temporaryFiles, [...e.target.files, docStatus]])
  }
  const modifyLastFileStatus = (status) => {
    setTemporaryFiles([...temporaryFiles.slice(0, -1), [...temporaryFiles.slice(-1)[0].slice(0, -1), status]])
  }

  const deleteFile = (name) => {
    const filtered = temporaryFiles.filter((item) => item[0].name !== name)
    setTemporaryFiles(filtered)
  }

  useEffect(() => {
    if (tasks.length > 0) {
      setFiles(getFiles(tasks, taskID))
    }
  }, [tasks])


  return (
    <>
      <Overlay onClick={onClose} />
      <Draggable
        positionOffset={{ x: '-50%', y: '-50%' }}
        nodeRef={nodeRef}
        onDrag={(e, data) => trackPos(data)}
        onStart={handleStart}
        onStop={handleEnd}
        cancel=".no-drag"
      >

        <Wrap name={'task modal'} ref={nodeRef}>
          <img
            src={closeIcon}
            alt='close-icon'
            onClick={handleClose}
            id='close'
          />
          <Title>{complete
            ? data.type !== 'default' && !isReportSource
              ? 'Complete Considerations Task:'
              : 'Complete a Task:'
            : assigneNew
              ? isReportSource
                ? store.getReadyTask
                  ? 'Assign a Get Ready Task:'
                  : 'Assign a Task:'
                : store.considerationTask
                  ? 'Assign a Consideration Task:'
                  : 'Assign a Task:'
              : data.type !== 'default' && !isReportSource
                ? 'Edit Consideration Task:'
                : 'Edit a Task:'
          }
          </Title>
          {!complete && !assigneNew &&
            <>
              <Subtitle className="no-drag">
                <strong>Assigned by: </strong>{store.getMember(data?.assigner)}
              </Subtitle>
              <Subtitle style={{ marginBottom: 20 }} className="no-drag">
                <strong>Date: </strong>{isReportSource ? moment(data?.created).format('MM/DD/YYYY') : moment(data?.assigned_date).format('MM/DD/YYYY')}
              </Subtitle>
            </>
          }
          {!complete && (
            <Row className="no-drag">
              <Input
                type={'select'}
                label={'Task Owner'}
                validation={{ required: true }}
                control={control}
                disabled={disabled}
                dropdownStyle={{ position: 'absolute', zIndex: 9999999 }}
                name={'assignee'}
                showSearch
                customOptions={isReportSource
                  ? store.members
                    .filter((item) => item.have_access_to_report_source)
                    .map(
                      (item) =>
                        item.is_active && (
                          <Select.Option value={item.id} key={item.id}>
                            {item.first_name + ' ' + item.last_name}
                          </Select.Option>
                        )
                    )
                  : store.members
                    .filter((item) => item.have_access_to_tracking)
                    .map(
                      (item) =>
                        item.is_active && (
                          <Select.Option value={item.id} key={item.id}>
                            {item.first_name + ' ' + item.last_name}
                          </Select.Option>
                        )
                    )}
                smallLabel
                error={errors?.assignee && 'This field is required'}

              />
              <Controller
                control={control}
                name={'due_date'}
                rules={{ required: true }}
                render={({ onChange, value }) => (
                  <CalendarCustom
                    handleChange={(value) => {
                      onChange(moment(value, 'YYYY-MM-DD').format('YYYY-MM-DD'))
                    }}
                    incomeValue={value}
                    loading={loadingDueDate}
                    disabled={disabled}
                    required
                    label={'Task Due Date'}
                    error={errors?.due_date && 'This field is required'}
                    redCircleDate={redCircleDate}
                  />
                )}
              />
            </Row>
          )}
          {complete && (
            <>
              <Subtitle className="no-drag">
                <strong>Assigned by: </strong>{store.getMember(data?.assigner)}
              </Subtitle>
              <Subtitle style={{ marginBottom: 20 }} className="no-drag">
                <strong>Task: </strong>{data?.description}
              </Subtitle>
            </>
          )}
          <Row style={{ gridTemplateColumns: '1fr' }} className="no-drag">
            <Input
              type={'autoTextarea'}
              label={complete ? 'Task Comment' : 'Task Description'}
              isPatternText={complete ? false : isReportSource
                ? (assigneNew
                  ? (!!store.getReadyTask)
                  : (data?.type !== 'default')
                )
                : (assigneNew
                  ? (!!store.considerationTask)
                  : (data?.type !== 'default'))}
              patternText={complete ? ''
                : isReportSource
                  ? assigneNew ? !!store.getReadyTask && store.getReadyTask.text : data?.task_template ? data?.task_template.trim() + ':' : ''
                  : assigneNew ? patternSet[store.considerationTask] : patternSet[data?.type]
              }
              control={control}
              disabled={disabled}
              name={'description'}
              smallLabel
              error={errors?.description && 'This field is required'}
              editing={!complete && !assigneNew}
              validation={{
                required: true,
                validate: {
                  notEmpty: value => value.trim() !== '' || !value || 'This field is required',
                  patternTextIncluded: (value, context) => {
                    if (value.trim() === '' || !value) {
                      return 'This field is required';
                    }
                    const isPatternText = isReportSource ? (assigneNew
                      ? (!!store.getReadyTask && !complete)
                      : (data?.type !== 'default' && !complete)
                    )
                      : (assigneNew
                        ? (!!store.considerationTask && !complete)
                        : (data?.type !== 'default' && !complete));
                    if (isPatternText) {
                      const patternText = isReportSource
                        ? assigneNew ? store.getReadyTask.text : data?.task_template.trim() + ':'
                        : assigneNew ? patternSet[store.considerationTask] : patternSet[data?.type]
                      const patternWithoutLastChar = patternText.trim()
                      if (value.trim() === patternWithoutLastChar) {
                        return 'This field is required';
                      }
                      return value.trim() !== patternText.trim() || 'This field is required';
                    }

                    return true;
                  }
                },
              }}
            />
          </Row>
          {complete && (
            <div className="no-drag">
              <Input
                type={'file'}
                name={'file'}
                label={'Upload Document'}
                control={control}
                styleRow={{ marginTop: 10, marginBottom: 10 }}
                handleChange={(e) => addFile(e)}
                loading={loadingFile}
                multiple
                smallLabel
              />
            </div>
          )}
          {temporaryFiles.map((item, index) => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <ButtonOrange text={item[0].name} key={index} />
              <img
                src={deleteFileIcon}
                alt={'delete-icon'}
                onClick={() => deleteFile(item[0].name)}
                style={{ marginLeft: 5, cursor: 'pointer' }}
              />
            </div>
          ))}
          {complete && isReportSource && (
            <div style={{ marginTop: 15 }} className="no-drag">
              <Radio.Group
                onChange={(e) => {
                  setDocStatus(e.target.value)
                  temporaryFiles.length && modifyLastFileStatus(e.target.value)
                }}
                defaultValue={docStatus}
              >
                {docStatusList.map(({ label, value }) => (
                  <Radio value={value} key={value}>
                    {label}
                  </Radio>
                ))}
              </Radio.Group>
            </div>
          )}

          {complete &&
            taskFiles?.map((item, index) => {
              return (
                <>
                  <ButtonOrange
                    text={item.name}
                    key={index}
                    link={item.link}
                    defaultLink
                  />
                </>
              )
            })}
          {(complete ||
            !accessAllowed) && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: 40,
                }}
                className="no-drag"
              >
                <Cancel onClick={handleClose}>Cancel</Cancel>
                <Button
                  text={complete ? 'Complete a Task' : assigneNew ? 'Assign a Task' : 'Edit a Task'}
                  onClick={onSubmit}
                  loading={loading}
                />
              </div>
            )}
        </Wrap>
      </Draggable>
    </>
  )
}

export default Modal

export const getFiles = (tasks, taskID) => {
  let files = tasks.find((item) => +item.id === +taskID)?.files

  if (files?.length > 0) {
    return files.map((item) => {
      const splitedString = item.file_name.split('/')
      return {
        name: splitedString[splitedString.length - 1],
        link: item.file,
        id: item.id,
      }
    })
  }
}
