import { useState, useEffect } from "react";
import Modal from "../../../../components/common/ModalNew"
import { Text, Title, GoBackButton, ModalWrapper } from "./style";
import { useForm } from "react-hook-form";
import Input from "../../../../components/common/Input";
import Button from "../../../../components/common/Button/button";



const RemoveUserModal = ({ onClose, onSubmit, userName, allUsersPage, selectedBulkActions }) => {
  const { handleSubmit, control, getValues, watch, errors } = useForm()
  const [inputValue, setinputValue] = useState('')

  const isArray = Array.isArray(userName)
  const isMultipleUsers = isArray && userName.length > 1

  useEffect(() => {
    setinputValue(getValues('delete'))
  }, [watch('delete')])


  return (
    <Modal
      styleWrap={{ width: 'auto', height: 'auto', padding: 30, maxWidth: 580 }}
      onClose={onClose}>
      {isMultipleUsers ?
        <Title>
          Delete the following <span>{userName.length} users?</span>
        </Title>
        : isArray ?
          <Title>
            Delete <span>{userName[0].fullName}</span>
          </Title>
          : <Title>
            Delete <span>{userName}</span>
          </Title>
      }
      <ModalWrapper multipleUsers={isMultipleUsers}>
        {isMultipleUsers &&
          userName.map(item => {
            return (
              <div style={{
                fontFamily: 'var(--bold)',
                fontSize: 16,
                color: 'var(--blue)'
              }}>
                {item.fullName} - {item.email}
              </div>
            )
          })
        }
      </ModalWrapper>

      <Text>
        Are you sure you want to Delete this {isMultipleUsers ? 'users' : 'user'}?<br />If so type DELETE below to complete this action. <span>It can not be undone</span>.
      </Text>

      <Input
        style={{ width: 300, marginTop: 20 }}
        control={control}
        type="text"
        name="delete"
        placeholder="Type DELETE"
      />
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '1.5em' }}>
        <GoBackButton onClick={() => onClose()}>
          GoBack
        </GoBackButton>
        <Button
          text={`Delete ${isMultipleUsers ? 'Users' : 'User'}`}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            onSubmit()
          }}
          disabled={inputValue !== 'DELETE'}
          type={'main'}
          style={{
            padding: 10,
            marginRight: 0,
          }}
        />
      </div>
    </Modal>
  )
}

export default RemoveUserModal