import styled from 'styled-components'
import { Link, NavLink } from 'react-router-dom'
import { query800 } from '../../pages/Home/helpers'

export const Wrap = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 5.625em;
  border-bottom: ${(props) => (
    props.adminPanel ? '9px solid #F2A122'
      : props.serviceName === 'tracking'
        ? '9px solid #9EC3F8'
        : props.serviceName === 'report-source'
          ? '9px solid rgba(242,161,34, 0.4)'
          : props.serviceName === 'resource'
            ? '9px solid rgba(145, 192, 62, 0.4)'
            : props.serviceName === 'proposed-source'
              ? '9px solid rgba(69,81,84, 0.3)'
              : '4px solid #e5e5e5')};
  padding: 0 2.3em;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 9999;
@media${query800} {
  padding: 0 15px;
}
`

export const Right = styled('div')`
  display: flex;
  align-items: center;
@media${query800} {
  position: absolute;
  width: 100vw;
  left: 0;
  background-color: #fff;
  justify-content: center;
  border-bottom: 4px solid ${(props) => (props.active ? '#e5e5e5' : '#fff')};
  top: 90px;
  flex-direction: column;
  max-height: ${(props) => (props.active ? '300px' : '0px')};
  overflow-y: hidden;
  transition: all 0.2s ease-in-out;
  padding: ${(props) => (props.active ? '15px 0' : '0')};
}
`

export const Menu = styled('div')`
  display: flex;
`

export const Submenu = styled('div')`
  position: absolute;
  bottom: 0;
  transform: translateY(96.5%);
  background-color: #fff;
  flex-direction: column;
  box-shadow: 0 15px 10px rgba(0, 0, 0, 0.05);
  right: 0;
  display: none;
@media${query800} {
  position: relative;
  transform: none;
  bottom: auto;
  right: auto;
}
`



export const MenuItem = styled(Link)`
  color: var(--text);
  font-size: 1em;
  font-family: var(--regular);
  position: relative;
  cursor: pointer;
  margin-left: 2em;
  white-space: nowrap;
  line-height: 1em;
  height: 85px;
  display: flex;
  align-items: center;
  opacity: ${(props) => props.disabled && 0.5};

  span {
    color: #c4c4c4;
    font-size: 0.5em;
    margin-top: 0.3em;
    margin-left: 5px;
  }

  &:hover {
    ${Submenu} {
      display: flex;
    }
  }

@media${query800} {
  height: 60px;
}
`
export const SubMenuItem = styled(MenuItem)`
  &:hover { 
    font-family: var(--medium);
    background-color: rgba(34, 81, 148, 0.8);
    color: #fff;
  }
`

export const LogOut = styled(MenuItem)`
  display: none;
  @media ${query800} {
    display: flex;
  }
`

export const User = styled('div')`
  color: var(--blue);
  font-size: 0.875em;
  font-family: var(--medium);
  display: flex;
  align-items: center;
  margin-left: 1em;
  position: relative;
  height: 85px;
  justify-content: center;
  cursor: pointer;
@media${query800} {
  height: 60px;
  flex-direction: column;

  ${Submenu} {
    display: none !important;
  }
}

  img {
    height: 20px;
    width: 20px;
    margin-right: 0.5em;
  }

  ${Submenu} {
    min-width: 140px;
    width: 100%;
    right: auto;
    display: none;

    ${MenuItem} {
      color: rgba(34, 81, 148, 0.8);
      font-family: var(--medium);
      transition: all 0.2s ease-in-out;
      width: 100%;

      &:hover {
        background-color: rgba(34, 81, 148, 0.8);
        color: #fff;
      }
    }
  }

  &:hover {
    color: var(--blue);

    ${Submenu} {
      display: flex;
    }
  }
`

export const UserWrap = styled('div')`
  display: flex;
  align-items: center;
`

export const Hamburger = styled('div')`
  cursor: pointer;
  display: none;
  width: 40px;
  height: 2px;
  transition: all 0.2s ease-in-out;

  &:before,
  &:after {
    content: "";
    display: block;
    width: 40px;
    height: 2px;
    background: var(--blue);
    margin-top: -10px;
    transform-origin: right;
    transition: all 0.2s ease-in-out;
  }

  &:after {
    margin-top: 18px;
    transform: ${(props) =>
    props.active ? 'rotate(45deg) translateY(6px)' : 'none'};
  }

  &:before {
    transform: ${(props) =>
    props.active ? 'rotate(-45deg) translateY(-6px)' : 'none'};
  }

  background: ${(props) => (props.active ? '#fff' : 'var(--blue)')};
@media${query800} {
  display: block;
}
`
