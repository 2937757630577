import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import { useStore } from '../../../../../mobx-store/context'
import ResourceModal from '../../../components/ResourceAccessModal/resourceModal'
import React from 'react'
import EmailSettingsTable from './Table'
import { toJS } from 'mobx'
import { Title, SubTitle } from './Table/style'


const ReportTab = observer(({ setState, state, site }) => {
  const store = useStore()
  const history = useHistory()
  const reportEmailOptions = site ? store?.user[0]?.report_source_site_notifications : store?.user[0]?.report_source_notifications

  const liteMode = store.company[0].report_source_mode === 'report_source_lite'

  const ReportSiteSettings = [
    {
      key: 'new_report_item_in_tracking_site_notification',
      name: 'New report items being tracked',
    },
    {
      key: 'when_assigned_to_a_report_item_site_notification',
      name: 'When assigned to a report item',
    },
    {
      key: 'report_item_status_changed_site_notification',
      name: 'Report item status change',
    },
    {
      key: 'report_uploaded_or_deleted_documents_site_notification',
      name: 'Uploaded or deleted documents',
    },
    {
      key: 'report_item_due_site_notification',
      name: 'Report item is due',
    },
    {
      key: 'report_task_completion_site_notification',
      name: 'Task completion',
    },
    {
      key: 'report_comments_site_notification',
      name: 'Comment Mentions',
    },
  ];

  const ReportLiteSiteSettings = [
    {
      key: 'new_report_item_in_tracking_site_notification',
      name: 'New report items being tracked',
    },
  ];


  const ReportSiteTaskSettings = [
    {
      key: 'new_report_task_assigned_site_notification',
      name: 'Assigned new task',
    },
    {
      key: 'report_task_due_site_notification',
      name: 'Task is due',
    },
  ];

  const ReportSettings = [
    {
      key: 'new_report',
      name: 'New report items being tracked',
    },
    {
      key: 'assigned_to_report',
      name: 'When assigned to a report item',
    },
    {
      key: 'report_item_status_changed',
      name: 'Report item status change',
    },
    {
      key: 'upload_delete_document',
      name: 'Uploaded or deleted documents',
    },
    {
      key: 'report_item_due',
      name: 'Report item is due',
    },
    {
      key: 'report_task_completion',
      name: 'Task completion',
    },
    {
      key: 'new_report_comments',
      name: 'Comment Mentions',
    },
  ];

  const ReportLiteSettings = [
    {
      key: 'new_report',
      name: 'New report items being tracked',
    },
  ];

  const ReportTaskSettings = [
    {
      key: 'assigned_new_task',
      name: 'Assigned new task',
    },
    {
      key: 'report_task_is_due',
      name: 'Task is due',
    },
  ];

  const isTaskTable = true && !liteMode;

  const titles = site ? ['Report Owner Alerts', 'Task  Owner Alerts'] : ['Report Owner Emails', 'Task  Owner Emails']

  if (!store.user?.[0]?.have_access_to_report_source || !store.company?.[0]?.have_access_to_report_source)
    return (
      <ResourceModal
        type='reportSource'
        to='/edit-profile'
        onClose={() => history.push('/edit-profile')}
      />
    )

  return (
    <>
      <Title>{liteMode ? 'ReportSource Lite' : 'ReportSource'}</Title>
      <SubTitle>Select notifications times for the following actions.</SubTitle>
      <EmailSettingsTable
        site={site}
        titles={titles}
        tasksSetings={true}
        initialSettings={site ? liteMode ? ReportLiteSiteSettings : ReportSiteSettings : liteMode ? ReportLiteSettings : ReportSettings}
        initialTaskSettings={site ? ReportSiteTaskSettings : ReportTaskSettings}
        initialValues={toJS(reportEmailOptions)}
        setState={setState}
        state={state}
        isTaskTable={isTaskTable}
        isReportSource={true}
      />
    </>
  )
})

export default ReportTab
