import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import Moment from 'react-moment'
import { Link, useParams } from 'react-router-dom'
import edit from '../../../../assets/images/edit.svg'
import ButtonOrange from '../../../../components/common/ButtonOrange'
import { TableStyled } from '../../../../components/common/style'
import { useStore } from '../../../../mobx-store/context'
import { TableHeaderTitle } from '../../../Tracking/Table/table.style'
import { Row, Title } from '../../components/common/style'
import ActivityModal from '../ManageTeam/Modal'
import { Render } from './style'

const roles = {
  member: 'Team Member',
  compliance_owner: 'CO',
  admin: 'Admin',
  report_owner: 'RO',
}

const Table = observer(({ noTitle, data, isProductSetting }) => {
  const store = useStore()
  const params = useParams()
  const [visible, setVisible] = useState(false)
  const [userId, setUserId] = useState(0)
  const [userName, setUserName] = useState('')

  const company = store.company?.[0]

  const productMapping = {
    compliance_source_ultra_lite: 'ComplianceSource Ultra Lite',
    compliance_source_lite: 'ComplianceSource Lite',
    compliance_source: 'ComplianceSource',
  };

  const product = productMapping[company.compliance_source_mode]?.toString() ?? 'ComplianceSource';

  useEffect(() => {
    if (!store.members.length) store.loadMembers()
  }, [])

  const getRolesOrProduct = (user, variable = 'role') => {
    const result = []

    if (!user.is_active) return 'Deactivated'

    // If we are on Product setting page
    // we need to show only one role
    // that depends on tab
    if (isProductSetting) {
      return !params.type || params.type === 'compliance_source'
        ? roles[user.member_type]
        : params.type === 'reportSource' ? roles[user.report_source_role]
          : roles[user.resource_role]
    }

    if (user.have_access_to_resource && company.have_access_to_resource)
      result.push({ role: roles[user.resource_role], product: 'ReSource' })

    if (user.have_access_to_tracking) {
      result.push({
        role: roles[user.member_type],
        product: product
      });
    }

    if (user.have_access_to_report_source)
      result.push({
        role: roles[user.report_source_role],
        product: 'ReportSource',
      })
    if (user.have_access_to_proposed_source)
      result.push({
        role: roles[user.member_type],
        product: 'ProposedSource',
      })



    if (
      result.length > 1 &&
      result[0].role === result[1].role &&
      variable === 'role'
    )
      return result[0][variable]

    return result.map(
      (item, index) =>
        item[variable] +
        (result.length - 1 === index ? '' : variable === 'role' ? '/' : ' | ')
    )
  }

  const columnManageTheTeam = [
    {
      title: <TableHeaderTitle>Product</TableHeaderTitle>,
      dataIndex: 'product',
      key: 'product',
      render: (text, record) => getRolesOrProduct(record, 'product'),
    },
  ]

  const columns = [
    {
      title: <TableHeaderTitle>Last name</TableHeaderTitle>,
      dataIndex: 'last_name',
      key: 'last_name',
      sorter: true,
      render: (text, record) => (
        <Render isActive={record.is_active}>{text}</Render>
      ),
    },
    {
      title: <TableHeaderTitle>First Name</TableHeaderTitle>,
      dataIndex: 'first_name',
      key: 'first_name',
      sorter: true,
      render: (text, record) => (
        <Render isActive={record.is_active}>{text}</Render>
      ),
    },
    {
      title: <TableHeaderTitle>Role</TableHeaderTitle>,
      dataIndex: 'member_type',
      key: 'member_type',
      sorter: true,
      render: (text, record) => getRolesOrProduct(record),
    },
    ...(isProductSetting ? [] : columnManageTheTeam),
    {
      title: <TableHeaderTitle>Join Date</TableHeaderTitle>,
      dataIndex: 'date_joined',
      key: 'join_date',
      align: 'center',
      sorter: true,
      render: (date) => <Moment format={'MM/DD/YY'}>{date}</Moment>,
    },
    {
      title: <TableHeaderTitle>Activity Log</TableHeaderTitle>,
      dataIndex: 'activity_log',
      key: 'activity_log',
      align: 'center',
      render: (text, record) => (
        <ButtonOrange
          onClick={() => {
            setUserId(record.id)
            setUserName(`${record.first_name} ${record.last_name}`)
            setVisible(true)
            store.setSingleValue('activityModalOpen', true)
          }}
          text={'View Activity'}
        />
      ),
    },
    {
      title: <TableHeaderTitle>Edit</TableHeaderTitle>,
      dataIndex: '',
      key: 'id',
      align: 'center',
      render: ({ id }, record) => {
        return id ? (
          <Link
            to={
              record.member_type === 'admin' &&
                store.user?.[0]?.member_type === 'compliance_owner'
                ? '#'
                : `/edit-team-members-profile/${id}/tracking`
            }
          >
            <img src={edit} alt='edit-icon' />
          </Link>
        ) : (
          <img src={edit} alt='edit-icon' />
        )
      },
    },
  ]

  return (
    <>
      {!noTitle && (
        <Row style={{ marginTop: 50 }}>
          <Title>Team Members ({data?.length}) </Title>
          <ButtonOrange
            text={'Manage Team Members'}
            link={'/manage-team-members'}
            style={{ marginLeft: 30 }}
          />
        </Row>
      )}
      {visible && (
        <ActivityModal
          userId={userId}
          userName={userName}
          onClose={() => {
            setVisible(false)
            store.setSingleValue('activityModalOpen', false)
          }}
        />
      )}

      <TableStyled
        columns={columns}
        dataSource={toJS(data) || store.members}
        rowKey={'id'}
        onChange={(pagination, b, sorter) => {
          if (sorter.column) {
            store.setSingleValue('searchableMembersObject', {
              ...store.searchableMembersObject,
              ordering:
                (sorter.order === 'ascend' ? '' : '-') +
                sorter.column.dataIndex,
            })
          } else {
            const searchObj = store.searchableMembersObject
            if (searchObj) {
              delete searchObj.ordering
              store.setSingleValue(
                'searchableMembersObject',
                Object.keys(searchObj).length ? searchObj : null
              )
            }
          }
        }}
        size='small'
        pagination={{
          hideOnSinglePage: true,
          pageSize: 25,
          position: ['bottomLeft'],
        }}
      />
    </>
  )
})

export default Table
