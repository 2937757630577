
import { SwitchTitle, SwitchText, Row, DetailsWrap, AllCheckboxContainer, TableTitle, AddButton } from "../../../CreateCompany/pages/ManageProducts/style";
import { useForm } from "react-hook-form";
import Input from "../../../../../components/common/Input";
import { Text, TextContainer } from '../../../EditUser/style'
import CheckboxGroup from '../../../CreateCompany/pages/ManageProducts/CheckboxGroup'
import moment from 'moment'

const ResourceInfo = ({ resource, handleTabChange }) => {
  const { control } = useForm();
  console.log(resource, 'resource')
  return (
    <div style={{ width: '100%', marginTop: 30 }}>
      <Row style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <SwitchTitle>ReSource Guides - Access Given {resource.date_added}</SwitchTitle>
        <div style={{
          color: 'var(--blue)',
          fontFamily: 'var(--bold)',
          fontSize: 12,
          cursor: 'pointer',
          textDecoration: 'underline',
        }} onClick={() => { handleTabChange('resource') }}> {'View Details'}</div>
      </Row>
      <Row style={{ gridTemplateColumns: '1fr 1fr 1fr', }}>
        <div>
          <SwitchTitle style={{ marginBottom: 3, fontFamily: 'var(--medium)' }}>Group Date</SwitchTitle>
          <TextContainer style={{ color: 'var(--grey)' }} >
            <Text style={{ color: 'var(--grey)' }}>{moment(resource.begin_date).format('MM/DD/YYYY')}</Text>
          </TextContainer>
        </div>
        <div>
          <SwitchTitle style={{ marginBottom: 3, fontFamily: 'var(--medium)' }}>Group End Date</SwitchTitle>
          <TextContainer style={{ color: 'var(--grey)' }}  >
            <Text style={{ color: 'var(--grey)' }}>{moment(resource.end_date).format('MM/DD/YYYY')}</Text>
          </TextContainer>
        </div>
        <div>
          <SwitchTitle style={{ marginBottom: 3, fontFamily: 'var(--medium)' }}>Price Per Guide</SwitchTitle>
          <TextContainer style={{ color: 'var(--grey)' }}>
            <Text style={{ color: 'var(--grey)' }}>{`$${resource.value}`}</Text>
          </TextContainer>
        </div>
      </Row>
      <DetailsWrap style={{ gridTemplateColumns: '1fr' }}>
        <TableTitle>
          Guides({resource.selected_guides.length})
        </TableTitle>
      </DetailsWrap>
      <DetailsWrap style={{ gridTemplateColumns: '1fr' }}>
        <CheckboxGroup
          // defaultValue={extractIds(group.selected_guides.filter(guide => guide.have_access === false))}
          compact={true}
          control={control}
          name={'selected_guides'}
          disabled={true}
          options={resource.selected_guides}
          allChecked={true}
        />
      </DetailsWrap>
    </div>
  )
};

export default ResourceInfo;