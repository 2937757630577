import { message } from 'antd'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'
import { api } from '../../../../api'
import { checkRole, getError } from '../../../../helpers/helpers'
import { useStore } from '../../../../mobx-store/context'
import Modal from '../../../Tracking/Right/Modal'
import { PageTitle } from '../../components/common/style'
import PageWrap from '../../components/Container'
import TabsNav from '../../components/TabsNav/tabsNav'
import Form from './Form/form'
import Right from './Right'
import { Grid } from './style'
import { varTypes } from './Tabs/static'
import Tab from './Tabs/tab'
import UserDependenciesWarning from './UserDependenciesWarning'
import ModalRole from './UserDependenciesWarning/Modal'

const EditTeamMemberProfile = observer(() => {
  const {
    control,
    handleSubmit,
    errors,
    reset,
    watch,
    getValues,
    formState: { dirtyFields },
    setValue,
    register,
  } = useForm()
  const history = useHistory()
  const params = useParams()
  const store = useStore()
  // State
  const [addedUsers, setAddedUsers] = useState([])
  const [loading, setLoading] = useState(false)
  const [memberType, setMemberType] = useState(null)
  const [visible, setVisible] = useState(false)
  const [roleError, setRoleError] = useState(false)
  const [showChangingAdminModal, setShowChangingAdminModal] = useState(false)
  const [error, setError] = useState(null)
  const [deleting, setDeleting] = useState(false)
  const [active, setActive] = useState()
  const [isAccessRemoving, setIsAccessRemoving] = useState(false)

  const userID = +params.id

  const member = store.member

  const complianceMode = store.company[0].compliance_source_mode
  const reportMode = store.company[0].report_source_mode

  const complianceButtonName = complianceMode === 'compliance_source_ultra_lite'
    ? 'CS Ultra Lite'
    : complianceMode === 'compliance_source_lite'
      ? 'CS Lite' : 'ComplianceSource'

  const reportButtonName = reportMode === 'report_source_lite' ?
    'ReportSource Lite' : 'ReportSource'


  const tabs = {
    tracking: {
      button: complianceButtonName,
      link: `/edit-team-members-profile/${userID}/tracking`,
    },
    resource: {
      button: 'ReSource',
      link: `/edit-team-members-profile/${userID}/resource`,
    },
    reportSource: {
      button: reportButtonName,
      link: `/edit-team-members-profile/${userID}/reportSource`,
    },
    proposedSource: {
      button: 'ProposedSource',
      link: `/edit-team-members-profile/${userID}/proposedSource`,
    },
  }

  watch(['topics', 'member_type', 'have_access_to_resource'])

  useEffect(() => {
    reset({
      ...member,
    })
    setMemberType(member?.member_type)
  }, [member, store.selectedJurisdictions])

  const editUser = (user) => {
    const index = addedUsers.findIndex((item) => item.id === user.id)

    const newUsers = addedUsers

    newUsers[index] = user

    setAddedUsers(newUsers)
  }

  useEffect(() => {
    store.loadMember(params.id)
    if (!store.lineOfBusiness || !store.topics)
      store.loadSelect('memberOptions')
    store.loadSelect('companyOptions')
  }, [])

  useEffect(() => {
    store.loadMember(params.id)
  }, [params.type])



  useEffect(() => {
    setActive(params.type || 'tracking')
  }, [params.type])

  const deactivateUser = () => {
    setError(null)
    setDeleting(true)
    api(
      `/members/${userID}/change_is_active/`,
      { is_active: false },
      'POST'
    ).then((data) => {
      if (!data.ok) {
        if (data.error_code === '1 admin') {
          setError({ code: 'at_least_one_admin' })
        }
        if (data.error_code === 'items_assigned') {
          setError({
            code: 'items_assigned',
            legislatives_assigned: data.legislatives_assigned,
            tasks_assigned: data.tasks_assigned,
            report_source_tasks_assigned: data.report_source_tasks_assigned,
            reports_assigned: data.reports_assigned,
          })
        }
      } else {
        history.push('/manage-team-members')
        setDeleting(false)
        message.success('User Deactivated')
      }
    })
  }

  const isRoleChangingToAdmin = () => {
    const isRoleChangedToAdmin =
      member.member_type !== 'admin' && memberType === 'admin'

    if (isRoleChangedToAdmin) {
      return setShowChangingAdminModal(true)
    }

    handleSubmit(onSubmit)()
  }

  const handleDependencies = (data) => {
    if (data[0].error_code === 'items_assigned') {
      setError({
        code: 'items_assigned',
        legislatives_assigned: data[0].legislatives_assigned,
        tasks_assigned: data[0].tasks_assigned,
        report_source_tasks_assigned: data[0].report_source_tasks_assigned,
        reports_assigned: data[0].reports_assigned,
        button: 'Reassign',
      })
    } else {
      if (data.errors?.error_code === 'last admin') {
        setVisible(true)
      } else {
        message.error(getError(data))
      }
    }
  }

  const onSubmit = (values) => {
    setLoading(true)
    api(
      `/members/${member.id}/`,
      {
        ...values,
        ...(isAccessRemoving
          ? { [varTypes[params.type].variable]: false }
          : {}),
      },
      'PATCH'
    ).then((data) => {
      if (data.ok || data.id) {

        setIsAccessRemoving(false)
        message.success('User saved!')
        store.modifyAddedMember(data, true)
        store.setSingleValue('member', data)
      } else {
        handleDependencies(data)
      }
      setLoading(false)
    })
  }

  const onCloseAdminModal = () => {
    setShowChangingAdminModal(false)
    reset({ ...getValues(), member_type: member.member_type })
    setMemberType(member.member_type)
  }

  const onConfirmAdminModal = () => {
    setShowChangingAdminModal(false)
    handleSubmit(onSubmit)()
    setMemberType(member.member_type)
  }

  const handleShowModal = () => setVisible(true)

  useEffect(() => {
    if (!store.jurs) store.loadSelect('companyOptions')
  }, [])

  return (
    <PageWrap
      modalOpen={store.activityModalOpen}
      showAdding={[
        'Manage The Team',
        `${member && member.first_name} ${member && member.last_name}`,
        member && member.id,
      ]}
      gridStyle={{ width: 1200 }}
    >
      {visible && (
        <Modal
          onConfirm={() => {
            setVisible(false)
            reset(member)
          }}
          onClose={() => {
            setVisible(false)
            reset(member)
          }}
          title={'There Must Be At Least 1 Admin'}
          text={
            'In order to be delete this user another admin must be added first. There must be at least one admin per subscription.'
          }
        />
      )}
      {showChangingAdminModal && (
        <Modal
          onConfirm={onConfirmAdminModal}
          onClose={onCloseAdminModal}
          title={'Admin Role Applies to All Products'}
          text={
            'Making a user an admin give them admin rights for all PBMSource products. '
          }
          buttonText={'Change to Admin'}
        />
      )}

      {roleError && (
        <ModalRole
          error={'items_assigned'}
          onClose={() => {
            setRoleError(false)
            setIsAccessRemoving(false)
          }}
          deactivate={deactivateUser}
          deleting
        />
      )}
      <Grid>
        <div style={{ paddingRight: 40 }}>
          <PageTitle>Edit User</PageTitle>
          <Form
            onEditUser={(user) => editUser(user)}
            control={control}
            reset={reset}
            errors={errors}
            handleSubmit={() => isRoleChangingToAdmin()}
            memberType={memberType}
          />
          {checkRole([store.user?.[0]?.member_type], ['admin']) && (
            <UserDependenciesWarning
              deactivateUser={deactivateUser}
              error={error}
              userID={userID}
              setError={setError}
              deleting={deleting}
              setDeleting={setDeleting}
              onEdit={handleSubmit(onSubmit)}
              setIsAccessRemoving={setIsAccessRemoving}
            />
          )}
        </div>

        <Right memberType={memberType} />
      </Grid>
      <TabsNav
        tabs={tabs}
        active={active}
        onRedirectTo={(page) =>
          history.push(`/edit-team-members-profile/${params.id}/${page}`)
        }
      />
      <Tab
        control={control}
        memberType={memberType}
        onSubmit={isRoleChangingToAdmin}
        loading={loading}
        member={member}
        handleRoleModal={handleShowModal}
        setValue={setValue}
        register={register}
        deactivateUser={deactivateUser}
        tab={params.type}
        isResource={params.type === 'resource'}
        isReportSource={params.type === 'reportSource'}
        isTracking={params.type === 'tracking'}
        isProposedSource={params.type === 'proposedSource'}
        handleDependencies={handleDependencies}
        setIsAccessRemoving={setIsAccessRemoving}
        reset={reset}
        getValues={getValues}
      />
    </PageWrap>
  )
})

export default EditTeamMemberProfile
