import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useStore } from '../../../mobx-store/context'
import Search from '../Search/search'
import Table from '../Table/table'
import ReportMonthlyTable from './ReportMonthlyTable'
import { Wrap } from './left.style'

const Left = observer(
  ({
    isReportSource = false,
    auth,
    getCurrentLegislative,
    archived,
    monitor,
    all,
    apcd,
    changeTab,
    currentLegislativeLoading,
    active,
    currentPage,
    setCurrentPage,
    isMyTasks,
    complianceItem,
    onCreateNew,
    setTouched,
    setEditing,
    setVisible,
    touched,
    getData,
  }) => {
    const store = useStore()
    const [page, setPage] = useState(1)

    const count =
      active === 'main' || active === 'closed' || active === 'all' || active === 'monitor' || active === 'apcd'
        ? store.legislatives?.count
        : store.tasks?.count

    useEffect(() => {
      setCurrentPage(1)
      setPage(1)
    }, [active])

    const checkForTrial = () =>
      store.company.length > 0 && store.company[0].payment_status === 'trial'

    return (
      <Wrap>
        <Search
          auth={auth}
          closed={archived}
          all={all}
          monitor={monitor}
          apcd={apcd}
          isTrial={checkForTrial()}
          onSearch={() => setCurrentPage(1)}
          isMyTasks={active === 'myTasks'}
          activeTab={active}
          isReportSource={isReportSource}
          getData={getData}
        />
        {isReportSource && (active === 'main' || active === 'apcd')
          && <ReportMonthlyTable isReportSource={isReportSource} active={active} />}
        <Table
          count={count || 0}
          loading={currentLegislativeLoading}
          changeTab={(key) => changeTab(key)}
          getCurrentLegislative={(id) => getCurrentLegislative(id)}
          archived={archived}
          monitor={monitor}
          all={all}
          isTrial={checkForTrial()}
          currentPage={currentPage}
          onCurrentPageChange={(page) => setCurrentPage(page)}
          activeTab={active}
          isMyTasks={isMyTasks}
          complianceItem={complianceItem}
          onCreateNew={onCreateNew}
          setTouched={setTouched}
          setEditing={setEditing}
          setCreateItemVisible={setVisible}
          touched={touched}
          isReportSource={isReportSource}
        />
      </Wrap>
    )
  }
)

export default Left
