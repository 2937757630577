import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useStore } from '../../../../mobx-store/context'
import { Spin } from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import { PageTitle } from '../../components/common/style'
import PageWrap from '../../components/Container'
import TabsNav from '../../components/TabsNav/tabsNav'
import TrackingTab from './Tabs/Tracking/tracking'
import ReportTab from './Tabs/ReportSource/'
import { api } from '../../../../api'
import EmptyTab from './Tabs/EmptyTab'
import SettingsActivity from './Settings Activity'

const AutoAssignments = observer(() => {
  const store = useStore()
  const params = useParams()
  const history = useHistory()
  const [active, setActive] = useState(null)
  const [loading, setLoading] = useState(true)
  const [showActivity, setShowActivity] = useState(false)
  const [activityData, setActivityData] = useState([])
  const user = store.user?.[0]

  const getAssignLists = () => {
    api('/companies/auto_assign_lists/', {}, 'GET').then((data) => {
      if (!data.errors) {
        store.setSingleValue('autoAssignLists', data)
      }
    }).then(() => api('/companies/auto_assign_type/', {}, 'GET').then((data) => {
      store.setSingleValue('autoAssignType', data)
    })).then(() => api(`/companies/auto_assign_members/?filter_for=${active === 'tracking' ? 'compliance_source' : 'report_source'}`, {}, 'GET').then((data) => {
      store.setSingleValue('autoAssignMembers', data)
    })).then(() => store.loadMembers()).then(() => store.loadSelect('companyOptions')).then(() => setLoading(false))
  }

  const getActtivity = () => {
    api(`/companies/auto_assign_activity/`, {}, 'GET').then((data) => {
      setActivityData(data)
    })
  }

  const tabs = {
    tracking: {
      button: 'ComplianceSource',
      component: <TrackingTab setShowActivity={setShowActivity} />,
      link: '/auto-assignments/tracking',
    },
    // resource: {
    //   button: 'ReSource',
    //   component: <EmptyTab />,
    //   link: '/auto-assignments/resource',
    // },
    reportSource: {
      button: 'ReportSource',
      component: <ReportTab setShowActivity={setShowActivity} />,
      link: '/auto-assignments/reportSource',
    },
    // proposedSource: {
    //   button: 'ProposedSource',
    //   component: <EmptyTab />,
    //   link: '/auto-assignments/proposedSource',
    // }
  }

  useEffect(() => {
    setActive(
      user?.have_access_to_tracking
        ? 'tracking'
        : 'reportSource'
    )
  }, [])

  useEffect(() => {
    params.type && setActive(params.type)
  }, [params.type])

  useEffect(() => {
    setShowActivity(false)
  }, [active])

  useEffect(() => {
    getAssignLists()
  }, []);

  useEffect(() => {
    getActtivity()
  }, [showActivity])



  return (
    <PageWrap modalOpen={false}>
      <PageTitle>{showActivity ? 'Auto Assignments Activity' : 'Auto Assignments'}</PageTitle>
      {showActivity ?
        <SettingsActivity data={activityData} setShowActivity={setShowActivity} />
        : <>
          <TabsNav
            tabs={tabs}
            active={active}
            onRedirectTo={(page) => history.push(`/auto-assignments/${page}`)}
          />
          {loading ? <Spin /> : tabs[active].component}
        </>
      }
    </PageWrap>
  )
})

export default AutoAssignments
