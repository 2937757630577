
import { observer } from 'mobx-react-lite'
import { Title } from '../../../../components/common/style'

const EmptyTab = observer(() => {

  return (
      <Title style={{ fontSize: 14, marginBottom: 15, fontFamily: 'var(--regular)' }}>
        No items to auto assign
      </Title>
  )
})

export default EmptyTab
