import { useState, useEffect } from 'react'
import { SubTitle, Title, CompanyDescription } from '../../CreateCompany/pages/FirstPage/style'
import { NawPageWrap, Row, SwitchTitle, SwitchText } from '../../CreateCompany/pages/ManageProducts/style'
import FullScreenLoader from '../../../../components/common/FullScreenLoader';
import { api } from '../../../../api';
import { useStore } from '../../../../mobx-store/context';
import { useForm, Controller } from "react-hook-form";
import { useParams } from 'react-router-dom';
import { StyledTable } from '../Tabs/Users/style';
import { TableHeaderTitle } from "../../CompaniesTable/style";
import { Checkbox, message, Select } from 'antd';
import { SelectBLock, CustomizeButton } from './style';
import { useHistory } from 'react-router-dom';
import Button from '../../../../components/common/Button/button';
import { observer } from "mobx-react-lite"
import CustomizeUserModal from './CustomizeUserModal';
import { toJS } from 'mobx';
import Wraper from '../../CMSContainer';

const AddMultipleUsers = observer(() => {
  const [loading, setLoading] = useState(true)
  const [selectedBulkActions, setSelectedBulkActions] = useState([])
  const [isHeaderCheckboxChecked, setIsHeaderCheckboxChecked] = useState(false);
  const [visible, setVisible] = useState(false)
  const [currentUser, setCurrentUser] = useState(null)
  const [currentService, setCurrentService] = useState(null)
  const params = useParams()
  const store = useStore()
  const history = useHistory()
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    register,
    getValues,
    trigger
  } = useForm({
    defaultValues: {}
  });

  const trackingMemberTypes = [
    {
      id: 'admin',
      name: 'Admin',
    },
    {
      id: 'compliance_owner',
      name: 'Compliance Owner',
    },
    {
      id: 'member',
      name: 'Member',
    },
  ]
  const reportMemberTypes = [
    {
      id: 'admin',
      name: 'Admin',
    },
    {
      id: 'report_owner',
      name: 'Report Owner',
    },
    {
      id: 'member',
      name: 'Member',
    },
  ]
  const resourceMemberTypes = [
    {
      id: 'admin',
      name: 'Admin',
    },
    {
      id: 'member',
      name: 'Member',
    },
  ]



  const getOptions = (options) => {
    return options?.map((item) => (
      <Select.Option style={{ fontSize: 12, fontFamily: 'var(--regular)' }} value={item.id} key={item.id}>
        {item.name}
      </Select.Option>
    ))
  }

  const getResources = () => {
    setLoading(true)
    api(`/cms/companies/${params.id}/`, {}, 'GET').then((data) => {
      store.setSingleValue('cmsCompany', [data])
      getTempUsers()
    }).then(() => {
      getCmsOptions()
    })
  }

  const getTempUsers = () => {
    api(`/cms/intermediate_users/?company=${params.id}`, {}, 'GET').then((data) => {
      store.setSingleValue('cmsTempUsers', data)
    })
  }

  const getCmsOptions = () => {
    api(`/cms/dictionaries_data/company_data/?company=${params.id}`, {}, 'GET').then((data) => {
      if (!data.errors) {
        store.setSingleValue('cms_company_options', data)
        setLoading(false)
      }
    })
  }

  const editTempUsersAcess = (recordKey, dataIndex, newValue) => {
    api(`/cms/intermediate_users/${recordKey}/`, { [dataIndex]: newValue }, 'PATCH').then((res) => {
      if (!res.errors)
        message.success('User access updated')
    })
  }

  const onSubmit = () => {
    api(`/cms/companies/${params.id}/add_multiple_users_to_company/`, { users: selectedBulkActions }, 'POST').then((res) => {
      if (!res.errors) {
        message.success('Users added successfully')
        history.push(`/dashboard-edit-company/${params.id}`)
      }
    })
  }

  const onCheckboxChange = (recordKey, dataIndex) => (e) => {
    const newValue = e.target.checked;
    setValue(`${recordKey}-${dataIndex}`, newValue);
    editTempUsersAcess(recordKey, dataIndex, newValue)
  };

  const onBulkActionCheckboxChange = (recordKey, dataIndex) => (e) => {
    const newValue = e.target.checked;
    setValue(`${recordKey}-${dataIndex}`, newValue);
    setSelectedBulkActions((prevSelectedBulkActions) => {
      const updatedSelectedBulkActions = [...prevSelectedBulkActions];
      if (newValue) {
        updatedSelectedBulkActions.push(recordKey);
      } else {
        const index = updatedSelectedBulkActions.indexOf(recordKey);
        if (index !== -1) {
          updatedSelectedBulkActions.splice(index, 1);
        }
      }
      return updatedSelectedBulkActions;
    });
  };
  const onHeaderCheckboxChange = () => {
    const newCheckedState = !isHeaderCheckboxChecked;

    setIsHeaderCheckboxChecked(newCheckedState);

    store?.cmsTempUsers?.forEach((record) => {
      setValue(`${record.id}-bulk_users_actions`, newCheckedState);
      handleBulkActionCheckboxChange(record.id, 'bulk_users_actions')(newCheckedState);
    });
  };

  const handleBulkActionCheckboxChange = (recordKey, dataIndex) => (newValue) => {
    setSelectedBulkActions((prevSelectedBulkActions) => {
      const updatedSelectedBulkActions = [...prevSelectedBulkActions];
      if (newValue) {
        updatedSelectedBulkActions.push(recordKey);
      } else {
        const index = updatedSelectedBulkActions.indexOf(recordKey);
        if (index !== -1) {
          updatedSelectedBulkActions.splice(index, 1);
        }
      }
      return updatedSelectedBulkActions;
    });

  };

  const watchBulkActions = watch();


  useEffect(() => {
    const areAllBulkActionsSelected = store?.cmsTempUsers?.every((record) =>
      watchBulkActions[`${record.id}-bulk_users_actions`] === true
    );
    setIsHeaderCheckboxChecked(areAllBulkActionsSelected);
  }, [watchBulkActions]);

  useEffect(() => {
    getResources()
  }, [])

  useEffect(() => {
    if (store.cmsTempUsers) {
      store.cmsTempUsers.forEach(user => {
        setValue(`${user.id}-member_type`, user.member_type);
        setValue(`${user.id}-resource_role`, user.resource_role);
        setValue(`${user.id}-report_source_role`, user.report_source_role);
      });
    }
  }, [store?.cmsTempUsers]);

  const columns = [
    {
      title: <TableHeaderTitle>Name</TableHeaderTitle>,
      dataIndex: 'full_name',
      width: '20%',
      key: 'full_name',
      sorter: false,
      render: (text, record) => (
        <div
          style={{
            color: '#225194',
            cursor: 'pointer',
            textDecoration: 'underline',
            fontFamily: 'var(--regular)',
            fontSize: 12,
          }}
          onClick={() => { }}
        >
          {record.first_name} {record.last_name}
        </div>
      ),
    },
    {
      title: <TableHeaderTitle>Email</TableHeaderTitle>,
      dataIndex: 'email',
      key: 'email',
      width: '20%',
      sorter: false,
      render: (text, record) => (
        <div
          style={{
            fontFamily: 'var(--regular)',
            fontSize: 12,
          }}
        >
          {text}</div>
      ),
    },
    {
      title: <TableHeaderTitle>Compliance</TableHeaderTitle>,
      dataIndex: 'have_access_to_tracking',
      width: '5%',
      key: 'have_access_to_tracking',
      sorter: false,
      render: (text, record) => (
        <Controller
          name={`${record.id}-have_access_to_tracking`}
          control={control}
          defaultValue={text}
          render={(field) => (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <Checkbox
                  style={{ marginRight: 10 }}
                  disabled={!store.cmsCompany[0].is_registration_passed}
                  checked={field.value}
                  onClick={(e) => e.stopPropagation()}
                  onChange={onCheckboxChange(record.id, 'have_access_to_tracking')}
                />
                <SelectBLock>
                  <Select
                    {...field}
                    defaultValue={record.member_type}
                    onChange={(value) => {
                      setValue(`${record.id}-member_type`, value);
                      editTempUsersAcess(record.id, 'member_type', value);
                    }}
                    value={getValues()[`${record.id}-member_type`]}
                  >
                    {getOptions(trackingMemberTypes)}
                  </Select>
                </SelectBLock>
              </div>
              <CustomizeButton
                onClick={() => {
                  setCurrentUser(toJS(record))
                  setCurrentService('compliance')
                  setVisible(true)
                }} >
                Select Different than Default</CustomizeButton>
            </div>
          )}
        />
      ),
    },
    {
      title: <TableHeaderTitle>Proposed</TableHeaderTitle>,
      dataIndex: 'have_access_to_proposed_source',
      key: 'have_access_to_proposed_source',
      width: '5%',
      sorter: false,
      render: (text, record) => (
        <Controller
          name={`${record.id}-have_access_to_proposed_source`}
          control={control}
          defaultValue={text}
          render={(field) => (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Checkbox
                disabled={!store.cmsCompany[0].have_access_to_proposed_source}
                checked={field.value}
                onClick={(e) => e.stopPropagation()}
                onChange={onCheckboxChange(record.id, 'have_access_to_proposed_source')}
              />
              <CustomizeButton
                style={{ width: 130 }}
                onClick={() => {
                  setCurrentUser(record)
                  setCurrentService('proposed')
                  setVisible(true)
                }} >
                Select Different than Default
              </CustomizeButton>
            </div>
          )}
        />
      ),
    },
    {
      title: <TableHeaderTitle>Resource</TableHeaderTitle>,
      dataIndex: 'have_access_to_resource',
      width: '5%',
      key: 'have_access_to_resource',
      sorter: false,
      render: (text, record) => (
        <Controller
          name={`${record.id}-have_access_to_resource`}
          control={control}
          defaultValue={text}
          render={(field) => (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <Checkbox
                  style={{ marginRight: 10 }}
                  disabled={!store.cmsCompany[0].have_access_to_resource}
                  checked={field.value}
                  onClick={(e) => e.stopPropagation()}
                  onChange={onCheckboxChange(record.id, 'have_access_to_resource')}
                />
                <SelectBLock>
                  <Select
                    {...field}
                    disabled={!store.cmsCompany[0].have_access_to_resource}
                    defaultValue={record.resource_role}
                    onChange={(value) => {
                      setValue(`${record.id}-resource_role`, value);
                      editTempUsersAcess(record.id, 'resource_role', value);
                    }}
                    value={getValues()[`${record.id}-resource_role`]}
                  >
                    {getOptions(resourceMemberTypes)}
                  </Select>
                </SelectBLock>
              </div>
              <CustomizeButton
                onClick={() => {
                  setCurrentUser(record)
                  setCurrentService('resource')
                  setVisible(true)
                }} >
                Select Different than Default
              </CustomizeButton>
            </div>
          )}
        />
      ),
    },
    {
      title: <TableHeaderTitle>Report</TableHeaderTitle>,
      dataIndex: 'have_access_to_report_source',
      width: '5%',
      key: 'have_access_to_report_source',
      sorter: false,
      render: (text, record) => (
        <Controller
          name={`${record.id}-have_access_to_report_source`}
          control={control}
          defaultValue={text}
          render={(field) => (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <Checkbox
                  style={{ marginRight: 10 }}
                  disabled={!store.cmsCompany[0].have_access_to_report_source}
                  checked={field.value}
                  onClick={(e) => e.stopPropagation()}
                  onChange={onCheckboxChange(record.id, 'have_access_to_report_source')}
                />
                <SelectBLock>
                  <Select
                    {...field}
                    defaultValue={record.report_source_role}
                    disabled={!store.cmsCompany[0].have_access_to_report_source}
                    onChange={(value) => {
                      setValue(`${record.id}-resource_role`, value);
                      editTempUsersAcess(record.id, 'report_source_role', value);
                    }}
                    value={getValues()[`${record.id}-report_source_role`]}
                  >
                    {getOptions(reportMemberTypes)}
                  </Select>
                </SelectBLock>
              </div>
              <CustomizeButton
                onClick={() => {
                  setCurrentUser(record)
                  setCurrentService('report')
                  setVisible(true)
                }}>
                Select Different than Default
              </CustomizeButton>
            </div>
          )}
        />
      ),
    },
    {
      title: (
        <div>
          <Checkbox
            checked={isHeaderCheckboxChecked}
            onChange={onHeaderCheckboxChange}
          />
        </div>
      ),
      dataIndex: 'bulk_users_actions',
      key: 'bulk_users_actions',
      sorter: false,
      width: '5%',
      render: (text, record) => (
        <Controller
          name={`${record.id}-bulk_users_actions`}
          control={control}
          defaultValue={text}
          render={(field) => (
            <Checkbox
              checked={field.value}
              onClick={(e) => e.stopPropagation()}
              onChange={onBulkActionCheckboxChange(record.id, 'bulk_users_actions')}
            />
          )}
        />
      ),
    },
  ]

  return (
    <Wraper>
      <NawPageWrap style={{ width: 910 }}>
        {visible && <CustomizeUserModal user={currentUser} service={currentService} onClose={() => setVisible(false)} getTempUsers={getTempUsers} />}
        {loading
          ? <FullScreenLoader />
          : <>
            <div>
              <SubTitle style={{ fontSize: 24 }}>{store.cmsCompany[0].name}</SubTitle>
              <CompanyDescription>Company Domain: {store.cmsCompany[0].domain} <br /> {store.cmsCompany[0].address1}</CompanyDescription>
            </div>
            <SubTitle>AddMultipleUsers</SubTitle>
            <CompanyDescription>The default status is the settings for each product that has been set for this company.</CompanyDescription>
            <StyledTable
              style={{ marginTop: '1em' }}
              bordered
              columns={columns}
              dataSource={store?.cmsTempUsers}
              rowKey={'id'}
              size='small'
              pagination={false}
            />

            <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                text={'Add Users'}
                disabled={selectedBulkActions.length === 0}
                onClick={() => { onSubmit() }}
                type={'main'}
                style={{
                  width: '100px',
                  padding: 0,
                  marginRight: 0,
                  marginLeft: '.5em',
                  marginTop: '2.3em',
                }}
              />
            </Row>

          </>
        }
      </NawPageWrap>
    </Wraper>
  );
});

export default AddMultipleUsers;