import { message } from 'antd'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useStore } from '../../../../mobx-store/context'
import ResourceModal from '../ResourceAccessModal/resourceModal'
import { Button, Wrap } from './style'

const TabsNav = ({
  tabs,
  active = 'tracking',
  styleWrap,
  allowResource,
  onRedirectTo,
  noCheck,
}) => {
  const store = useStore()
  const history = useHistory()
  const params = useParams()
  const [visible, setVisible] = useState(false)
  const [modalData, setModalData] = useState({})

  const user = store.user?.[0]
  const company = store.company?.[0]

  const accessCheck = () => {
    if (
      user &&
      company &&
      (!user?.have_access_to_tracking || !company?.have_access_to_tracking) &&
      (!user?.have_access_to_report_source || !company?.have_access_to_report_source)
      && (!user?.have_access_to_proposed_source || !company?.have_access_to_proposed_source)
      && (!user?.have_access_to_resource || !company?.have_access_to_resource)
    ) {
      message.error("You don't have access to any mode")
      history.push('/edit-profile')
    }
    if (
      active === 'tracking' &&
      (!user?.have_access_to_tracking || !company?.have_access_to_tracking)
    ) {
      onRedirectTo('resource')
      return
    }
    if (
      active === 'proposedSource' &&
      (!user?.have_access_to_proposed_source || !company?.have_access_to_proposed_source)
    ) {
      onRedirectTo('tracking')
      return
    }
    if (
      active === 'resource' &&
      (!user?.have_access_to_resource || !company?.have_access_to_resource)
    ) {
      onRedirectTo('reportSource')
      return
    }
    if (
      active === 'reportSource' &&
      (!user?.have_access_to_report_source || !company?.have_access_to_report_source)
    ) {
      onRedirectTo('proposedSource')
      return
    }
  }

  const onClick = (item, e) => {
    if (
      noCheck ||
      ((user?.member_type === 'admin' || user?.resource_role === 'admin' || user?.proposed_source_role === 'admin' || user?.proposed_source_role === 'admin') &&
        company['have_access_to_' + item])
    )
      return
    if (
      item === 'resource' &&
      (!user.have_access_to_resource || !company.have_access_to_resource) &&
      !allowResource
    ) {
      setModalData({
        to:
          user?.member_type === 'admin'
            ? company.have_access_to_resource
              ? `/edit-team-members-profile/${user.id}/resource`
              : '/resource/options'
            : '/edit-profile',
        type: 'resource',
      })
      setVisible(true)
      e.preventDefault()
      return
    } else if (
      item === 'tracking' &&
      (!user.have_access_to_tracking || !company.have_access_to_tracking)
    ) {
      setVisible(true)
      setModalData({
        type: 'tracking',
        to:
          store.user[0]?.member_type === 'admin' ? '/topicsAndJur' : '/edit-profile',
      })
      e.preventDefault()
      return
    } else if (
      item === 'reportSource' &&
      (!user.have_access_to_report_source || !company.have_access_to_report_source)
    ) {
      setVisible(true)
      setModalData({
        type: 'reportSource',
        to:
          user.member_type === 'admin' ? '/addReportMember/' : '/edit-profile',
      })
      e.preventDefault()
      return
    } else if (
      item === 'proposedSource' &&
      (!user.have_access_to_proposed_source || !company.have_access_to_proposed_source)
    ) {
      setVisible(true)
      setModalData({
        type: 'proposedSource',
        to:
          store.user[0]?.member_type === 'admin' ? '/addProposedMember' : '/edit-profile',
      })
      e.preventDefault()
      return
    } else if (
      item === 'resource' &&
      (!user.have_access_to_resource || !company.have_access_to_resource)
    ) {
      setVisible(true)
      setModalData({
        type: 'resource',
        to:
          store.user[0]?.member_type === 'admin' ? '/resource/options' : '/edit-profile',
      })
      e.preventDefault()
      return
    }

  }

  const onClose = () => setVisible(false)

  useEffect(() => {
    if (!noCheck) accessCheck()
  }, [user])

  return (
    <Wrap style={styleWrap}>
      {visible && <ResourceModal onClose={onClose} {...modalData} />}
      {Object.entries(tabs).map((item) => (
        <Button
          to={item[1].link}
          key={item[0]}
          active={active === item[0]}
          onClick={(e) => onClick(item[0], e)}
          name={item[1].button}
        >
          {item[1].button}
        </Button>
      ))}
    </Wrap>
  )
}

export default TabsNav
