import { TableHeaderTitle } from "./style";
import { useState, useEffect } from 'react'
import { useStore } from "../../../mobx-store/context";
import { api } from "../../../api";
import { Table } from 'antd'
import ItemsShowing from '../../../components/common/TableItemsShowing'
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom/";
import { Tag, Divider } from 'antd'
import Filters from '../EditCompany/Tabs/Users/filters'
import Cookies from 'universal-cookie';


const CompaniesTable = observer(() => {
  const [page, setPage] = useState(1)
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [selectedEditionItems, setSelectedEditionItems] = useState([]);
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const store = useStore()
  const history = useHistory()


  const getSearchableString = (item) => {
    if (!item) return ''
    return item

  }

  const getFile = () => {
    const cookies = new Cookies()
    const csrftoken = cookies.get('csrftoken')
    let token = cookies.get('pbm_token')

    fetch(
      `${process.env.REACT_APP_API_DEV
      }${`/cms/companies/export_to_xlsx/`
      }`,
      {
        method: 'POST',
        headers: {
          Authorization: `Token ${token}`,
          'X-CSRFToken': csrftoken,
          'Content-Type': 'application/json',
        },
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `companies.xlsx`)
        document.body.appendChild(link)

        link.click()

        link.parentNode.removeChild(link)
      })
  }

  const getData = (search) => {
    setLoading(true)
    api(`/cms/companies/?search=${getSearchableString(store.companiesSearch)}${search ? '' : `&page=${page}`}&${createQueryString(selectedFilters)}`, {}, 'GET').then((res) => {
      if (res.errors) return history.push('/home')
      store.setSingleValue('companies', res)
      setData(res)
      setLoading(false)
    })
  }
  useEffect(() => {
    getData(false)
  }, [page])

  useEffect(() => {
    getData(true)
  }, [store?.companiesSearch, selectedFilters])

  useEffect(() => {
    localStorage.removeItem('cmsCompanyId')
  }, [])

  function createQueryString(permissions) {
    if (permissions.length === 0) {
      return '';
    }

    const queryParams = {};
    permissions.forEach(permission => {
      queryParams[permission] = true;
    });

    const queryString = Object.entries(queryParams)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    return queryString;
  }


  const columns = [
    {
      title: <TableHeaderTitle>Company Name</TableHeaderTitle>,
      dataIndex: 'name',
      key: 'name',
      sorter: false,
      render: (text, record) => (
        <div>
          <a onClick={() => store.setSingleValue('cmsComanyId', record.id)} href={`/dashboard-edit-company/${record.id}`}>{text}</a>
          {record.is_testing_company &&
            <Tag
              style={{ width: 'fit-content', fontFamily: 'var(--regular)', borderRadius: 4, fontSize: 9, marginBottom: 5, marginLeft: 5 }}
              color="var(--green)">
              Testing Company
            </Tag>
          }
          {
            record.trial_option === 'trial_is_active'
              ? <Tag
                style={{ width: 'fit-content', fontFamily: 'var(--regular)', borderRadius: 4, fontSize: 9, marginBottom: 5, marginLeft: 5 }}
                color="var(--orange)">
                Active Trial
              </Tag>
              : record.trial_option === 'trial_is_over'
                ? <Tag
                  style={{ width: 'fit-content', fontFamily: 'var(--regular)', borderRadius: 4, fontSize: 9, marginBottom: 5, marginLeft: 5 }}
                  color="var(--grey)">
                  Trial is Over
                </Tag>
                : null
          }
        </div>
      ),
    },
    {
      title: <TableHeaderTitle>Last Activity</TableHeaderTitle>,
      dataIndex: 'last_activity',
      key: 'last_activity',
      sorter: false,
    },
    {
      title: <TableHeaderTitle>Last User</TableHeaderTitle>,
      dataIndex: 'last_user',
      key: 'last_user',
    },
    {
      title: <TableHeaderTitle>Products</TableHeaderTitle>,
      dataIndex: 'products',
      key: 'products',
    },
    {
      title: <TableHeaderTitle>Payment Status</TableHeaderTitle>,
      dataIndex: 'payment_status',
      key: 'payment_status',
    },
  ]
  return (
    <> {
      !loading &&
      <>
        <Divider />
        <div style={{ marginBottom: 10 }}>
          <Filters
            setPage={setPage}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            selectedEditionItems={selectedEditionItems}
            setSelectedEditionItems={setSelectedEditionItems}
            onEdit={() => { }}
            disabled={false}
            allUsersPage={'companyTable'}
          />
        </div>
        <Table
          columns={columns}
          dataSource={store?.companies.results || data.results}
          rowKey={'id'}
          bordered
          size='small'
          onChange={(pagination, _, sorter) => {
            setPage(pagination.current)
          }}
          pagination={{
            hideOnSinglePage: false,
            pageSize: 20,
            position: ['bottomRight'],
            total: data?.count,
            current: page,
            showSizeChanger: false,
          }}
        />
        <div style={{ marginTop: 5, display: 'flex', flexDirection: 'row', width: 'fit-content', alignItems: 'center' }}>
          <ItemsShowing
            page={page}
            pageSize={20}
            total={data?.count}
            position={'right'}
            adminPanel={true}
            style={{
              textAlign: 'right',
              marginTop: -31,
              paddingRight: 10,
              width: 'fit-content',
              marginBottom: 10,
            }}
          />
          <div
            onClick={() => getFile()}
            style={{
              color: '#225194',
              cursor: 'pointer',
              textDecoration: 'underline',
              fontFamily: 'var(--regular)',
              fontSize: 10,
              marginTop: -31,
              marginBottom: 10,
              marginLeft: '1em'
            }}
          >Export to XLSX
          </div>
        </div>
      </>
    }
    </>
  )
});

export default CompaniesTable;