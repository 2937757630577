import { observer } from 'mobx-react-lite'
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import setting from '../../../../assets/images/settings_24px.svg'
import { checkRole } from '../../../../helpers/helpers'
import useDidMountEffect from '../../../../hooks/useEffectNotOnPageLoad'
import { useStore } from '../../../../mobx-store/context'
import { Item, Title, Wrap } from './style'

const list = [
  {
    key: 0,
    title: 'Edit Profile',
    to: '/edit-profile',
  },
  {
    key: 1,
    title: 'Password and Security',
    to: '/password-and-security',
    secondary: false,
  },
  {
    key: 2,
    title: 'Email Notifications',
    to: '/profile-email',
    secondary: false,
  },
  {
    key: 2,
    title: 'Site Notifications',
    to: '/site-notifications',
    secondary: false,
    divider: true,
  },
  {
    key: 3,
    title: 'Product settings',
    to: '/product-settings',
    secondary: false,
    onlyAdmin: true,
    roles: ['admin', 'compliance_owner', 'report_owner'],
  },
  {
    key: 4,
    title: 'Restrictions',
    to: '/restrictions',
    secondary: true,
    onlyAdmin: true,
    roles: ['admin'],
  },
  {
    key: 4,
    title: 'Auto Assignments',
    to: '/auto-assignments',
    secondary: true,
    onlyAdmin: true,
    roles: ['admin'],
    check: (store) => store.company?.[0]?.have_access_to_tracking || store.company?.[0]?.have_access_to_report_source,
  },
  {
    key: 5,
    title: 'Billing Info',
    to: '/billing-info',
    secondary: true,
    onlyAdmin: true,
    roles: ['admin'],
    check: (store) => false
  },
  {
    key: 6,
    title: 'Current Plan',
    to: '/current-plan',
    secondary: true,
    onlyAdmin: true,
    roles: ['admin'],
  },
  {
    key: 7,
    title: 'Business info',
    to: '/business-info',
    secondary: true,
    onlyAdmin: true,
    roles: ['admin'],
  },
  {
    key: 8,
    title: 'Manage Dictionaries',
    to: '/manage-dictionaries/topics',
    roles: ['admin', 'compliance_owner', 'report_owner'],
    check: (store) => store.company?.[0]?.have_access_to_tracking,
    secondary: true,
  },
  {
    key: 9,
    title: 'Manage The Team',
    to: '/manage-team-members',
    secondary: true,
    onlyAdmin: true,
    roles: ['admin', 'compliance_owner', 'report_owner'],
  },
]

const Menu = observer(({ showAdding }) => {
  const store = useStore()
  const location = useLocation()
  const history = useHistory()

  const user = store.user?.[0]

  const disabledForUser = [
    '/product-settings',
    '/current-plan',
    '/business-info',
    '/manage-team-members',
    '/add-team-members-profile',
    '/billing-info',
  ]

  const isAdminOrComplianceOwner = checkRole([user?.member_type], ['admin', 'compliance_owner']);
  const isAdminOrReportOwner = checkRole([user?.report_source_role], ['admin', 'report_owner']);

  const accessAllowed = isAdminOrComplianceOwner || isAdminOrReportOwner;

  useDidMountEffect(() => {
    if (
      store.user[0] &&
      !accessAllowed &&
      disabledForUser.includes(location.pathname)
    ) {
      history.push('/edit-profile')
    }
  }, [store.user])


  return (
    <Wrap>
      <Title>
        <img src={setting} alt='setting-icon' /> Account Preferences
      </Title>
      {list.map((item) => {
        return (
          checkRole([user?.member_type, user?.report_source_role, user?.resource_role], item.roles) &&
          (item.hasOwnProperty('check') ? item.check(store) : true) && (
            <>
              <Item
                to={item.to}
                key={item.key}
                activeStyle={{ backgroundColor: '#fff', color: '#4E74A9' }}
                secondary={item.secondary ? 'true' : 'false'}
                disabled={item.disabled}
                style={{
                  backgroundColor:
                    showAdding && item.title === showAdding[0] && '#225194',
                }} // в showAdding добавил вручную тайтл страницы с которой был редирект (на даном этапе выбра такое решение)
                isActive={(_, location) => {
                  if (location.pathname.split('/')[1] === item.to.split('/')[1])
                    return true

                  return false
                }}
              >
                {item.secondary && <span>•</span>} {item.title}
              </Item>
              {item.divider && <div style={{ height: '2px', backgroundColor: '#E5E5E5', margin: '5px 20px 5px 0', }} />}
            </>
          )
        )
      })}
      {showAdding && showAdding[1] === 'add' && (
        <Item
          to={'/add-team-members-profile'}
          activeStyle={{
            backgroundColor: '#fff',
            color: '#4E74A9',
            fontSize: '12px',
            width: 'fit-content',
            marginLeft: 'auto',
            paddingRight: '10px',
          }}
        >
          <span>•</span> Add Team Members
        </Item>
      )}
      {showAdding && showAdding[1] !== 'add' && (
        <Item
          as='div'
          style={{
            backgroundColor: '#fff',
            color: '#4E74A9',
            fontSize: '12px',
            width: 'fit-content',
            marginLeft: 'auto',
            paddingRight: '10px',
          }}
        >
          <span>•Editing:</span> {showAdding[1].length > 25 ? showAdding[1].substring(0, 20) + '...' : showAdding[1]}
        </Item>
      )}
    </Wrap>
  )
})

export default Menu
