import { message } from 'antd'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { api } from '../../../../api'
import deleteIcon from '../../../../assets/images/cross-orange.svg'
import { useStore } from '../../../../mobx-store/context'
import ExampleComponent from '../../../Payments/payForm'
import { Title } from '../common/style'
import { TeamMember } from './style'

const YourTeamMembers = observer(
  ({ additionalUsers, onDelete, onClearAdditionalFromState }) => {
    const store = useStore()
    const params = useParams()

    const isResource = params.type === 'resource'
    const isReportSource = params.type === 'reportSource'
    const isProposedSource = params.type === 'proposedSource'

    const members =
      isReportSource
        ? store.members.filter(
          (member) =>
            member.have_access_to_report_source && (
              <TeamMember key={member.id}>
                {member.first_name} {member.last_name}
              </TeamMember>
            )
        )
        : isProposedSource
          ? store.members.filter(
            (member) =>
              member.have_access_to_proposed_source && (
                <TeamMember key={member.id}>
                  {member.first_name} {member.last_name}
                </TeamMember>
              )
          )
          : isResource
            ? store.members.filter(
              (member) =>
                member.have_access_to_resource && (
                  <TeamMember key={member.id}>
                    {member.first_name} {member.last_name}
                  </TeamMember>
                )
            )
            : store.members.filter(
              (member) =>
                member.have_access_to_tracking && (
                  <TeamMember key={member.id}>
                    {member.first_name} {member.last_name}
                  </TeamMember>
                )
            )

    const confirmMember = (nonce) => {
      api(
        '/members/confirm_team/',
        {
          members: additionalUsers,
          nonce,
        },
        'POST'
      )
        .then((data) => {
          if (!data.errors) {
            message.success('Success payment')
            onClearAdditionalFromState()
            store.loadMembers(null, isReportSource)
          } else {
            message.error('Error')
          }
        })
        .catch((error) => console.log(error))
    }

    useEffect(() => {
      if (!store.members.length) {
        store.loadMembers(null, isReportSource)
      }
    }, [])
    return (
      <>
        <div style={{ marginLeft: 'auto' }}>
          <Title style={{ margin: '1em 0' }}>
            {isReportSource
              ? 'ReportSource Team'
              : isProposedSource
                ? 'ProposedSource Team'
                : isResource
                  ? 'ReSource Team'
                  : 'ComplianceSource Team'}
          </Title>
          {members.map((member) => (
            <TeamMember key={member.id}>
              {member.first_name} {member.last_name}
            </TeamMember>
          ))}
          {additionalUsers.map((member) => (
            <TeamMember
              key={member.id}
              style={{ backgroundColor: 'rgba(242, 161, 34, 0.2)' }}
            >
              {member.first_name} {member.last_name}{' '}
              <img
                src={deleteIcon}
                alt='delete-icon'
                onClick={() => onDelete(member)}
              />
            </TeamMember>
          ))}
          <br />
          {additionalUsers.length > 0 && (
            <ExampleComponent
              noSkip
              noCheckbox
              isValid
              onClick={(nonce) => confirmMember(nonce)}
              className={'braintree-dropin-react hide-choose-pay'}
            />
          )}
        </div>
      </>
    )
  }
)

export default YourTeamMembers
