import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams, Prompt } from 'react-router-dom'
import { api } from '../../api'
import placeholderImage from '../../assets/images/legislatives-palceholder.svg'
import reportPlacegolderImage from '../../assets/images/report-empty.svg'
import FullScreenLoader from '../../components/common/FullScreenLoader'
import Tabs from '../../components/common/Tabs/tabs'
import Container from '../../components/containers/container'
import {
  checkForRegisterPassed,
  redirectToUnfilledPage,
} from '../../helpers/helpers'
import { useStore } from '../../mobx-store/context'
import Auth from './Auth/auth'
import CreateNewComplianceItem from './CreateNew'
import Left from './Left/left'
import { Title, TitleContainer } from './Left/left.style'
import Reporting from './Reporting'
import ReportCalendar from './ReportCalendar'
import Right from './Right/right'
import Disclaimer from '../../components/common/Disclaimer'
import TCModal from './Modal/tcModal'
import { NotChoosen } from './Right/right.style'
import { Top, Wrap, Back } from './tracking.style'
import CustomPromptModal from '../../components/common/CustomPrompt/CustomPromptModal'
import { tabs, liteTabs, reportTabs, reportLiteTabs } from './static'
import { TrialBanner } from './TrialBanner/index'
import ChangePasswordModal from '../Auth/ChangePassowordModal'




const Tracking = observer(({ isReportSource }) => {
  const store = useStore()
  const history = useHistory()
  const params = useParams()
  const [active, setActive] = useState('main')
  const [currentLegislativeLoading, setCurrentLegislativeLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [visible, setVisible] = useState(false)
  const [editing, setEditing] = useState(false)
  const [touched, setTouched] = useState(false)
  const [visibleConfModal, setVisibleConfModal] = useState(false);
  const [expiredPassword, setExpiredPassword] = useState(false);
  const [showDisclaimer, setShowDisclaimer] = useState({ show: false, fadeOut: false });
  const [detailsState, setDetailsState] = useState(null)

  const urls = {
    default: isReportSource ? 'page_size=20&status=actual' : 'page_size=20&actual=actual',
    closed: 'page_size=20&status=closed',
    myTasks: 'page_size=20&status=mytasks',
    monitor: 'page_size=20&status=monitor',
    apcd: 'page_size=20&status=apcd',
    all: 'page_size=20',
  }

  const ending = {
    main: `get_page_for=legislatives&actual=actual`,
    myTasks: `get_page_for=tasks`,
    closed: `get_page_for=legislatives&status=closed`,
    monitor: `get_page_for=legislatives&status=monitor`,
    apcd: '`get_page_for=legislatives&status=apcd',
    all: `get_page_for=legislatives&all=all`,
  }

  const loading = false
  const auth = false
  const company = store.company?.[0]
  const user = store.user?.[0]

  const trackingMode = isReportSource ? company?.report_source_mode : company?.compliance_source_mode
  const showModeBlocks = isReportSource
    ? trackingMode === 'report_source' ? true : false
    : trackingMode === 'compliance_source' ? true : false

  let search = new URL(document.location).searchParams
  let complianceItem = search.get('compliance_item')
  let report = search.get('report')
  const prevItem = isReportSource ? report : complianceItem

  const serviceMember = isReportSource ? user?.report_source_role : user?.member_type

  const isMyTasks =
    active === 'myTasks' ||
    (serviceMember === 'member' && active !== 'closed')

  const onLoad = (data) => {
    let status;
    if (active === 'all') {
      status = {};
    } else {
      switch (data.status) {
        case 'closed':
          status = { status: 'closed' };
          break;
        case 'monitor':
          status = { status: 'monitor' };
          break;
        case 'mytasks':
          status = { status: 'mytasks' };
          break;
        default:
          status = { actual: 'actual' };
          break;
      }
    }

    store[isMyTasks ? 'loadTasks' : 'loadLegislatives']({
      page: data.page,
      page_size: 20,
      ...status,
    }, isReportSource)
  }

  const onError = () => {
    store[isMyTasks ? 'loadTasks' : 'loadLegislatives']({
      page: 1,
      page_size: 20,
    }, isReportSource)
  }

  const getData = () => {
    setCurrentStatus()
    const lastSearchName = isReportSource ? 'remember_my_last_search_report_source' : 'remember_my_last_search_compliance'
    if (checkForRegisterPassed(store.company[0], isReportSource) && store.user.length > 0) {
      if (params.id || complianceItem || report) {
        getCurrentLegislative(
          params.id || complianceItem || report,
          onLoad,
          onError,
          false,
          isReportSource
        )
      } else {
        if (isMyTasks) {
          store.user[0][lastSearchName][active] || store.firstTimeRememberSearch ?
            api(`/users/${store?.user[0]?.id}/last_search_data/`, {}, 'GET').then((data) => {
              isReportSource ?
                store.setSingleValue('searchableObjectTasks', { ...data.report_data[active], tab_name: active })
                : store.setSingleValue('searchableObjectTasks', { ...data.compliance_data[active], tab_name: active })
              store[active === 'closed' ? 'loadLegislatives' : 'loadTasks']({
                page: 1,
                page_size: checkForTrial() ? 10 : 20,
              }, isReportSource)
              store.setSingleValue('firstTimeRememberSearch', false)
            })
            :
            store.setSingleValue('searchableObjectTasks', { ...store.searchableObjectTasks, tab_name: active })
          store[active === 'closed' ? 'loadLegislatives' : 'loadTasks']({
            page: 1,
            page_size: checkForTrial() ? 10 : 20,
          }, isReportSource)
        } else {
          if (active === 'calendar') {
            if (store.user[0].remember_my_last_search_report_source.calendar || store.firstTimeRememberSearch) {
              api(`/users/${store?.user[0]?.id}/last_search_data/`, {}, 'GET').then((data) => {
                store.setSingleValue('searchableObject', { ...data.report_data[active], tab_name: active, ...store.calendarDateRange })
                store.loadLegislatives({
                  page: 1,
                  page_size: checkForTrial() ? 10 : 20,
                }, true)
                store.setSingleValue('firstTimeRememberSearch', false)
              })
            } else {
              store.setSingleValue('searchableObject', { ...store.searchableObject, tab_name: active, ...store.calendarDateRange })
              store.loadLegislatives({
                page: 1,
                page_size: checkForTrial() ? 10 : 20,
              }, true)
            }
          }
          else {
            store.user[0][lastSearchName][active] || store.firstTimeRememberSearch ?
              api(`/users/${store?.user[0]?.id}/last_search_data/`, {}, 'GET').then((data) => {
                isReportSource
                  ? store.setSingleValue('searchableObject', { ...data.report_data[active], tab_name: active })
                  : store.setSingleValue('searchableObject', { ...data.compliance_data[active], tab_name: active })
                store.loadLegislatives({
                  page: 1,
                  page_size: checkForTrial() ? 10 : 20,
                }, isReportSource)
                store.setSingleValue('firstTimeRememberSearch', false)
              })
              :
              store.setSingleValue('searchableObject', { ...store.searchableObject, tab_name: active })
            store.loadLegislatives({
              page: 1,
              page_size: checkForTrial() ? 10 : 20,
            }, isReportSource)
          }
        }
      }
    }
  }

  const updateDetails = () => {
    if (detailsState === '<p><br></p>' || detailsState === store.detailsInitialValue) { return }
    const url = isReportSource ? `/report_company/${store?.currentLegislative?.id}/details/` : `/legislative-details/${store?.currentLegislative?.id}/`;
    api(url, { details_html: detailsState, }, 'PUT').then((data) => {
      setDetailsState(null)
    })
  }

  const getCurrentLegislative = (id, onLoad, onError, isClicked, isReportSource, pageLoad = true) => {

    const url = isReportSource
      ? `/report_company/${id}/?${urls[active === 'closed'
        ? 'closed'
        : active === 'all'
          ? 'all'
          : active === 'apcd'
            ? 'apcd'
            : active === 'myTasks'
              ? 'myTasks'
              : 'default']}&${store.searchableString}`
      : `/legislative/${id}/?${isClicked
        ? urls[active === 'closed'
          ? 'closed'
          : active === 'all'
            ? 'all'
            : active === 'monitor'
              ? 'monitor'
              : 'default']
        : ending[active]}&${store.searchableString || ''}`

    api(url, {}, 'GET').then((data) => {
      if (!data.errors) {
        if (data.status === 'closed' && active !== 'all') {
          history.push(`${isReportSource ? '/report-source/' : '/tracking/'}closed/?${isReportSource ? 'report' : 'compliance_item'}=${data.id}`)
          setActive('closed')
        }
        onLoad && onLoad(data)
        store.setCurrentLegislative(data)
        pageLoad ? setCurrentPage(data.page || currentPage || 1) : setCurrentPage(currentPage)
        setDetailsState(null)
      } else {
        onError && onError()
        store.setCurrentLegislative(null)
        setDetailsState(null)
      }
      setCurrentLegislativeLoading(false)
      setVisible(false)
      setDetailsState(null)
    })
  }

  const checkForTrial = () =>
    store.company.length > 0 && !isReportSource && store.company[0].payment_status === 'trial'

  const archived = active === 'closed'
  const all = active === 'all'
  const monitor = active === 'monitor'
  const apcd = active === 'apcd'
  const calendar = active === 'calendar'

  const setCurrentStatus = () => {
    if (archived) {
      store.setSingleValue('legislativesStatus', 'closed')
      if (all) {
        store.setSingleValue('legislativesStatus', 'all')
        return
      }
      if (monitor) {
        store.setSingleValue('legislativesStatus', 'monitor')
        return
      }
      if (apcd) {
        store.setSingleValue('legislativesStatus', 'apcd')
        return
      }
      if (calendar) {
        store.setSingleValue('legislativesStatus', 'calendar')
        return
      }
    } else {
      if (store.searchableObject?.status === 'closed') {
        store.setSingleValue('legislativesStatus', 'closed')
      }
      if (store.searchableObject?.status === 'monitor') {
        store.setSingleValue('legislativesStatus', 'monitor')
      }
      if (calendar) {
        store.setSingleValue('legislativesStatus', 'calendar')
        return
      }
    }
  }

  const onCreateNew = () => {
    if (touched) { setVisibleConfModal(true) }
    else {
      history.push(`/${isReportSource
        ? 'report-source'
        : 'tracking'}/createNew`)
      setVisible(true)
    }
  }

  const onConfirmConfModal = () => {
    setEditing(false)
    setVisible(false)
    setTouched(false)
    setVisibleConfModal(false)
  }


  const statsStatus = active === 'apcd' ? 'status=apcd' : 'status=actual'

  const onTabChange = (key) => {
    history.push(`${isReportSource ? '/report-source/' : '/tracking/'}${key}`)

    store.setSingleValue('currentLegislative', null)
    store.setSingleValue('legislatives', null)
    store.setSingleValue('tasks', null)

    if (key === 'closed') {
      store.setSingleValue('legislativesStatus', 'closed')
    } if (key === 'all') {
      store.setSingleValue('legislativesStatus', 'all')
      return
    } if (key === 'monitor') {
      store.setSingleValue('legislativesStatus', 'monitor')
      return
    }
    if (key === 'apcd') {
      store.setSingleValue('legislativesStatus', 'apcd')
      return
    }
    if (key === 'calendar') {
      store.setSingleValue('legislativesStatus', 'all')
      return
    }
    else {
      store.setSingleValue('legislativesStatus', 'actual')
    }
  }

  let content;

  if (active === 'reporting-task') {
    content = <Reporting isReportSource={isReportSource} reportingTask={true} />
  } else if (active === 'reporting-item') {
    content = <Reporting isReportSource={isReportSource} reportingTask={false} />
  }
  else if (active === 'calendar') {
    content = <ReportCalendar
      setActive={setActive}
      getData={getData}
      getCurrentLegislative={
        (id) => getCurrentLegislative(id, null, null, true, isReportSource)} />
  } else {
    content =
      <Wrap>
        <>
          {store.user.length > 0 && (
            <Left
              auth={auth}
              archived={active === 'closed'}
              all={active === 'all'}
              monitor={active === 'monitor'}
              apcd={active === 'apcd'}
              active={active}
              setActive={(key) => setActive(key)}
              changeTab={(key) => setActive(key)}
              getCurrentLegislative={
                (id) => getCurrentLegislative(id, null, null, true, isReportSource)}
              currentLegislativeLoading={currentLegislativeLoading}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              isMyTasks={isMyTasks}
              complianceItem={isReportSource ? report : complianceItem}
              onCreateNew={onCreateNew}
              setTouched={(value) => setTouched(value)}
              setEditing={(value) => setEditing(value)}
              setVisible={(value) => setVisible(value)}
              touched={touched} editing
              isReportSource={isReportSource}
              getData={getData}
            />
          )}

          {auth ? (
            <Auth />
          ) : visible ? (
            <CreateNewComplianceItem
              onClose={() => {
                setTouched(false)
                setVisible(false)
                setEditing(false)
              }}
              touched={(value) => setTouched(value)}
              editing={editing}
              isMyTasks={isMyTasks}
              activeTab={active}
              isReportSource={isReportSource}
              getCurrentLegislative={(id) =>
                getCurrentLegislative(id, (data) => {
                  isMyTasks ?
                    store.loadTasks({
                      page: data.page,
                      page_size: 20,
                    }, isReportSource)
                    : store.loadLegislatives({
                      page: 1,
                      page_size: 20,
                    }, isReportSource)
                }, null, true, isReportSource)
              }
            />
          ) : store.currentLegislative ? (
            <Right
              detailsState={detailsState}
              setDetailsState={setDetailsState}
              updateDetails={updateDetails}
              data={toJS(store.currentLegislative)}
              getCurrentLegislative={getCurrentLegislative}
              showModeBlocks={showModeBlocks}
              trackingMode={trackingMode}
              currentPage={currentPage}
              isMyTasks={isMyTasks}
              complianceItem={isReportSource ? report : complianceItem}
              activeTab={active}
              isReportSource={isReportSource}
              onEdit={() => {
                setVisible(true)
                setEditing(true)
              }}
            />
          ) : (
            <NotChoosen>
              <img src={isReportSource ? reportPlacegolderImage : placeholderImage} alt='placeholder' />
              Select a {isReportSource ? 'report entry' : 'compliance entry'} to begin
            </NotChoosen>
          )}
        </>
      </Wrap>
  }

  useEffect(() => {
    store.getUser()
  }, [active])

  useEffect(() => {
    isReportSource
      ? document.title = 'PBMSource - ReportSource'
      : document.title = 'PBMSource - ComplianceSource'
  }, []);

  useEffect(() => {
    if (store.company.length && checkForRegisterPassed(store.company[0], isReportSource)) {
      store.loadSelect('companyOptions')
    } else {
      redirectToUnfilledPage(history, store)
    }
  }, [])

  useEffect(() => {
    const user = store.user?.[0]
    if (isReportSource && user?.report_source_role === 'member') setActive('myTasks')
    if (!isReportSource && user?.member_type === 'member') setActive('myTasks')
    if (user && !user?.have_access_to_tracking && !user?.have_access_to_report_source)
      history.push('/edit-profile')
  }, [store.user])

  useEffect(() => {
    active !== 'calendar' && getData()
  }, [active, store.user])



  useEffect(() => {
    if (isMyTasks) setActive('myTasks')
  }, [isMyTasks])

  useEffect(() => {
    if (params.tabName && active !== params.tabName) {
      setActive(params.tabName)
      if (params.tabName === 'closed') {
        store.setSingleValue('legislativesStatus', 'closed')
      } if (params.tabName === 'all') {
        store.setSingleValue('legislativesStatus', 'all')
        return
      } if (params.tabName === 'monitor') {
        store.setSingleValue('legislativesStatus', 'monitor')
        return
      }
      if (params.tabName === 'apcd') {
        store.setSingleValue('legislativesStatus', 'apcd')
        return
      }
      if (params.tabName === 'myTasks') {
        setActive('myTasks')
        return
      }
      else {
        store.setSingleValue('legislativesStatus', 'actual')
      }
    }
    if (params.tabName && !params.tabName === 'reporting' && (
      isReportSource
        ? !reportTabs.find((item) => item.key === params.tabName)
        : !tabs.find((item) => item.key === params.tabName))) {
      setActive('main')
      isReportSource
        ? history.push('/report-source/main')
        : history.push('/tracking/main')
    }
  }, [params.tabName])


  useEffect(() => {
    if (isReportSource) {
      api(`/report_company/stats/?${statsStatus}`, {}, 'GET').then((data) => {
        store.setSingleValue('reportStats', data)
      })
    }
  }, [active])

  useEffect(() => {
    if (isReportSource && report) {
      api(`/report_company/${report}/prior_reports/`, {}, 'GET').then((data) => {
        store.setSingleValue('currentItemLinkedReports', data)
      })
    }
  }, [report])

  useEffect(() => {
    setExpiredPassword(localStorage.getItem('is_time_to_change_password') === 'true')
  }, [])

  useEffect(() => {
    const show = sessionStorage.getItem('showDisclaimer') === 'true';
    setShowDisclaimer({ show: show, fadeOut: !show });
  }, []);

  const handleDisclaimerClose = () => {
    setShowDisclaimer(prev => ({ ...prev, fadeOut: true }));
    setTimeout(() => {
      setShowDisclaimer({ show: false, fadeOut: false });
      sessionStorage.setItem('showDisclaimer', 'false');
    }, 500);
  }

  useEffect(() => {
    store.setSingleValue('currentLegislative', null)
  }, [])

  useEffect(() => {
    isReportSource &&
      api('/reporting_entities/', {}, 'GET').then((data) => {
        store.setSingleValue('reportingEntities', data)
      })
  }, [])

  useEffect(() => {
    isReportSource &&
      api('/report_types/', {}, 'GET').then((data) => {
        store.setSingleValue('reportTypes', data)
      })
  }, [])

  useEffect(() => {
    const statuses = ['trial_is_over', 'error', 'canceled']
    if (statuses.includes(company.payment_status)) {
      history.push(
        `/company_error/${company.payment_status === 'trial_is_over'
          ? company.payment_status
          : 'payment_' + company.payment_status
        }`
      )
    }
  }, [company])

  useEffect(() => {
    detailsState && updateDetails()
  }, [complianceItem, report, params.id])

  if (loading || !store.user[0]) return <FullScreenLoader />

  return (
    store.company.length > 0 &&
    checkForRegisterPassed(store.company[0], isReportSource) &&
    !loading &&
    (user.have_access_to_tracking || user.have_access_to_report_source) && (
      <>
        <Container wide>
          {expiredPassword && <ChangePasswordModal onClose={() => setExpiredPassword(false)} expiredPassword />}
          <Top fullWidth>
            <Back style={{ marginTop: '7.75em' }} onClick={() => history.goBack()}>Go Back</Back>
            <TitleContainer>
              <Title>
                {isReportSource
                  ? trackingMode === 'report_source_lite'
                    ? 'ReportSource Lite'
                    : 'ReportSource'
                  : trackingMode === 'compliance_source_ultra_lite'
                    ? 'ComplianceSource Ultra Lite'
                    : trackingMode === 'compliance_source_lite'
                      ? 'ComplianceSource Lite'
                      : 'ComplianceSource'}
              </Title>
              {checkForTrial() && <TrialBanner />}
            </TitleContainer>

            <Tabs
              tabs={isReportSource
                ? showModeBlocks
                  ? reportTabs
                  : reportLiteTabs
                : showModeBlocks
                  ? tabs
                  : liteTabs}
              isReportSource={isReportSource}
              active={active}
              onClick={(key) => {
                if (key === active) return null
                if (touched) { setVisibleConfModal(true) } else {
                  onTabChange(key)
                  setActive(key)
                  setVisible(false)
                }
              }}
            />
          </Top>
          {content}
          {
            !user.is_accepted_terms_and_conditions &&
            <TCModal />
          }
          {
            user.should_see_pbm_updates &&
            <TCModal type='announcement' />
          }
          <Prompt when={touched} message={'Are you sure you want to leave before saving?'} />
          <div id='custom-prompt'></div>
          {visibleConfModal &&
            <CustomPromptModal
              onConfirm={onConfirmConfModal}
              onCancel={() => setVisibleConfModal(false)} />}
        </Container>
        {showDisclaimer.show && <Disclaimer onClose={handleDisclaimerClose} fadeOut={showDisclaimer.fadeOut} />}
      </>
    )
  )
})

export default Tracking
