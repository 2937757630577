import React, { useEffect, useState } from 'react'
import { Title } from './style'
import { api } from '../../../../../api'
import CheckboxComponent from './CheckboxList'

const GetReady = ({ title, reportCompanyId, handleOpen, setAssigneNew, getReadyData, receiveGetReadyData, assignee }) => {
  useEffect(() => {
    receiveGetReadyData()
  }, [])
  return (
    <>
      <Title>
        {title}
      </Title>
      {getReadyData &&
        <CheckboxComponent
          assignee={assignee}
          data={getReadyData}
          reportCompanyId={reportCompanyId}
          getData={receiveGetReadyData}
          handleOpen={handleOpen}
          setAssigneNew={(value) => setAssigneNew(value)} />
      }
    </>
  )
}

export default GetReady