import styled from 'styled-components'

export const Wrap = styled('div')`
 max-height: 90%;
 overflow: hidden;
  `
export const Report = styled('div')`
    width: fit-content;
    max-height: 40px;
    line-height: 13px;
    overflow: hidden;
    border: 1px solid #CFEAEA;
    font-size: 10px;
    margin-bottom: 2px;
    border-radius: 6px;
    padding: 5px 10px;
    background-color: #CFEAEA;
    font-family: var(--semiBold);
     &:hover {
      border: 1px solid var(--orange);
    }
    span {
      font-size: 11px;
      font-family: var(--bold);
      color: #E62A01;
    }
  `
export const ShowMore = styled('div')`
  border: 1px solid #F2A122;
  font-size: 11px;
  margin-bottom: 2px;
  border-radius: 6px;
  padding: 5px;
  background-color: #F2A122;
  max-width: 60px;
  font-family: var(--semiBold);
  `