import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { api } from '../../../../api'
import { PlainText } from '../../../../components/common/PlainText/style'
import { useStore } from '../../../../mobx-store/context'
import { PageTitle, Title } from '../../components/common/style'
import PageWrap from '../../components/Container'
import YourTeamMembers from '../../components/TeamRightSidebar'
import Form from './Form/form'
import { BlueBlock, Grid } from './style'

const plans = {
  topic_tract: 'TopicTrack',
  juris_tract: 'JurisTrack',
  total_tract: 'TotalTrack',
  select_tract: 'SelectTrack',
}

const AddTeamMemberProfile = observer(() => {
  const history = useHistory()
  const store = useStore()
  const [editing, setEditing] = useState(false)
  const [defaultValues, setDefaultValues] = useState({
    jurisdictions: [],
    topics: [],
    resource_guides: [],
    resource_jurisdictions: [],
    line_of_business: [],
    assign: 'jurisdictions',
  })
  const [addedUsers, setAddedUsers] = useState([])
  const [price, setPrice] = useState(0)
  const user = store.user?.[0]

  useEffect(() => {
    if (store.user.length) {
      if (user.member_type === 'member' && user.resource_role === 'member' && user.report_source_role === 'member')
        history.push('/tracking')
    } else {
      store.getUser()
    }
  }, [store.user])

  const deleteAdditionalUser = (user) => {
    const filtered = addedUsers.filter((item) => item.email !== user.email)

    setAddedUsers(filtered)
  }

  const shouldShowInfo = () => {
    if (!store.tariffInfo) return false

    return store.members?.length >= store.currentTariff?.included_members
  }

  useEffect(() => {
    if (!store.members.length) store.loadMembers()
    api('/payments/tariff_string/', {}, 'GET').then((data) => {
      if (!data.errors) {
        setPrice(data.price)
      }
    })
    if (!store.tariffInfo) store.getTariffInfo()
  }, [])

  useEffect(() => {
    if (!store.jurs) store.loadSelect('companyOptions')
  }, [])

  return (
    <PageWrap
      showAdding={['Manage Team Members', 'add']}
      gridStyle={{ width: 1200 }}
    >
      <PageTitle>Add Team Members</PageTitle>
      <Grid>
        <div style={{ paddingRight: 30 }}>
          <Title style={{ marginTop: '1em' }}>Adding Team Members</Title>
          <PlainText style={{ fontSize: '.875em' }}>
            Every team grows and changes with time. Add more users to your plan
            and get your new team members up and running!
          </PlainText>
          {store.tariffInfo && shouldShowInfo() && (
            <BlueBlock>
              Users added to {plans[store.company[0].tariff]} Plan{' '}
              {store.company[0].is_premium && ' + Premium Line of Business'}
              <br /> will be charged at{' '}
              <span style={{ fontFamily: 'var(--bold)' }}>
                ${store.currentTariff?.per_member_over_included} per user per
                month
              </span>
            </BlueBlock>
          )}

          <Form
            editing={editing}
            defaultValues={defaultValues}
            handleEditing={() => {
              setEditing(false)
              setDefaultValues({
                jurisdictions: [],
                topics: [],
                line_of_business: [],
                assign: 'jurisdictions',
              })
            }}
            onAddUser={(user) => setAddedUsers([...addedUsers, user])}
            additionalUsers={addedUsers}
          />
        </div>
        <YourTeamMembers
          additionalUsers={addedUsers}
          onDelete={(user) => deleteAdditionalUser(user)}
          onClearAdditionalFromState={() => setAddedUsers([])}
        />
      </Grid>
    </PageWrap>
  )
})

export default AddTeamMemberProfile
