import { toJS } from 'mobx'
import moment from 'moment'
import React, { useCallback } from 'react'
import ReactDOM from "react-dom";
import Moment from 'react-moment'
import Cookies from 'universal-cookie'
import { api } from '../api'
import Validation from '../components/common/Validation/validation'
import { statuses, reportMyTaskStatuses, trackingMyTaskStatuses } from '../pages/Tracking/Table/table'
import CustomPrompt from '../components/common/CustomPrompt/CustomPrompt'
export const tariffs = {
  topic_tract: 'TopicTrack',
  juris_tract: 'JurisTrack',
  total_tract: 'TotalTrack',
  select_tract: 'SelectTrack',
}

export const verifyToken = () => {
  const cookies = new Cookies()
  const token = cookies.get('pbm_token')

  return !(!token || token === 'undefined')
}

export const formatDate = (value, backend) => {
  const month = value.getMonth() + 1
  const day = value.getDate()
  const year = value.getFullYear()

  const addZero = (value) => {
    return value < 10 ? '0' + value : value
  }

  return backend
    ? `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day
    }`
    : `${addZero(month)}/${addZero(day)}/${year}`
}

export const getDescription = (arr, toEqual, varName) => {
  if (Array.isArray(toEqual)) {
    return toEqual
      .map((item) => {
        const foundItem = arr?.find((findItem) => findItem.id === item)

        if (foundItem) {
          return foundItem.is_deprecated
            ? foundItem[varName || 'name'] + ' (deprecated)'
            : foundItem[varName || 'name']
        } else {
          return null
        }
      })
      ?.join(', ')
  }
  if (arr?.length) {
    const foundItem = arr.find((item) => toJS(item?.id) === toEqual)

    if (foundItem) {
      return foundItem.is_deprecated
        ? foundItem[varName || 'name'] + ' (deprecated)'
        : foundItem[varName || 'name']
    }

    return null
  }
}
export const modifyDescription = (store, data) => {
  if (getDescription(store, data)) {
    return getDescription(store, data)
      .split(',')
      .join('<br />')
  }
  getDescription(store, data)
}

export const getMember = (arr, toEqual) => {
  if (arr.length) {
    return toJS(
      arr.find((item) => toJS(item?.key) === toEqual)?.props?.children || null
    )
  }
}

export const checkForRegisterPassed = (company, isReportSource) => {
  if (isReportSource) {
    return !!(
      company.name &&
      company.is_report_source_registration_passed
    )
  }

  return !!(
    company.name &&
    company.tariff &&
    company.start_date &&
    company.topics.length &&
    company.jurisdictions.length
  )
}

export const highlightSearchedWords = (text, searchWords) => {
  if (!text || !searchWords) {
    return text;
  }
  const regex = new RegExp(searchWords, 'gi');

  return text.replace(regex, match => `<mark style="background-color: var(--yellow);">${match}</mark>`);
};


export const redirectToUnfilledPage = (history, store, isLogin) => {
  const company = store.company[0]
  const user = store.user[0]

  if (user.account_type !== 'regular_user') {
    return history.push('/dashboard-landing')
  }

  if (company.started_from_report_source && !company.have_access_to_resource && !company.have_access_to_proposed_source && !company.have_access_to_report_source) {
    return history.push('/businessInfo')
  }

  if (company.started_from_proposed_source && !company.have_access_to_resource && !company.have_access_to_proposed_source && !company.have_access_to_report_source) {
    return history.push('/businessInfo')
  }

  if (company.started_from_resource && !company.have_access_to_resource && !company.have_access_to_proposed_source && !company.have_access_to_report_source) {
    return history.push('/businessInfo')
  }

  if (company.have_access_to_tracking && !company.tracking_registration_date) {
    return history.push('/billing/tracking')
  }

  if (!company.have_access_to_tracking && company.have_access_to_report_source) {
    if (localStorage.getItem('lastSessionPathname')) {
      return history.push(localStorage.getItem('lastSessionPathname'))
    }
    return history.push('/service-selection')
  }

  if (!company.have_access_to_tracking && company.have_access_to_proposed_source) {
    if (localStorage.getItem('lastSessionPathname')) {
      return history.push(localStorage.getItem('lastSessionPathname'))
    }
    return history.push('/service-selection')
  }

  if (
    (!company.have_access_to_tracking || !user.have_access_to_tracking) &&
    !isLogin
  )
    return history.push('/resource/table/guides')

  if (!company.name) {
    history.push('/businessInfo')
    // } else if (!company.tariff) {
    //   history.push('/pricing')
  } else if ((!company.topics.length || !company.jurisdictions.length)
    && !company.started_from_report_source
    && !company.started_from_proposed_source
    && !company.started_from_resource) {
    history.push('/topicsAndJur')
  }
  else {
    const legislativeToGo = localStorage.getItem('legislativeToGo')
    const assignee = localStorage.getItem('assignee')

    if (user.have_access_to_tracking) {
      if (legislativeToGo && legislativeToGo !== 'null') {
        return history.push(legislativeToGo)
      } else if (assignee) {
        return history.push(assignee)
      } else {
        if (localStorage.getItem('lastSessionPathname')) {
          return history.push(localStorage.getItem('lastSessionPathname'))
        }
        return history.push('/service-selection')
      }
    }

    if (user.have_access_to_resource) {
      if (localStorage.getItem('lastSessionPathname')) {
        return history.push(localStorage.getItem('lastSessionPathname'))
      }
      return history.push('/service-selection')
    }
    if (user.have_access_to_report_source) {
      if (localStorage.getItem('lastSessionPathname')) {
        return history.push(localStorage.getItem('lastSessionPathname'))
      }
      return history.push('/service-selection')
    }
    if (user.have_access_to_proposed_source) {
      if (localStorage.getItem('lastSessionPathname')) {
        return history.push(localStorage.getItem('lastSessionPathname'))
      }
      return history.push('/service-selection')
    }
    if (user.have_access_to_report_source) {
      return history.push('/service-selection')
    }
    if (user.have_access_to_proposed_source) {
      return history.push('/service-selection')
    }
  }
  return false
}

export const checkUrlAndWriteToLS = (pathname, search) => {
  const cookies = new Cookies()
  const splitedPathName = pathname.split('/')
  if (
    (splitedPathName[1] === 'tracking' || splitedPathName[1] === 'myTasks') &&
    !cookies.get('pbm_token') &&
    splitedPathName[2]
  ) {
    const compliance_item = new URLSearchParams(search).get('compliance_item')
    const assignee = new URLSearchParams(search).get('assignee')

    if (!compliance_item && !assignee) return

    localStorage.setItem(
      compliance_item ? 'legislativeToGo' : 'assignee',
      `${pathname}?${compliance_item ? 'compliance_item' : 'assignee'}=${compliance_item || assignee
      }`
    )
    window.location.href = '/login'
  }
}

export const callbackGetCompany = (data, setLoading, store, history) => {
  setLoading(false)
  if (!data.errors) {
    store.setSingleValue('company', data)
    const company = data[0]

    if (company.payment_status === 'paid') {
      history.push(`/tracking`)
    } else if (!company.name) {
      history.push(`/businessInfo/${company.id}`)
    } else if (!company.tariff || company.tariff === 'trial') {
      history.push(`/pricing`)
    }
    else if (!company.started_from_report_source || !company.jurisdictions || !company.topics) {
      history.push(`/topicsAndJur`)
    }
    else if (company.members_count < 2) {
      history.push(`/addMember`)
    } else if (company.payment_status === 'trial') {
      history.push(`/billing`)
    } else {
      // какая нужна страница, если ничего не понятно?
      history.push(`/tracking`)
    }
  }
}

export const getDirtyFields = (dirtyFields, values) => {
  let result = {}

  if (!Object.keys(dirtyFields).length) return null

  for (let key in dirtyFields) {
    result[key] = values[key]
  }

  return result
}

export function isPasswordValid(value) {

  const uppercaseRegex = /[A-Z]/;
  if (!uppercaseRegex.test(value)) {
    return false;
  }

  const lowercaseRegex = /[a-z]/;
  if (!lowercaseRegex.test(value)) {
    return false;
  }

  const numberRegex = /[0-9]/;
  if (!numberRegex.test(value)) {
    return false;
  }

  const specialCharRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/;
  if (!specialCharRegex.test(value)) {
    return false;
  }

  return true;
}

export const validateResetPassword = (getValues, success, errors) => {
  if (
    getValues('password')?.length < 8 ||
    getValues('password_confirm')?.length < 8
  ) {
    return 'Please Ensure that you password meets all of the following criteria:'
  } else if (getValues('password') !== getValues('password_confirm')) {
    return 'New passwords do not match. Please try again.'
  } else if (success) {
    return `Your Password has been reset!`
  } else if (
    errors.password?.type === 'required' ||
    errors.password_confirm?.type === 'required'
  ) {
    return 'Please Ensure that you password meets all of the following criteria:'
  } else {
    return ''
  }
}

export const resetPasswordValidationBlock = (
  getValues,
  success,
  errors,
  upperText
) => (
  <Validation
    style={{
      marginTop: '3.5em',
      marginBottom: '3.5em',
    }}
    upperText={upperText ?? validateResetPassword(getValues, success, errors)}
    noLineThrough={
      getValues('password') && getValues('password_confirm')
        ? getValues('password')?.length < 8 ||
        getValues('password') !== getValues('password_confirm')
        : true
    }
    success={success}
  />
)

export const getError = (data, setError) => {
  let error = ''

  if (!data.errors) return data.message || 'error'

  if (typeof data.errors === 'string') return data.errors

  for (let key in data.errors) {
    error = data.errors[key]

    setError &&
      setError(key, {
        type: 'manual',
        message: Array.isArray(error)
          ? error
            .map((item) => item)
            .join(',')
            .charAt(0)
            .toUpperCase() +
          error
            .map((item) => item)
            .join(',')
            .slice(1)
          : data.errors[key],
      })
  }

  return error
}

export const getInputError = (error, message) => {
  return error && (message || 'true')
}

export const getReadStatuses = () => {
  api('/legislative/read_for_current_user/', {}, 'GET').then((data) => {
    if (!data.errors) {
      localStorage.setItem('readItems', JSON.stringify(data))
    }
  })
}

export const getReportsReadStatuses = () => {
  api('/report_company/read_for_current_user/', {}, 'GET').then((data) => {
    if (!data.errors) {
      localStorage.setItem('readReportItems', JSON.stringify(data))
    }
  })
}


export const setStatusToRead = (id) => {
  api(`/legislative/${id}/read/`, {}, 'POST').then((data) => {
    if (!data.errors) {
      const readItems = JSON.parse(localStorage.getItem('readItems'))
      localStorage.setItem(
        'readItems',
        JSON.stringify({ ...readItems, ...data })
      )
    }
  })
}

export const setReportToRead = (id) => {

  const readItems = JSON.parse(localStorage.getItem('readReportItems'))
  localStorage.setItem(
    'readItems',
    JSON.stringify({ ...readItems, ...{ id: true } })
  )
}

export const getDate = (date, msg) => {
  return date &&
    (moment(date, 'MM/DD/YYYY', true).isValid() ||
      moment(date, 'MM/DD/YY', true).isValid() ||
      moment(date, 'YYYY-MM-DD', true).isValid()) ? (
    <Moment format={'MM/DD/YYYY'}>{date}</Moment>
  ) : (
    msg || 'See notes'
  )
}

export const getJurisdictionCode = (id, store, index) => {
  return store.resourceFilters?.jurisdictions.find((item) => item[0] === id)[
    index || 2
  ]
}

export const getLocalStorageItem = (id) => {
  const itemsObj = JSON.parse(localStorage.getItem('readItems'))
  return (itemsObj && itemsObj[id]) || false
}
export const getReportLocalStorageItem = (id) => {
  const itemsObj = JSON.parse(localStorage.getItem('readReportItems'))
  return (itemsObj && itemsObj[id]) || false
}

export const getRecurentReportIdLocalStorage = () => {
  const recurentReportId = JSON.parse(localStorage.getItem('recurentReportId'))
  return (recurentReportId && recurentReportId) || false
}

export const getStatusColor = (status, data) => {
  if (status === 'closed') return 'grey'
  if (status === 'monitor') return '#CBE0EC'

  if (!data) return '#fff'
  if (
    !isNaN(Date.parse(data.compliance_date)) &&
    new Date(data.compliance_date).getTime() < new Date().getTime()
  )
    return 'red'

  return data.status !== 'closed' && data.status !== 'in_progress'
    ? getLocalStorageItem(data.id)
      ? statuses?.[status]?.[1]
      : statuses?.['unread']?.[1]
    : statuses?.[status]?.[1]
}

export const getTaskReportStatusColor = (status, data) => {
  if (status === 'incomplete') return 'green'
  if (status === 'complete') return 'grey'
}

export const getReportStatusColor = (status, data) => {
  if (status === 'closed' || status === 'closed_submitted' || status === 'closed_na') return 'grey'

  if (!data) return '#fff'
  if (
    !isNaN(Date.parse(data.report_due)) &&
    new Date(data.report_due).getTime() < new Date().getTime()
  )
    return 'red'

  return data.status !== 'closed' && data.status !== 'in_progress'
    ? getReportLocalStorageItem(data.id)
      ? statuses?.[status]?.[1]
      : statuses?.['unread']?.[1]
    : statuses?.[status]?.[1]
}
export const getReportMyTaskStatusColor = (status, record) => {

  if (
    !isNaN(Date.parse(record.report_due)) &&
    new Date(record.report_due).getTime() < new Date().getTime()
  )
    return 'red'

  return reportMyTaskStatuses?.[status]?.[1]
}

export const getTrackingMyTaskStatusColor = (status, record) => {

  if (
    !isNaN(Date.parse(record?.legislative?.end_date)) &&
    new Date(record?.legislative?.end_date).getTime() < new Date().getTime()
  )
    return 'red'

  return trackingMyTaskStatuses?.[status]?.[1]
}

export const modifyPaymentCardValues = (values) => {
  const args = {
    ...values,
    card_exp_date: `${values.month}${values.year}`,
    card_number: values?.card_number?.replace(/\s+/g, ''),
  }

  delete args.month
  delete args.year

  return args
}

export const checkRole = (me, roles) => {
  return !roles || me?.some((role) => roles.includes(role))
}

export const checkForTrial = (store) =>
  store.company.length > 0 && store.company[0].payment_status === 'trial'

export const userConfirmation = (message, callback) => {
  const node = document.getElementById("custom-prompt");

  const cleanUp = (answer) => {
    ReactDOM.unmountComponentAtNode(node);
    callback(answer);
  };

  ReactDOM.render(<CustomPrompt message={message} cleanUp={cleanUp} />, node);
};

export function filterByTopicIds(ids, items) {
  return items.filter(item => ids.includes(item.topic));
}