import { get, set, toJS, values } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import Input from '../../../../components/common/Input'
import {
  getDescription,
  getJurisdictionCode,
} from '../../../../helpers/helpers'
import { useStore } from '../../../../mobx-store/context'
import List from '../../components/List'
import { Item } from '../../components/List/style'
import { Text } from '../../components/style'
import { Wrap } from '../Right/style'

const Left = observer(({ active, setActive, setLoading, loading }) => {
  const history = useHistory()
  const location = useLocation()
  const params = useParams()
  const store = useStore()
  const [selectedLineOfBusiness, setSelectedLineOfBusiness] = useState(store.resourceLob || []);
  const { control, reset, getValues } = useForm()

  const isGuides = params.type === 'guides'

  const jurs = store.user?.[0]?.resource_jurisdictions.map((item) => ({
    id: item,
    name: getDescription(store.jurs, item),
  }))

  const resources = store.resources?.filter((item) =>
    store.user?.[0]?.resource_guides.includes(item.guide_name_id)
  )


  const getJurisdictions = () => {
    const uniqueRows = store.resourcesCurrentTopic?.rows
      .map((e) => e.jurisdiction)
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter((e) => store.resourcesCurrentTopic?.rows[e])
      .map((e) => store.resourcesCurrentTopic?.rows[e]);


    const index = uniqueRows.findIndex(e => e.jurisdiction === 1);


    if (index > 0) {
      const [item] = uniqueRows.splice(index, 1);
      uniqueRows.unshift(item);
    }

    return uniqueRows;
  }
  // const getJurisdictions = () => {
  //   return (
  //     store.resourcesCurrentTopic?.rows
  //       .map((e) => e.jurisdiction)
  //       .map((e, i, final) => final.indexOf(e) === i && i)
  //       .filter((e) => store.resourcesCurrentTopic?.rows[e])
  //       .map((e) => store.resourcesCurrentTopic?.rows[e])
  //   )
  // }

  const handleSetActive = (item) => {
    if ((!item.requirements && !item.rows[0].requirements) || loading) return
    setActive(item)
    setLoading(true)
    history.push(
      `/resource/guides-jurisdictions-list/${params.type}/${item.id}/${params.selectId}`
    )
    store.getResourceTopic(item.id, () => {
      setLoading(false)
    }, null, params.selectId
    )
  }

  const getOptions = (type, all = true) => {
    const options = store.resourceFilters?.[type]

    return options && (all ? [['_all', 'All'], ...options] : options)
  }

  const filteredOptions = useMemo(() => {
    let options = isGuides ? getJurisdictions() : toJS(store.resourceFiltered);
    if (selectedLineOfBusiness && selectedLineOfBusiness.length > 0) {
      isGuides ?
        options = options.filter(item =>
          item.line_of_business.some(line => selectedLineOfBusiness.includes(line))
        )
        : options = options.filter(item =>
          item.all_guide_lines_of_business.some(line => selectedLineOfBusiness.includes(line))
        )
    }

    return isGuides
      ? options?.map((item) => (
        <Item
          key={item.jurisdiction || item.id} // ключ должен быть уникальным
          active={item.jurisdiction === active?.jurisdiction}
          id={'active'}
          onClick={() => {
            if (!item.requirements) return
            if (+item.id !== +params.id) {
              setActive(item)
              history.push(
                `/resource/guides-jurisdictions-list/${params.type}/${item.id}/${params.selectId}/${item.jurisdiction}`
              )
            }
          }}
          cursor={item.requirements}
          disabled={!item.requirements}
        >
          {getJurisdictionCode(item.jurisdiction, store, 1)}
          <span
            style={{
              color: !item.requirements && '#bbb',
              width: 80,
              textAlign: 'center',
            }}
          >
            {item.requirements ? 'Yes' : 'No'}
          </span>
        </Item>
      ))
      : options
  }, [selectedLineOfBusiness, store.resourceFiltered, store.resourceFilters, active, store.resourcesCurrentTopic]);

  useEffect(() => {
    reset({
      topicJurs: +params.selectId,
    })
  }, [location])

  useEffect(() => {
    const items = isGuides
      ? store.resourcesCurrentTopic?.rows
      : store.resourceFiltered

    const activeItem = items?.find((item) => {
      if (isGuides) {
        return +item.jurisdiction === +params.jurisdictionID
      } else {
        return +item.id === +params.id
      }
    })

    if (
      activeItem &&
      (activeItem?.rows?.[0]?.requirements || activeItem?.requirements)
    )
      return setActive(activeItem)

    const newActiveItem = items?.find((item) => {
      return isGuides ? item.requirements : item?.rows?.[0]?.requirements
    })

    if (
      newActiveItem &&
      !activeItem?.rows?.[0]?.requirements &&
      !activeItem?.requirements
    ) {
      history.push(
        `/resource/guides-jurisdictions-list/${params.type}/${newActiveItem.id
        }/${params.selectId}/${params.jurisdictionID || ''}`
      )
    }

    setActive(
      isGuides
        ? +activeItem?.jurisdiction === +params.id && activeItem?.requirements
          ? activeItem
          : newActiveItem
        : +activeItem?.id === +params.id && activeItem?.rows[0].requirements
          ? activeItem
          : newActiveItem
    )
  }, [store.resourcesCurrentTopic])

  useEffect(() => {
    store.setFilteredResources(store?.resourcesTable)
  }, [])


  return (
    <Wrap>
      <Input
        name={'topicJurs'}
        style={{ marginBottom: 10 }}
        control={control}
        label={`Select a ${store.resourcesSelect?.type === 'guides'
          ? 'ReSource Guide'
          : 'Jurisdiction'
          }`}
        type={'select'}
        defaultValue={+params.selectId || null}
        allowClear={false}
        handleChange={(id) => {
          isGuides ? store.getResourceTopic(id, null, null, +params.selectId) : store.getJurisdiction(id)
          !isGuides && store.setSingleValue('currentJurId', id)
          history.push(
            isGuides ? `/resource/guides-jurisdictions-list/${params.type}/${params.id}/${id}/${params.jurisdictionID}`
              : `/resource/guides-jurisdictions-list/${params.type}/${params.id}/${id}/${store.currentJurId}`
          )
        }}
        options={isGuides ? resources : jurs.sort((x, y) => {
          return x.name === 'Federal' ? -1 : y.name === 'Federal' ? 1 : 0;
        })}
        showSearch={params.type !== 'guides'}
        getPopupContainer={true}
      />
      <Input
        name={'line_of_business'}
        control={control}
        type={'multiSelect'}
        selectAll={false}
        options={getOptions('line_of_business', false)}
        selectVars={{ value: 0, name: 1 }}
        label={'Line of Business'}
        smallLabel
        defaultValue={store?.resourceLob || []}
        loading={!store.resourceFilters?.line_of_business}
        handleChange={() => {
          setSelectedLineOfBusiness(getValues('line_of_business'))
          store.setSingleValue('resourceLob', getValues('line_of_business'))
        }}
        allowClear={false}
        styleRow={{ maxWidth: 400 }}
        getPopupContainer={true}
      />

      <Text style={{ marginTop: 25 }}>
        Select a {isGuides ? 'Jurisdiction' : 'ReSource Guide'}
      </Text>
      <List
        options={filteredOptions}
        active={active}
        setActive={handleSetActive}
        customOptions={isGuides}
        loading={loading}
      />
    </Wrap>
  )
})

export default Left
