import React, { useState, useEffect } from 'react'
import { Item, DeleteCover } from './style'
import { api } from '../../../../../api'
import deleteIcon from '../../../../../assets/images/delete-icon.svg'
import { Empty } from 'antd'

const List = () => {
  const [proposedItems, setProposedItems] = useState([])
  const [subscription, setSubscription] = useState(false)

  const fetchProposedItems = () => {
    api('/proposed_source/subscribed/', {}, 'GET').then((response) => {
      if (!response.errors) {
        setProposedItems(response)
      }
    }).catch((error) => {
      console.error(error)
    })
  }

  const fetchSubscription = () => {
    api('/proposed_source/', {}, 'GET').then((response) => {
      if (!response.errors) {
        setSubscription(response.subscribed_to_all_proposed_source_items)
      }
    }).catch((error) => {
      console.error(error)
    })
  }

  const toggleSubscription = () => {
    api('/proposed_source/unsubscribe_for_all/', {}, 'POST').then((response) => {
      if (!response.errors) {
        setSubscription(false)
        fetchProposedItems()
      }
    }).catch((error) => {
      console.error(error)
    })
  }

  const handleDelete = (id) => {
    api(`/proposed_source/${id}/toggle_subscribe/`, {}, 'POST').then((response) => {
      if (!response.errors) {
        fetchProposedItems()
      }
    }).catch((error) => {
      console.error(error)
    })
  }

  useEffect(() => {
    fetchSubscription()
    fetchProposedItems()
  }, [])

  const renderContent = () => {
    if (isEmpty && subscription) {
      return (
        <Item>
          <div>
            Receive All Notifications
          </div>
          <DeleteCover
            style={{ display: 'flex', alignItems: 'center' }}
            onClick={toggleSubscription}>
            Delete
            <img style={{ marginLeft: 5 }} src={deleteIcon} alt='delete-icon' />
          </DeleteCover>
        </Item>
      )
    } else if (isEmpty) {
      return <Empty />
    } else {
      return proposedItems.map((item) => (
        <Item key={item.id}>
          <div style={{ textDecoration: 'underline' }}>
            {item.name}
          </div>
          <DeleteCover
            style={{ display: 'flex', alignItems: 'center' }}
            onClick={() => handleDelete(item.id)}>
            Delete
            <img style={{ marginLeft: 5 }} src={deleteIcon} alt='delete-icon' />
          </DeleteCover>
        </Item>
      ))
    }
  }

  const isEmpty = proposedItems.length === 0

  return (
    <div>
      {renderContent()}
    </div>
  )
}


export default List
