import React from 'react'
import Input from '../../../components/common/Input'
import Validation from '../../../components/common/Validation/validation'
import Activities from './activities'
import CommentContainer from './Comment/commentContainer'
import CommentForm from './CommentForm/commentForm'
import MyEditor from './Details'
import Documents from './documents'
import { Block } from './right.style'
import ReportMaterials from './ReportMaterials'
import ReportTimePeriod from './ReportMaterials/ReportTimePeriod'
import EditReportMaterials from './EditReportMaterials'
import GetReady from './ReportMaterials/GetReady/GetReady'
import GetReadyLight from './ReportMaterials/GetReady/index'
import Considerations from './Considerations'

export const showTabs = (obj) => {
  return {
    details: {
      render: () => <MyEditor 
      reportId={obj.data?.report?.id} 
      legislative={obj.data?.id} 
      isReportSource={obj.isReportSource} 
      detailsState={obj.detailsState} 
      handleDetailsState={obj.handleDetailsState} />,
    },
    comments: {
      render: () => (
        <>
          <CommentForm reportId={obj.data?.report?.id} legislative={obj.data?.id} isReportSource={obj.isReportSource} />

          <Block style={{ paddingBottom: '1.5em' }}>
            <CommentContainer reportId={obj.data?.report?.id} legislative={obj.data?.id} isReportSource={obj.isReportSource} />
          </Block>
        </>
      ),
    },
    documents_working: {
      render: () => (
        <>
          {obj.isReportSource ?
            <Input
              type={'file'}
              control={obj.control}
              name={'file'}
              placeholder={''}
              handleChange={(e) => {
                obj.postFile(e, null, null, false, 'working')
              }}
              loading={obj.loading.file}
              styleContainer={{ marginTop: '1em' }}
              label={'Upload Document'}
              labelStyle={obj.labelStyle}
              transparent
            />
            : <Input
              type={'file'}
              control={obj.control}
              name={'file'}
              placeholder={''}
              handleChange={(e) => {
                obj.postFile(e)
              }}
              loading={obj.loading.file}
              styleContainer={{ marginTop: '1em' }}
              label={'Upload Document'}
              labelStyle={obj.labelStyle}
              transparent
            />
          }
          <Documents reportId={obj.data?.report?.id} legislative={obj.data.id} getTasks={obj.getTasks} isReportSource={obj.isReportSource} status={'working'} activeTab={obj.activeTab} />
          {obj.successFile && (
            <Validation
              noLineThrough
              success
              text={<span>{obj.successFile} was Uploaded</span>}
            />
          )}
        </>
      ),
    },
    documents_submitted: {
      render: () => (
        <>
          {obj.isReportSource ?

            <Input
              type={'file'}
              control={obj.control}
              name={'file'}
              placeholder={''}
              handleChange={(e) => {
                obj.postFile(e, null, null, false, 'submitted')
              }}
              loading={obj.loading.file}
              styleContainer={{ marginTop: '1em' }}
              label={'Upload Document'}
              labelStyle={obj.labelStyle}
              transparent
            />
            : <Input
              type={'file'}
              control={obj.control}
              name={'file'}
              placeholder={''}
              handleChange={(e) => {
                obj.postFile(e)
              }}
              loading={obj.loading.file}
              styleContainer={{ marginTop: '1em' }}
              label={'Upload Document'}
              labelStyle={obj.labelStyle}
              transparent
            />
          }
          <Documents reportId={obj.data?.report?.id} legislative={obj.data.id} getTasks={obj.getTasks} isReportSource={obj.isReportSource} status={'submitted'} />
          {obj.successFile && (
            <Validation
              noLineThrough
              success
              text={<span>{obj.successFile} was Uploaded</span>}
            />
          )}
        </>
      ),
    },
    documents_finalized: {
      render: () => (
        <>
          {obj.isReportSource ?

            <Input
              type={'file'}
              control={obj.control}
              name={'file'}
              placeholder={''}
              handleChange={(e) => {
                obj.postFile(e, null, null, false, 'finalized')
              }}
              loading={obj.loading.file}
              styleContainer={{ marginTop: '1em' }}
              label={'Upload Document'}
              labelStyle={obj.labelStyle}
              transparent
            />
            : <Input
              type={'file'}
              control={obj.control}
              name={'file'}
              placeholder={''}
              handleChange={(e) => {
                obj.postFile(e)
              }}
              loading={obj.loading.file}
              styleContainer={{ marginTop: '1em' }}
              label={'Upload Document'}
              labelStyle={obj.labelStyle}
              transparent
            />
          }
          <Documents reportId={obj.data?.report?.id} legislative={obj.data.id} getTasks={obj.getTasks} isReportSource={obj.isReportSource} status={'finalized'} />
          {obj.successFile && (
            <Validation
              noLineThrough
              success
              text={<span>{obj.successFile} was Uploaded</span>}
            />
          )}
        </>
      ),
    },
    documents_deficiency: {
      render: () => (
        <>
          {obj.isReportSource ?

            <Input
              type={'file'}
              control={obj.control}
              name={'file'}
              placeholder={''}
              handleChange={(e) => {
                obj.postFile(e, null, null, false, 'deficiency')
              }}
              loading={obj.loading.file}
              styleContainer={{ marginTop: '1em' }}
              label={'Upload Document'}
              labelStyle={obj.labelStyle}
              transparent
            />
            : <Input
              type={'file'}
              control={obj.control}
              name={'file'}
              placeholder={''}
              handleChange={(e) => {
                obj.postFile(e)
              }}
              loading={obj.loading.file}
              styleContainer={{ marginTop: '1em' }}
              label={'Upload Document'}
              labelStyle={obj.labelStyle}
              transparent
            />
          }
          <Documents reportId={obj.data?.report?.id} legislative={obj.data.id} getTasks={obj.getTasks} isReportSource={obj.isReportSource} status={'deficiency'} />
          {obj.successFile && (
            <Validation
              noLineThrough
              success
              text={<span>{obj.successFile} was Uploaded</span>}
            />
          )}
        </>
      ),
    },
    documents_other: {
      render: () => (
        <>
          {obj.isReportSource ?

            <Input
              type={'file'}
              control={obj.control}
              name={'file'}
              placeholder={''}
              handleChange={(e) => {
                obj.postFile(e, null, null, false, 'other')
              }}
              loading={obj.loading.file}
              styleContainer={{ marginTop: '1em' }}
              label={'Upload Document'}
              labelStyle={obj.labelStyle}
              transparent
            />
            : <Input
              type={'file'}
              control={obj.control}
              name={'file'}
              placeholder={''}
              handleChange={(e) => {
                obj.postFile(e)
              }}
              loading={obj.loading.file}
              styleContainer={{ marginTop: '1em' }}
              label={'Upload Document'}
              labelStyle={obj.labelStyle}
              transparent
            />
          }
          <Documents reportId={obj.data?.report?.id} legislative={obj.data.id} getTasks={obj.getTasks} isReportSource={obj.isReportSource} status={'other'} />
          {obj.successFile && (
            <Validation
              noLineThrough
              success
              text={<span>{obj.successFile} was Uploaded</span>}
            />
          )}
        </>
      ),
    },
    considerations: {
      render: () => <Considerations legislative={obj.data.id}
        assignee={obj.data.assignee}
        status={obj.data.status}
        visible={obj.visible}
        handleClose={obj.handleClose}
        handleOpen={obj.handleOpen}
        assigneNew={obj.assigneNew}
        setAssigneNew={obj.setAssigneNew}
        getConsiderationData={obj.getConsiderationData}
        considerationData={obj.considerationData}
      />,
    },
    activity: {
      render: () => <Activities reportId={obj.data?.report?.id} legislative={obj.data.id} isReportSource={obj.isReportSource} />,
    },
  }
}

export const showReportMaterialsTabs = ({ data, isMyTasks, receiveGetReadyData, handleOpen, getReadyData, setAssigneNew, showModeBlocks }) => {
  return {
    getReady: {
      render: () => showModeBlocks
        ? <GetReady
          title={data?.checkboxes_title}
          assignee={data?.assignee}
          getReadyData={getReadyData}
          receiveGetReadyData={receiveGetReadyData}
          reportCompanyId={data.id}
          handleOpen={handleOpen}
          setAssigneNew={setAssigneNew}
        />
        : <GetReadyLight
          title={data?.checkboxes_title}
          assignee={data?.assignee}
          toDoData={data.checkboxes_data}
          receiveGetReadyData={receiveGetReadyData}
          reportCompanyId={data.id}
          isMyTasks={isMyTasks}
        />
    },
    reportPeriod: {
      render: () => <ReportTimePeriod isTips={false} isMyTasks={isMyTasks} />
    },
    forms: {
      render: () => <ReportMaterials
        reportId={data?.report?.id}
        reportCompanyId={data?.id}
        materialType={'forms'}
        isMyTasks={isMyTasks} />
    },
    notes: {
      render: () => <ReportMaterials
        reportId={data?.report?.id}
        reportCompanyId={data?.id}
        materialType={'notes'}
        isMyTasks={isMyTasks} />
    },
    guidance: {
      render: () => <ReportMaterials
        reportId={data?.report?.id}
        reportCompanyId={data?.id}
        materialType={'guidances'}
        isMyTasks={isMyTasks} />
    },
    tips: {
      render: () => <ReportTimePeriod isTips={true}
        isMyTasks={isMyTasks} />
    },
  }
}

export const editReportMaterialsTabs = ({ control }) => {
  return {
    forms: {
      render: () => <EditReportMaterials control={control} materialType={'forms'} />
    },
    instructions: {
      render: () => <EditReportMaterials control={control} materialType={'instructions'} />
    },
    guidance: {
      render: () => <EditReportMaterials control={control} materialType={'guidances'} />
    },
    definitions: {
      render: () => <EditReportMaterials control={control} materialType={'definitions'} />
    },
  }
}
