import { message } from 'antd'
import { observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'
import { api } from '../../../api'
import img from '../../../assets/images/reset-password.svg'
import Logo from '../../../components/common/Logo/logo'
import { useStore } from '../../../mobx-store/context'
import { useForm } from 'react-hook-form'
import {
  BackButton,
  Container,
  Description,
  Wrap,
} from '../Agreement/agreement.style'
import { Title } from '../authForm.style'
import { Image, MiniTitle } from '../ResetPass/resetPass.style'
import { useState } from 'react'
import { useCookies } from 'react-cookie'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import ReactCodeInput from "react-code-input";

import {
  getReadStatuses,
  getReportsReadStatuses,
  redirectToUnfilledPage,
} from '../../../helpers/helpers'

const ValidateAccount = observer(({ otpVaidation = false }) => {
  const store = useStore()
  const [pinCode, setPinCode] = useState("");
  const [cookies, setCookie, removeCookie] = useCookies(['pbm_token', 'userId'])
  const history = useHistory()
  const email = localStorage.getItem('email')

  const sendAgain = () => {
    api('/users/resend_device_confirmation_email/ ', { email: email }, 'POST', true).then((data) => {
      if (!data.errors) {
        message.success('Email sent')
      } else {
        message.error('Error while sending email')
      }
    })
  }

  const handlePinChange = (pinCode) => {
    setPinCode(pinCode);
    if (pinCode.length === 6) {
      const url = otpVaidation
        ? '/users/confirm_otp/'
        : '/users/confirm_device/'
      api(url,
        otpVaidation ? {
          otp_code: pinCode,
          email: localStorage.getItem('email'),
        }
          : { verification_code: pinCode },
        'POST', true).then((data) => {

          if (otpVaidation ? data.message === "Authentication code confirmed" : data.message === "Device confirmed") {
            setCookie('pbm_token', data.access, { path: '/' })
            getReadStatuses()
            getReportsReadStatuses()

            store.getCompany(() => {
              store.getUser(() => {
                localStorage.setItem('accountType', store.user[0].account_type)
                localStorage.setItem('required_to_change_temporary_password', store.user[0].required_to_change_temporary_password)
                localStorage.setItem('is_time_to_change_password', store.user[0].is_time_to_change_password)
                store.getResourceTariffInfo(() => {
                  if (
                    store.company[0].started_from_resource &&
                    !store.company[0].have_access_to_tracking &&
                    store.company[0].have_access_to_resource
                  ) {
                    history.push('/resource/table/guides')
                  } else {
                    redirectToUnfilledPage(history, store, true)
                  }
                  localStorage.removeItem('legislativeToGo')
                  localStorage.removeItem('assignee')
                  localStorage.removeItem('lastSessionPathname')
                })
              })
            })
            store.loadSelect('companyOptions', () =>
              store.getSelectedJurisdictions()
            )
            message.success(otpVaidation ? 'Authentication code confirmed' : 'Device confirmed')
            localStorage.removeItem('email')
          } else {
            message.error('Incorrect Code')
            setPinCode('')
          }
        })
    }
  };


  return (
    <Container>
      <Wrap disableScroll>
        <Logo large />
        <Title
          style={{
            borderBottom: '1px solid rgba(196, 202, 204, 0.6)',
            paddingBottom: '.7em',
            textAlign: 'center',
          }}
        >
          To continue, please validate your account
        </Title>
        <Image src={img} alat={'email-image'} />
        {otpVaidation ?
          <MiniTitle style={{ marginTop: 15 }}>Check your OTP application for the authentication code.
          </MiniTitle>
          : <MiniTitle style={{ marginTop: 15 }}>A verification email with an authentication code was sent to:<br />
            {email}
          </MiniTitle>
        }
        <Title
          style={{
            marginTop: 20,
            textAlign: 'center',
          }}
        >
          Authentication Code
        </Title>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <ReactCodeInput
            id="pinCode"
            type="tel"
            fields={6}
            onChange={handlePinChange}
            value={pinCode}
          />
        </div>
        {!otpVaidation && (
          <Description
            style={{
              fontFamily: 'var(--regular)',
              fontSize: '.875em',
              textAlign: 'center',
            }}
          >
            <span style={{ fontFamily: 'var(--medium)' }}>
              Didn’t get the message? Check your spam folder or
              <span
                style={{
                  color: 'var(--orange)',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
                onClick={sendAgain}
              >
                {' '}send it again
              </span>
            </span>
          </Description>
        )}
        <BackButton to={'/login'} as={Link}>
          &lt;&lt; {'Return to Sign In'}
        </BackButton>
      </Wrap>
    </Container>
  )
})

export default ValidateAccount
