import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'
import { api } from '../../../../api'
import ButtonOrange from '../../../../components/common/ButtonOrange'
import { checkRole } from '../../../../helpers/helpers'
import useDidMountEffect from '../../../../hooks/useEffectNotOnPageLoad'
import useModal from '../../../../hooks/useModal'
import { useStore } from '../../../../mobx-store/context'
import { Label } from '../right.style'
import List from './List'
import Modal from './Modal'
import { Placeholder, Wrap } from './style'

const Tasks = observer(
  ({
    data,
    getCurrentLegislative,
    postFile,
    isMyTasks,
    currentPage,
    loadingFile,
    complianceItem,
    getTasks,
    tasks,
    activeTab,
    setActiveTab,
    isReportSource = false,
    visible,
    handleClose,
    handleOpen,
    assigneNew,
    setAssigneNew,
    getConsiderationData,
    receiveGetReadyData,
  }) => {
    const store = useStore()
    const { control, handleSubmit, errors, reset, getValues } = useForm()
    // const { visible, handleClose, handleOpen } = useModal()
    const [shouldComplete, setShouldComplete] = useState(false)
    const [temporaryFiles, setTemporaryFiles] = useState([])
    const [loading, setLoading] = useState(false)
    const [currentTask, setCurrentTask] = useState()

    const params = useParams()
    const history = useHistory()

    const ID = data.id
    const reportId = data?.report?.id

    let search = new URL(document.location).searchParams
    let taskID = search.get('taskID')

    const onComplete = (values) => {
      api(isReportSource ?
        `/report_company/${store.currentLegislative.id}/tasks/${taskID || store.currentLegislative.id}/complete/`
        : `/tasks/${taskID || store.currentLegislative.id}/complete/`,
        { text: values.description },
        'POST'
      ).then((data) => {
        setLoading(false)

        if (!data.errors) {
          temporaryFiles.length > 0 &&
            temporaryFiles.map((item) => {
              postFile(null, taskID, item, true)
            })
          setTemporaryFiles([])
          getCurrentLegislative(ID, null, null, true, isReportSource)
          isReportSource ? receiveGetReadyData() : getConsiderationData()
          getTasks()
          store.loadActivity(store.currentLegislative.id, 1, null, isReportSource)
          handleClose()
          store[isMyTasks ? 'loadTasks' : 'loadLegislatives']({
            page: currentPage,
          }, isReportSource)
          const routePath = `/${isReportSource ? 'report-source' : 'tracking'}`;
          const routeType = isMyTasks ? 'myTasks' : params.tabName === 'main' ? 'main' : params.tabName === 'apcd' ? 'apcd' : params.tabName === 'all' ? 'all' : params.tabName === 'monitor' ? 'monitor' : 'closed';
          const queryParam = isReportSource ? 'report' : 'compliance_item';
          const complianceItemId = complianceItem || ID;
          const route = `${routePath}/${routeType}/?${queryParam}=${complianceItemId}`;
          history.push(route);
        }
        setActiveTab()
        setShouldComplete(false)
      })
    }

    const onSubmit = (values) => {
      setLoading(true)
      if (shouldComplete) return onComplete(values)
      const url =
        isReportSource
          ? taskID && !assigneNew
            ? `/report_company/${store.currentLegislative.id}/tasks/${taskID}/`
            : `/report_company/${store.currentLegislative.id}/tasks/`
          : taskID && !assigneNew
            ? `/tasks/${taskID || store.currentLegislative.id}/`
            : '/tasks/'
      const object = taskID && !assigneNew ? {
        legislative: store.currentLegislative.id,
        ...values,
      }
        : !!store.getReadyTask && !!store.getReadyTask.id ?
          {
            legislative: store.currentLegislative.id,
            type: isReportSource ? !!store.getReadyTask && !!store.getReadyTask.id ? 'get_ready_task' : 'default' : store.considerationTask || 'default',
            get_ready_checkbox: store.getReadyTask.id,
            ...values,
          }
          : {
            legislative: store.currentLegislative.id,
            type: isReportSource ? !!store.getReadyTask && !!store.getReadyTask.id ? 'get_ready_task' : 'default' : store.considerationTask || 'default',

            ...values,
          }
      api(
        url,
        object,
        taskID && !assigneNew ? 'PATCH' : 'POST'
      ).then((data) => {
        setLoading(false)
        isReportSource ? receiveGetReadyData() : getConsiderationData()
        isReportSource ? store.setSingleValue('getReadyTask', null) : store.setSingleValue('considerationTask', null)
        if (!data.errors) {
          handleClose()
          getCurrentLegislative(ID, null, null, true, isReportSource)
          store.loadActivity(ID, 1, null, isReportSource)
          store[isMyTasks ? 'loadTasks' : 'loadLegislatives']({
            page: currentPage,
          }, isReportSource)
          getTasks()
          const routePath = isReportSource ? '/report-source/' : '/tracking/';
          const routeType = isMyTasks
            ? 'myTasks'
            : params.tabName === 'main'
              ? 'main'
              : params.tabName === 'apcd'
                ? 'apcd'
                : params.tabName === 'all'
                  ? 'all'
                  : params.tabName === 'monitor'
                    ? 'monitor'
                    : params.tabName === 'closed'
                      ? 'closed'
                      : 'all';
          const queryParam = isReportSource ? 'report' : 'compliance_item';
          const complianceItemId = ID || params.id;
          const route = `${routePath}${routeType}?${queryParam}=${complianceItemId}`;
          history.push(route)
          setAssigneNew(false)
        }
      })
    }

    const addFiles = (files) => {
      setTemporaryFiles(files)
      reset({ ...getValues(), file: null })
    }

    useEffect(() => {
      getTasks()
    }, [store.currentLegislative])

    useDidMountEffect(() => {
      if (!visible) {
        reset({ file: null })
      }
    }, [visible])

    return (
      <Wrap name={'tasks-block'}>
        <Label>Tasks</Label>
        {visible && (
          <Modal
            data={currentTask}
            control={control}
            onClose={() => {
              handleClose()
              reset({})
              setShouldComplete(false)
              setAssigneNew(false)
              setTemporaryFiles([])
              setCurrentTask(null)
              store.setSingleValue('getReadyTask', null)
              store.setSingleValue('considerationTask', null)
            }}
            onSubmit={handleSubmit(onSubmit)}
            errors={errors}
            assigneNew={assigneNew}
            complete={shouldComplete}
            postFile={postFile}
            taskID={taskID}
            tasks={tasks}
            complianceItem={complianceItem}
            loadingFile={loadingFile}
            redCircleDate={data?.compliance_date}
            temporaryFiles={temporaryFiles}
            setTemporaryFiles={addFiles}
            loading={loading}
            getTasks={getTasks}
            activeTab={activeTab}
            isReportSource={isReportSource}
            getConsiderationData={getConsiderationData}
            receiveGetReadyData={receiveGetReadyData}
          />
        )}
        {!data.assignee && (
          <Placeholder>{isReportSource
            ? 'You must assign a Report Owner before assigning tasks.'
            : 'You must assign a Compliance Owner before assigning tasks.'
          }
          </Placeholder>
        )}
        {tasks.length > 0 && (
          <List
            currentTask={currentTask}
            setCurrentTask={setCurrentTask}
            tasks={tasks}
            complianceItem={complianceItem}
            isMyTasks={isMyTasks}
            taskID={taskID}
            onModalOpen={(data) => {
              reset(data)
              handleOpen()
            }}
            onComplete={() => {
              setShouldComplete(true)
              setAssigneNew(false)
              handleOpen()
            }}
            data={data}
            getTasks={getTasks}
            isReportSource={isReportSource}
            activeTab={activeTab}
          />
        )}
        {isReportSource
          ? data.assignee &&
          checkRole(
            [store.user?.[0]?.report_source_role],
            ['owner', 'admin', 'report_owner']
          ) &&
          data.status !== 'closed' && (
            <ButtonOrange
              text={'Assign a Task'}
              onClick={() => {
                reset({
                  assignee: null,
                  due_date: null,
                  description: null,
                })
                setAssigneNew(true)
                handleOpen()
              }}
            />
          )
          : data.assignee &&
          checkRole(
            [store.user?.[0]?.member_type],
            ['owner', 'admin', 'compliance_owner']
          ) &&
          data.status !== 'closed' && (
            <ButtonOrange
              text={'Assign a Task'}
              onClick={() => {
                reset({
                  assignee: null,
                  due_date: null,
                  description: null,
                })
                setAssigneNew(true)
                handleOpen()
              }}
            />
          )}
      </Wrap>
    )
  }
)

export default Tasks
