import { observer } from 'mobx-react-lite'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import plusBlue from '../../../assets/images/plus-blue.svg'
import CustomPromptModal from '../../../components/common/CustomPrompt/CustomPromptModal'
import ItemsShowing from '../../../components/common/TableItemsShowing'
import { checkRole, getLocalStorageItem, getReportLocalStorageItem } from '../../../helpers/helpers'
import { useStore } from '../../../mobx-store/context'
import { columnsCommon, columnsUltraLite, columnsCommonReports, columnsTasks, columnsTasksReports, getRecord } from './columns'
import Empty from './EmptyTable/empty'
import { toJS } from 'mobx'
import {
  Header,
  HeaderButton,
  HeaderTitle,
  TableStyled,
  Wrap,
  MonthName,
  Clear,
} from './table.style'

const tableHeaderButton = {
  main: {
    title: 'View My Tasks',
    key: 'myTasks',
  },
  myTasks: {
    title: 'View Tracking',
    key: 'main',
  },
  closed: {
    title: 'View Tracking',
    key: 'main',
  },
}

const tableHeaderButtonSecond = {
  main: {
    title: 'View Closed',
    key: 'closed',
  },
  myTasks: {
    title: 'View Closed',
    key: 'closed',
  },
  closed: {
    title: 'View My Tasks',
    key: 'myTasks',
  },
}

const Table = observer(
  ({
    style,
    getCurrentLegislative,
    count,
    archived,
    all,
    isTrial,
    currentPage,
    onCurrentPageChange,
    activeTab,
    isMyTasks,
    complianceItem,
    onCreateNew,
    setTouched,
    setEditing,
    setCreateItemVisible,
    touched,
    isReportSource = false,
  }) => {
    const history = useHistory()
    const store = useStore()
    const params = useParams()
    const [sorter, setSorter] = useState({})
    const [sortLoading, setSortLoading] = useState(false)
    const [visible, setVisible] = useState(false);
    const [active, setActive] = useState(null)
    const [dataSource, setDataSource] = useState([])

    const trackingMode = store?.company[0]?.compliance_source_mode
    const showModeBlocks = isReportSource
      ? true
      : trackingMode === 'compliance_source' ? true : false

    const sortLegislatives = (direction, mutatedDirection, dataIndex, page) => {
      if (direction) {
        store.setSingleValue('searchableObject', {
          ...store.searchableObject,
          ordering: mutatedDirection[direction] + dataIndex,
        })
      } else {
        const newObj = store.searchableObject

        delete newObj.ordering

        store.setSingleValue('searchableObject', {
          ...newObj,
        })
      }
      store.loadLegislatives({
        page,
        handleLoading: () => setSortLoading(false),
      }, isReportSource)
    }

    const sort = (direction, dataIndex, page) => {
      const mutatedDirection = {
        ascend: '',
        descend: '-',
      }
      if (activeTab === 'myTasks') {
        if (direction) {
          store.setSingleValue('searchableObjectTasks', {
            ...store.searchableObjectTasks,
            ordering: mutatedDirection[direction] + dataIndex,
          })
        } else {
          const newObj = store.searchableObjectTasks

          delete newObj.ordering

          store.setSingleValue('searchableObjectTasks', {
            ...newObj,
          })
        }
        store.loadTasks({
          page,
          handleLoading: () => setSortLoading(false),
        }, isReportSource)
      } else {
        sortLegislatives(direction, mutatedDirection, dataIndex, page)
      }
    }

    const onClear = () => {
      store.setSingleValue('filterMode', false)
      store.setSingleValue('activeSearchMonth', null)

      store.setSingleValue('searchableObject', {})

      store.loadLegislatives({ page: 1, page_size: 20 }, isReportSource)
    }

    const handleTableChange = (sorter, page) => {
      setSorter(sorter)
      setSortLoading(true)
      sort(
        sorter.order,
        Array.isArray(sorter.field) ? sorter.field[1] : sorter.field,
        page
      )
    }

    const onClick = (record) => {
      const id = getRecord({ record, activeTab, isMyTasks, isReportSource })?.id

      getCurrentLegislative(
        getRecord({ record, activeTab, isMyTasks, isReportSource })?.id
      )

      !isMyTasks && Object.keys(store.searchableObject) !== 0 && store.loadLegislatives({ page: currentPage }, isReportSource)

      setActive(+id)
      history.push(`/${isReportSource
        ? 'report-source'
        : 'tracking'}/${activeTab}/?${isReportSource
          ? 'report'
          : 'compliance_item'
        }=${id}`)

      const readItems =
        isReportSource
          ? JSON.parse(localStorage.getItem('readReportItems'))
          : JSON.parse(localStorage.getItem('readItems'))

      isReportSource
        ? localStorage.setItem(
          'readReportItems',
          JSON.stringify({ ...readItems, [id]: true })
        )
        : localStorage.setItem(
          'readItems',
          JSON.stringify({ ...readItems, [id]: true })
        )
    }

    const onConfirm = (record) => {
      setTouched(false);
      setVisible(false);
      onClick(record)
    }

    useEffect(() => {
      setSorter(null)
    }, [archived, activeTab])

    useEffect(() => {
      if (isReportSource) {
        if (store.searchableObject?.status === 'unread') {
          const data = store.legislatives.results.filter(
            (item) => !JSON.parse(localStorage.getItem('readReportItems'))[item.id]
          )

          return setDataSource(data)
        }
        if (store.searchableObject?.status === 'read') {
          const data = store.legislatives.results.filter(
            (item) => JSON.parse(localStorage.getItem('readReportItems'))[item.id]
          )

          return setDataSource(data)
        }

        return setDataSource(store?.legislatives?.results)
      } else {
        if (store.searchableObject?.status === 'unread') {
          const data = store.legislatives.results.filter(
            (item) => !JSON.parse(localStorage.getItem('readItems'))[item.id]
          )

          return setDataSource(data)
        }
        if (store.searchableObject?.status === 'read') {
          const data = store.legislatives.results.filter(
            (item) => JSON.parse(localStorage.getItem('readItems'))[item.id]
          )

          return setDataSource(data)
        }

        return setDataSource(store?.legislatives?.results)
      }
    }, [isMyTasks, store.legislatives, store.legislatives?.results, isReportSource])

    useEffect(() => {
      if (isMyTasks) {
        setDataSource(store.tasks?.results)
      }
    }, [isMyTasks, store.tasks])

    const isLoading = isMyTasks ? !store.tasks : !store.legislatives

    const canCreateNew =
      !isMyTasks &&
      !archived &&
      showModeBlocks &&
      checkRole(
        isReportSource
          ? [store.user[0].report_source_role]
          : [store.user[0].member_type],
        ['admin', isReportSource ? 'report_owner' : 'compliance_owner']
      );

    if (!store.topics) return null

    return (
      <Wrap style={style}>
        <Header>
          <HeaderTitle>
            {archived
              ? 'Tracking Archive'
              : all
                ? isReportSource
                  ? 'All Reports'
                  : ' All Compliance Items'
                : isMyTasks
                  ? 'My Tasks'
                  : isReportSource
                    ? store.activeSearchMonth
                      ? 'Reports Currently Being Tracked:'
                      : 'Reports Currently Being Tracked'
                    : 'Compliance Items Currently Being Tracked'
            }
            {store.activeSearchMonth &&
              <>
                <MonthName>{store.activeSearchMonth}</MonthName>
                <Clear onClick={onClear}>(clear)</Clear>
              </>

            }
          </HeaderTitle>
          <div style={{ display: 'flex' }}>
            {canCreateNew && (
              <HeaderButton onClick={onCreateNew} style={{ marginRight: 10 }}>
                Create Your Own
                <img src={plusBlue} alt='plus-icon' />
              </HeaderButton>
            )}
          </div>
        </Header>

        <TableStyled
          locale={{
            emptyText: <Empty isReportSource={isReportSource} />,
          }}
          dataSource={dataSource}
          rowKey={(record) => record.id} // Обязательно должны быть разные ID !!!!!
          columns={
            isReportSource
              ? isMyTasks
                ? columnsTasksReports({ store, sorter, isMyTasks, activeTab })
                : columnsCommonReports({ store, sorter, isMyTasks, activeTab })
              : isMyTasks
                ? columnsTasks({
                  store,
                  sorter,
                  isMyTasks,
                  activeTab,
                  reference_number: {
                    width: 200,
                  },
                })
                : store?.company[0]?.compliance_source_mode === 'compliance_source_ultra_lite'
                  ? columnsUltraLite({ store, sorter, isMyTasks, activeTab })
                  : columnsCommon({ store, sorter, isMyTasks, activeTab })
          }
          onChange={(pagination, _, sorter) => {
            handleTableChange(sorter, pagination.current)
            onCurrentPageChange(pagination.current)
          }}
          loading={isLoading || sortLoading}
          size='small'
          pagination={{
            position: ['bottomLeft'],
            pageSize: isReportSource ? 20 : isTrial ? 10 : 20,
            hideOnSinglePage: false,
            total: isReportSource ? count : isTrial ? 10 : count,
            disabled: isLoading,
            current: currentPage,
            showSizeChanger: false,
          }}
          rowClassName={(record) => {
            if (isReportSource) {
              if (+getRecord({ record, activeTab, isMyTasks, isReportSource })?.id === (+params.id || +complianceItem)) {
                return 'active-row'
              }
              if (!getReportLocalStorageItem(+getRecord({ record, activeTab, isMyTasks, isReportSource })?.id)) {
                return 'unread-row'
              }
            } else {
              if (+getRecord({ record, activeTab, isMyTasks, isReportSource })?.id === (+params.id || +complianceItem)) {
                return 'active-row'
              }
              if (!getLocalStorageItem(+getRecord({ record, activeTab, isMyTasks, isReportSource })?.id)) {
                return 'unread-row'
              }
            }

          }}
          onRow={(record) => {
            return {
              onClick: () => {
                if (touched) { setVisible(true) }
                else {
                  onClick(record)
                }
              },
            }
          }}
        />

        <ItemsShowing
          page={currentPage}
          pageSize={isTrial ? 10 : 20}
          total={isMyTasks ? store.tasks?.count : store.legislatives?.count}
          style={{
            textAlign: 'right',
            marginTop: -31,
            paddingRight: 10,
            width: 'fit-content',
            marginBottom: 10,
          }}
        />
        {visible &&
          <CustomPromptModal
            onCancel={() => setVisible(false)}
            onConfirm={() => {
              setTouched(false);
              setVisible(false);
              setEditing(false);
              setCreateItemVisible(false);
            }}
          />
        }
      </Wrap>
    )
  }
)

Table.propTypes = {
  style: PropTypes.object,
}

export default Table

export const statuses = {
  read: ['Read', 'var(--blue)'],
  unread: ['Unread', 'var(--orange)'],
  in_progress: ['In progress', 'var(--green)'],
  closed: ['Closed', 'grey'],
  all_tasks_completed: ['All Tasks Completed', 'var(--green)'],
  complete: ['Completed', 'var(--green)'],
  incomplete: ['Incomplete', 'var(--orange)'],
  unassigned: ['Unassigned', 'var(--orange)'],
  monitor: ['Monitor', '#CBE0EC'],
  in_progress_deficiency: ['In progress', 'var(--green)'],
  closed_submitted: ['Closed-Submitted', 'grey'],
  closed_na: ['Closed-NA', 'grey'],
}
export const statusesTaskReport = {
  complete: ['Completed', 'var(--green)'],
  incomplete: ['In Progress', 'var(--orange)'],

}

export const reportMyTaskStatuses = {
  report_owner: ['Report Owner', 'var(--blue)'],
  report_owner_and_all_tasks_completed: ['Report Owner: All Tasks Completed', 'var(--green)'],
  report_owner_and_have_incomplete_tasks: ['Report Owner: Have Incomplete Tasks', 'var(--orange)'],
  all_tasks_completed: ['All Tasks Completed', 'var(--green)'],
  have_incomplete_tasks: ['Have Incomplete Tasks', 'var(--orange)'],
}

export const trackingMyTaskStatuses = {
  compliance_owner: ['Compliance Owner', 'var(--blue)'],
  compliance_owner_and_all_tasks_completed: ['Compliance Owner: All Tasks Completed', 'var(--green)'],
  compliance_owner_and_have_incomplete_tasks: ['Compliance Owner: Have Incomplete Tasks', 'var(--orange)'],
  all_tasks_completed: ['All Tasks Completed', 'var(--green)'],
  have_incomplete_tasks: ['Have Incomplete Tasks', 'var(--orange)'],
}
