import styled from 'styled-components'

export const Wrap = styled("div")`
  position: relative;
  display: grid;
  grid-template-columns: 50% 50%;
`;

export const Right = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-height: 770px;
  }
`;

export const FormWrap = styled("div")`
  margin-top: 50px;
  margin-bottom: 100px;
  width: 100%;
  max-width: 850px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 3vw;
  z-index: 1;
  @media (max-aspect-ratio: 1/1), (max-width: 1000px) {
    width: 100%;
  }
`;

export const Title = styled("div")`
  font-size: 1.5em;
  font-family: var(--medium);
  color: var(--text);
  margin-top: 1em;
  span {
    font-family: var(--regular);
    border-left: 1px solid var(--text);
    padding-left: 0.5em;
    margin-left: 0.5em;
  }
`;

export const Description = styled("div")`
  font-size: 1em;
  margin-top: 0.5em;
  max-width: 538px;
  color: var(--text);
  font-family: var(--regular);
  line-height: 1.3em;
`;

export const HaveAcc = styled("span")`
  span {
    font-family: var(--semiBold);
    font-size: 0.875em;
    a {
      color: var(--orange);
      text-decoration: underline;
      font-size: 1em;
      font-family: var(--medium);
    }
  }
`;

export const FormTitle = styled("div")`
  font-size: 1em;
  margin-top: 1.5em;
  color: var(--text);
  font-family: var(--sf-d-bold);
`;

export const Bottom = styled("div")`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 463px;
  margin-top: 1.5em;
`;

export const SkipButton = styled("div")`
  color: #cacecf;
  font-family: var(--light);
  font-size: 1em;
  a {
    color: var(--orange);
    text-decoration: underline;
    font-family: var(--medium);
    margin-right: 0.5em;
  }
`;
