import { message } from 'antd'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { api } from '../../../../api'
import Button from '../../../../components/common/Button/button'
import Input from '../../../../components/common/Input'
import { Label } from '../../../../components/common/Input/input.style'
import Modal from '../../../../components/common/ModalNew/index'
import { getError } from '../../../../helpers/helpers'
import { useStore } from '../../../../mobx-store/context'
import { Cancel } from '../../Right/Tasks/Modal/style'
import { Select } from 'antd'

const AddNewModal = ({
  type = 'jurisdiction',
  onClose,
  setValue,
  value,
  edit,
  data,
  onSuccess,
  onRemove,
  adding,
  reset: resetMain,
  values: mainValues,
  isReportSource,
  cyo = false,
}) => {
  const { control, handleSubmit, reset, errors, getValues } = useForm()
  const store = useStore()
  const [loading, setLoading] = useState(false)

  console.log(data, 'data')

  const url = edit
    ? types(edit, adding)[type].url + data.id + '/'
    : types(edit, adding)[type].url

  const onSubmit = (values) => {
    setLoading(true)
    api(url, values, edit ? 'PUT' : 'POST').then((data) => {
      setLoading(false)
      if (data.errors) return message.error(getError(data))

      store.loadSelect('companyOptions')
      store.loadSelect('currentMemberOptions')
      store.getCompany()

      resetMain &&
        resetMain({
          ...mainValues,
          [type]:
            type === 'topics' || type === 'line_of_business'
              ? [...(value ?? []), data.id]
              : data.id,
        })
      onClose && onClose()
      onSuccess && onSuccess()
    })
  }

  useEffect(() => {
    if (edit) reset(data)
  }, [])

  return (
    <Modal onClose={onClose}>
      <Label
        style={{
          marginBottom: 15,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {types(edit, adding)[type].title}
        {edit && (
          <Cancel style={{ fontSize: 16 }} onClick={onRemove}>
            Remove
          </Cancel>
        )}
      </Label>
      {types(edit, adding)[type].inputs.map((item) => (
        item.name === 'topic'
          ? <Input
            type='select'
            options={store?.legislative_options?.topics}
            control={control}
            defaultValue={!edit && cyo && mainValues.topics}
            disabled={!edit && cyo}
            key={item.key}
            {...item}
            style={{ ...item.style, marginBottom: 15 }}
            error={errors[item.name] && 'This field is required'}
            smallLabel
            dropdownStyle={{ position: 'absolute', zIndex: 9999999 }}
          />
          :
          item.name === 'Users'
            ? <Input
              selectAll={true}
              type='multiSelect'
              defaultValue={edit ? (data ? data.users.length > 0 ? data.users : [data.user] : store?.user[0].id) : store?.user[0].id}
              options={store?.members?.filter(
                (item) => isReportSource
                  ? item.report_source_role !== 'member' && item.have_access_to_report_source
                  : item.member_type !== 'member' && item.have_access_to_tracking
              )
                .map((item) => ({
                  id: item.id,
                  name: item.first_name + ' ' + item.last_name
                }))}
              control={control}
              key={item.key}
              {...item}
              style={{ ...item.style, marginBottom: 15 }}
              error={errors[item.name] && 'This field is required'}
              smallLabel
              getPopupContainer={true}
              dropdownStyle={{ position: 'absolute', zIndex: 9999999 }}
            />
            : <Input
              control={control}
              key={item.key}
              {...item}
              style={{ ...item.style, marginBottom: 15 }}
              error={errors[item.name] && 'This field is required'}
              smallLabel
            />
      ))}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Cancel onClick={onClose}>Cancel</Cancel>
        <Button
          text='Submit'
          onClick={handleSubmit(onSubmit)}
          loading={loading}
        />
      </div>
    </Modal>
  )
}

export default AddNewModal

const types = (edit, adding) => ({
  jurisdiction: {
    title: edit ? 'Edit Jurisdiction' : 'Add New Jurisdiction',
    inputs: [
      {
        key: 0,
        name: 'name',
        label: 'Jurisdiction Name',
      },
      {
        key: 1,
        name: 'code',
        label: 'Jurisdiction Abbreviation',
        style: { width: '50%' },
      },
      {
        key: 2,
        name: 'Users',
        label: 'Users',
      }
    ],
    url: '/jurisdictions/',
  },
  report_source_jurisdictions: {
    title: edit ? 'Edit Jurisdiction' : 'Add New Jurisdiction',
    inputs: [
      {
        key: 0,
        name: 'name',
        label: 'Jurisdiction Name',
      },
      {
        key: 1,
        name: 'code',
        label: 'Jurisdiction Abbreviation',
        style: { width: '50%' },
      },
      {
        key: 2,
        name: 'Users',
        label: 'Users',
      }
    ],
    url: '/report_source_jurisdictions/',
  },
  topics: {
    title: edit ? 'Edit Topic' : 'Add New Topic',
    inputs: [
      {
        key: 0,
        name: 'name',
        label: 'Topic Name',
      },
      {
        key: 1,
        name: 'Users',
        label: 'Users',
      }
    ],
    url: '/topics/',
  },
  sub_topics: {
    title: edit ? 'Edit Sub-Topic' : 'Add New Sub-Topic',
    inputs: [
      {
        key: 0,
        name: 'name',
        label: 'Sub-Topic Name',
      },
      {
        key: 1,
        name: 'topic',
        label: 'Topic',
      },
    ],
    url: '/sub_topics/',
  },
  [adding ? 'line_of_business' : 'lines_of_business']: {
    title: edit ? 'Edit Lines of Business' : 'Add New Lines of Business',
    inputs: [
      {
        key: 0,
        name: 'name',
        label: 'Line of Business Name',
      },
      {
        key: 1,
        name: 'Users',
        label: 'Users',
      }
    ],
    url: '/lines_of_business/',
  },
  report_types: {
    title: edit ? 'Edit Report Type' : 'Add New Report Type',
    inputs: [
      {
        key: 0,
        name: 'name',
        label: 'Report Type Name',
      },
      {
        key: 1,
        name: 'Users',
        label: 'Users',
      }
    ],
    url: '/report_types/',
  },
  reporting_entities: {
    title: edit ? 'Edit Reporting Entity' : 'Add New Reporting Entity',
    inputs: [
      {
        key: 0,
        name: 'name',
        label: 'Report Entity Name',
      },
    ],
    url: '/reporting_entities/',
  },
})
