import { useEffect, useState } from 'react'
import { Empty } from 'antd'
import Checkbox from '../../../../../components/common/Checkbox'
import { useStore } from '../../../../../mobx-store/context'
import { checkRole } from '../../../../../helpers/helpers'
import { api } from '../../../../../api'
import { Block, EmptyBlock } from '../../right.style'
import Highlighter from "react-highlight-words";


const GetReady = ({ title, toDoData, reportCompanyId, isMyTasks }) => {
  const store = useStore()

  const [todoItems, setTodoItems] = useState([])
  const [todoTitle, setTodoTitle] = useState('')
  const [rcId, setRcId] = useState('')

  useEffect(() => {
    setTodoItems(toDoData)
    setTodoTitle(title)
    setRcId(reportCompanyId)
  }, [reportCompanyId, title, toDoData])



  const disabled = !checkRole(
    [store.user?.[0].report_source_role],
    ['admin', 'report_owner']
  )

  const handleToggleCheck = (id) => {
    const updatedItems = todoItems.map((item) => {
      if (item.id === id) {
        return { ...item, checked: !item.checked }
      }
      return item
    })
    api(`/report_company/${rcId}/toggle_get_ready_checkbox/`, { checkbox_id: id }, 'PATCH').then((data) => {
      if (!data.errors) {
        setTodoItems(updatedItems)
      }
    })
  }
  const highlightStyle = {
    backgroundColor: 'var(--yellow)', 
  };

  if (todoItems.length === 0) return (
    <EmptyBlock>
      Not Specified.
    </EmptyBlock>)
  return (
    <Block
      style={{
        marginTop: '1em',
        paddingBottom: '1em',
        minHeight: 120,
      }}
      bordered>
      <div style={{ fontFamily: 'var(--regular)', color: 'var(--text)', fontSize: 14 }}>
        <Highlighter
          searchWords={isMyTasks ? [store?.searchableObjectTasks?.search] : [store?.searchableObject?.search]}
          autoEscape={true}
          textToHighlight={todoTitle}
          highlightStyle={highlightStyle}
        />
      </div>
      {todoItems.map((item) => (
        <div style={{ display: 'flex', marginLeft: 5 }}>
          <Checkbox
            checked={item.checked}
            onChange={() => handleToggleCheck(item.id)}
            disabled={disabled}
          />
          <div style={{ marginLeft: 10, fontFamily: 'var(--regular)', color: 'var(--text)', fontSize: 14 }}>
            <Highlighter
              searchWords={isMyTasks ? [store?.searchableObjectTasks?.search] : [store?.searchableObject?.search]}
              autoEscape={true}
              textToHighlight={item.text}
              highlightStyle={highlightStyle}
            />
          </div>
        </div>
      ))}
    </Block>
  )
}

export default GetReady