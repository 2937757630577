import React, { useEffect, useState } from 'react';
import Input from "../../../../components/common/Input";
import { useForm } from 'react-hook-form';


function GetReadyList({ tasks, setTasks, }) {
  const { control } = useForm();
  const [count, setCount] = useState(1);

  const createNewTask = () => {
    setTasks([...tasks, { id: count, text: '', new: true }])
    setCount(count + 1)
  }

  const handleTaskChange = (id, newText) => {
    const updatedTasks = tasks.map(task =>
      task.id === id ? { ...task, text: newText } : task
    );
    setTasks(updatedTasks);
  };



  useEffect(() => {
    tasks.length === 0 && createNewTask();
  }, [tasks]);

  return (
    <div>
      <div
        style={{
          color: 'var(--text)',
          fontFamily: 'var(--medium)',
          fontSize: 14,
        }}
      >
        Get Ready Checkbox
      </div>
      {tasks.map(task => (
        <Input
          style={{ width: '100%', marginBottom: 10 }}
          control={control}
          type={'text'}
          name={`task-${task.id}`}
          defaultValue={task.text || ''}
          handleChange={(e) => handleTaskChange(task.id, e.target.value)}
        />
      ))}
      <div style={{
        color: 'var(--blue)',
        fontFamily: 'var(--bold)',
        fontSize: 12,
        cursor: 'pointer',
        textDecoration: 'underline',
      }} onClick={createNewTask}> {'Add Another'}</div>
    </div>
  );
}



export default GetReadyList;
