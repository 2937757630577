import { Checkbox, Select } from 'antd'
import { useGA4React } from 'ga-4-react'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import { api } from '../../../api'
import Button from '../../../components/common/Button/button'
import ButtonOrange from '../../../components/common/ButtonOrange'
import Input from '../../../components/common/Input'
import useDidMountEffect from '../../../hooks/useEffectNotOnPageLoad'
import { useStore } from '../../../mobx-store/context'
import { toJS } from 'mobx'
import { Bottom, Inputs, CalendarInputs, ShowMore, TopLine, Wrap, CheckboxCover, CheckboxName } from './search.style'

const taskStatuses = [
  {
    key: 0,
    value: 'incomplete',
    name: 'Incomplete',
  },
  {
    key: 1,
    value: 'complete',
    name: 'Completed',
  },
]

const Search = observer(
  ({ auth, closed, all, monitor, apcd, calendar, isTrial, onSearch, isMyTasks, activeTab, isReportSource = false, getData, showSearchByWord = true }) => {
    const store = useStore()
    const ga4React = useGA4React()
    const location = useLocation()
    const { control, handleSubmit, reset, setValue, register, watch } = useForm({
      reValidateMode: 'onChange',
      defaultValues: {
        completed_tasks: false,
        past_due_items: false,
        most_recent_items: false,
        // tab_name: activeTab,
      }
    })
    const [active, setActive] = useState(false)
    const [showSearchBlock, setSearchShowBlock] = useState(false)
    const [isDateRangeVisible, setIsDateRangeVisible] = useState(false)
    const [remamberLastSearch, setRemamberLastSearch] = useState(false)
    const [listHeight, setListHeight] = useState(170)
    const type = isMyTasks ? 'searchableObjectTasks' : 'searchableObject'

    const isAdmin = isReportSource ? store.user[0]?.report_source_role === 'admin' : store.user[0]?.member_type === 'admin'
    const isAdminOrOwner = isReportSource
      ? store.user[0]?.report_source_role === 'admin' || store.user[0]?.report_source_role === 'report_owner'
      : store.user[0]?.member_type === 'admin' || store.user[0]?.member_type === 'compliance_owner'
    const { only_admins_see_unassigned_report_source_items, only_admins_see_unassigned_compliance_source_items } = store.company[0] || {};

    useEffect(() => {
      const handleResize = () => {
        const width = window.innerWidth;
        if (width >= 1500) {
          setListHeight(200);
        } else if (width >= 1380) {
          setListHeight(170);
        } else {
          setListHeight(100);
        }
      };

      window.addEventListener('resize', handleResize);

      handleResize();

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);




    useEffect(() => {

      isReportSource ?
        setRemamberLastSearch(store.user[0].remember_my_last_search_report_source[activeTab])
        : setRemamberLastSearch(store.user[0].remember_my_last_search_compliance[activeTab])
    }, [activeTab, isReportSource, store.user])

    const trackingMode = isReportSource ? store.company[0]?.report_source_mode : store.company[0]?.compliance_source_mode
    const showModeBlocks = isReportSource
      ? trackingMode === 'report_source' ? true : false
      : trackingMode === 'compliance_source' ? true : false

    const isNotUltraLiteMode = isReportSource
      ? true
      : trackingMode === 'compliance_source_ultra_lite' ? false : true

    const getFilteredMembers = (members, haveAccessTo) =>
      members.filter(
        (item) =>
          item.member_type !== 'member' &&
          item.is_active &&
          item[haveAccessTo]
      ).map((item) => (
        <Select.Option value={item.id} key={item.id}>
          {item.first_name + ' ' + item.last_name}
        </Select.Option>
      ));

    const toggleLastSearch = () => {
      api(`/users/${store.user[0].id}/${isReportSource
        ? 'toggle_report_remember_my_search_checkbox'
        : 'toggle_remember_my_search_checkbox'}/`,
        { checkbox_name: activeTab },
        'POST')
        .then((data) => {
          setRemamberLastSearch(data.checked)
          data.checked && store.setSingleValue('firstTimeRememberSearch', true)
          store.getUser(() => getData())
        })
    }

    const haveAccessTo = isReportSource ? 'have_access_to_report_source' : 'have_access_to_tracking';
    const membersAdmins = getFilteredMembers(store?.members, haveAccessTo);

    const assigner = new URLSearchParams(location.search).get('assigner')

    const onSubmit = (values) => {
      onSearch()
      if (values.search === (store.searchableObject?.search || null)) {
        try {
          ga4React?.gtag('event', `search_tracking_extended`)
        } catch (e) {
          console.error('analytic search error', e)
        }
      }

      let searchObject = store[type] || {}

      searchObject && closed ? delete searchObject.actual : delete searchObject.status

      for (let key in values) {
        if (values[key]) {
          if (key === 'adopted_date' || key === 'compliance_date' || key === 'primary_due_date' || key === 'report_due_date') {
            const start = moment(values[key].split('±')[0]).format('YYYY-MM-DD')
            const end = moment(values[key].split('±')[1]).format('YYYY-MM-DD')
            searchObject[key + '_after'] = start
            searchObject[key + '_before'] = end
          } else {
            searchObject[key] = values[key]
          }
        } else {
          delete searchObject[key]
        }
      }


      if (isMyTasks) {
        store.setSingleValue('searchableObjectTasks', {
          ...searchObject,
        })
        store.loadTasks({ page: 1 }, isReportSource)
      } else {
        store.setSingleValue('legislativesStatus', closed
          ? 'closed'
          : all
            ? 'all'
            : monitor
              ? 'monitor'
              : apcd
                ? 'apcd'
                : calendar ?
                  'calendar'
                  : 'actual')
        if (calendar) {
          store.setSingleValue('searchableObject', {
            ...searchObject, ...store.calendarDateRange

          })
        } else {
          store.setSingleValue('searchableObject', {
            ...searchObject,

          })
        }
        store.loadLegislatives({ page: 1, page_size: isTrial ? 10 : 20 }, isReportSource)
      }
    }

    const shouldShowSearchBlock = () => {
      if (!store[type]) return setSearchShowBlock(false)

      const keysLength = Object.keys(store[type]).length

      if (keysLength === 1 && store[type].ordering) {
        return setSearchShowBlock(false)
      }
      if (keysLength > 0) {
        setSearchShowBlock(true)
      } else {
        setSearchShowBlock(false)
      }
    }
    const handleReset = (clear) => {
      reset({
        topics: null,
        line_of_business: null,
        search: '',
        reference_number: '',
        reference_type: null,
        adopted_date: '',
        jurisdiction: null,
        status: null,
        assignee: null,
        task_assignee: null,
        ties_to_license: null,
        primary_due_date: null,
        compliance_date: null,
        report_title: '',
        report_type: null,
        reporting_entity: null,
        report_to: '',
        report_due_date: null,
        report_owner: null,
        report_company_status: null,
        sub_topics: null,
      })

      store.setSingleValue('searchableObjectTasks', {})
      store.setSingleValue('searchableObject', {})

      store.setSingleValue('legislativesStatus', closed ? 'closed' : all ? 'all' : monitor ? 'monitor' : apcd
        ? 'apcd' : calendar ? 'calendar' : 'actual')

      const lastSearchName = isReportSource ? 'remember_my_last_search_report_source' : 'remember_my_last_search_compliance';
      store.user[0][lastSearchName][activeTab] && !clear ?
        api(`/users/${store?.user[0]?.id}/last_search_data/`, {}, 'GET').then((data) => {
          isMyTasks
            ? isReportSource
              ? store.setSingleValue('searchableObjectTasks', { ...data.report_data[activeTab], tab_name: activeTab })
              : store.setSingleValue('searchableObjectTasks', { ...data.compliance_data[activeTab], tab_name: activeTab })
            : isReportSource ?
              store.setSingleValue('searchableObject', { ...data.report_data[activeTab], tab_name: activeTab })
              : store.setSingleValue('searchableObject', { ...data.compliance_data[activeTab], tab_name: activeTab })
        }) : isMyTasks
          ? store.setSingleValue('searchableObjectTasks', { tab_name: activeTab })
          : store.setSingleValue('searchableObject', { tab_name: activeTab })

      store.setSingleValue('filterMode', false)
      store.setSingleValue('activeSearchMonth', null)
      calendar && store.setSingleValue('searchableObject', { tab_name: activeTab, ...store.calendarDateRange })

      clear &&
        store[isMyTasks ? 'loadTasks' : 'loadLegislatives']({
          page: 1,
          page_size: isTrial ? 10 : 20,
        }, isReportSource)

    }

    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        handleSubmit(onSubmit)();
      }
    };
    function filterByTopicIds(ids, items) {
      return items ? items.filter(item => ids.includes(item.topic)) : null;
    }
    useEffect(() => {
      store.loadSelect('currentMemberOptions')
      reset(store.searchableObject)
      if (!store.membersMentions.length || !store.members.length)
        store.loadMembers(null, isReportSource)
    }, [store.searchableObject])


    useEffect(() => {
      shouldShowSearchBlock()
    }, [store.searchableObject, store.searchableObjectTasks])

    useEffect(() => {
      reset({
        ...store[type],
      })
    }, [active])

    useEffect(() => {
      isMyTasks && setActive(false)
    }, [isMyTasks])

    useEffect(() => {
      if (assigner && !isMyTasks)
        store.setSingleValue('searchableObject', {
          ...store.searchableObject,
          assigner: +assigner,
        })
      let search = new URL(document.location).searchParams
      let assignee = search.get('assignee')

      if (assignee) {
        store.setSingleValue('searchableObject', {
          ...store.searchableObject,
          assignee: +assignee,
        })
      } else {
        const search = store.searchableObject

        if (search && search.assignee) {
          store.setSingleValue('searchableObject', null)
        }
      }
    }, [])

    useDidMountEffect(() => {
      setIsDateRangeVisible(false)
      handleReset()
    }, [activeTab])

    useEffect(
      () => () => {
        store.setSingleValue('searchableObject', {})
        store.setSingleValue('searchableObjectTasks', {})
        isReportSource ?
          activeTab === 'apcd' ? store.setSingleValue('legislativesStatus', 'apcd') : store.setSingleValue('legislativesStatus', 'actual')
          : store.setSingleValue('legislativesStatus', 'actual')
      }, [])

    useEffect(() => {
      store.getLegislativeOptions()
    }, [])

    const defaultDateRange = store.searchableObject?.adopted_date_after
      ? store.searchableObject?.adopted_date_after + '±' +
      store.searchableObject?.adopted_date_before
      : null

    const defaultComplianceRange = store.searchableObject?.compliance_date_after
      ? store.searchableObject?.compliance_date_after + '±' +
      store.searchableObject?.compliance_date_before
      : null

    const defaultPrimary = store.searchableObject?.primary_due_date_after
      ? store.searchableObject?.primary_due_date_after + '±' +
      store.searchableObject?.primary_due_date_before
      : null

    const defaultReportRange = store.searchableObject?.report_due_date_after
      ? store.searchableObject?.report_due_date_after + '±' +
      store.searchableObject?.report_due_date_before
      : null

    return (
      <Wrap calendar={calendar}>
        <TopLine calendar={calendar}>
          {showSearchByWord && <div style={{ display: 'flex', alignItems: 'center' }}>
            <Input
              type={'text'}
              handleClear={handleSubmit(onSubmit)}
              name={'search'}
              control={control}
              placeholder={isReportSource ? 'Search by Word' : 'Search with in Title and Summary'}
              onKeyDown={handleKeyDown}
              transparent
              style={{ height: 40, width: 313 }}
              validation={{
                validate: () => true,
              }}
            />
            <Button
              text={'Search'}
              type={'main'}
              style={{ marginLeft: '0.625em' }}
              blue
              onClick={handleSubmit(onSubmit)}
            />
            {(showSearchBlock || store.searchableObject?.search) && (
              <ButtonOrange
                text={'Clear'}
                style={{ marginLeft: '.5em' }}
                onClick={() => handleReset(true)}
              />
            )}
          </div>}
          {auth ? (
            <ShowMore style={{ textDecoration: 'none' }}>
              <Checkbox style={{ marginRight: '.1em' }} /> Only with Documents
            </ShowMore>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {calendar &&
                <ButtonOrange
                  text={'Clear'}
                  style={{ marginRight: '.5em' }}
                  onClick={() => handleReset(true)}
                />}
              <ShowMore
                onClick={() => {
                  setActive(!active)
                }}
              >
                {active
                  ? '<< Hide Search Options'
                  : 'Show Search Options >>'}
              </ShowMore>
            </div>
          )}
        </TopLine>
        <Bottom
          calendar={calendar}
          active={active}
          isDateRangeVisible={isDateRangeVisible}
          isMyTasks={isMyTasks}
        >
          {calendar ?
            <>
              <CalendarInputs showModeBlocks={showModeBlocks}>
                <Input
                  type={'text'}
                  name={'report_title'}
                  control={control}
                  placeholder={'Type a Report Name'}
                  label={'Report Title'}
                  handleChange={handleSubmit(onSubmit)}
                  smallLabel
                  transparent
                  validation={{
                    validate: () => true,
                  }}
                />
                <Input
                  type={'multiSelect'}
                  listHeight={listHeight}
                  selectAll={false}
                  name={'report_type'}
                  control={control}
                  options={store?.legislative_options?.report_types}
                  placeholder={'Select Report Type'}
                  label={'Report Type'}
                  handleChange={handleSubmit(onSubmit)}
                  smallLabel
                  transparent
                  getPopupContainer={true}
                />
                {showModeBlocks &&
                  <Input
                    type={'multiSelect'}
                    listHeight={listHeight}
                    selectAll={false}
                    name={'report_company_status'}
                    control={control}
                    options={
                      only_admins_see_unassigned_report_source_items && store.user[0]?.report_source_role === 'report_owner'
                        ? store.report_source_statuses?.filter((item) => item[0] !== 'monitor' && item[0] !== 'closed' && item[0] !== 'unassigned')
                        : store.statuses?.filter((item) => item[0] !== 'monitor' && item[0] !== 'closed')}
                    placeholder={'Select Current Status'}
                    label={'Current Status'}
                    disabled={false}
                    handleChange={handleSubmit(onSubmit)}
                    selectVars={{ value: 0, name: 1 }}
                    smallLabel
                    transparent
                    getPopupContainer={true}
                  />
                }
                <Input
                  type={'multiSelect'}
                  listHeight={listHeight}
                  selectAll={false}
                  name={'reporting_entity'}
                  control={control}
                  options={store?.legislative_options?.report_entities}
                  placeholder={'Select Report Entity'}
                  label={'Reporting Entity'}
                  handleChange={handleSubmit(onSubmit)}
                  smallLabel
                  transparent
                  getPopupContainer={true}
                />
              </CalendarInputs>
              <CalendarInputs showModeBlocks={showModeBlocks}>
                <Input
                  type={'multiSelect'}
                  listHeight={listHeight}
                  selectAll={false}
                  name={'jurisdiction'}
                  control={control}
                  placeholder={'Select Jurisdiction'}
                  options={store.legislative_options?.report_source_jurisdictions?.filter(
                    (item) => (item.is_system ? item.enabled : true)
                  )}
                  label={'Jurisdiction'}
                  handleChange={handleSubmit(onSubmit)}
                  showDeprecatedLabel
                  showDeprecated
                  showSearch
                  smallLabel
                  smaillList
                  transparent
                  getPopupContainer={true}
                />
                {
                  showModeBlocks &&
                  <Input
                    type={'multiSelect'}
                    listHeight={100}
                    selectAll={false}
                    name={'task_assignee'}
                    control={control}
                    customOptions={store.members
                      .filter(
                        (item) => item.is_active && item.have_access_to_tracking
                      )
                      .map((item) => (
                        <Select.Option value={item.id} key={item.id}>
                          {item.first_name + ' ' + item.last_name}
                        </Select.Option>
                      ))}
                    placeholder={'Select'}
                    handleChange={handleSubmit(onSubmit)}
                    label={'Has a Task Assigned To'}
                    smallLabel
                    smallList
                    transparent
                    showSearch
                    getPopupContainer={true}
                  />
                }
                <Input
                  type={'multiSelect'}
                  listHeight={listHeight}
                  selectAll={false}
                  name={'ties_to_license'}
                  control={control}
                  options={store?.legislative_options?.report_source_ties_to_license}
                  placeholder={'Select License(s)'}
                  handleChange={handleSubmit(onSubmit)}
                  label={'Report Ties To License'}
                  smallLabel
                  transparent
                  showSearch
                  getPopupContainer={true}
                />
                <Input
                  type={'text'}
                  name={'report_to'}
                  control={control}
                  placeholder={'Type to search'}
                  label={'Report To'}
                  handleChange={handleSubmit(onSubmit)}
                  smallLabel
                  transparent
                  getPopupContainer={true}
                  validation={{
                    validate: () => true,
                  }}
                />
              </CalendarInputs>
              <CalendarInputs showModeBlocks={showModeBlocks}>
                {showModeBlocks &&
                  <Input
                    type={'multiSelect'}
                    listHeight={listHeight}
                    selectAll={false}
                    name={'report_owner'}
                    control={control}
                    placeholder={'Select Owner'}
                    disabled={!isAdmin && !isMyTasks && !closed && only_admins_see_unassigned_report_source_items}
                    handleChange={handleSubmit(onSubmit)}
                    label={'Report Owner'}
                    customOptions={membersAdmins}
                    showSearch
                    smaillList
                    smallLabel
                    transparent
                    getPopupContainer={true}
                  />
                }
                <CheckboxCover style={{ marginLeft: 0, marginBottom: 0, marginTop: 25 }}>
                  <Checkbox
                    {...register("past_due_items")}
                    checked={watch('past_due_items')}
                    onChange={(e) => {
                      setValue('past_due_items', e.target.checked);
                      handleSubmit(onSubmit)();
                    }}
                    name={"past_due_items"}
                    style={{ marginRight: 5 }}
                  />
                  <CheckboxName
                    style={{
                      fontFamily: 'var(--medium)',
                      opacity: 1,
                      fontSize: 14
                    }}>{'Show only past due report items'}
                  </CheckboxName>
                </CheckboxCover>
                <CheckboxCover style={{ marginLeft: 0, marginBottom: 0, marginTop: 25 }}>
                  <Checkbox
                    {...register("only_new_reports")}
                    checked={watch('only_new_reports')}
                    onChange={(e) => {
                      setValue('only_new_reports', e.target.checked);
                      handleSubmit(onSubmit)();
                    }}
                    name={"only_new_reports"}
                    style={{ marginRight: 5 }}
                  />
                  <CheckboxName
                    style={{
                      fontFamily: 'var(--medium)',
                      opacity: 1,
                      fontSize: 14
                    }}>{'Show only new report items'}
                  </CheckboxName>
                </CheckboxCover>
                <CheckboxCover style={{ marginLeft: 0, marginBottom: 0, marginTop: 25 }}>
                  <Checkbox
                    {...register("only_new_requirements")}
                    checked={watch('only_new_requirements')}
                    onChange={(e) => {
                      setValue('only_new_requirements', e.target.checked);
                      handleSubmit(onSubmit)();
                    }}
                    name={"only_new_requirements"}
                    style={{ marginRight: 5 }}
                  />
                  <CheckboxName
                    style={{
                      fontFamily: 'var(--medium)',
                      opacity: 1,
                      fontSize: 14
                    }}>Show only report items with new requirements
                  </CheckboxName>
                </CheckboxCover>
              </CalendarInputs>
              <CalendarInputs showModeBlocks={showModeBlocks}>
                <CheckboxCover style={{ marginLeft: 0, marginBottom: 0, marginTop: 20 }}>
                  <Checkbox
                    checked={remamberLastSearch}
                    onChange={(e) => {
                      toggleLastSearch();

                    }}
                    name={"remember_last_search"}
                    style={{ marginRight: 5 }}
                  />
                  <CheckboxName
                    style={{
                      fontFamily: 'var(--medium)',
                      opacity: 1,
                      fontSize: 14
                    }}>Remember last search
                  </CheckboxName>
                </CheckboxCover>
              </CalendarInputs>
            </>
            : <>
              {isNotUltraLiteMode &&
                <Inputs style={{ marginBottom: '1em' }}>
                  {isReportSource ? (
                    <>
                      <Input
                        type={'text'}
                        name={'report_title'}
                        control={control}
                        placeholder={'Type a Report Name'}
                        label={'Report Title'}
                        handleChange={handleSubmit(onSubmit)}
                        smallLabel
                        transparent
                        validation={{
                          validate: () => true,
                        }}
                      />
                      <Input
                        type={'multiSelect'}
                        listHeight={listHeight}
                        selectAll={false}
                        name={'report_type'}
                        control={control}
                        options={activeTab === 'main'
                          ? store?.legislative_options?.report_types?.filter((item) => item.name !== 'APCD')
                          : store?.legislative_options?.report_types}
                        placeholder={'Select Report Type'}
                        label={'Report Type'}
                        handleChange={handleSubmit(onSubmit)}
                        smallLabel
                        disabled={apcd}
                        transparent
                        getPopupContainer={true}
                      />
                      {/* <Input
                  type={'select'}
                  name={'line_of_business'}
                  control={control}
                  placeholder={'Select Line of Business'}
                  // disabled={!store.company[0]?.is_premium}
                  label={'Line of Business'}
                  handleChange={handleSubmit(onSubmit)}
                  options={store.legislative_options?.report_source_line_of_business}
                  showDeprecatedLabel
                  showDeprecated
                  showSearch
                  smallLabel
                  transparent
                /> */}
                    </>
                  ) : (
                    <>
                      <Input
                        type={'multiSelect'}
                        listHeight={listHeight}
                        selectAll={false}
                        name={'topics'}
                        control={control}
                        placeholder={'Select Topic'}
                        options={store.legislative_options?.topics?.filter((item) =>
                          item.is_system ? item.enabled : true
                        )}
                        label={'Topic'}
                        handleChange={handleSubmit(onSubmit)}
                        showDeprecatedLabel
                        showDeprecated
                        showSearch
                        smallLabel
                        transparent
                        getPopupContainer={true}
                      />
                      <Input
                        type={'multiSelect'}
                        listHeight={listHeight}
                        selectAll={false}
                        name={'sub_topics'}
                        control={control}
                        placeholder={'Select Sub-Topic'}
                        options={
                          store[type]?.topics?.length ?
                            filterByTopicIds(toJS(store[type]?.topics), toJS(store?.legislative_options?.sub_topics))
                            : store.legislative_options?.sub_topics?.filter((item) =>
                              item.is_system ? item.enabled : true
                            )}
                        label={'Sub-Topic'}
                        handleChange={handleSubmit(onSubmit)}
                        showDeprecatedLabel
                        showDeprecated
                        showSearch
                        smallLabel
                        transparent
                        getPopupContainer={true}
                      />
                    </>
                  )}
                </Inputs>
              }
              <Inputs>
                {isReportSource ? (
                  <>
                    <Input
                      type={'multiSelect'}
                      listHeight={listHeight}
                      selectAll={false}
                      name={'reporting_entity'}
                      control={control}
                      options={store?.legislative_options?.report_entities}
                      placeholder={'Select Report Entity'}
                      label={'Reporting Entity'}
                      handleChange={handleSubmit(onSubmit)}
                      smallLabel
                      transparent
                      getPopupContainer={true}
                    />
                    <Input
                      type={'multiSelect'}
                      listHeight={listHeight}
                      selectAll={false}
                      name={'jurisdiction'}
                      control={control}
                      placeholder={'Select Jurisdiction'}
                      options={apcd
                        ? store.legislative_options?.report_source_apcd_jurisdictions?.filter(
                          (item) => (item.is_system ? item.enabled : true)
                        )
                        : store.legislative_options?.report_source_jurisdictions?.filter(
                          (item) => (item.is_system ? item.enabled : true)
                        )}
                      label={'Jurisdiction'}
                      handleChange={handleSubmit(onSubmit)}
                      showDeprecatedLabel
                      showDeprecated
                      showSearch
                      smallLabel
                      smaillList
                      transparent
                      getPopupContainer={true}
                    />
                  </>
                ) : (
                  <>
                    <Input
                      type={'text'}
                      name={'reference_number'}
                      control={control}
                      placeholder={'###ABCD'}
                      label={'Reference Number'}
                      handleChange={handleSubmit(onSubmit)}
                      smallLabel
                      transparent
                      getPopupContainer={true}
                      validation={{
                        validate: () => true,
                      }}
                    />
                    <Input
                      type={'multiSelect'}
                      listHeight={listHeight}
                      selectAll={false}
                      name={'reference_type'}
                      control={control}
                      options={store.legislative_options?.reference_types}
                      placeholder={'###ABCD'}
                      label={'Reference Type'}
                      handleChange={handleSubmit(onSubmit)}
                      smallLabel
                      transparent
                      getPopupContainer={true}
                    />
                  </>
                )}
              </Inputs>
              <Inputs>
                {isReportSource ? (
                  <>
                    <Input
                      type={'text'}
                      name={'report_to'}
                      control={control}
                      placeholder={'Type to search'}
                      label={'Report To'}
                      handleChange={handleSubmit(onSubmit)}
                      smallLabel
                      transparent
                      getPopupContainer={true}
                      validation={{
                        validate: () => true,
                      }}
                    />
                    <Input
                      type={'date_range'}
                      name={'report_due_date'}
                      control={control}
                      placeholder={'Select Report Due Date'}
                      label={'Report Due Date'}
                      handleChange={handleSubmit(onSubmit)}
                      defaultValue={defaultReportRange}
                      disabled={calendar}
                      isSearchOpened={active || showSearchBlock}
                      dateRangeStyle={{ bottom: 0 }}
                      smallLabel
                      transparent
                      getPopupContainer={true}
                      reset={() => {
                        setValue('report_due_date', null)
                        const search = store[type]

                        delete search.report_due_date_before
                        delete search.report_due_date_after

                        store.setSingleValue(type, search)
                      }}
                      handleShowDateRange={(value) => setIsDateRangeVisible(value)}
                      noLimit
                    />
                  </>
                ) : (
                  <>
                    <Input
                      type={'date_range'}
                      name={'adopted_date'}
                      control={control}
                      placeholder={'Select Adopted Date'}
                      label={'Adopted Date'}
                      handleChange={handleSubmit(onSubmit)}
                      defaultValue={defaultDateRange}
                      isSearchOpened={active || showSearchBlock}
                      smallLabel
                      transparent
                      getPopupContainer={true}
                      reset={() => {
                        setValue('adopted_date', null)
                        const search = store[type]

                        delete search.adopted_date_before
                        delete search.adopted_date_after
                      }}
                      handleShowDateRange={(value) => setIsDateRangeVisible(value)}
                    />
                    <div>
                      <Input
                        type={'multiSelect'}
                        listHeight={listHeight}
                        selectAll={false}
                        name={'jurisdiction'}
                        control={control}
                        placeholder={'Select Jurisdiction'}
                        options={store.legislative_options?.jurisdictions?.filter(
                          (item) => (item.is_system ? item.enabled : true)
                        )}
                        label={'Jurisdiction'}
                        handleChange={handleSubmit(onSubmit)}
                        showDeprecatedLabel
                        showDeprecated
                        smaillList
                        smallLabel
                        transparent
                        getPopupContainer={true}
                      />
                    </div>
                    {trackingMode === 'compliance_source_lite' &&
                      <>
                        <Input
                          type={'date_range'}
                          name={'compliance_date'}
                          control={control}
                          placeholder={'Select Compliance Date'}
                          label={'Compliance Date'}
                          handleChange={handleSubmit(onSubmit)}
                          defaultValue={defaultComplianceRange}
                          isSearchOpened={active || showSearchBlock}
                          smallLabel
                          transparent
                          getPopupContainer={true}
                          reset={() => {
                            setValue('compliance_date', null)
                            const search = store[type]

                            delete search.compliance_date_before
                            delete search.compliance_date_after
                          }}
                          handleShowDateRange={(value) => setIsDateRangeVisible(value)}
                        />
                        <Input
                          type={'multiSelect'}
                          listHeight={listHeight}
                          selectAll={false}
                          name={'line_of_business'}
                          control={control}
                          placeholder={'Select Line of Business'}
                          // disabled={!store.company[0]?.is_premium}
                          label={'Line of Business'}
                          handleChange={handleSubmit(onSubmit)}
                          options={store.legislative_options?.line_of_business}
                          showDeprecatedLabel
                          showDeprecated
                          showSearch
                          smallLabel
                          transparent
                          getPopupContainer={true}
                          disclamerContent={'Determining the applicable line of business for a law/requirement often is subject to interpretation. PBMSource has made efforts to assess the appropriate lines of business (which are subject to change as additional information becomes available). This information is provided as guidance only, and you are encouraged to do your own research and assessments and/or contact an attorney or the applicable government agency.If you question or conclude a different line of business(es), let us know so that we can consider updates to the line of business information. Contact us at 888-684-1391 or contact@pbmsource.com.'}
                          disclamer={true}
                        />

                      </>
                    }
                    {!showModeBlocks && isNotUltraLiteMode &&
                      <>
                        <CheckboxCover style={{ marginLeft: 0, marginBottom: 0, marginTop: 0 }}>
                          <Checkbox
                            {...register("past_due_items")}
                            checked={watch('past_due_items')}
                            onChange={(e) => {
                              setValue('past_due_items', e.target.checked);
                              handleSubmit(onSubmit)();
                            }}
                            name={"past_due_items"}
                            style={{ marginRight: 5 }}
                          />
                          <CheckboxName
                            style={{
                              fontFamily: 'var(--medium)',
                              opacity: 1,
                              fontSize: 14
                            }}>{isReportSource
                              ? 'Show only past due report items'
                              : 'Show only past due compliance items'}
                          </CheckboxName>
                        </CheckboxCover>
                        <CheckboxCover style={{ marginLeft: 0, marginTop: 0 }}>
                          <Checkbox
                            checked={remamberLastSearch}
                            onChange={(e) => {
                              toggleLastSearch();

                            }}
                            name={"remember_last_search"}
                            style={{ marginRight: 5 }}
                          />
                          <CheckboxName
                            style={{
                              fontFamily: 'var(--medium)',
                              opacity: 1,
                              fontSize: 14
                            }}>Remember last search
                          </CheckboxName>
                        </CheckboxCover>
                        <CheckboxCover style={{ marginLeft: 0, marginBottom: 0 }}>
                          <Checkbox
                            {...register("most_recent_items")}
                            checked={watch('most_recent_items')}
                            onChange={(e) => {
                              setValue('most_recent_items', e.target.checked);
                              handleSubmit(onSubmit)();
                            }}
                            name={"past_due_items"}
                            style={{ marginRight: 5 }}
                          />
                          <CheckboxName
                            style={{
                              fontFamily: 'var(--medium)',
                              opacity: 1,
                              fontSize: 14
                            }}>Sort by most recently added
                          </CheckboxName>
                        </CheckboxCover>
                      </>
                    }
                  </>
                )}
              </Inputs>
              {showModeBlocks ?
                <>
                  {isReportSource ?
                    <Inputs>
                      <Input
                        type={'multiSelect'}
                        listHeight={listHeight}
                        selectAll={false}
                        name={'report_company_status'}
                        control={control}
                        options={all
                          ? only_admins_see_unassigned_report_source_items && store.user[0]?.report_source_role === 'report_owner'
                            ? store.report_source_statuses?.filter((item) => item[0] !== 'monitor' && item[0] !== 'unassigned')
                            : store.report_source_statuses?.filter((item) => item[0] !== 'monitor')
                          : closed
                            ? only_admins_see_unassigned_report_source_items && store.user[0]?.report_source_role === 'report_owner'
                              ? store.report_source_statuses?.filter((item) => (item[0] === 'closed' || item[0] === 'closed_na' || item[0] === 'closed_submitted') && item[0] !== 'unassigned')
                              : store.report_source_statuses?.filter((item) => item[0] === 'closed' || item[0] === 'closed_na' || item[0] === 'closed_submitted')
                            : only_admins_see_unassigned_report_source_items && store.user[0]?.report_source_role === 'report_owner'
                              ? store.report_source_statuses?.filter((item) => item[0] !== 'closed' && item[0] !== 'closed_na' && item[0] !== 'closed_submitted' && item[0] !== 'monitor' && item[0] !== 'unassigned')
                              : store.report_source_statuses?.filter((item) => item[0] !== 'closed' && item[0] !== 'closed_na' && item[0] !== 'closed_submitted' && item[0] !== 'monitor')
                        }
                        placeholder={'Select Current Status'}
                        label={'Current Status'}
                        disabled={monitor}
                        handleChange={handleSubmit(onSubmit)}
                        selectVars={{ value: 0, name: 1 }}
                        smallLabel
                        transparent
                        getPopupContainer={true}
                      />
                      <Input
                        type={'multiSelect'}
                        listHeight={listHeight}
                        selectAll={false}
                        name={isMyTasks
                          ? isReportSource
                            ? 'report_owner'
                            : 'compliance_owner'
                          : 'assignee'}
                        control={control}
                        disabled={!isAdminOrOwner && !isMyTasks && !closed}
                        placeholder={'Select Owner'}
                        handleChange={handleSubmit(onSubmit)}
                        label={isReportSource ? 'Report Owner' : 'Compliance Owner'}
                        customOptions={membersAdmins}
                        showSearch
                        smallLabel
                        transparent
                        getPopupContainer={true}
                      />
                    </Inputs>
                    : <Inputs>
                      <Input
                        type={'date_range'}
                        name={'compliance_date'}
                        control={control}
                        placeholder={'Select Compliance Date'}
                        label={'Compliance Date'}
                        handleChange={handleSubmit(onSubmit)}
                        defaultValue={defaultComplianceRange}
                        isSearchOpened={active || showSearchBlock}
                        smallLabel
                        transparent
                        getPopupContainer={true}
                        reset={() => {
                          setValue('compliance_date', null)
                          const search = store[type]

                          delete search.compliance_date_before
                          delete search.compliance_date_after
                        }}
                        handleShowDateRange={(value) => setIsDateRangeVisible(value)}
                      />
                      <Input
                        type={'multiSelect'}
                        listHeight={listHeight}
                        selectAll={false}
                        name={isMyTasks
                          ? isReportSource
                            ? 'report_owner'
                            : 'compliance_owner'
                          : 'assignee'}
                        control={control}
                        disabled={!isAdminOrOwner && !isMyTasks && !closed}
                        placeholder={'Select Owner'}
                        handleChange={handleSubmit(onSubmit)}
                        label={isReportSource ? 'Report Owner' : 'Compliance Owner'}
                        customOptions={membersAdmins}
                        showSearch
                        smallLabel
                        transparent
                        getPopupContainer={true}
                      />
                    </Inputs>
                  }
                  <Inputs>
                    {isMyTasks ? (
                      <Input
                        type={'multiSelect'}
                        listHeight={listHeight}
                        selectAll={false}
                        name={'task_status'}
                        control={control}
                        customOptions={taskStatuses.map((item) => (
                          <Select.Option value={item.value} key={item.value}>
                            {item.name}
                          </Select.Option>
                        ))}
                        placeholder={'Select Status'}
                        handleChange={handleSubmit(onSubmit)}
                        label={'Task Status'}
                        smallLabel
                        transparent
                        getPopupContainer={true}
                      />
                    ) :
                      <>
                        {isReportSource ?
                          <>
                            <Input
                              type={'multiSelect'}
                              listHeight={listHeight}
                              selectAll={false}
                              name={'ties_to_license'}
                              control={control}
                              options={store?.legislative_options?.report_source_ties_to_license}
                              placeholder={'Select License(s)'}
                              handleChange={handleSubmit(onSubmit)}
                              label={'Select License(s)'}
                              smallLabel
                              transparent
                              showSearch
                              getPopupContainer={true}
                            />
                          </>
                          :
                          <>
                            <Input
                              type={'multiSelect'}
                              listHeight={listHeight}
                              selectAll={false}
                              name={'status'}
                              control={control}
                              options={all ?
                                only_admins_see_unassigned_compliance_source_items && store.user[0]?.member_type === 'compliance_owner'
                                  ? store.statuses?.filter((item) => item[0] !== 'unassigned')
                                  : store.statuses
                                : only_admins_see_unassigned_compliance_source_items && store.user[0]?.member_type === 'compliance_owner' ?
                                  store.statuses?.filter((item) => item[0] !== 'unassigned' && item[0] !== 'closed')
                                  : store.statuses?.filter((item) => item[0] !== 'closed')}
                              placeholder={'Select Current Status'}
                              label={'Current Status'}
                              disabled={closed || monitor}
                              handleChange={handleSubmit(onSubmit)}
                              selectVars={{ value: 0, name: 1 }}
                              smallLabel
                              transparent
                              getPopupContainer={true}
                            />
                            <Input
                              type={'date_range'}
                              name={'primary_due_date'}
                              control={control}
                              placeholder={'Select Adopted Date'}
                              label={'Internal Due Date'}
                              handleChange={handleSubmit(onSubmit)}
                              defaultValue={defaultPrimary}
                              isSearchOpened={active || showSearchBlock}
                              dateRangeStyle={{ bottom: 0 }}
                              smallLabel
                              transparent
                              getPopupContainer={true}
                              reset={() => {
                                setValue('primary_due_date', null)
                                const search = store[type]

                                delete search.primary_due_date_before
                                delete search.primary_due_date_after

                                store.setSingleValue(type, search)
                              }}
                              handleShowDateRange={(value) => setIsDateRangeVisible(value)}
                              noLimit
                            />
                          </>
                        }
                      </>
                    }
                    <Input
                      type={'multiSelect'}
                      listHeight={100}
                      selectAll={false}
                      name={'task_assignee'}
                      control={control}
                      customOptions={store.members
                        .filter(
                          (item) => item.is_active && item.have_access_to_tracking
                        )
                        .map((item) => (
                          <Select.Option value={item.id} key={item.id}>
                            {item.first_name + ' ' + item.last_name}
                          </Select.Option>
                        ))}
                      placeholder={'Select'}
                      handleChange={handleSubmit(onSubmit)}
                      label={'Has a Task Assigned To'}
                      smallLabel
                      transparent
                      showSearch
                      getPopupContainer={true}
                    />
                    {
                      !isReportSource &&
                      <>
                        <Input
                          type={'multiSelect'}
                          listHeight={100}
                          selectAll={false}
                          name={'line_of_business'}
                          control={control}
                          placeholder={'Select Line of Business'}
                          // disabled={!store.company[0]?.is_premium}
                          label={'Line of Business'}
                          handleChange={handleSubmit(onSubmit)}
                          options={store.legislative_options?.line_of_business}
                          showDeprecatedLabel
                          showDeprecated
                          showSearch
                          smallLabel
                          transparent
                          getPopupContainer={true}
                          disclamerContent={'Determining the applicable line of business for a law/requirement often is subject to interpretation. PBMSource has made efforts to assess the appropriate lines of business (which are subject to change as additional information becomes available). This information is provided as guidance only, and you are encouraged to do your own research and assessments and/or contact an attorney or the applicable government agency.If you question or conclude a different line of business(es), let us know so that we can consider updates to the line of business information. Contact us at 888-684-1391 or contact@pbmsource.com.'}
                          disclamer={true}
                        />
                      </>
                    }
                  </Inputs>
                  <Inputs>
                    <CheckboxCover style={{ marginLeft: 0, marginBottom: 0 }}>
                      <Checkbox
                        {...register("past_due_items")}
                        checked={watch('past_due_items')}
                        onChange={(e) => {
                          setValue('past_due_items', e.target.checked);
                          handleSubmit(onSubmit)();
                        }}
                        name={"past_due_items"}
                        style={{ marginRight: 5 }}
                      />
                      <CheckboxName
                        style={{
                          fontFamily: 'var(--medium)',
                          opacity: 1,
                          fontSize: 14
                        }}>{isReportSource
                          ? 'Show only past due report items'
                          : 'Show only past due compliance items'}
                      </CheckboxName>
                    </CheckboxCover>
                    {isReportSource &&
                      <CheckboxCover style={{ marginLeft: 0, marginBottom: 0, }}>
                        <Checkbox
                          {...register("only_new_reports")}
                          checked={watch('only_new_reports')}
                          onChange={(e) => {
                            setValue('only_new_reports', e.target.checked);
                            handleSubmit(onSubmit)();
                          }}
                          name={"only_new_reports"}
                          style={{ marginRight: 5 }}
                        />
                        <CheckboxName
                          style={{
                            fontFamily: 'var(--medium)',
                            opacity: 1,
                            fontSize: 14
                          }}>{'Show only new report items'}
                        </CheckboxName>
                      </CheckboxCover>
                    }
                    {!isReportSource &&
                      <CheckboxCover style={{ marginLeft: 0, marginBottom: 0 }}>
                        <Checkbox
                          {...register("most_recent_items")}
                          checked={watch('most_recent_items')}
                          onChange={(e) => {
                            setValue('most_recent_items', e.target.checked);
                            handleSubmit(onSubmit)();
                          }}
                          name={"past_due_items"}
                          style={{ marginRight: 5 }}
                        />
                        <CheckboxName
                          style={{
                            fontFamily: 'var(--medium)',
                            opacity: 1,
                            fontSize: 14
                          }}>Sort by most recently added
                        </CheckboxName>
                      </CheckboxCover>
                    }
                  </Inputs>
                  <Inputs>
                    {isReportSource &&
                      <CheckboxCover style={{ marginLeft: 0, marginBottom: 0 }}>
                        <Checkbox
                          {...register("only_new_requirements")}
                          checked={watch('only_new_requirements')}
                          onChange={(e) => {
                            setValue('only_new_requirements', e.target.checked);
                            handleSubmit(onSubmit)();
                          }}
                          name={"only_new_requirements"}
                          style={{ marginRight: 5 }}
                        />
                        <CheckboxName
                          style={{
                            fontFamily: 'var(--medium)',
                            opacity: 1,
                            fontSize: 14
                          }}>Show only report items with new requirements
                        </CheckboxName>
                      </CheckboxCover>
                    }
                    <CheckboxCover style={{ marginLeft: 0, marginBottom: 0 }}>
                      <Checkbox
                        checked={remamberLastSearch}
                        onChange={(e) => {
                          toggleLastSearch();

                        }}
                        name={"remember_last_search"}
                        style={{ marginRight: 5 }}
                      />
                      <CheckboxName
                        style={{
                          fontFamily: 'var(--medium)',
                          opacity: 1,
                          fontSize: 14
                        }}>Remember last search
                      </CheckboxName>
                    </CheckboxCover>
                  </Inputs>
                  {calendar &&
                    <Inputs>
                      <CheckboxCover style={{ marginLeft: 0, marginBottom: 0 }}>
                        <Checkbox
                          {...register("only_new_reports")}
                          checked={watch('only_new_reports')}
                          onChange={(e) => {
                            setValue('only_new_reports', e.target.checked);
                            handleSubmit(onSubmit)();
                          }}
                          name={"only_new_reports"}
                          style={{ marginRight: 5 }}
                        />
                        <CheckboxName
                          style={{
                            fontFamily: 'var(--medium)',
                            opacity: 1,
                            fontSize: 14
                          }}>{'Show only new report items'}
                        </CheckboxName>
                      </CheckboxCover>
                    </Inputs>
                  }
                </>
                : isReportSource &&
                <>
                  <Inputs>
                    <Input
                      type={'multiSelect'}
                      listHeight={listHeight}
                      selectAll={false}
                      name={'ties_to_license'}
                      control={control}
                      options={store?.legislative_options?.report_source_ties_to_license}
                      placeholder={'Select License(s)'}
                      handleChange={handleSubmit(onSubmit)}
                      label={'Select License(s)'}
                      smallLabel
                      transparent
                      showSearch
                      getPopupContainer={true}
                    />
                    <CheckboxCover style={{ marginLeft: 0, marginBottom: 0, marginTop: 25 }}>
                      <Checkbox
                        {...register("past_due_items")}
                        checked={watch('past_due_items')}
                        onChange={(e) => {
                          setValue('past_due_items', e.target.checked);
                          handleSubmit(onSubmit)();
                        }}
                        name={"past_due_items"}
                        style={{ marginRight: 5 }}
                      />
                      <CheckboxName
                        style={{
                          fontFamily: 'var(--medium)',
                          opacity: 1,
                          fontSize: 14
                        }}>{isReportSource
                          ? 'Show only past due report items'
                          : 'Show only past due compliance items'}
                      </CheckboxName>
                    </CheckboxCover>
                  </Inputs>
                  <Inputs>
                    <CheckboxCover style={{ marginLeft: 0, marginTop: 0 }}>
                      <Checkbox
                        checked={remamberLastSearch}
                        onChange={(e) => {
                          toggleLastSearch();

                        }}
                        name={"remember_last_search"}
                        style={{ marginRight: 5 }}
                      />
                      <CheckboxName
                        style={{
                          fontFamily: 'var(--medium)',
                          opacity: 1,
                          fontSize: 14
                        }}>Remember last search
                      </CheckboxName>
                    </CheckboxCover>
                    <CheckboxCover style={{ marginLeft: 0, marginTop: 0 }}>
                      <Checkbox
                        {...register("only_new_requirements")}
                        checked={watch('only_new_requirements')}
                        onChange={(e) => {
                          setValue('only_new_requirements', e.target.checked);
                          handleSubmit(onSubmit)();
                        }}
                        name={"only_new_requirements"}
                        style={{ marginRight: 5 }}
                      />
                      <CheckboxName
                        style={{
                          fontFamily: 'var(--medium)',
                          opacity: 1,
                          fontSize: 14
                        }}>Show only report items with new requirements
                      </CheckboxName>
                    </CheckboxCover>
                    <CheckboxCover style={{ marginLeft: 0, marginTop: 0 }}>
                      <Checkbox
                        {...register("only_new_reports")}
                        checked={watch('only_new_reports')}
                        onChange={(e) => {
                          setValue('only_new_reports', e.target.checked);
                          handleSubmit(onSubmit)();
                        }}
                        name={"only_new_reports"}
                        style={{ marginRight: 5 }}
                      />
                      <CheckboxName
                        style={{
                          fontFamily: 'var(--medium)',
                          opacity: 1,
                          fontSize: 14
                        }}>{'Show only new report items'}
                      </CheckboxName>
                    </CheckboxCover>
                  </Inputs>
                </>
              }
            </>
          }
        </Bottom>
        {isMyTasks &&
          <CheckboxCover>
            <Checkbox
              {...register("completed_tasks")}
              checked={watch('completed_tasks')}
              onChange={(e) => {
                setValue('completed_tasks', e.target.checked);
                handleSubmit(onSubmit)();
              }}
              name={"completed_tasks"}
              style={{ marginRight: 5 }}
            />
            <CheckboxName>Show only incomplete tasks</CheckboxName>
          </CheckboxCover>
        }
      </Wrap>
    )
  }
)

export default Search
