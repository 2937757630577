import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import Button from '../../../../components/common/Button/button'
import Input from '../../../../components/common/Input'
import { useStore } from '../../../../mobx-store/context'
import { Row, Wrap } from './style'
import { toJS } from 'mobx'
import { Popover, message } from 'antd'
import questIcon from '../../../../assets/images/help_24px.svg'

const Filters = observer(({ tabKey, jurs, resources }) => {
  const { control, handleSubmit, setValue, reset } = useForm()
  const store = useStore()
  const params = useParams()
  const history = useHistory()
  const location = useLocation()

  const isGuides = params.type === 'guides'

  const getOptions = (type, all = true) => {
    const options = store.resourceFilters?.[type]

    return options && (all ? [['_all', 'All'], ...options] : options)
  }

  const onSubmit = (values) => {
    const filteredValues = {}

    store.setSingleValue('resourceLob', values.line_of_business)

    store.setSingleValue('resourceFiltersValues', {
      line_of_business: values.line_of_business.length ? values.line_of_business : '_all',
      requirements: values.requirements,
    })

    for (let key in values) {
      filteredValues[key] = values[key]
    }
    const data = isGuides
      ? store.resourcesCurrentTopic?.rows
      : store.resourcesTable

    const filtered = data.filter((tableItem) => {
      const item = isGuides ? toJS(tableItem) : toJS(tableItem.rows[0]);

      const requirementsMatch =
        filteredValues.requirements === '_all' || filteredValues.requirements === item?.requirements;

      const lineOfBusinessEmpty = Array.isArray(filteredValues.line_of_business) && filteredValues.line_of_business.length === 0;
      const lineOfBusinessMatch = lineOfBusinessEmpty ||
        filteredValues.line_of_business.includes('_all') ||
        (Array.isArray(item.line_of_business) &&
          item.line_of_business.some((lob) =>
            filteredValues.line_of_business.includes(lob)
          ));

      return requirementsMatch && lineOfBusinessMatch;
    });

    isGuides
      ? store.setSingleValue('resourceFilteredTopics', filtered)
      : store.setFilteredResources(filtered)
  }

  useEffect(() => {
    if (jurs && resources) {
      if (params.type === 'jurisdictions') {
        const id =
          (jurs.find((item) => item.id === +params.id) && +params.id) ||
          jurs?.[0]?.id
        store.setSingleValue('currentJurId', id)
        store.getJurisdiction(id)
        setValue('topicsJurs', id)
        setResourceSelect(id)
      } else {
        const id =
          (resources.find((item) => item.id === +params.id) && +params.id) ||
          resources?.[0]?.id

        store.getResourceTopic(id)
        setValue('topicsJurs', id)
        setResourceSelect(id)
      }
    }
  }, [tabKey])

  const setResourceSelect = (id) => {
    store.setSingleValue('resourcesSelect', {
      ...store.resourcesSelect,
      value: id,
      type: tabKey === 0 ? 'guides' : 'jurisdictions',
      [tabKey === 0 ? 'guides' : 'juris']: id,
    })
  }

  const onSearch = (values) => {
    if (!values.search) {
      message.error('Please enter a word to search')
      return
    }
    localStorage.setItem('resource_search_word', values.search)
    history.push(`/resource/word-search/`)
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmit(onSearch)();
    }
  };

  const onViewAcrossFixClick = () => {
    store.setResourcePrevLink([...store.resourcePrevLink, location.pathname])

    history.push(
      `/resource/guides-jurisdictions-list/${params.type}/null/${store.resourcesSelect?.value}`
    )
  }
  // useEffect(() => {
  //   store.resourceLob ? setValue('line_of_business', store.resourceLob) : setValue('line_of_business', [])
  // }, [])

  useEffect(() => {
    store.setSingleValue('resourceLob', [])
  }, [])

  const filters = () => (
    <>
      <Input
        name={'requirements'}
        control={control}
        type={'select'}
        options={getOptions('requirements')}
        selectVars={{ value: 0, name: 1 }}
        label={'Requirements'}
        smallLabel
        defaultValue={'_all'}
        loading={!store.resourceFilters?.requirements}
        handleChange={handleSubmit(onSubmit)}
        allowClear={false}
        styleRow={{ maxWidth: 100 }}
        getPopupContainer={true}
      />
      <Input
        name={'line_of_business'}
        control={control}
        type={'multiSelect'}
        selectAll={false}
        options={getOptions('line_of_business', false)}
        selectVars={{ value: 0, name: 1 }}
        label={'Line of Business'}
        smallLabel
        defaultValue={[]}
        loading={!store.resourceFilters?.line_of_business}
        handleChange={handleSubmit(onSubmit)}
        allowClear={false}
        styleRow={{ maxWidth: 400 }}
        getPopupContainer={true}
      />
      {/* {!isGuides &&
        <>
          <div style={{ width: 0.2, height: 65, border: '0.2px solid var(--grey)', marginRight: 23 }}></div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Input
              label={`Search by word`}
              smallLabel
              type={'text'}
              // handleClear={handleSubmit(onSearch)}
              name={'search'}
              control={control}
              placeholder={'Search'}
              onKeyDown={handleKeyDown}
              transparent
              style={{ height: 40, width: 280 }}
              validation={{
                validate: () => true,
              }}
            />
            <Button
              text={'Search'}
              type={'main'}
              style={{ marginLeft: 0, marginTop: 27 }}
              blue
              onClick={handleSubmit(onSearch)}
            />
            <Popover
              placement='rightTop'
              title={''}
              overlayStyle={{ width: 250, fontFamily: 'var(--semiBold)' }}
              overlayInnerStyle={{ border: '1px solid #F2A122' }}
              content={
                'Searches by word in Differentiator, Summary, and Details of ReSource Guides.'
              }
              trigger='hover'
            >
              <img src={questIcon} alt='question-icon' />
            </Popover>
          </div>
        </>
      } */}
    </>
  )

  const onChange = (id) => {
    store.setSingleValue('currentJurId', id)
    setResourceSelect(id)

    const url = id
      ? `/resource/table/${params.type || 'jurisdiction'}/${id}`
      : `/resource/table/${params.type || 'jurisdiction'}`

    history.push(url)
    tabKey === 0
      ? store.getResourceTopic(id)
      : store.getJurisdiction(id)
    reset({
      topicsJurs: id,
      requirements: '_all',
      line_of_business: [],
    })
  }

  return (
    <Wrap>
      <Row>
        <Input
          name={'topicsJurs'}
          control={control}
          type={'select'}
          options={tabKey === 0
            ? resources
            : jurs.sort((x, y) => {
              return x.name === 'Federal' ? -1 : y.name === 'Federal' ? 1 : 0;
            })}
          showSearch={true}
          label={
            tabKey === 0 ? 'Select a ReSource Guide' : 'Select a Jurisdiction'
          }
          loading={!store.jurs?.length}
          placeholder={
            tabKey === 0 ? 'Select a Guide' : 'Select a Jurisdiction'
          }
          handleChange={onChange}
          styleRow={{ maxWidth: 300 }}
          allowClear={false}
          getPopupContainer={true}
        />
        {
          store.resourceFilters && isGuides
            ? store.resourceFilteredTopics && filters()
            : store.resourcesTable?.length > 0 && filters()
        }
      </Row>
    </Wrap>
  )
})

export default Filters
