import React, { useState } from 'react'
import Moment from 'react-moment'
import { useHistory, useParams } from 'react-router-dom'
import ButtonOrange from '../../../../../components/common/ButtonOrange'
import { checkRole } from '../../../../../helpers/helpers'
import useModal from '../../../../../hooks/useModal'
import { useStore } from '../../../../../mobx-store/context'
import ModalCompleted from '../Modal/ModalCompleted'
import { Item } from './style'

const List = ({
  tasks,
  currentTask,
  setCurrentTask,
  onModalOpen,
  onComplete,
  taskID,
  complianceItem,
  data,
  isMyTasks,
  getTasks,
  activeTab,
  setEdit,
  isReportSource = false,
}) => {
  const store = useStore()
  const history = useHistory()
  const params = useParams()
  const { visible, handleOpen, handleClose } = useModal()

  const isCompleted = (item) => item.status === 'complete'
  const handleCurrentTask = (item) => setCurrentTask(item)


  const onClick = (item) => {
    if (isCompleted(item)) {
      handleCurrentTask(item)
      handleOpen()
    } else {
      handleCurrentTask(item)
      onModalOpen(item)
    }
    const routePath = isReportSource ? '/report-source/' : '/tracking/';
    const routeType = isMyTasks ? 'myTasks' : activeTab === 'main' ? 'main' : activeTab === 'apcd' ? 'apcd' : activeTab === 'all' ? 'all' : activeTab === 'monitor' ? 'monitor' : 'closed';
    const queryParam = isReportSource ? 'report=' : 'compliance_item=';
    const complianceItemId = complianceItem || params.id;
    const taskID = item.id;
    const route = `${routePath}${routeType}?${queryParam}${complianceItemId}&taskID=${taskID}`;
    history.push(route);
  }

  const isAdminOrComplianceOwner = checkRole([store.user?.[0]?.member_type], ['admin', 'compliance_owner', 'owner']);
  const isAdminOrReportOwner = checkRole([store.user?.[0]?.report_source_role], ['admin', 'report_owner', 'owner']);
  const accessAllowed = isAdminOrComplianceOwner || isAdminOrReportOwner;

  return (
    <div>
      {visible && (
        <ModalCompleted
          isReportSource={isReportSource}
          data={currentTask}
          onClose={() => {
            handleClose()
            const routePath = isReportSource ? '/report-source/' : '/tracking/';
            const routeType = isMyTasks ? 'myTasks' : activeTab === 'main' ? 'main' : activeTab === 'apcd' ? 'apcd' : activeTab === 'all' ? 'all' : activeTab === 'monitor' ? 'monitor' : 'closed';
            const queryParam = isReportSource ? 'report' : 'compliance_item';
            const complianceItemId = complianceItem || params.id;

            const route = `${routePath}${routeType}?${queryParam}=${complianceItemId}`;
            history.push(route);
          }}
          tasks={tasks}
          taskID={taskID}
          complianceItem={complianceItem}
          getTasks={getTasks}
        />
      )}
      <Item
        style={{
          fontFamily: 'var(--bold)',
        }}
      >
        <div>Assigned To:</div>
        <div>Task:</div>
        <div style={{ paddingLeft: 20 }}>Due:</div>
        <div>Status:</div>
      </Item>
      {tasks?.map((item) => {
        return (
          <Item>
            <div>{store.getMember(item.assignee)}</div>
            {isReportSource ?
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {item.type === 'get_ready_task' &&
                  <div style={{
                    textDecoration: 'none !important',
                    textDecorationColor: '#fff',
                    fontFamily: 'var(--medium)',
                    color: 'var(--blue)',
                  }}>
                    Get Ready Task:
                  </div>
                }
                <div
                  style={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    maxWidth: 300,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  onClick={() => { onClick(item) }}
                >
                  {item.description}
                </div>
              </div>
              :
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {item.type !== 'default' &&
                  <div style={{
                    textDecoration: 'none !important',
                    textDecorationColor: '#fff',
                    fontFamily: 'var(--medium)',
                    color: 'var(--blue)',
                  }}>
                    Consideration Task:
                  </div>
                }
                <div
                  style={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    maxWidth: 350,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  onClick={() => { onClick(item) }}
                >
                  {item.description}
                </div>
              </div>

            }
            <div style={{ paddingLeft: 20 }}>
              <Moment format={'MM/DD/YYYY'}>{item.due_date}</Moment>
            </div>
            <div
              style={{
                textDecoration: item.status === 'complete' && 'underline',
                cursor: item.status === 'complete' && 'pointer',
              }}
            >
              {isCompleted(item) ? (
                <div
                  style={{ fontFamily: 'var(--regular)', fontSize: '0.875em' }}
                  onClick={() => {
                    handleCurrentTask(item)
                    handleOpen()
                    const routePath = isReportSource ? '/report-source' : '/tracking';
                    const routeType = isMyTasks ? 'myTasks' : activeTab === 'main' ? 'main' : activeTab === 'apcd' ? 'apcd' : activeTab === 'all' ? 'all' : activeTab === 'monitor' ? 'monitor' : 'closed';
                    const queryParam = isReportSource ? 'report' : 'compliance_item';
                    const complianceItemId = complianceItem || params.id;
                    const taskID = item.id;
                    const route = `${routePath}/${routeType}?${queryParam}=${complianceItemId}&taskID=${taskID}`;
                    history.push(route)
                  }}
                >
                  Completed
                </div>
              ) : (
                (accessAllowed ||
                  store.user?.[0]?.id === item.assignee) &&
                (
                  <ButtonOrange
                    text={'Complete task'}
                    onClick={() => {
                      if (data.status === 'closed') return
                      onComplete()
                      handleCurrentTask(item)
                      const routePath = isReportSource ? '/report-source/' : '/tracking/';
                      const routeType = isMyTasks ? 'myTasks' : activeTab === 'main' ? 'main' : activeTab === 'apcd' ? 'apcd' : activeTab === 'all' ? 'all' : activeTab === 'monitor' ? 'monitor' : 'closed';
                      const queryParam = isReportSource ? 'report' : 'compliance_item';
                      const complianceItemId = complianceItem || params.id;
                      const route = `${routePath}${routeType}?${queryParam}=${complianceItemId}&taskID=${item.id}`;
                      history.push(route)
                    }}
                  />
                )
              )}
            </div>
          </Item>
        )
      })}
    </div>
  )
}

export default List
