import styled from "styled-components";

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #70A3EC;
  padding: 12px;
  margin-Top: 20px;
  a{
    color: #515151;
    font-family: var(--regular);
    text-decoration: underline;
    font-size: 14px;
  }
`

export const Title = styled.div`
  color: var(--text);
  font-family: var(--semiBold);
  font-size: 14px;
`