import React from 'react';
import { Checkbox, Button } from 'antd';
import { api } from '../../../../../api';
import { TaskCreateButton, CustomCheckbox, CheckboxCover, } from './style'
import { useStore } from '../../../../../mobx-store/context';


const CheckboxComponent = ({ data, reportCompanyId, getData, handleOpen, setAssigneNew, assignee }) => {
  const store = useStore()

  const toggleCheckbox = (id) => {
    api(`/report_company/${reportCompanyId}/get_ready_tasks_information/`, { checkbox_id: id }, 'PATCH').then((data) => {
      if (!data.errors) {
        getData()
      }
    })
  }

  return (
    <>
      {data.map((item, key) => (
        <div key={key} style={{ paddingTop: 3, paddingBottom: 3, display: 'flex' }}>
          <CheckboxCover>
            <CustomCheckbox
              disabled={!assignee || store.user[0].report_source_role === 'member'}
              green={item.all_tasks_completed}
              checked={item.checked}
              onChange={(e) => {
                if (!e.target.checked) {
                  store.setSingleValue('getReadyTask', { text: item.text + ':', id: item.id })
                  setAssigneNew(true)
                  handleOpen()
                }
                toggleCheckbox(item.id, e.target.checked)
                if (e.target.checked) {
                  store.setSingleValue('getReadyTask', { text: item.text + ':', id: item.id })
                  setAssigneNew(true)
                  handleOpen()
                }
              }}
            />
            <TaskCreateButton
              inActive={!assignee || store.user[0].report_source_role === 'member'}
              onClick={() => {
                if (store.user[0].report_source_role === 'member' || !assignee) return
                toggleCheckbox(item.id, true)
                store.setSingleValue('getReadyTask', { text: item.text + ':', id: item.id })
                setAssigneNew(true)
                handleOpen()
              }}>
              {item.text}
            </TaskCreateButton>
          </CheckboxCover>
        </div>
      ))}
    </>
  );
};

export default CheckboxComponent;


