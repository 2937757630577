import React, { useState, useEffect, } from 'react'
import { Wrap, Report, ShowMore } from './style'
import { useHistory } from 'react-router-dom'
import { Dropdown, Menu } from 'antd'

const DataList = ({ listData, setActive, getCurrentLegislative }) => {

  const history = useHistory()

  const onClick = (e, item) => {
    e.stopPropagation()
    getCurrentLegislative(item.id)
    setActive('all')
    history.push(`/report-source/all/?report=${item.id}`)
  }

  const menu = (
    <Menu>
      {listData.map((item) => (
        <Menu.Item>
          <Report
            onClick={(e) => onClick(e, item)}
          >
             {item.report_title}{(item.is_new_report || item.is_new_requirement) && '-'} {item.is_new_report && <span>New Report</span>}
            {item.is_new_report && item.is_new_requirement && ','} {item.is_new_requirement && <span style={{color: '#DF7411'}}>New Requirement(s)</span>}
          </Report>
        </Menu.Item>
      ))}
    </Menu>
  )


  return (
    <>
      <Wrap>
        {listData.slice(0, 3).map((item) => (
          <Report onClick={(e) => onClick(e, item)}>
            {item.report_title}{(item.is_new_report || item.is_new_requirement) && '-'} {item.is_new_report && <span>New Report</span>}
            {item.is_new_report && item.is_new_requirement && ','} {item.is_new_requirement && <span style={{color: '#DF7411'}}>New Requirement(s)</span>}
          </Report>
        ))}
      </Wrap>
      {listData.length > 3
        && <Dropdown overlay={menu}
          placement="top"
          trigger={["click", "hover"]}
        >
          <ShowMore onClick={(e) => e.stopPropagation()}>
            + {listData.length - 3} More
          </ShowMore>
        </Dropdown>
      }
    </>
  )

}

export default DataList