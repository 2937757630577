import {
  BlockWrap,
  ImageBlock,
  BlockTitle,
  BlockSubtitle,
  BlockDescription,
  BlockContent,
} from './style'
import { useHistory } from 'react-router-dom'
import Button from '../../../../../components/common/Button/button'

export const Tool = ({
  number,
  name,
  subtitle,
  description,
  backgroundColor,
  image,
  color,
  link,
  checked,
  signUpText,
  disabled }) => {
  const history = useHistory();

  return (
    <>
      <BlockWrap
        number={number}
        backgroundColor={backgroundColor}
        onClick={() => { }}>
        <ImageBlock disabled={disabled}>
          <img src={image} alt={{ name }} />
        </ImageBlock>
        <BlockContent>
          <BlockTitle color={color} disabled={disabled}>
            <strong>{name}</strong>Source
          </BlockTitle>
          <BlockSubtitle>{subtitle}</BlockSubtitle>
          <BlockDescription disabled={disabled}>{description}</BlockDescription>
          <Button
            text={'Learn More'}
            onClick={()=> history.push(link)}
            style={{ marginTop: 20, padding: '20px 40px' }}
          />
        </BlockContent>
      </BlockWrap>
    </>

  )
}