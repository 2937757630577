import { Select } from 'antd'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { api } from '../../../../../api'
import Input from '../../../../../components/common/Input'
import { checkRole, getError } from '../../../../../helpers/helpers'
import { useStore } from '../../../../../mobx-store/context'
import Bills from '../Bills/index'
import AdminModal from './AdminModal'
import AccessModal from './Modal/modal'
import Placeholder from './Placeholder/placeholder'
import { Button } from './Placeholder/style'
import Right from './right'
import {
  data,
  rolesCompliance,
  rolesReportSourceLite,
  rolesComplianceLite,
  rolesResource,
  rolesReportSource,
  rolesProposedSource,
  varTypes
} from './static'
import { Wrap } from './style'

const Tab = observer(
  ({
    control,
    errors,
    memberType,
    onSubmit,
    member,
    register,
    setValue,
    deactivateUser,
    isResource,
    isReportSource,
    isProposedSource,
    isTracking,
    tab,
    handleDependencies,
    setIsAccessRemoving,
    reset,
    getValues,
  }) => {
    const store = useStore()
    const params = useParams()
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [visible, setVisible] = useState(false)
    const [isAccess, setIsAccess] = useState(false)
    const [adminModal, setAdminModal] = useState(false)

    const myType = isReportSource
      ? store?.user?.[0]?.report_source_role
      : isResource
        ? store?.user?.[0]?.resource_role
        : store?.user?.[0]?.member_type

    const roles = isReportSource
      ? store.company[0].report_source_mode === 'report_source_lite' ?
        rolesReportSourceLite : rolesReportSource
      : isProposedSource
        ? rolesProposedSource
        : isResource
          ? rolesResource
          : (store.company[0].compliance_source_mode === 'compliance_source_lite' ||
            store.company[0].compliance_source_mode === 'compliance_source_ultra_lite')
            ? rolesComplianceLite
            : rolesCompliance

    const company = store.company[0]

    const serviceMember = isReportSource ? member?.report_source_role :
      isResource ? member?.resource_role :
        isProposedSource ? 'admin' :
          member?.member_type

    const isMemberTypeDisabled =
      checkRole([myType], ['member']) ||
      (myType === 'compliance_owner' && serviceMember === 'admin')


    const removeAccess = () => {
      api(
        `/members/${member.id}/`,
        {
          [varTypes[tab].variable]: false,
        },
        'PATCH'
      )
        .then((data) => {
          if (data.ok || data.id) {
            store.setSingleValue('member', data)
            setVisible(false)
            if (
              !data.have_access_to_resource &&
              !data.have_access_to_tracking &&
              !data.have_access_to_report_source &&
              !data.have_access_to_proposed_source
            ) {
              deactivateUser()
            }
            setIsAccessRemoving(false)
          } else {
            setVisible(false)
            handleDependencies(data)
          }
        })
        .catch((err) => getError(err))
    }

    const toggleAccess = (variable, value) => {
      register(variable)
      setValue(variable, value, { shouldDirty: true })
      setIsAccess(value)
      setVisible(false)
      api(
        `/members/${member.id}/`,
        {
          [varTypes[tab].variable]: true,
        },
        'PATCH'
      )
    }

    const onClose = () => {
      setVisible(false)
      setIsAccessRemoving(false)
    }

    const onCancelAdmin = () => {
      reset({
        ...getValues(),
        [isResource ? 'resource_role' : 'member_type']:
          member[isResource ? 'resource_role' : 'member_type'],
      })
      setAdminModal(false)
    }

    const handleShowModal = () => {
      setVisible(true)
      setIsAccessRemoving(true)
    }

    const onRoleChange = (value) => {
      if (value === 'admin') setAdminModal(true)
    }

    useEffect(() => {
      setIsAccess(false)
    }, [params.type])

    // if (
    //   (!isResource && !store.user[0]?.have_access_to_tracking) ||
    //   (isResource && !store.user[0]?.have_access_to_resource)
    // )
    //   return (
    //     <ResourceModal
    //       type={!isResource ? 'tracking' : 'resource'}
    //       to={'/edit-profile'}
    //       onClose={() => history.push('/edit-profile')}
    //     />
    //   )

    if (
      (isReportSource &&
        (!member?.have_access_to_report_source ||
          !company?.have_access_to_report_source) && !isAccess) ||
      (isProposedSource &&
        (!member?.have_access_to_proposed_source ||
          !company?.have_access_to_proposed_source) && !isAccess) ||
      (isResource &&
        (!member?.have_access_to_resource ||
          !company?.have_access_to_resource) &&
        !isAccess) ||
      (isTracking && !member?.have_access_to_tracking && !isAccess)
    )
      return (
        <Placeholder
          toggleAccess={toggleAccess}
          loading={loading}
          control={control}
          myType={myType}
        />
      )

    return (
      <Wrap>
        {adminModal && (
          <AdminModal
            onClose={() => setAdminModal(false)}
            onCancel={onCancelAdmin}
          />
        )}
        {visible && (
          <AccessModal
            onConfirm={removeAccess}
            onClose={onClose}
            member={member}
          />
        )}
        <div>
          {isProposedSource
            ? <Input
              name={data[params.type || 'tracking'].inputName}
              label={'Role'}
              type={'select'}
              control={control}
              disabled={true}
              transparent
              style={{ marginBottom: errors && errors.jurisdictions && '0' }}
              customOptions={
                <Select.Option key={1} value={1}>
                  {'No Roles'}
                </Select.Option>
              }
              loading={false}
              handleChange={onRoleChange}
            />
            : <Input
              name={data[params.type || 'tracking'].inputName}
              label={'Role'}
              error={errors && errors.member_type && 'This field is required'}
              type={'select'}
              control={control}
              validation={{ required: true }}
              disabled={isMemberTypeDisabled}
              transparent
              style={{ marginBottom: errors && errors.jurisdictions && '0' }}
              customOptions={roles.map(
                (item) =>
                  checkRole(
                    [isReportSource
                      ? store.user?.[0]?.report_source_role
                      : isResource
                        ? store.user?.[0]?.resource_role
                        : store.user?.[0]?.member_type],
                    item.roles_that_can_change_current_role
                  ) && (
                    <Select.Option key={item.key} value={item.value}>
                      {item.name}
                    </Select.Option>
                  )
              )}
              loading={false}
              handleChange={onRoleChange}
            />
          }

          {(isResource || isProposedSource)
            ? null
            : <Bills styleWrap={{ marginTop: 20 }} isReportSource={isReportSource} />}
          {checkRole([myType], ['admin']) && (
            <Button onClick={handleShowModal}>
              Remove User From {
                isReportSource
                  ? 'ReportSource'
                  : isProposedSource
                    ? 'ProposedSource'
                    : isResource
                      ? 'ReSource'
                      : 'ComplianceSource'}
            </Button>
          )}
        </div>
        <div>
          <Right
            memberType={serviceMember}
            control={control}
            onSubmit={onSubmit}
            loading={loading} />
        </div>
      </Wrap>
    )
  }
)

export default Tab
