import React, { useEffect, useRef } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const MyEditor = ({ title, handleChange, defaultValue, required }) => {

  return (
    <div >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{
          color: 'var(--text)',
          fontFamily: 'var(--medium)',
          fontSize: 14,
        }}>{title}</div>{required && <div style={{ color: 'var(--orange)' }}>*</div>}
      </div>
      <CKEditor
        editor={ClassicEditor}
        data={defaultValue}
        // onReady={editor => {
        //   editorRef.current = editor;
        // }}
        // onChange={(event, editor) => {
        //   const data = editor.getData();
        //   handleChange( data );
        // }}
        onBlur={(event, editor) => {
          const data = editor.getData();
          handleChange(data);
        }}
      // onFocus={(event, editor) => {
      //   console.log('Focus.', editor);
      // }}
      />
    </div>
  );
};

export default MyEditor;
