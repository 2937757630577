import React from 'react'
import Input from '../../../../components/common/Input'
import { useStore } from '../../../../mobx-store/context'

const Form = ({ control, errors }) => {
  const commonStyles = { marginBottom: 20 }
  const store = useStore()
  const user = store.user[0]

  const applications = [
    {
      id: '',
      name: 'Not Selected',
    },
    {
      id: 'compliance_source',
      name: 'ComplianceSource',
    },
    {
      id: 're_source',
      name: 'ReSource',
    },
    {
      id: 'proposed_source',
      name: 'ProposedSource',
    },
    {
      id: 'report_source',
      name: 'ReportSource',
    }
  ]
  const have_access = {
    default: true,
    have_access_to_proposed_source: user?.have_access_to_proposed_source,
    have_access_to_report_source: user?.have_access_to_report_source,
    have_access_to_resource: user?.have_access_to_resource,
    have_access_to_tracking: user?.have_access_to_tracking,
  };
  
  const idToAccessFieldMap = {
    '': 'default',
    compliance_source: 'have_access_to_tracking',
    re_source: 'have_access_to_resource',
    proposed_source: 'have_access_to_proposed_source',
    report_source: 'have_access_to_report_source'
  };

  const filteredApplications = applications.filter(app => have_access[idToAccessFieldMap[app.id]]);


  return (
    <form style={{ width: 400 }}>
      <Input
        control={control}
        name={'first_name'}
        label={'First Name'}
        error={errors && errors.first_name && 'Enter valid name'}
        validation={{ required: true, pattern: /[A-Za-z]+/ }}
        style={commonStyles}
        transparent
        smallLabel
      />
      <Input
        control={control}
        name={'last_name'}
        label={'Last Name'}
        error={errors && errors.last_name && 'Enter valid last name'}
        validation={{ required: true, pattern: /[A-Za-z]+/ }}
        style={commonStyles}
        transparent
        smallLabel
      />
      <Input
        control={control}
        type={'email'}
        name={'email'}
        label={'Email'}
        disabled
        style={commonStyles}
        transparent
        smallLabel
      />
      <Input
        control={control}
        type={'select'}
        options={filteredApplications}
        name={'memorized_tool'}
        label={'Default Application'}
        style={commonStyles}
        transparent
        smallLabel
      />
    </form>
  )
}

export default Form
