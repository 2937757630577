import styled from 'styled-components'

export const Wrap = styled("div")`
  height: 100vh ;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    height: 400px;
  }
  @media(max-height: 800px){
    margin-top: 50px;
    height: 100%;
  }
`;

export const Title = styled("div")`
  font-family: var(--regular);
  font-size: 36px;
  line-height: 40px;
  color: var(--black);
  margin-top: 70px;
  margin-bottom: 50px;
  strong {
    font-family: var(--medium);
    color: ${(props) => props.serviceName === 'ComplianceSource'
    ? 'var(--blue)'
    : props.serviceName === 'ReSource'
      ? 'var(--green)'
      : props.serviceName === 'ReportSource'
        ? 'var(--orange)'
        : 'var(--text)'};
  };
`;

export const Text = styled("div")`
  text-align: center;
  width: 900px;
  font-family: var(--light);
  font-size: 25px;
  line-height: 31px;
  color: var(--black);
  margin-top: 21px;
  strong {
    font-family: var(--medium)
    color: var(--green)
  }
  a {
    color: var(--orange);
    text-decoration: underline;
    cursor: pointer;
  }
`;
