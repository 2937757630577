export const ComplianceSourceSiteSettings = [
    {
      key: 'new_compliance_item_in_tracking_site_notification',
      name: 'New compliance item added',
      disabled: false,
    },
    {
      key: 'when_assigned_to_a_compliance_item_site_notification',
      name: 'When assigned to a compliance item',
      disabled: false,
    },
    {
      key: 'compliance_item_status_changed_site_notification',
      name: 'Compliance item status change',
      disabled: false,
    },
    {
      key: 'uploaded_or_deleted_documents_site_notification',
      name: 'Uploaded or deleted documents',
      disabled: false,
    },
    {
      key: 'compliance_item_is_due_site_notification',
      name: 'Compliance item is due',
      disabled: false,
    },
    {
      key: 'task_completion_site_notification',
      name: 'Task completion',
      disabled: false,
    },
    {
      key: 'comment_mentions_site_notifications',
      name: 'Comment Mentions',
      disabled: false,
    },
    {
      key: 'updates_to_existing_compliance_items_site_notification',
      name: 'Updates to existing compliance items',
      disabled: false,
    },
  ]

  export const ComplianceSiteTaskSettings = [
    {
      key: 'assigned_new_task_site_notification',
      name: 'Assigned new task',
      disabled: false,
    },
    {
      key: 'task_is_due_site_notification',
      name: 'Task is due',
      disabled: false,
    },
  ];
  export const ComplianceLiteSiteSettings = [
    {
      key: 'new_compliance_item_in_tracking_site_notification',
      name: 'New compliance item added',
      disabled: false,
    },
  ];
  export const MemberSiteComplianceTaskSettings = [
    {
      key: 'assigned_new_task_site_notification',
      name: 'Assigned new task',
      disabled: false,
    },
    {
      key: 'task_is_due_site_notification',
      name: 'Task is due',
      disabled: false,
    },
    {
      key: 'comment_mentions_site_notifications',
      name: 'Comment Mentions',
      disabled: true,
    },
  ]

  export const ComplianceSettings = [
    {
      key: 'new_legislatives',
      name: 'New compliance item added',
      disabled: false,
    },
    {
      key: 'new_compliance_item_assigned',
      name: 'When assigned to a compliance item',
      disabled: false,
    },
    {
      key: 'status_changed',
      name: 'Compliance item status change',
      disabled: false,
    },
    {
      key: 'documents',
      name: 'Uploaded or deleted documents',
      disabled: false,
    },
    {
      key: 'legislative_is_due',
      name: 'Compliance item is due',
      disabled: false,
    },
    {
      key: 'task_completion',
      name: 'Task completion',
      disabled: false,
    },
    {
      key: 'new_comments',
      name: 'Comment Mentions',
      disabled: false,
    },
    {
      key: 'updates_to_existing_compliance_items',
      name: 'Updates to existing compliance items',
      disabled: false,
    },
  ];

  export const ComplianceLiteSettings = [
    {
      key: 'new_legislatives',
      name: 'New compliance item added',
      disabled: false,
    },
  ];

  export const ComplianceTaskSettings = [
    {
      key: 'assigned_task',
      name: 'Assigned new task',
      disabled: false,
    },
    {
      key: 'task_is_due',
      name: 'Task is due',
      disabled: false,
    },
  ];

  export const MemberComplianceTaskSettings = [
    {
      key: 'assigned_task',
      name: 'Assigned new task',
      disabled: false,
    },
    {
      key: 'task_is_due',
      name: 'Task is due',
      disabled: false,
    },
    {
      key: 'new_comments',
      name: 'Comment Mentions',
      disabled: true,
    },
  ]