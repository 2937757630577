import React from 'react';
import { NavPageWrap, PageTitle, TitleBlock, Subtitle, InputsBlock, RemoveButton, ButtonContainer } from "../ReportEdit/ReportPage/style";
import { useState, useEffect } from 'react'
import { useStore } from "../../../mobx-store/context";
import { api } from "../../../api";
import { useHistory } from 'react-router-dom'
import ButtonOrange from "../../../components/common/ButtonOrange";
import Input from "../../../components/common/Input";
import { useForm } from 'react-hook-form';
import Button from "../../../components/common/Button/button";
import { message } from 'antd';
import Version from './Version'
import bin from '../../../assets/images/delete_red_bin.svg'
import RemoveReportModal from '../ReportEdit/ReportPage/Modal/';
import { useParams } from 'react-router-dom';
import Wraper from '../CMSContainer';



const ProposedPage = ({ editMode }) => {
  const history = useHistory()
  const store = useStore()
  const params = useParams()
  const { handleSubmit, control, reset, } = useForm();
  const [isEditMode, setIsEditMode] = useState(editMode)
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false)
  const [loading, setLoading] = useState(isEditMode ? true : false)
  const [versions, setVersions] = useState([])
  const [newVersionsCount, setNewVersionsCount] = useState(0)


  const getCurrentItem = (reportId) => {
    setLoading(true)
    api(`/cms/pending_bills/${reportId}/`, {}, 'GET').then((res) => {
      store.setSingleValue('cmsCurrentProposedItem', res)
      reset({
        jurisdiction: res.jurisdiction,
      })
      setVersions(res.versions)
    }).then(() => {
      setLoading(false)
    })
  }

  const updateVersion = (id, updatedVersionData) => {
    setVersions(prevVersions => prevVersions.map(version =>
      version.id === id ? { ...version, ...updatedVersionData } : version
    ));
  };


  useEffect(() => {
    if (store?.cmsCurrentLegislative?.id) {
      setIsEditMode(true)
    }
  }, [store?.cmsCurrentLegislative?.id])

  useEffect(() => {
    if (isEditMode && params.id) {
      getCurrentItem(params.id)
    }
  }, [])

  function createUpdatedArray(objects) {
    return objects.map(obj => {
      const { file, file_url, ...rest } = obj;

      if (obj.newVersion === true) {
        const { id, newVersion, ...newObj } = rest;
        return newObj;
      }
      return rest;
    });
  }

  const createVersionBody = (version, id) => {
    const formData = new FormData();

    for (const key in version) {
      if (version[key] === null || version[key] === undefined) {
        continue;
      }
      formData.append(key, version[key]);
    }
    !version.id && formData.append('pending_bill', id);

    return formData;
  }


  const onSubmit = (data) => {
    const url = isEditMode ? `/cms/pending_bills/${params.id}/` : `/cms/pending_bills/`
    api(url, { jurisdiction: data.jurisdiction }, isEditMode ? 'PUT' : 'POST').then((res) => {
      if (!res.errors) {
        const id = res.id
        for (let i = 0; i < versions.length; i++) {
          const preparedVersions = createUpdatedArray(versions, params.id)
          const body = createVersionBody(preparedVersions[i], id)
          const url = preparedVersions[i].id ? `/cms/pending_bills/${params.id}/update_versions/` : `/cms/pending_bill_version/`

          api(url, body, preparedVersions[i].id ? 'PATCH' : 'POST', false, true).then((res) => {
            if (!res.errors) {
              history.push(`/dashboard-proposed-table`)
              !isEditMode && setIsEditMode(true)
            }
          })
        }
      }
    })
  }

  const onRemoveProposed = () => {
    api(`/cms/pending_bills/${store?.cmsCurrentLegislative?.id || params.id}/`, {}, 'DELETE').then((res) => {
      if (!res.errors) {
        message.success('Proposed Item removed successfully')
        setIsRemoveModalOpen(false)
        history.goBack()
      }
    })
  }

  const getCmsOptions = () => {
    api(`/cms/dictionaries_data/all/`, {}, 'GET').then((data) => {
      if (!data.errors) {
        store.setSingleValue('cms_options', data)
      }
    })
  }

  useEffect(() => {
    store.getLegislativeOptions()
  }, [])


  useEffect(() => {
    getCmsOptions()
  }, [])


  const createNewVersion = () => {
    setNewVersionsCount(newVersionsCount + 1)
    setVersions([
      ...versions,
      {
        id: newVersionsCount,
        name: '',
        title: '',
        type: '',
        file: '',
        date: '',
        status: '',
        notes: '',
        newVersion: true,
      },
    ])
  }

  useEffect(() => {
    !editMode && createNewVersion()
  }, [])

  return (
    <>
      {!loading &&
        <Wraper>
          <NavPageWrap >
            {isRemoveModalOpen && isEditMode &&
              <RemoveReportModal
                onClose={() => { setIsRemoveModalOpen(false) }}
                onRemove={onRemoveProposed}
                serviceName={'proposed'}
              />
            }
            <TitleBlock style={{ width: '50%' }}>
              <PageTitle>
                {isEditMode ? 'Edit Proposed Item' : 'Add New Proposed Item'}
              </PageTitle>
              <ButtonOrange
                text={'Activity'}
                onClick={() => history.push(`/dashboard-proposed-activity/${store?.cmsCurrentLegislative?.id || params.id}`)}
                style={{ fontFamily: 'var(--bold)', color: 'var(--blue)', fontSize: 12, }}
              />
            </TitleBlock>
            <TitleBlock style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
              <Subtitle>
                {isEditMode ? 'Edit a Proposed Item for subscribers.' : 'Add a new Proposed Item for subscribers.'}
              </Subtitle>
              <ButtonOrange
                text={'< Go Back'}
                onClick={() => history.goBack()}
                style={{ fontFamily: 'var(--bold)', color: '#4E74A9', fontSize: 12, textDecoration: 'none', marginBottom: 32 }}
              />
            </TitleBlock>
            <InputsBlock style={{ width: '50%', marginBottom: 32 }}>
              <Input
                style={{ marginBottom: 32 }}
                smallLabel
                name={'jurisdiction'}
                label={'Jurisdition'}
                options={store?.cms_options?.jurisdictions}
                type={'select'}
                control={control}
                validation={{ required: !isEditMode }}
                transparent
                defaultValue={store?.cmsCurrentLegislative?.jurisdiction || ''}
              />
            </InputsBlock>
            {versions.length > 0 && versions.map((item, index) => {
              return (
                <Version
                  edit={isEditMode}
                  number={index + 1}
                  version={item}
                  updateVersion={updateVersion}
                  versions={versions}
                />
              )
            })}
            <div style={{
              color: 'var(--blue)',
              fontFamily: 'var(--bold)',
              fontSize: 16,
              cursor: 'pointer',
              textDecoration: 'underline',
            }} onClick={createNewVersion}> {'Add Additional Version'}</div>
            < ButtonContainer editMode={isEditMode}>
              {isEditMode &&
                <RemoveButton onClick={() => setIsRemoveModalOpen(true)}>
                  <img src={bin} alt='delete-icon' />
                  <span>Remove this Proposed Item</span>
                </RemoveButton>
              }
              <Button
                text={'Save'}
                onClick={handleSubmit(onSubmit)}
                type={'main'}
                style={{
                  padding: 15,
                  marginRight: 0,
                  marginLeft: '.5em',
                  marginTop: '2.3em',
                }}
              />
            </ButtonContainer>
          </NavPageWrap >
        </Wraper >
      }
    </>

  );
};

export default ProposedPage;
