import { Select } from 'antd'
import { checkRole } from '../../../../../helpers/helpers'
import { roles, reportRoles, complianceLiteRoles, reportLiteRoles } from '../../EditTeamMember/Form/form'

export const complianceInputs = (store, errors, showFields) => [
  {
    key: 0,
    name: 'member_type',
    label: 'Role',
    type: 'select',
    customOptions: store.company[0].compliance_source_mode === 'compliance_source' ? roles.map(
      (item) =>
        checkRole(
          [store.user?.[0]?.member_type],
          item.roles_that_can_change_current_role
        ) && (
          <Select.Option key={item.key} value={item.value}>
            {item.name}
          </Select.Option>
        )
    ) : complianceLiteRoles.map(
      (item) =>
        checkRole(
          [store.user?.[0]?.member_type],
          item.roles_that_can_change_current_role
        ) && (
          <Select.Option key={item.key} value={item.value}>
            {item.name}
          </Select.Option>
        )
    ),
    placeholder: 'Select Role',
    validation: { required: true },
    error: errors && errors.member_type && 'Select Role',
  },
  {
    key: 1,
    name: 'topics',
    label: 'Assign By Topic',
    type: 'multiSelect',
    options: store.topicsMembers,
    placeholder: 'All Topics in My Plan',
    validation: { required: false },
    error: errors && errors.topics && 'This field is required',
    transparent: true,
    loading: !store.topicsMembers.length,
    style: { marginBottom: errors && errors.topics && '0' },
    showFields: showFields && store?.company[0]?.compliance_source_mode !== "compliance_source_ultra_lite"
  },
  {
    key: 2,
    name: 'jurisdictions',
    label: 'Assign By Jurisdiction',
    type: 'multiSelect',
    options: store.jursMembers,
    placeholder: 'All Jurisdictions in My Plan',
    validation: { required: false },
    error: errors && errors.jurisdictions && 'This field is required',
    transparent: true,
    loading: !store.jursMembers.length,
    style: { marginBottom: errors && errors.jurisdictions && '0' },
    showFields,
  },
  {
    key: 3,
    name: 'line_of_business',
    label: 'Assign By Line of Business',
    type: 'multiSelect',
    options: store.lineOfBusinessesMembers,
    placeholder: 'All Lines of Business',
    validation: { required: false },
    error: errors && errors.line_of_business && 'This field is required',
    transparent: true,
    loading: !store.lineOfBusinessesMembers.length,
    style: { marginBottom: errors && errors.line_of_business && '0' },
    showFields:
      showFields && store?.company?.length > 0 && store.company[0].is_premium
      && store?.company[0]?.compliance_source_mode !== "compliance_source_ultra_lite"
  },
]

export const reportSourceInputs = (store, errors, showFields) => [
  {
    key: 0,
    name: 'report_source_role',
    label: 'Role',
    type: 'select',
    customOptions: store.company[0].report_source_mode === 'report_source' ? reportRoles.map(
      (item) =>
        checkRole(
          [store.user?.[0]?.report_source_role],
          item.roles_that_can_change_current_role
        ) && (
          <Select.Option key={item.key} value={item.value}>
            {item.name}
          </Select.Option>
        )
    ) : reportLiteRoles.map(
      (item) =>
        checkRole(
          [store.user?.[0]?.report_source_role],
          item.roles_that_can_change_current_role
        ) && (
          <Select.Option key={item.key} value={item.value}>
            {item.name}
          </Select.Option>
        )
    ),
    placeholder: 'Select Role',
    validation: { required: true },
    error: errors && errors.member_type && 'Select Role',
  },
  {
    key: 1,
    name: 'report_source_jurisdictions',
    label: 'Assign By Jurisdiction',
    type: 'multiSelect',
    options: store.jurs,
    placeholder: 'All Jurisdictions in My Plan',
    validation: { required: true },
    error: errors && errors.jurs && 'This field is required',
    transparent: true,
    loading: !store.jurs,
    style: { marginBottom: errors && errors.jurs && '0' },
    showFields,
  },
  {
    key: 2,
    name: 'report_source_report_types',
    label: 'Assign By Report Types',
    type: 'multiSelect',
    options: store?.memberReportTypes,
    placeholder: 'All Report Types',
    validation: { required: true },
    transparent: true,
    loading: !store?.memberReportTypes?.length,
    style: { marginBottom: errors && errors.reportTypes && '0' },
    showFields,
  },
  {
    key: 3,
    name: 'report_source_line_of_business',
    label: 'Assign By Line of Business',
    type: 'multiSelect',
    options: store.legislative_options?.report_source_line_of_business,
    placeholder: 'All Lines of Business',
    validation: { required: true },
    error: errors && errors.line_of_business && 'This field is required',
    transparent: true,
    loading: !store.legislative_options?.report_source_line_of_business.length,
    style: { marginBottom: errors && errors.line_of_business && '0' },
    showFields,
  },
]

export const proposedSourceInputs = () => [
  {
    key: 0,
    name: 'proposed_source_role',
    label: 'Role',
    type: 'select',
    placeholder: 'No Roles',
    disabled: true,
  },
]

export const resourceInputs = (store, errors, showFields) => [
  {
    key: 0,
    name: 'resource_role',
    label: 'Role',
    type: 'select',
    customOptions: roles
      .filter((item) => item.key !== 'compliance_owner')
      .map(
        (item) =>
          checkRole(
            [store.user?.[0]?.resource_role],
            item.roles_that_can_change_current_role
          ) && (
            <Select.Option key={item.key} value={item.value}>
              {item.name}
            </Select.Option>
          )
      ),
    placeholder: 'Select Role',
    validation: { required: true },
    error: errors && errors.resource_role && 'Select Role',
  },
  {
    key: 2,
    name: 'resource_guides',
    label: 'Assign By Guides',
    type: 'multiSelect',
    options: store.resources,
    placeholder: 'All Guides in My Plan',
    validation: { required: false },
    error: errors && errors.topics && 'This field is required',
    transparent: true,
    loading: !store.resources?.length,
    style: { marginBottom: errors && errors.topics && '0' },
    showFields,
    selectVars: { value: 'guide_name_id' },
  },
  {
    key: 3,
    name: 'resource_jurisdictions',
    label: 'Assign By Jurisdiction',
    type: 'multiSelect',
    options: store.selectedJurisdictions,
    placeholder: 'All Jurisdictions in My Plan',
    validation: { required: false },
    error: errors && errors.jurisdictions && 'This field is required',
    transparent: true,
    loading: !store.selectedJurisdictions,
    style: { marginBottom: errors && errors.jurisdictions && '0' },
    showFields,
  },
]
