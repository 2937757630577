import React from 'react'
import reportBenefitsImg from '../../../../assets/images/Benefits4.svg'
import { ContainerGlobal, TitleGlobal, DescriptionGlobal } from '../styles'
import Item from './Item'
import { LeftSide, RightSide, Wrap } from './style'

const Benefits = ({ content }) => {
  return (
    <ContainerGlobal style={{ paddingTop: 100, paddingBottom: 100 }}>
      <LeftSide service={content.service}>
        <img src={content.benefitsImg} alt='benefits' />
      </LeftSide>
      <div>
        <TitleGlobal style={{ marginBottom: 25 }}>
          {content.benefitsTitle}
        </TitleGlobal>
        {content.benefitsDescription &&
          <DescriptionGlobal style={{ fontSize: 24, marginBottom: 20 }}>
            {content.benefitsDescription}
          </DescriptionGlobal>
        }
        <RightSide>
          {content.benefits.map((item) => (
            <Item
              key={item.key}
              title={item.title}
              description={item.description}
            />
          ))}
        </RightSide>
      </div>
    </ContainerGlobal>
  )
}

export default Benefits
