import { message, Popover, Dropdown, Menu } from 'antd'
import { useGA4React } from 'ga-4-react'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import Moment from 'react-moment'
import React, { useEffect, useState, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useParams, Link, } from 'react-router-dom'
import showdown from 'showdown'
import { api } from '../../../api'
import flag from '../../../assets/images/flag.svg'
import flagGrey from '../../../assets/images/flag_grey.svg'
import questIcon from '../../../assets/images/help_24px.svg'
import pdf from '../../../assets/images/pdf.svg'
import Button from '../../../components/common/Button/button'
import ButtonOrange from '../../../components/common/ButtonOrange'
import CalendarCustom from '../../../components/common/Calendar'
import Input from '../../../components/common/Input'
import Tabs from '../../../components/common/Tabs/tabs'
import useModal from '../../../hooks/useModal'
import image from '../../../assets/images/warning_big.svg'
import {
  checkForTrial,
  checkRole,
  getDescription,
  getError,
  getStatusColor,
  getReportStatusColor,
  highlightSearchedWords,
} from '../../../helpers/helpers'
import { useStore } from '../../../mobx-store/context'
import DeleteModal from './DeleteModal'
import { infoInputs, reportInfoInputs } from './inputs'
import Modal from './Modal'
import ConfirmModal from './ConfirmModal'
import MoreInfo from './MoreInfo/moreInfo'
import {
  Block,
  InternalDueDateBlock,
  DocumentWrap,
  RowWrap,
  Inputs,
  Label,
  LineOfBusiness,
  Read,
  Reference,
  Row,
  Text,
  Wrap,
  DisclaimerBlock,
} from './right.style'
import NewRequirementBlock from './NewRequirementBlock/index'
import { showTabs, showReportMaterialsTabs } from './tabs'
import Tasks from './Tasks'
import TasksReAssignModal from './TaskReAssignModal'
import DeficiencyModal from './DeficiencyModal'
import ImpactedClients from './Considerations/TagInput'
import InterLinks from './InterLinks'

const converter = new showdown.Converter()

export const labelStyle = {
  color: 'var(--text)',
  fontFamily: 'var(--open-sans-semiBold)',
  fontSize: '0.875em',
}

const Right = observer(
  ({
    isReportSource = false,
    data,
    getCurrentLegislative,
    currentPage,
    isMyTasks,
    complianceItem,
    activeTab: mainActiveTab,
    onEdit,
    trackingMode,
    showModeBlocks,
    detailsState,
    setDetailsState,
    updateDetails,
  }) => {
    const [visibleModal, setVisibleModal] = useState()
    const [visibleOwnerModal, setVisibleOwnerModal] = useState(false)
    const [visibleTaskAssignModal, setVisibleTaskAssignModal] = useState(false)
    const [deleting, setDeleting] = useState(false)
    const [tasks, setTasks] = useState([])
    const [archiveButtonLoading, setArchiveButtonLoading] = useState(false)

    const [assigneNew, setAssigneNew] = useState(false)
    const [successFile, setSuccessFile] = useState(null)
    const [activeTab, setActiveTab] = useState('details')
    const [activeMaterialsTab, setActiveMaterialsTab] = useState('getReady')
    const [considerationData, setConsiderationData] = useState(null)
    const [getReadyData, setGetReadyData] = useState(null)
    const [visibleDeficiencyModal, setVisibleDeficiencyModal] = useState(false)
    const [visibleClosedSubmittedModal, setVisibleClosedSubmittedModal] = useState(false)
    const [visibleConfirmModal, setVisibleConfirmModal] = useState(false)
    const history = useHistory()
    const params = useParams()
    const ga4React = useGA4React()
    const tabsRef = useRef(null);
    const { visible, handleClose, handleOpen } = useModal()
    const { control, reset, getValues, setValue } = useForm({
      defaultValues: {
        status: data.status,
        assign: data.assignee,
      },
    })
    const store = useStore()
    const [loading, setLoading] = useState({
      assignee: false,
      status: false,
      all: false,
      file: false,
      primary_due_date: false,
      deleting: false,
    })


    const { id } = data
    const reportId = data?.report?.id

    const confirmDelete = (isReportSource) => {
      setLoading((prev) => ({
        ...prev,
        deleting: true,
      }))
      const url = isReportSource ? `/report_company/${id}/report/` : `/legislative_edit/${id}/`
      api(url, {}, 'DELETE').then((data) => {
        if (data.errors) return message.error(getError(data))

        store.loadLegislatives({
          page: 1,
          page_size: 20,
        }, isReportSource)

        store.setSingleValue('currentLegislative', null)
        history.push(isReportSource ? '/report-source/main' : '/tracking/main')
        setDeleting(false)
        setLoading((prev) => ({
          ...prev,
          deleting: false,
        }))
      })
    }

    const onDelete = () => setDeleting(true)

    useEffect(() => {
      if (
        !store.lineOfBusinesses.length ||
        !store.jurs.length ||
        !store.topics.length
      ) {
        store.loadSelect('companyOptions')
      }

      if (!store.statuses.length || store.report_source_statuses.length) {
        store.loadSelect('currentMemberOptions')
      }
    }, [store])

    const modifyDescription = (store, data) => {
      if (getDescription(store, data)) {
        return getDescription(store, data)
          .split(',')
          .join('<br />')
      }
      getDescription(store, data)
    }

    const isAdminOrComplianceOwner = checkRole([store.user?.[0]?.member_type], ['admin', 'compliance_owner']);
    const isAdminOrReportOwner = checkRole([store.user?.[0]?.report_source_role], ['admin', 'report_owner']);

    const accessAllowed = isAdminOrComplianceOwner || isAdminOrReportOwner;

    const references = [
      {
        key: 0,
        title: isReportSource ? 'Report Title' : 'Reference #',
        description: isMyTasks ?
          isReportSource
            ? highlightSearchedWords(data?.report?.report_title, store?.searchableObjectTasks?.search)
            : highlightSearchedWords(data?.reference_number, store?.searchableObjectTasks?.search)
          : isReportSource
            ? highlightSearchedWords(data?.report?.report_title, store?.searchableObject?.search)
            : highlightSearchedWords(data?.reference_number, store?.searchableObject?.search),
      },
      {
        key: 1,
        title: (
          <LineOfBusiness>
            Line of Business{' '}

          </LineOfBusiness>
        ),
        description:
          isReportSource
            ? modifyDescription(store.lineOfBusinesses, data?.report?.line_of_business)
            : modifyDescription(store.lineOfBusinesses, data?.line_of_business),
        hide: isReportSource
          ? true
          : trackingMode === 'compliance_source_ultra_lite'
            ? true
            : !store.company[0]?.is_premium,
      },
      {
        key: 2,
        title: isReportSource ? 'Report Type' : 'Topic',
        description: isReportSource
          ? getDescription(store.reportTypes, data?.report?.report_type)
          : isMyTasks
            ? highlightSearchedWords(modifyDescription(store.topics, data.topics), store?.searchableObjectTasks?.search)
            : highlightSearchedWords(modifyDescription(store.topics, data.topics), store?.searchableObject?.search),
        hide: isReportSource
          ? false
          : trackingMode === 'compliance_source_ultra_lite'
            ? true
            : false,
      },
      {
        key: 3,
        title: 'Sub-Topic',
        hide: isReportSource
          ? true
          : trackingMode === 'compliance_source_ultra_lite'
            ? true
            : !data.sub_topics.length
              ? true
              : !store.company[0]?.is_premium,
        description: isMyTasks
          ? highlightSearchedWords(modifyDescription(store?.legislative_options?.sub_topics, data.sub_topics), store?.searchableObjectTasks?.search)
          : highlightSearchedWords(modifyDescription(store?.legislative_options?.sub_topics, data.sub_topics), store?.searchableObject?.search)
      },
      {
        key: 4,
        title: 'Jurisdiction',
        description: isReportSource
          ? getDescription(store.jurs, data?.report?.jurisdiction)
          : getDescription(store.jurs, data.jurisdiction),
      },
      {
        key: 5,
        title: 'Adopted Date',
        description: moment(data.adopted_date).format('MM/DD/YYYY'),
        hide: isReportSource
          ? true
          : trackingMode === 'compliance_source_ultra_lite'
            ? false
            : true,
      },
    ]

    const statsStatus = activeTab === 'apcd' ? 'status=apcd' : 'status=actual'


    const handleChange = (value, type, check = true) => {
      setLoading((prev) => ({
        ...prev,
        [type]: true,
      }))
      isReportSource &&
        api(`/report_company/stats/${statsStatus}`, {}, 'GET').then((data) => {
          store.setSingleValue('reportStats', data)
        })
      if (value === 'in_progress_deficiency') {
        setVisibleDeficiencyModal(true)
        setValue('status', data.status)
        return
      }
      if (value === 'closed_submitted' && data.assignee !== null && !(store.legislativeTasks?.find((item) => item.status !== 'complete'))) {
        setVisibleClosedSubmittedModal(true)
        setValue('status', data.status)
        return
      }
      if (value === 'closed' || value === 'closed_submitted' || value === 'closed_na') {
        if (data.assignee === null) {
          setVisibleOwnerModal(true)
          setValue('status', data.status)
          return
        }
        if (
          store.legislativeTasks?.find((item) => item.status !== 'complete')
        ) {
          setVisibleModal(true)
          setValue('status', data.status)
          return
        }
      }
      if (value === 'unassigned' && check === true) {
        if (isReportSource ? data.is_report_owner_tasks_assigner : data.is_compliance_owner_tasks_assigner) {
          setVisibleTaskAssignModal(true)
          setValue('status', data.status)
          return
        }
      }
      const url = isReportSource
        ? `/report_company/${data.id}/details/`
        : `/legislative/${data.id}/`
      api(
        url,
        {
          [type]:
            type === `${isReportSource ? 'internal_due_date' : 'primary_due_date'}`
              ? moment(value, 'YYYY-MM-DD').format('YYYY-MM-DD')
              : isNaN(value)
                ? value
                : parseInt(value),
          status:
            type === 'assignee'
              ? 'in_progress'
              : type === 'status'
                ? value
                : data.status,
        },
        'PATCH'
      ).then((data) => {
        if (!data.errors) {
          store.setCurrentLegislative(data, true)
          // store[isMyTasks ? 'loadTasks' : 'loadLegislatives']({
          //   page: currentPage,
          // }, isReportSource)
          setLoading((prev) => ({
            ...prev,
            [type]: false,
          }))
          store.loadActivity(id, 1, null, isReportSource, reportId)
        }
      })
    }

    const onTaskOwnerChange = (value) => {
      const url = `/${isReportSource ? 'report_company' : 'legislative'}/${data.id}/change_tasks_assigner/`
      api(url, { new_assigner: value }, 'POST').then((data) => {
        if (!data.errors) {
          setValue('status', data.status)

          // handleChange('data.status', 'status', false)
          handleChange(value, 'assignee', false)
          message.success('Task Owner changed!')
          setVisibleTaskAssignModal(false)
        } else {
          message.error('Something went wrong!')
        }
      })
    }

    const onDatesSetUp = (value, type) => {
      const url = `/report_company/${data.id}/details/`
      const obj = type === 'deficiency'
        ? {
          in_progress_deficiency_date: moment(value, 'YYYY-MM-DD').format('YYYY-MM-DD'),
          status: 'in_progress_deficiency',
        }
        : {
          closed_submitted_date: moment(value, 'YYYY-MM-DD').format('YYYY-MM-DD'),
          status: 'closed_submitted',
        }
      api(url, obj, 'PATCH').then((data) => {
        if (!data.errors) {
          store.setCurrentLegislative(data, true)
          // store[isMyTasks ? 'loadTasks' : 'loadLegislatives']({
          //   page: currentPage,
          // }, isReportSource)
          store.loadActivity(id, 1, null, isReportSource, reportId)
        }
      })
    }

    const moveLegislativeToArchive = () => {
      setArchiveButtonLoading(true)
      const obj = data?.status === 'closed' ? { status: 'unassigned' } : { status: 'closed' }
      api(`/${isReportSource ? 'report_company' : 'legislative'}/${data.id}/`, obj, 'PATCH').then((data) => {
        if (!data.errors) {
          store.setCurrentLegislative(data, true)
          store.loadLegislatives({ page: currentPage }, isReportSource)
          setArchiveButtonLoading(false)

        }
      })
      store.loadActivity(id, 1, null, isReportSource, reportId)
    }

    const postFile = (e, taskID, files, isTaskFile = false, status = 'working') => {
      setLoading((prev) => ({
        ...prev,
        file: true,
      }))

      ga4React?.gtag('event', 'file_upload')

      let formData = new FormData()

      isTaskFile
        ? formData.append('file', files[0] || e.target.files[0])
        : formData.append('file', files || e.target.files[0])

      isTaskFile
        ? formData.append('status', files[1])
        : formData.append('status', status)
      formData.append('legislative', data.id)
      if (taskID) formData.append('task', taskID) // Oleksandr, add optional task_id here

      api(isReportSource ? `/report_company/${data.id}/files/` : '/legislative_file/', formData, 'POST', false, true).then((data) => {
        if (!data.errors) {
          if (!taskID) {
            store.setDocument(data)
            setSuccessFile(data.file_name)
          }

          if (taskID)
            getCurrentLegislative(store.currentLegislative.id, null, null, true, isReportSource)

          store.loadDocuments(id, 1, isReportSource, status)

          setLoading((prev) => ({
            ...prev,
            file: false,
          }))
        } else {
          message.error(getError(data))
        }
        reset({ ...getValues(), file: '' })
      })
    }

    const isAssigneeMember = (id) => {
      const result = isReportSource
        ? store.members?.find((item) => item.id === id)?.report_source_role === 'member'
        : store.members?.find((item) => item.id === id)?.member_type === 'member'
      return (
        result
      )
    }

    const getTasks = () => {
      if (!params.id && !complianceItem) return null;

      const endpoint = isReportSource
        ? `/report_company/${id}/tasks/`
        : `/tasks/?legislative=${id}&page_size=100`;

      api(endpoint, {}, 'GET')
        .then((data) => {
          if (!data.errors) {
            const tasksData = isReportSource ? data : data.results;
            setTasks(tasksData);
            store.setSingleValue('legislativeTasks', tasksData);
          }
        })
        .catch((error) => {
          message.error('Error fetching tasks:', error);
        });
    };

    const handleStarred = () => {
      api(
        `${isReportSource ? '/report_company/' : '/legislative/'}${data?.id}/set_starred/`,
        { is_starred: !data?.is_starred },
        'POST'
      ).then((data) => {
        if (!data.errors) {
          const mutatedLegislative = {
            ...store.currentLegislative,
            is_starred: data[id],
          }
          store[isMyTasks ? 'loadTasks' : 'loadLegislatives']({
            page: currentPage,
            page_size: checkForTrial(store) ? 10 : 20,
          }, isReportSource)
          store.setSingleValue(
            'currentLegislative',
            mutatedLegislative
          )
        }
      })
    }
    const handleDetailsState = (value) => {
      setDetailsState(value)
    }

    const getConsiderationData = () => {
      api(`/legislative/${store.currentLegislative.id}/considerations_info/`, {}, 'GET').then((data) => {
        setConsiderationData(data)
      })
    }

    const receiveGetReadyData = () => {
      api(`/report_company/${store.currentLegislative.id}/get_ready_tasks_information/`, {}, 'GET').then((data) => {
        setGetReadyData(data)
      })
    }


    useEffect(() => {
      !isReportSource && !considerationData && getConsiderationData()
    }, [id])

    useEffect(() => {
      isReportSource && receiveGetReadyData()
    }, [id])

    useEffect(() => {
      reset({
        ...data,
        assignee: isAssigneeMember(data.assignee) ? null : data.assignee,
      })
    }, [data])

    useEffect(() => {
      store.loadActivity(id, 1, null, isReportSource, reportId)
      getTasks()
    }, [])

    useEffect(() => {
      if (params.subTab === 'comments') {
        if (tabsRef.current) {
          tabsRef.current.scrollIntoView({ behavior: 'smooth' });
          setActiveTab('comments')
        }
      }
    }, [])

    useEffect(() => {
      setTimeout(() => {
        setSuccessFile(null)
      }, 5000)
    }, [successFile])

    useEffect(() => {
      (detailsState && activeTab !== 'details') && updateDetails()
    }, [activeTab])

    const dateMenu = (
      <>
        {isReportSource && mainActiveTab === 'main' &&
          <Menu>
            {store?.currentItemLinkedReports?.length > 0
              ? (() => {
                let variableCount = 0;
                return store.currentItemLinkedReports.map((item, index) => {
                  if (item.is_variable || item.is_upon_request) {
                    variableCount++;
                  }
                  const reportNumber = item.is_variable || item.is_upon_request ? variableCount : index + 1;
                  return (
                    <Menu.Item key={item.report_company_id} onClick={(e) => {
                      e.stopPropagation();
                    }}>
                      <Link to={`/report-source/closed/?report=${item.report_company_id}`} target="_blank">
                        {item.is_variable || item.is_upon_request
                          ? store?.currentItemLinkedReports.length === 1 ? 'Prior Report' : `Prior Report ${reportNumber}`
                          : <Moment format={'MM/DD/YYYY'}>{item.report_due_date}</Moment>}
                      </Link>
                    </Menu.Item>
                  );
                });
              })()
              : <Menu.Item >
                <div>No Prior Reports</div>
              </Menu.Item>
            }
          </Menu>
        }
      </>
    );


    return (
      <Wrap name={'right-screen'}>
        {deleting && (
          <DeleteModal
            isReportSource={isReportSource}
            onConfirm={(isReportSource) => confirmDelete(isReportSource)}
            name={isReportSource
              ? data?.report?.report_title
              : data?.reference_number}
            onClose={() => setDeleting(!deleting)}
            loading={loading.deleting}
          />
        )}
        {visibleDeficiencyModal &&
          <DeficiencyModal
            type={'deficiency'}
            onClose={() => setVisibleConfirmModal(true)}
            onSubmit={(value) => {
              onDatesSetUp(value, 'deficiency')
              setVisibleDeficiencyModal(false)
            }}
          />
        }
        {visibleClosedSubmittedModal &&
          <DeficiencyModal
            type={'closed-submitted'}
            onClose={() => setVisibleConfirmModal(true)}
            onSubmit={(value) => {
              onDatesSetUp(value, 'closed-submitted')
              setVisibleClosedSubmittedModal(false)
            }}
          />
        }
        {visibleConfirmModal &&
          <ConfirmModal
            onClose={() => setVisibleConfirmModal(false)}
            onSubmit={() => {
              if (visibleDeficiencyModal) {
                setVisibleDeficiencyModal(false)
              } else {
                setVisibleClosedSubmittedModal(false)
              }
              setVisibleConfirmModal(false)
            }}
          />}
        {visibleModal && (
          <Modal
            isReportSource={isReportSource}
            onClose={() => setVisibleModal(false)}
            onConfirm={() => setVisibleModal(false)}
          />
        )}
        {visibleOwnerModal && (
          <Modal
            title={`Need a ${isReportSource ? 'Report' : 'Compliance'}  Owner to Close`}
            text={`To close a ${isReportSource ? 'report' : 'compliance'} item, a compliance owner must be assigned.`}
            buttonText={`Add a ${isReportSource ? 'Report' : 'Compliance'}  Owner`}
            showCancel={false}
            onClose={() => setVisibleOwnerModal(false)}
            onConfirm={() => setVisibleOwnerModal(false)}
          />
        )}
        {visibleTaskAssignModal && (
          <TasksReAssignModal assignee={data.assignee}
            isReportSource={isReportSource}
            itemId={data.id}
            onSubmit={(value) => onTaskOwnerChange(value)}
            onClose={() => setVisibleTaskAssignModal(false)}
          />
        )
        }
        <Reference showLinkedReports={isReportSource && mainActiveTab === 'main' && store.currentLegislative.report.created_by_company === null}>
          <RowWrap>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 30px 30px',
                gap: 10,
                alignItems: 'center',
              }}
            >
              {isReportSource ? data?.report?.report_title : data.reference_number}
              {isReportSource ?
                showModeBlocks
                  ? <span style={{ backgroundColor: getReportStatusColor(data?.report?.status, data) }} />
                  : <span style={{ backgroundColor: `${data?.status === 'closed' ? 'var(--grey)' : 'var(--blue)'}`, }} />
                : showModeBlocks
                  ? <span style={{ backgroundColor: getStatusColor(data?.status, data), }} />
                  : <span style={{ backgroundColor: `${data?.status === 'closed' ? 'var(--grey)' : 'var(--blue)'}`, }} />
              }
              {isReportSource && data?.status === 'in_progress_deficiency' && <img src={image} alt='warningBig' />}
            </div>
            {accessAllowed && trackingMode !== 'compliance_source_ultra_lite' && (
              <Button
                text={
                  <>
                    Priority{' '}
                    <img
                      src={data?.is_starred ? flag : flagGrey}
                      alt='flag-icon'
                    />
                  </>
                }
                type={'white'}
                onClick={handleStarred}
              />
            )}
          </RowWrap>
          {isReportSource && mainActiveTab === 'main' && store.currentLegislative.report.created_by_company === null &&
            <DocumentWrap
              style={{
                padding: '10px 15px',
                marginTop: 15,
                fontSize: 14,
                justifyContent: 'start',
                borderTop: '1px solid #C4CACC'
              }}
              grey={true}>
              {/* <div style={{ marginRight: 10 }}>Prior Reports</div> */}
              <Dropdown overlay={dateMenu} >
                <div style={{ cursor: 'pointer' }}>
                  Prior Reports ▼
                </div>
              </Dropdown>
            </DocumentWrap>
          }
        </Reference>
        <Row style={{ marginTop: '1em' }}>
          {references?.map(
            ({ key, title, description, hide }) =>
              !hide && (
                <Block key={key} style={{ maxWidth: 200 }}>
                  <Label>{title}</Label>
                  <Text dangerouslySetInnerHTML={{ __html: description }} />
                </Block>
              )
          )}
        </Row>

        {isReportSource && data.report && data?.report?.ties_to_license[0] !== 5 &&
          <Block style={{ marginTop: '1em', backgroundColor: 'var(--rose)', padding: 12 }}>
            <Label>{'Reporting applies if Reporting Entity holds the following license/registration in this jurisdiction:'}</Label>
            <Text>
              {getDescription(store?.legislative_options?.report_source_ties_to_license, data?.report?.ties_to_license[0])}
            </Text>
          </Block>
        }

        <div style={{ marginTop: 20 }}>
          <MoreInfo data={data} isReportSource={isReportSource} noHide={true} />
        </div>
        {accessAllowed && trackingMode !== 'compliance_source_ultra_lite' && (!!data.report_interlinking_items.length || !!data.compliance_interlinking_items.length)
          && <InterLinks data={data} store={store} />}
        {isReportSource && data.is_new_requirement &&
          <NewRequirementBlock text={data.new_requirement_text} updateDate={data.requirement_text_updated_date} />
        }
        {isReportSource ?
          data.internal_due_date &&
          <InternalDueDateBlock>
            <strong>Internal Due Date:</strong>  {data.internal_due_date}
          </InternalDueDateBlock>
          : data.primary_due_date &&
          <InternalDueDateBlock>
            <strong>Internal Due Date:</strong>  {data.primary_due_date}
          </InternalDueDateBlock>
        }

        {data.notes && (trackingMode !== 'compliance_source_ultra_lite') && (
          <Block style={{ marginTop: '1em' }}>
            <Label>Notes:</Label>
            <Text
              dangerouslySetInnerHTML={{
                __html: converter.makeHtml(data.notes),
              }}
            />
          </Block>
        )}
        {!isReportSource && data.title
          && <Block style={{ marginTop: '1em' }}>
            <Label>{'Title:'}</Label>
            <Text
              dangerouslySetInnerHTML={{
                __html: converter.makeHtml(data.title),
              }}
            />
          </Block>
        }
        {!isReportSource && data.summary
          && <Block style={{ marginTop: '1em' }}>
            <Label>{'Summary:'}</Label>
            <Text
              dangerouslySetInnerHTML={{
                __html: isMyTasks
                  ? highlightSearchedWords(data.summary, store?.searchableObjectTasks?.search)
                  : highlightSearchedWords(data.summary, store?.searchableObject?.search),
              }}
            />
          </Block>
        }
        {!isReportSource && data.tips && data.tips.trim().length > 0
          && <Block style={{ marginTop: '1em', backgroundColor: '#DBE9F1', padding: 12 }}>
            <Label>{'Tips:'}</Label>
            <Text dangerouslySetInnerHTML={{
              __html: data.tips,
            }} />
            <DisclaimerBlock>
              This information is informational only and is not a substitute for an attorney or law firm.
              Determining the applicable line of business and other applicability for a report/requirement often is subject to interpretation.
              PBMSource has made efforts to assess the appropriate lines of business and applicability of these requirements
              (which are subject to change as additional information becomes available).
              This information is provided as guidance only, and you are encouraged to do your own research and assessments and/or contact an attorney or the applicable government agency.
              If you question or conclude a different line of business(es) or applicability,
              please let us know so that we can consider updates to this information. Contact us at 888-684-1391 or <a href='mailto:contact@pbmsource.com'>contact@pbmsource.com</a>.
            </DisclaimerBlock>
          </Block>
        }

        {!isReportSource ?
          showModeBlocks ?
            <Read
              style={{ backgroundColor: 'var(--green)', marginTop: '1em' }}
              icon={pdf}
              href={data?.reference_document}
              as={'a'}
              target={'_blank'}
            >
              <img src={pdf} alt='pdf-icon' />
              View Source
            </Read>
            :
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Read
                style={{ backgroundColor: 'var(--green)', marginTop: '1em' }}
                icon={pdf}
                href={data?.reference_document}
                as={'a'}
                target={'_blank'}
              >
                <img src={pdf} alt='pdf-icon' />
                View Source
              </Read>
              <Button
                style={{ backgroundColor: 'var(--blue)', marginTop: '1em' }}
                onClick={moveLegislativeToArchive}
                loading={archiveButtonLoading}
                text={data?.status === 'closed' ? 'To Tracking' : 'Archive'}
              />
            </div>
          : null
        }

        {isReportSource &&
          <>
            <Tabs
              Reporting={false}
              tabs={[
                {
                  key: 'getReady',
                  title: 'Get Ready',
                },
                {
                  key: 'reportPeriod',
                  title: 'Report Period',
                },
                {
                  key: 'forms',
                  title: 'Forms',
                },
                {
                  key: 'guidance',
                  title: 'Guidance',
                },
                {
                  key: 'notes',
                  title: 'Notes',
                },
                {
                  key: 'tips',
                  title: 'Tips',
                },
              ]}
              onClick={(key) => {
                getCurrentLegislative(store.currentLegislative.id, null, null, true, isReportSource)
                setActiveMaterialsTab(key)
              }}
              active={activeMaterialsTab}
            />

            {showReportMaterialsTabs({
              data, isMyTasks, getReadyData, receiveGetReadyData, handleOpen, setAssigneNew, showModeBlocks
            })[activeMaterialsTab].render()
            }
          </>
        }

        {isReportSource && !showModeBlocks &&
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            <Button
              style={{ backgroundColor: 'var(--blue)', marginTop: '1em' }}
              onClick={moveLegislativeToArchive}
              loading={archiveButtonLoading}
              text={data?.status === 'closed' ? 'To Report' : 'Archive'}
            />
          </div>
        }

        <Inputs name={'info-inputs'}>
          {isReportSource && showModeBlocks ?
            reportInfoInputs(store, data).map((item) =>
              item.role.includes(store.user[0]?.report_source_role) ? (
                item.type === 'date' ? (
                  <CalendarCustom
                    handleChange={(value) =>
                      handleChange(value, 'internal_due_date')
                    }
                    incomeValue={data?.internal_due_date}
                    loading={loading.primary_due_date}
                    styleDropdown={{ left: 'auto', right: 0, top: 50 }}
                    placeholder={'Select Date'}
                    label={'Internal Due Date'}
                    redCircleDate={data?.internal_due_date}

                  />
                ) : (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Input
                      type={'select'}
                      control={control}
                      name={item.name}
                      placeholder={item.placeholder}
                      allowClear={false}
                      label={item.label}
                      options={item.options}
                      customOptions={item.customOptions}
                      selectVars={item.selectVars}
                      handleChange={(value) => handleChange(value, item.name)}
                      getPopupContainer={true}
                      smallLabel
                      transparent
                      showSearch={item.name === 'assignee'}
                    />
                    {item.name === 'status' && data.in_progress_deficiency_date && data.status === 'in_progress_deficiency' &&
                      <div style={{
                        fontSize: 12,
                        fontFamily: 'var(--semiBold)',
                        color: 'var(--grey)',
                        marginLeft: 10,
                      }}>
                        Deficiency Date: {moment(data.in_progress_deficiency_date, 'YYYY-MM-DD').format('MM/DD/YYYY')}
                      </div>
                    }
                    {item.name === 'status' && data.closed_submitted_date && data.status === 'closed_submitted' &&
                      <div style={{
                        fontSize: 12,
                        fontFamily: 'var(--semiBold)',
                        color: 'var(--grey)',
                        marginLeft: 10,
                      }}>
                        Closed Sumbited Date: {moment(data.closed_submitted_date, 'YYYY-MM-DD').format('MM/DD/YYYY')}
                      </div>
                    }
                  </div>
                )
              ) : (
                <div>
                  <Label smallLables>{item.label}</Label>
                  <span style={{ fontSize: 14 }}>{item.value}</span>
                </div>
              )
            )
            : trackingMode === "compliance_source" && infoInputs(store, data).map((item) =>
              item.role.includes(store.user[0]?.member_type) ? (
                item.type === 'date' ? (
                  <CalendarCustom
                    handleChange={(value) =>
                      handleChange(value, 'primary_due_date')
                    }
                    incomeValue={data?.primary_due_date}
                    loading={loading.primary_due_date}
                    styleDropdown={{ left: 'auto', right: 0, top: 50 }}
                    placeholder={'Select Date'}
                    label={'Internal Due Date'}
                    redCircleDate={data?.compliance_date}
                  />
                ) : (
                  <Input
                    type={'select'}
                    control={control}
                    name={item.name}
                    placeholder={item.placeholder}
                    allowClear={false}
                    label={item.label}
                    options={item.options}
                    customOptions={item.customOptions}
                    selectVars={item.selectVars}
                    handleChange={(value) => handleChange(value, item.name)}
                    smallLabel
                    getPopupContainer={true}
                    transparent
                    showSearch={item.name === 'assignee'}
                  />
                )
              ) : (
                <div>
                  <Label smallLables>{item.label}</Label>
                  <span style={{ fontSize: 14 }}>{item.value}</span>
                </div>
              )
            )}
        </Inputs>

        {showModeBlocks &&
          <Tasks
            data={data}
            getCurrentLegislative={getCurrentLegislative}
            postFile={postFile}
            isMyTasks={isMyTasks}
            currentPage={currentPage}
            loadingFile={loading.file}
            complianceItem={complianceItem}
            tasks={tasks}
            getTasks={getTasks}
            activeTab={mainActiveTab}
            isReportSource={isReportSource}
            setActiveTab={() => setActiveTab('details')}
            visible={visible}
            handleClose={handleClose}
            handleOpen={handleOpen}
            assigneNew={assigneNew}
            setAssigneNew={setAssigneNew}
            getConsiderationData={getConsiderationData}
            receiveGetReadyData={receiveGetReadyData}
          />
        }
        {isReportSource && showModeBlocks && <ImpactedClients id={store.currentLegislative.id} isReportSource={true} />}
        {showModeBlocks &&
          <Tabs
            Reporting={false}
            tabs={isReportSource
              ? [
                {
                  key: 'details',
                  title: 'Details',
                },
                {
                  key: 'comments',
                  title: 'Comments',
                },
                {
                  key: 'documents_working',
                  title: 'Working',
                },
                {
                  key: 'documents_finalized',
                  title: 'Finalized',
                },
                {
                  key: 'documents_submitted',
                  title: 'Submitted',
                },
                {
                  key: 'documents_deficiency',
                  title: 'Deficiency',
                },
                {
                  key: 'documents_other',
                  title: 'Other',
                },
                {
                  key: 'activity',
                  title: 'Activity',
                },
              ]
              : [
                {
                  key: 'details',
                  title: 'Details',
                },
                {
                  key: 'comments',
                  title: 'Comments',
                },
                {
                  key: 'documents_working',
                  title: 'Documents',
                },
                {
                  key: 'activity',
                  title: 'Activity',
                },
                {
                  key: 'considerations',
                  title: 'Considerations'
                },
              ]

            }
            onClick={(key) => setActiveTab(key)}
            documentTabs={true}
            active={activeTab}
          />
        }

        {showModeBlocks && showTabs({
          data,
          control,
          postFile,
          loading,
          labelStyle,
          successFile,
          getTasks,
          isReportSource,
          detailsState,
          handleDetailsState,
          visible,
          handleClose,
          handleOpen,
          assigneNew,
          setAssigneNew,
          getConsiderationData,
          considerationData,
        })[activeTab].render()
        }
        {isReportSource ? checkRole(
          [store.user?.[0]?.report_source_role],
          ['admin', 'report_owner']
        ) &&
          data?.report?.created_by_company === store.company?.[0]?.id && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: 30,
              }}
            >
              {!tasks?.length && !data.assignee && (
                <ButtonOrange
                  text='Delete Report'
                  style={{
                    color: 'var(--text)',
                    fontSize: 16,
                    cursor: 'pointer',
                    marginBottom: 50,
                  }}
                  onClick={onDelete}
                />
              )}

              <ButtonOrange
                text='Edit Report'
                style={{
                  color: 'var(--text)',
                  fontSize: 16,
                  cursor: 'pointer',
                  marginBottom: 50,
                }}
                onClick={onEdit}
              />
            </div>
          )
          : checkRole(
            [store.user?.[0]?.member_type],
            ['admin', 'compliance_owner']
          ) &&
          data.created_by_company === store.company?.[0]?.id && showModeBlocks && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: 30,
              }}
            >
              {!tasks?.length && !data.assignee && (
                <ButtonOrange
                  text='Delete Compliance Item'
                  style={{
                    color: 'var(--text)',
                    fontSize: 16,
                    cursor: 'pointer',
                    marginBottom: 50,
                  }}
                  onClick={onDelete}
                />
              )}

              <ButtonOrange
                text='Edit Compliance Item'
                style={{
                  color: 'var(--text)',
                  fontSize: 16,
                  cursor: 'pointer',
                  marginBottom: 50,
                }}
                onClick={onEdit}
              />
            </div>
          )}
        <div ref={tabsRef} />
      </Wrap>
    )
  }
)

export default Right

export const getString = (arr, toEqual) => {
  const getContent = () => {
    const description = getDescription(arr, toEqual)

    if (description?.split(',').length > 2) {
      return description
        .split(',')
        .splice(2, description.length - 1)
        .join(', ')
    } else {
      return description
    }
  }

  return (
    <>
      {getDescription(arr, toEqual)?.split(',').length > 2
        ? getDescription(arr, toEqual)
          .split(',')
          .map(
            (item, index) => index < 2 && item + (index !== 1 ? ',' : ' and')
          )
        : getDescription(arr, toEqual)}
      <Popover
        content={<div style={{ maxWidth: 150 }}>{getContent()} </div>}
        placement={'rightTop'}
      >
        <span
          style={{
            fontFamily: 'var(--bold)',
            color: 'var(--blue)',
            paddingLeft: '.5em',
          }}
        >
          {getDescription(arr, toEqual)?.split(',').length > 2 &&
            `${getDescription(arr, toEqual)?.split(',').length - 2} more`}
        </span>
      </Popover>
    </>
  )
}
