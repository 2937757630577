import Modal from "../../../../../../../components/common/ModalNew"
import { Title } from "../RemoveGroupModal/style"
import Button from "../../../../../../../components/common/Button/button"
import { PrevBtn } from "../../../../../CreateCompany/pages/FirstPage/Form/style"

const PricingRemoveModal = ({ open, onClose, tempEndDate, setValue, styleOverlay, }) => {
  return (
    <Modal styleWrap={{
      borderRadius: 8,
      padding: 30,
      display: 'flex',
      flexDirection: 'column',
    }}
      onClose={onClose}
    >
      <Title>Are you sure you want to remove access on this date?</Title>
      <div style={{ marginTop: 40, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignContent: 'center' }}>
        <PrevBtn style={{ marginTop: 7, textDecoration: 'none' }} onClick={() => onClose()}>GoBack</PrevBtn>
        <Button
          text={'Confirm'}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            setValue()
            onClose()
          }}
          type={'main'}
          style={{
            padding: '10px 10px',
            marginRight: 0,
            marginLeft: '.5em',
          }}
        />
      </div>
    </Modal>
  )
}

export default PricingRemoveModal