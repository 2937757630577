import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import { useStore } from '../../../../../../mobx-store/context'
import { useForm } from 'react-hook-form'
import Input from '../../../../../../components/common/Input'
import Button from '../../../../../../components/common/Button/button'
import { Title } from '../../../../components/common/style'
import ResourceModal from '../../../../components/ResourceAccessModal/resourceModal'
import { getError } from '../../../../../../helpers/helpers'
import { api } from '../../../../../../api'
import Table from '../../Table'
import { checkRole } from '../../../../../../helpers/helpers'
import { Back } from './style'
import AutoAssignmentsInfoModal from '../LoadingModal'



const assignmentTypes = [
  {
    id: 'jurisdiction',
    name: 'Jurisdictions',
  },
  {
    id: 'topic',
    name: 'Topics',
  },
  {
    id: 'jurisdiction_and_topic',
    name: 'Jurisdictions and Topics',
  },
]



const TrackingTab = observer(({ setShowActivity }) => {
  const store = useStore()
  const history = useHistory()
  const [assignType, setAssignType] = useState('')
  const [memberId, setMemberId] = useState('')
  const [btnLoading, setBtnLoading] = useState(false)
  const [visible, setVisible] = useState(false)

  const [data, setData] = useState([])

  const { handleSubmit, errors, control, reset, setError } = useForm()

  const members = store?.members
    .filter((item) => {
      return (
        item.is_active &&
        item.have_access_to_tracking &&
        checkRole([item.member_type], ['admin', 'compliance_owner', 'owner'])
      )
    })
    .map((item) => (
      {
        id: item.id,
        name: item.first_name + ' ' + item.last_name
      }
    ))

  if (!store.user?.[0]?.have_access_to_tracking || !store.company?.[0]?.have_access_to_tracking)
    return (
      <ResourceModal
        type='tracking'
        to='/edit-profile'
        onClose={() => history.push('/edit-profile')}
      />
    )

  const onSubmit = (values) => {
    const data = {
      compliance_jurisdictions_auto_assign: values.compliance_jurisdictions_auto_assign,
      compliance_topics_auto_assign: values.compliance_topics_auto_assign,
      auto_assign: true
    }
    setBtnLoading(true)
    setVisible(true)
    api(`/members/${memberId}/`, { ...data }, 'PATCH',).then(
      (data) => {
        setBtnLoading(false)
        if (!data.errors) {
          reset({
            compliance_jurisdictions_auto_assign: '',
            compliance_topics_auto_assign: '',
          })
          setMemberId('')
          api('/companies/auto_assign_members/?filter_for=compliance_source', {}, 'GET').then((data) => {
            store.setSingleValue('autoAssignMembers', data)
            setData(data)
          })
          api('/companies/auto_assign_lists/', {}, 'GET').then((data) => {
            if (!data.errors) {
              store.setSingleValue('autoAssignLists', data)
            }
          })
        } else {
          getError(data, setError)
        }
      },
    )
  }

  useEffect(() => {
    api('/companies/auto_assign_members/?filter_for=compliance_source', {}, 'GET').then((data) => {
      store.setSingleValue('autoAssignMembers', data)
      setData(data)
    })
  }, [])

  const onAssignmentTypeChange = (value) => {
    api(`/companies/auto_assign_type/`, { auto_assign_by: value }, 'PATCH').then(
      (data) => {
        if (!data.errors) {
          store.setSingleValue('autoAssignType', data)
        } else {
          getError(data, setError)
        }
      },
    )
  }



  useEffect(() => {
    setAssignType(store.autoAssignType.compliance_auto_assign_type)
  }, [])


  return (
    <>
      {visible && <AutoAssignmentsInfoModal onClose={() => setVisible(false)} />}
      <Title style={{ fontSize: 14, marginBottom: 5, fontFamily: 'var(--regular)' }}>
        Select the type of data, then user, to set up an auto assignment. <br />
        The user will automatically be assigned as the Compliance Owner of the selected items.
        <Back onClick={() => setShowActivity(true)}>View Auto Assignment Activity</Back>
      </Title>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <div style={{ width: 270 }}>
          <Input
            defaultValue={store?.autoAssignType?.compliance_auto_assign_type}
            control={control}
            name='auto_assign_by'
            type='select'
            placeholder='Select Auto Assignment Type'
            options={assignmentTypes}
            styleContainer={{ marginRight: 15, width: 250 }}
            handleChange={(value) => {
              setAssignType(value)
              onAssignmentTypeChange(value)
            }}
            smallLabel
            getPopupContainer={true}
            disabled={store?.autoAssignMembers.length > 0}
          />
        </div>
        <div style={{ fontSize: 16, fontFamily: 'var(--regular)', color: '#515151' }}>
          Selecting an Assignment Type applies to all users.
        </div>
      </div>
      <div style={{ display: 'flex', marginTop: 20 }}>
        <Input
          control={control}
          name='member'
          type='select'
          placeholder='Select a User'
          options={members}
          style={{ minWidth: 100, height: 30, }}
          styleContainer={{ marginRight: 15 }}
          handleChange={(value) => setMemberId(value)}
          smallLabel
          getPopupContainer={true}
          disabled={!assignType}
        />
        <Input
          selectAll={true}
          control={control}
          name='compliance_jurisdictions_auto_assign'
          type='multiSelect'
          placeholder='Select Jurisdiction'
          options={store?.autoAssignLists?.compliance_jurisdictions.map((item) => {
            return {
              ...item,
              disabled: item.is_taken,
              name: item.name + (item.is_taken ? ' (taken)' : '')
            }
          })}
          styleContainer={{ marginRight: 15 }}
          style={{ width: 180 }}
          smallLabel
          showSearch
          getPopupContainer={true}
          disabled={assignType === 'topic' || !assignType || !memberId}
        />
        <Input
          control={control}
          selectAll={true}
          name='compliance_topics_auto_assign'
          type='multiSelect'
          placeholder='Select Topic'
          options={store?.autoAssignLists?.compliance_topics.map((item) => {
            return {
              ...item,
              disabled: item.is_taken,
              name: item.name + (item.is_taken ? ' (taken)' : '')
            }
          })}
          styleContainer={{ marginRight: 15 }}
          style={{ minWidth: 180}}
          smallLabel
          showSearch
          getPopupContainer={true}
          disabled={assignType === 'jurisdiction' || !assignType || !memberId}
        />
        <Button
          text={'Add Auto Assignment'}
          onClick={handleSubmit(onSubmit)}
          type={'main'}
          loading={btnLoading}
          disabled={!assignType || !memberId}
        />
      </div>
      <Table data={data} setData={(value) => setData(value)} serviceName={'compliance_source'} reset={(value) => reset(value)} />
    </>
  )
})

export default TrackingTab
