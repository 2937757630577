import React from 'react'
import Button from '../../../../components/common/Button/button'
import { Label } from '../../../../components/common/Input/input.style'
import ModalWrap from '../../../../components/common/ModalNew/index'
import { Text } from '../right.style'
import { Cancel } from '../Tasks/Modal/style'

const Modal = ({
  isReportSource,
  onClose,
  title = `${isReportSource ? 'Report' : 'Compliance'} Item Has Incomplete Tasks`,
  text = `Closing a ${isReportSource ? 'report' : 'compliance'} item with active tasks is not possible. Complete all tasks before closing compliance item.`,
  onConfirm,
  buttonText,
  showCancel = true
}) => {
  return (
    <ModalWrap onClose={onClose}>
      <Label>{title}</Label>
      <Text style={{ margin: '15px 0' }}>{text}</Text>
      <div style={{ display: 'flex', alignItems: 'center', marginTop: 30 }}>
        {showCancel && <Cancel onClick={onClose}>Cancel</Cancel>}
        <Button
          text={buttonText || 'I Understand'}
          style={{ marginLeft: 'auto' }}
          onClick={onConfirm}
        />
      </div>
    </ModalWrap>
  )
}

export default Modal
