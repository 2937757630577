import { useEffect, useState } from 'react'
import { api } from '../api'

export const useReportMaterials = ({ materialType, reportCompanyId, reportId }) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [materials, setMaterials] = useState([])


  useEffect(() => {
    setLoading(true)
    setError(false)
    api(
      `/report_company/${reportCompanyId}/${materialType}/`,
      { },
      'GET'
    ).then(res => {
      setMaterials(res[0])
      setLoading(false)
    }).catch(e => {
      setError(true)
    })
  }, [materialType, reportCompanyId, reportId]);

  return { loading, error, materials }
}