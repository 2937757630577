import { message } from 'antd'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { api } from '../../../../api'
import Button from '../../../../components/common/Button/button'
import Input from '../../../../components/common/Input'
import { Label } from '../../../../components/common/Input/input.style'
import Switch from '../../../../components/common/Switch/switch'
import Validation from '../../../../components/common/Validation/validation'
import { getDirtyFields, getError } from '../../../../helpers/helpers'
import { useStore } from '../../../../mobx-store/context'
import { Bottom } from '../../authForm.style'
import { ButtonsWrap, Grid, Wrap } from './form.style'
import { resourceAdditionalInputs, trackingAdditionalInputs, reportAdditionalInputs } from './inputs'

const Form = observer(
  ({
    editing,
    defaultValues,
    handleEditing,
    existing,
    editingId,
    isResource,
    isProposedSource,
    isReportSource,
  }) => {
    const store = useStore()
    const {
      control,
      handleSubmit,
      setError,
      errors,
      reset,
      clearErrors,
      formState: { dirtyFields },
    } = useForm({
      defaultValues,
    })
    const [loading, setLoading] = useState(false)
    const [active, setActive] = useState(false)
    const [emailDomainError, setEmailDomainError] = useState(false)

    const me = store.user?.[0]

    useEffect(() => {
      reset(defaultValues)
    }, [defaultValues])

    useEffect(() => {
      store.getLegislativeOptions()
    }, [])

    const handleFetch = (token, values) => {
      setLoading(true)

      const url = `/members/${defaultValues.id}/`

      const body = getDirtyFields(dirtyFields, values).member_type
        ? {
          ...getDirtyFields(dirtyFields, values),
          member_type: active ? 'compliance_owner' : 'member',
        }
        : getDirtyFields(dirtyFields, values)

      api(url, body, 'PATCH')
        .then((data) => {
          setLoading(false)
          if (!data.errors) {
            reset({
              jurisdictions: [],
              resource_guides: [],
              resource_jurisdictions: [],
              topics: [],
              assign: 'jurisdictions',
              first_name: '',
              last_name: '',
              phone_number: '',
              email: '',
            })

            store.modifyAddedMember(data, true)
            handleEditing()

            message.success(
              <div>
                User{' '}
                <span style={{ fontFamily: 'var(--open-sans-bold)' }}>
                  {data.first_name} {data.last_name}
                </span>{' '}
                successfully modified
              </div>
            )
          } else {
            if (data.email) message.error(data.email)
          }
        })
        .catch(() => {
          setLoading(false)
        })
    }

    const onSubmit = (values) => {
      if (editing) {
        if (existing) {
          handleFetch(null, values)
        } else {
          store.modifyAddedMember({
            ...values,
            id: editingId,
            have_access_to_resource: isResource,
          })
        }
        reset({
          jurisdictions: [],
          topics: [],
          resource_guides: [],
          resource_jurisdictions: [],
          line_of_business: [],
          assign: 'jurisdictions',
          first_name: '',
          last_name: '',
          phone_number: '',
          email: '',
        })
        handleEditing()
      } else {
        api(
          '/members/check_member/',
          {
            ...values,
            member_type: active
              ? 'compliance_owner'
              : isResource && me.member_type === 'admin'
                ? 'admin'
                : 'member',
          },
          'POST'
        )
          .then((data) => {
            if (!data.errors) {
              store.setAddedMember(
                {
                  ...values,
                  [isResource ? 'resource_role' : 'member_type']: !isResource
                    ? active
                      ? 'compliance_owner'
                      : 'member'
                    : 'member',
                  id: new Date().getTime(),
                  have_access_to_resource: isResource,
                  have_access_to_tracking: !isResource,
                },
                () => {
                  reset({
                    jurisdictions: [],
                    topics: [],
                    resource_guides: [],
                    resource_jurisdictions: [],
                    line_of_business: [],
                    assign: 'jurisdictions',
                    first_name: '',
                    last_name: '',
                    phone_number: '',
                    email: '',
                  })
                }
              )
            } else {
              getError(data, setError)
              if (data.errors.email_domain) {
                setEmailDomainError(data.errors.email_domain[0])
                setError('email', { message: ' ' })
              }
            }
          })
          .catch((err) => console.log(err))
      }
    }

    useEffect(() => {
      store.loadSelect('companyOptions')
      store.loadSelect('memberOptions', null, false)
      store.loadSelect('currentMemberOptions')
    }, [])

    useEffect(() => {
      store.setSingleValue('reportTypes', store?.legislative_options?.report_types)
      store.setSingleValue('reportJurisdictions', store?.legislative_options?.report_source_jurisdictions)
    }, [store.legislative_options])

    return (
      <Wrap onSubmit={handleSubmit(onSubmit)}>
        {editing && (
          <Label style={{ fontFamily: 'var(--semiBold)' }}>
            Edit Team Member
          </Label>
        )}
        <Grid>
          <Input
            name={'first_name'}
            label={'First Name'}
            error={errors && errors.first_name && 'Enter valid first name'}
            type={'text'}
            transparent
            control={control}
            validation={{ required: true, pattern: /[A-Za-z]+/ }}
            placeholder={'First Name'}
            style={{ marginBottom: errors && errors.first_name && '0' }}
          />
          <Input
            name={'last_name'}
            label={'Last Name'}
            error={errors && errors.last_name && 'Enter valid last name'}
            type={'text'}
            control={control}
            placeholder={'Last Name'}
            transparent
            validation={{ required: true, pattern: /[A-Za-z]+/ }}
            style={{ marginBottom: errors && errors.last_name && '0' }}
          />
        </Grid>
        <Grid>
          <Input
            name={'email'}
            label={editing ? 'Email cannot be edited' : 'Email Address'}
            error={
              errors &&
              errors.email &&
              (errors.email.message || 'Enter valid email')
            }
            type={'email'}
            handleChange={() => {
              if (emailDomainError) {
                clearErrors(['email', 'email_domain'])
                setEmailDomainError(null)
              }
            }}
            disabled={editing}
            placeholder={'Email Address'}
            transparent
            control={control}
            validation={{ required: true }}
            style={{ marginBottom: errors && errors.email && '0' }}
          />

          <Input
            name={'phone_number'}
            label={'Phone'}
            error={errors && errors.phone_number && 'Enter a valid number'}
            type={'phone'}
            transparent
            control={control}
            validation={{ required: false }}
            style={{ marginBottom: errors && errors.phone_number && '0' }}
          />
        </Grid>
        {emailDomainError && (
          <Validation text={emailDomainError} noLineThrough />
        )}
        <div
          style={{
            maxHeight:
              active || defaultValues.member_type === 'compliance_owner'
                ? '600px'
                : 0,
            overflow: 'hidden',
            transition: 'all .2s ease-in-out',
            marginBottom: '1em',
          }}
        >
          {isResource
            ? resourceAdditionalInputs(store).map(
              (item) =>
                !item.isHidden && (
                  <Input
                    name={item.name}
                    label={item.label}
                    error={
                      errors && errors[item.name] && 'This field is required'
                    }
                    type={item.type}
                    control={control}
                    validation={item.validation}
                    key={item.name}
                    transparent={item.transparent}
                    style={{
                      marginBottom: errors && errors[item.name] && '0',
                    }}
                    placeholder={item.placeholder}
                    options={store[item.options]}
                    loading={!store[item.options]?.length}
                  />
                )
            )
            : isReportSource ? reportAdditionalInputs(store).map(
              (item) =>
                !item.isHidden && (
                  <Input
                    name={item.name}
                    label={item.label}
                    error={
                      errors && errors[item.name] && 'This field is required'
                    }
                    type={item.type}
                    control={control}
                    validation={item.validation}
                    key={item.name}
                    transparent={item.transparent}
                    style={{
                      marginBottom: errors && errors[item.name] && '0',
                    }}
                    placeholder={item.placeholder}
                    options={store[item.options]}
                    // loading={!store[item.options]?.length}
                  />
                )
            )

              : trackingAdditionalInputs(store).map((item) =>
                !item.isHidden ? (
                  <Input
                    name={item.name}
                    label={item.label}
                    error={
                      errors && errors[item.name] && 'This field is required'
                    }
                    type={item.type}
                    control={control}
                    validation={item.validation}
                    key={item.name}
                    transparent={item.transparent}
                    style={{
                      marginBottom: errors && errors[item.name] && '0',
                    }}
                    placeholder={item.placeholder}
                    options={store[item.options]}
                    loading={!store[item.options].length}
                  />
                ) : null
              )}
        </div>
        {!isProposedSource && <Switch
          onChange={() => setActive(!active)}
          active={active || defaultValues.member_type === 'compliance_owner'}
          text={
            isResource ? (
              'Limit team member access by Guide, and Jurisdiction'
            ) : isReportSource ? 'Limit team member access by Report Type, and Jurisdiction' : (
              <div>
                This user is a{' '}
                <span style={{ fontFamily: 'var(--bold)' }}>
                  Compliance Owner
                </span>{' '}
                and can assign tasks to team members.
              </div>
            )
          }
        />}

        <Bottom style={{ maxWidth: '100%', alignItems: 'flex-start' }}>
          <ButtonsWrap>
            <Button
              text={editing ? 'Save' : 'Add to Team'}
              style={{ marginRight: '1em' }}
              loading={loading}
            />
          </ButtonsWrap>
        </Bottom>
      </Wrap>
    )
  }
)

export default Form
