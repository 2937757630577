import { useEffect, useState } from "react";
import { useStore } from "../../../../../../../mobx-store/context";
import { StyledSwitch } from "../../../FirstPage/style";
import { useHistory } from "react-router-dom";
import { SwitchTitle, SwitchText, Row, DetailsWrap, TableTitle, AllCheckboxContainer } from "../../style";
import { useForm } from "react-hook-form";
import Input from "../../../../../../../components/common/Input";
import { Divider, Checkbox } from "antd";
import Button from "../../../../../../../components/common/Button/button";
import { PrevBtn } from "../../../FirstPage/Form/style";
import CheckboxGroup from '../../CheckboxGroup'
import { api } from "../../../../../../../api";
import EndDateConfirmModal from "../../../../../EditCompany/Tabs/Resource/GuideGroup/EndDateConfirmModal";
import moment from 'moment'

const ProposedTab = ({ handleTabChange }) => {
  const history = useHistory()
  const store = useStore()
  const { handleSubmit, control, getValues, watch, errors, setValue } = useForm({
    defaultValues: {
      proposed_source_registration_date: store?.cmsCompany[0]?.proposed_source_registration_date,
      proposed_source_next_payment_date: store?.cmsCompany[0]?.proposed_source_next_payment_date,
      proposed_source_legislations_implement_date: store?.cmsCompany[0]?.proposed_source_legislations_implement_date,
    }
  })
  const [endDateModalVisible, setEndDateModalVisible] = useState(false)
  const [tempEndDate, setTempEndDate] = useState(null)
  const [hasAccesstoPS, setHasAccesstoPS] = useState(store?.cmsCompany[0]?.have_access_to_proposed_source)
  const [hasCustomPrice, setHasCustomPrice] = useState(store?.cmsCompany[0]?.has_proposed_source_custom_pricing)
  const [allJurisdictions, setAllJurisdictions] = useState(store?.cmsCompany[0]?.all_proposed_jurisdictions)
  const [jursCount, setJursCount] = useState(store?.cmsCompany[0]?.proposed_jurisdictions?.length)

  const updateCompany = (values) => {
    const data = {
      ...values,
      proposed_source_registration_date: values.proposed_source_registration_date
        ? moment(values.proposed_source_registration_date).format('YYYY-MM-DD') : null,
      proposed_source_next_payment_date: values.proposed_source_next_payment_date
        ? moment(values.proposed_source_next_payment_date).format('YYYY-MM-DD') : null,
      proposed_source_legislations_implement_date: values.proposed_source_legislations_implement_date
        ? moment(values.proposed_source_legislations_implement_date).format('YYYY-MM-DD') : null,
      have_access_to_proposed_source: hasAccesstoPS,
      has_proposed_source_custom_pricing: hasCustomPrice,
      proposed_source_custom_price: hasCustomPrice && values.proposed_source_custom_price
        ? values.proposed_source_custom_price.replace(/[$,a-zA-Z]/g, '')
        : null,
      all_proposed_jurisdictions: allJurisdictions,
    }
    api(`/cms/companies/${store?.cmsCompany[0]?.id}/`, data, 'PATCH').then((res) => {
      if (!res.errors)
        store.setSingleValue('cmsCompany', [res])
    })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const onSubmit = (values) => {
    updateCompany(values)
    handleTabChange('resource')
  }

  const watchValues = watch();

  useEffect(() => {
    setJursCount((getValues('proposed_jurisdictions') && getValues('proposed_jurisdictions').length) || 0)
  }, [watchValues]);

  useEffect(() => {
    updateCompany(getValues())
  }, [watchValues])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {endDateModalVisible && <EndDateConfirmModal onClose={() => setEndDateModalVisible(false)} setValue={() => setValue('proposed_source_next_payment_date', tempEndDate)} />}
      <div style={{ display: 'flex', alignItems: 'center', marginTop: 25, marginBottom: 20 }}>
        <StyledSwitch
          defaultChecked={store?.cmsCompany[0]?.have_access_to_proposed_source}
          checkedChildren="Yes"
          unCheckedChildren="No"
          onChange={(checked) => { setHasAccesstoPS(checked) }}
        />
        <div style={{ marginLeft: 10 }} >
          <SwitchTitle>Has Access to ProposedSource</SwitchTitle>
          <SwitchText>Company will have access to ProposedSource, the date they can first log in.</SwitchText>
        </div>
      </div>
      <Row style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Input
          smallLabel
          name={'proposed_source_registration_date'}
          label={'Select Start Date'}
          type={'date'}
          control={control}
          showCross={false}
          validation={{ required: false }}
          transparent
          placeholder={'Select Start Date'}
          noLimit
        />
        <Input
          smallLabel
          name={'proposed_source_next_payment_date'}
          label={'Select End Date'}
          showCross={true}
          type={'date'}
          control={control}
          validation={{ required: false }}
          transparent
          placeholder={'Select End Date'}
          noLimit
          handleChange={(e) => {
            setTempEndDate(e)
            setEndDateModalVisible(true)
          }}
          handleReset={() => setValue('proposed_source_next_payment_date', null)}
        />
      </Row >
      <Row style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Input
          smallLabel
          showCross={false}
          name={'proposed_source_legislations_implement_date'}
          label={'Show Legislation Items introduced date as of:'}
          type={'date'}
          control={control}
          validation={{ required: false }}
          transparent
          placeholder={'Select Date'}
          style={{ width: '48%' }}
          noLimit
        />
      </Row>
      {/* <Divider style={{ background: '#CCC' }} />
      <div style={{ display: 'flex', alignItems: 'center', marginTop: 10, marginBottom: 20 }}>
        <StyledSwitch
          defaultChecked={store?.cmsCompany[0]?.has_proposed_source_custom_pricing}
          checkedChildren="Yes"
          unCheckedChildren="No"
          onChange={(checked) => { setHasCustomPrice(checked) }}
        />
        <div style={{ marginLeft: 10 }} >
          <SwitchTitle>Has Custom Pricing</SwitchTitle>
          <SwitchText>Show a custom price, if not selected no price will <br /> show in the admin’s settings page.</SwitchText>
        </div>
      </div >
      {hasCustomPrice &&
        <Input
          smallLabel
          label={'Custom Price'}
          defaultValue={formatCurrency(store?.cmsCompany[0]?.proposed_source_custom_price) || ''}
          disabled={!hasCustomPrice}
          name={'proposed_source_custom_price'}
          type={'text'}
          control={control}
          validation={{ required: true }}
          transparent
          placeholder={'Enter Custom Price'}
          style={{ width: '48%' }}
          error={errors && errors.proposed_source_custom_price && 'Enter valid custom price'}
        />
      } */}
      <Divider style={{ background: '#CCC' }} />
      <SwitchTitle>Select which Jurisdictions has access to:</SwitchTitle>
      <DetailsWrap style={{ marginTop: 20, gridTemplateColumns: '1fr' }}>
        <TableTitle>Jurisdictions ({allJurisdictions ? 'All' : jursCount})</TableTitle>
      </DetailsWrap>
      <DetailsWrap style={{ gridTemplateColumns: '1fr' }}>
        <AllCheckboxContainer>
          <div> All Jurisdictions </div>
          <Checkbox
            onChange={(e) => setAllJurisdictions(e.target.checked)}
            name={'all_proposed_jurisdictions'}
            style={{ marginLeft: 0, marginRight: 115 }}
            checked={allJurisdictions}
          >
          </Checkbox>
        </AllCheckboxContainer>
      </DetailsWrap>
      {(!allJurisdictions) &&
        <DetailsWrap style={{ gridTemplateColumns: '1fr' }}>
          <CheckboxGroup
            control={control}
            name={'proposed_jurisdictions'}
            options={store?.cms_options.jurisdictions}
            disabled={allJurisdictions}
            defaultValue={store?.cmsCompany[0]?.proposed_jurisdictions}
          />
        </DetailsWrap>
      }
      <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
        <PrevBtn onClick={() => history.goBack()}>Previous</PrevBtn>
        <Button
          text={'Next'}
          htmlType="submit"
          type={'main'}
          style={{
            width: '10%',
            padding: 0,
            marginRight: 0,
            marginLeft: '.5em',
            marginTop: '2.3em',
          }}
        />
      </Row>
    </form>
  )
};

export default ProposedTab;