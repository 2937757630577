import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import image from '../../../../assets/images/manage-team.svg'
import ButtonOrange from '../../../../components/common/ButtonOrange'
import Input from '../../../../components/common/Input'
import { PlainText } from '../../../../components/common/PlainText/style'
import { useStore } from '../../../../mobx-store/context'
import { PageTitle, Row, Title } from '../../components/common/style'
import PageWrap from '../../components/Container'
import Info from '../CurrentPlan/info'
import Table from '../ProductSettings/table'
import { InputsWrap } from './style'

const options = [
  { id: 'no_sorting', name: 'No Sorting' },
  { id: 'jurs', name: 'Assigned Jurisdictions' },
  { id: 'topics', name: 'Assigned Topics' },
  { id: 'line_of_business', name: 'Line of Business' },
  { id: 'guides', name: 'By Assigned Guides' },
]

const ManageTeamMembers = observer(() => {
  const store = useStore()
  const { control, handleSubmit, setValue } = useForm()
  const [sorting, setSorting] = useState('no_sorting')

  const teamAdmins = store.members?.filter(
    (item) => item.is_active && item.member_type === 'admin'
  )

  const leftData = [
    {
      key: 0,
      title: 'Number of Users',
      text: store.members.length,
    },
    {
      key: 2,
      title: 'Team Admins',
      text: teamAdmins.length
        ? teamAdmins
          .map((item) => `${item.first_name} ${item.last_name}`)
          .join(', ')
        : '-',
    },
  ]

  const onSort = (value, name) => {
    if (value) {
      store.setSingleValue('searchableMembersObject', {
        ...store.searchableMembersObject,
        [name]: value,
      })
    } else {
      const searchableObj = store.searchableMembersObject
      delete searchableObj[name]

      store.setSingleValue(
        'searchableMembersObject',
        Object.keys(searchableObj).length ? searchableObj : null
      )
    }
  }

  const inputs = {
    no_sorting: null,
    jurs: (
      <Input
        name={'jurs'}
        type={'select'}
        control={control}
        options={store.jurs}
        placeholder={'Select Jurisdictions'}
        handleChange={(value) => onSort(value, 'jurisdictions')}
        allowClear={false}
        showSearch
      />
    ),
    topics: (
      <Input
        name={'topics'}
        type={'select'}
        control={control}
        options={store.topics}
        placeholder={'Select Topics'}
        handleChange={(value) => onSort(value, 'topics')}
        allowClear={false}
      />
    ),
    line_of_business: (
      <Input
        name={'line_of_business'}
        type={'select'}
        control={control}
        options={store.lineOfBusinesses}
        placeholder={'Select Line of Business'}
        handleChange={(value) => onSort(value, 'line_of_business')}
        allowClear={false}
      />
    ),
    guides: (
      <Input
        name={'guides'}
        type={'select'}
        control={control}
        options={store.resources}
        selectVars={{ value: 'guide_name_id' }}
        placeholder={'Select Guide'}
        handleChange={(value) => onSort(value, 'guides')}
        allowClear={false}
      />
    ),
  }

  function filterObjectByNameField(obj) {
    if (typeof obj === 'object' && obj !== null) {
      return Object.keys(obj)
        .filter(key => key === 'name')
        .reduce((newObj, key) => {
          newObj[key] = obj[key];
          return newObj;
        }, {});
    } else {
      return {}
    }
  }

  function handleInputFocus(store, setValue, setSorting) {
    return () => {
      const searchableObj = store.searchableMembersObject
      store.setSingleValue('searchableMembersObject', filterObjectByNameField(searchableObj))
      setValue('filter_type', 'no_sorting')
      setSorting('no_sorting')
    };
  }

  useEffect(() => {
    store.loadMembers()
  }, [store.searchableMembersObject])

  useEffect(
    () => () => store.setSingleValue('searchableMembersObject', null),
    []
  )

  useEffect(() => {
    if (!store.lineOfBusiness || !store.topics)
      store.loadSelect('companyOptions')

    if (!store.resources) store.getResourceTopic()
  }, [])

  return (
    <PageWrap modalOpen={store.activityModalOpen} gridStyle={{ width: 1100 }}>
      <PageTitle>Manage The Team</PageTitle>
      <Row>
        <div style={{ width: 400 }}>
          <Title style={{ marginTop: 20 }}>Your Team at a Glance</Title>
          <Info leftData={leftData} style={{ padding: '10px 20px' }} />
        </div>
        <img
          src={image}
          alt='image'
          style={{ width: '40%', marginLeft: '3em' }}
        />
      </Row>
      <Title style={{ marginTop: 15 }}>Team ({store.members.length})</Title>
      <PlainText style={{ fontSize: '.875em' }}>
        These are your team members, for ComplianceSource and ReSource you can
        add unlimited users as part of your subscription.
        <ButtonOrange
          link={'/add-team-members-profile/tracking'}
          text={'Need to add more Team Members?'}
          style={{ display: 'block' }}
        />
      </PlainText>
      <InputsWrap>
        <Input
          name={'name'}
          type={'text'}
          control={control}
          placeholder={'Search By First or Last Name'}
          styleContainer={{ marginRight: 15 }}
          allowClear={false}
          handleChange={(event) => {
            onSort(event.target.value, 'name')
          }}
          onFocus={handleInputFocus(store, setValue, setSorting)}
          handleClear={(event) => {
            onSort('', 'name')
          }}
        />
        <Input
          name={'filter_type'}
          type={'select'}
          control={control}
          options={
            store.company?.[0].is_premium
              ? options
              : options.filter((item) => item.id !== 'line_of_business')
          }
          defaultValue={'no_sorting'}
          handleChange={(value) => {
            setValue('name', '')
            setSorting(value)
            store.setSingleValue('searchableMembersObject', null)
            store.loadMembers()
          }}
          styleContainer={{ marginRight: 15 }}
          allowClear={false}
        />
        {inputs[sorting]}
      </InputsWrap>
      <Table noTitle />
    </PageWrap>
  )
})

export default ManageTeamMembers
