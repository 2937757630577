import React from 'react';
import { NavPageWrap, PageTitle, TitleBlock, Subtitle, InputsBlock, SwitchTitle, RemoveButton, ButtonContainer } from "../ReportEdit/ReportPage/style";
import { useState, useEffect } from 'react'
import { useStore } from "../../../mobx-store/context";
import { api } from "../../../api";
import { useHistory } from 'react-router-dom'
import ButtonOrange from "../../../components/common/ButtonOrange";
import Input from "../../../components/common/Input";
import { useForm } from 'react-hook-form';
import CheckboxTable from '../ReportEdit/ReportPage/CheckboxGroup/index'
import { StyledSwitch } from '../CreateCompany/pages/FirstPage/style';
import MyEditor from '../ReportEdit/ReportPage/CheckboxGroup/Editor';
import Button from "../../../components/common/Button/button";
import { message } from 'antd';
import moment from 'moment'
import GetReadyList from '../ReportEdit/ReportPage/GetReadyTasks';
import bin from '../../../assets/images/delete_red_bin.svg'
import RemoveReportModal from '../ReportEdit/ReportPage/Modal/';
import { useParams } from 'react-router-dom';
import Wraper from '../CMSContainer';
import Document from './document'


const CompliancePage = ({ editMode }) => {
  const history = useHistory()
  const store = useStore()
  const params = useParams()
  const { handleSubmit, control, reset, getValues, watch, errors } = useForm();
  const [isPublished, setIsPublished] = useState(store?.cmsCurrentLegislative?.is_published || false)
  const [summary, setSummary] = useState(store?.cmsCurrentLegislative?.summary || '')
  const [notes, setNotes] = useState(store?.cmsCurrentLegislative?.notes || '')
  const [internalNotes, setInternalNotes] = useState(store?.cmsCurrentLegislative?.internal_notes || '')
  const [file, setFile] = useState(store?.cmsCurrentLegislative?.file || '')
  const [isEditMode, setIsEditMode] = useState(editMode)
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false)
  const [loading, setLoading] = useState(isEditMode ? true : false)
  const [currentTopics, setCurrentTopics] = useState([])


  const getCurrentCompliance = (reportId) => {
    setLoading(true)
    api(`/cms/legislatives/${reportId}/`, {}, 'GET').then((res) => {
      store.setSingleValue('cmsCurrentLegislative', res)
      reset({
        title: res.title,
        summary: res.summary,
        notes: res.notes,
        internal_notes: res.internal_notes,
        reference_number: res.reference_number,
        reference_type: res.reference_type,
        reference_source: res.reference_source,
        survey_number: res.survey_number,
        adopted_date: res.adopted_date && moment(res.adopted_date).format('YYYY-MM-DD'),
        compliance_date: res.compliance_date && moment(res.compliance_date).format('YYYY-MM-DD'),
        effective_date: res.effective_date && moment(res.effective_date).format('YYYY-MM-DD'),
        end_date: res.end_date && moment(res.end_date).format('YYYY-MM-DD'),
        jurisdiction: res.jurisdiction,
        sub_topics: res.sub_topics,
      })
      setIsPublished(res.is_published)
      setSummary(res.summary)
      setNotes(res.notes)
      setInternalNotes(res.internal_notes)
      setCurrentTopics(res.topics)
      // setFile(res.reference_document)
      setLoading(false)
    }).then(() => {
      setLoading(false)
    })
  }


  useEffect(() => {
    if (store?.cmsCurrentLegislative?.id) {
      setIsEditMode(true)
    }
  }, [store?.cmsCurrentLegislative?.id])

  useEffect(() => {
    if (isEditMode && params.id) {
      getCurrentCompliance(params.id)
    }
  }, [])

  const getBody = (values) => {
    const formData = new FormData();

    const dateFields = ['adopted_date', 'compliance_date', 'effective_date', 'end_date'];
    const jsonFields = ['topics', 'line_of_business'];

    for (const key in values) {
      if (values[key] === null || values[key] === undefined || (dateFields.includes(key) && values[key] === false)) {
        continue;
      }

      if (dateFields.includes(key)) {
        formData.append(key, moment(values[key]).format('YYYY-MM-DD'));
      } else {
        formData.append(key, values[key]);
      }
    }

    formData.append('is_published', isPublished);
    formData.append('summary', summary);
    formData.append('notes', notes);
    formData.append('internal_notes', internalNotes);
    formData.append('reference_document', file);
    return formData;
  }



  const onSubmit = (data) => {
    const url = isEditMode ? `/cms/legislatives/${store?.cmsCurrentLegislative?.id || params.id}/` : `/cms/legislatives/`
    const body = getBody(data)
    api(url, body, isEditMode ? 'PATCH' : 'POST', false, true).then((res) => {
      if (!res.errors) {
        store.setSingleValue('cmsCurrentLegislative', res)
        message.success(isEditMode ? 'Compliance Item updated successfully' : 'Compliance Item created successfully')
        isEditMode && history.push(`/dashboard-compliance-table`)
        !isEditMode && setIsEditMode(true)
      }
    })
  }

  const onRemoveCompliance = () => {
    api(`/cms/legislatives/${store?.cmsCurrentLegislative?.id || params.id}/`, {}, 'DELETE').then((res) => {
      if (!res.errors) {
        message.success('Compliance Item removed successfully')
        setIsRemoveModalOpen(false)
        history.goBack()
      }
    })
  }

  const getCmsOptions = () => {
    api(`/cms/dictionaries_data/all/`, {}, 'GET').then((data) => {
      if (!data.errors) {
        store.setSingleValue('cms_options', data)
      }
    })
  }

  useEffect(() => {
    store.getLegislativeOptions()
  }, [])


  useEffect(() => {
    getCmsOptions()
  }, [])

  useEffect(() => {
    setCurrentTopics(getValues('topics'))
  }, [watch('topics')])

  const subTopicOptions = store?.legislative_options?.sub_topics
    .filter((item) => currentTopics?.includes(item.topic))
    .map((item) => ({
      id: item.id,
      name: item.name
    }));

  console.log(getValues())


  return (
    <>
      {!loading &&
        <Wraper>
          <NavPageWrap >
            {isRemoveModalOpen && isEditMode &&
              <RemoveReportModal
                onClose={() => { setIsRemoveModalOpen(false) }}
                onRemove={onRemoveCompliance}
                serviceName={'compliance'}
              />
            }
            <TitleBlock style={{ width: '50%' }}>
              <PageTitle>
                {isEditMode ? 'Edit ComplianceSource Item' : 'Add ComplianceSource Item'}
              </PageTitle>
              <ButtonOrange
                text={'Activity'}
                onClick={() => history.push(`/dashboard-compliance-activity/${store?.cmsCurrentLegislative?.id || params.id}`)}
                style={{ fontFamily: 'var(--bold)', color: 'var(--blue)', fontSize: 12, }}
              />
            </TitleBlock>
            <TitleBlock style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
              <Subtitle>
                {isEditMode ? 'Edit a ComplianceSource Item for subscribers.' : 'Add a new ComplianceSource Item for subscribers.'}
              </Subtitle>
              <ButtonOrange
                text={'< Go Back'}
                onClick={() => history.goBack()}
                style={{ fontFamily: 'var(--bold)', color: '#4E74A9', fontSize: 12, textDecoration: 'none', marginBottom: 32 }}
              />
            </TitleBlock>
            <InputsBlock style={{ width: '50%', marginBottom: 32 }}>
              <Input
                style={{ marginBottom: 32 }}
                smallLabel
                name={'jurisdiction'}
                label={'Jurisdition'}
                options={store?.cms_options?.jurisdictions}
                type={'select'}
                control={control}
                validation={{ required: !isEditMode }}
                transparent
                defaultValue={store?.cmsCurrentLegislative?.jurisdiction || ''}
              />
            </InputsBlock>
            <InputsBlock style={{ width: '50%', }}>
              <CheckboxTable
                options={store?.cms_options?.topics}
                name={'topics'}
                control={control}
                title={'Topics'}
                disabled={false}
                defaultValue={store?.cmsCurrentLegislative?.topics || []}
                compact={false}
              />
              <CheckboxTable
                options={store?.cms_options?.line_of_business}
                name={'line_of_business'}
                control={control}
                title={'Lines of Business'}
                disabled={false}
                defaultValue={store?.cmsCurrentLegislative?.line_of_business || []}
                compact={false}
              />
            </InputsBlock>
            <InputsBlock style={{ width: '50%', marginTop: 40 }}>
              <CheckboxTable
                options={subTopicOptions}
                name={'sub_topics'}
                control={control}
                title={'Sub Topics'}
                disabled={false}
                defaultValue={store?.cmsCurrentLegislative?.sub_topics || []}
                compact={false}
              />
            </InputsBlock>
            <div style={{ width: '50%', margin: '32px 0 32px 0 ', border: '0.5px solid #8B9394' }} />
            <InputsBlock style={{ width: '50%', marginBottom: 32 }} >
              <Input
                style={{ marginBottom: 32 }}
                smallLabel
                name={'reference_number'}
                label={'Reference #'}
                type={'text'}
                control={control}
                validation={{ required: !isEditMode }}
                transparent
                defaultValue={store?.cmsCurrentLegislative?.reference_number || ''}
              />
              <Input
                style={{ marginBottom: 32 }}
                smallLabel
                name={'reference_type'}
                label={'Reference Type'}
                options={store?.cms_options?.reference_types}
                type={'select'}
                control={control}
                validation={{ required: !isEditMode }}
                transparent
                defaultValue={store?.cmsCurrentLegislative?.reference_type || ''}
              />
            </InputsBlock>
            <InputsBlock>
              <Input
                style={{ marginBottom: 32 }}
                smallLabel
                name={'title'}
                label={'Title'}
                type={'text'}
                control={control}
                transparent
                defaultValue={store?.cmsCurrentLegislative?.title || ''}
              />
            </InputsBlock>
            <InputsBlock style={{ marginBottom: 32 }} >
              <MyEditor
                title={'Summary'}
                handleChange={(value) => setSummary(value)}
                defaultValue={store?.cmsCurrentLegislative?.summary || ''} />
            </InputsBlock>
            <InputsBlock style={{ marginBottom: 32 }} >
              <MyEditor
                title={'Notes'}
                handleChange={(value) => setNotes(value)}
                defaultValue={store?.cmsCurrentLegislative?.notes || ''} />
            </InputsBlock>
            <InputsBlock style={{ marginBottom: 32 }} >
              <MyEditor
                title={'Internal notes'}
                handleChange={(value) => setInternalNotes(value)}
                defaultValue={store?.cmsCurrentLegislative?.internal_notes || ''} />
            </InputsBlock>
            <InputsBlock style={{ width: '50%', marginBottom: 32 }}>
              <Input
                smallLabel
                name={'adopted_date'}
                label={'Adopted Date'}
                type={'date'}
                control={control}
                transparent
                placeholder={'Select Adopted Date'}
                showCross={false}
                validation={{ required: false }}
                noLimit
                defaultValue={store?.cmsCurrentLegislative?.adopted_date || ''}
              />
              <Input
                smallLabel
                name={'compliance_date'}
                label={'Compliance Date'}
                type={'date'}
                control={control}
                transparent
                placeholder={'Select Compliance Date'}
                showCross={false}
                validation={{ required: false }}
                noLimit
                defaultValue={store?.cmsCurrentLegislative?.compliance_date || ''}
              />
            </InputsBlock>
            <InputsBlock style={{ width: '50%', marginBottom: 32 }}>
              <Input
                smallLabel
                name={'effective_date'}
                label={'Effective Date'}
                type={'date'}
                control={control}
                transparent
                placeholder={'Select Effective Date'}
                showCross={false}
                validation={{ required: false }}
                noLimit
                defaultValue={store?.cmsCurrentLegislative?.effective_date || ''}
              />
              <Input
                smallLabel
                name={'end_date'}
                label={'End Date'}
                type={'date'}
                control={control}
                transparent
                placeholder={'Select End Date'}
                showCross={false}
                validation={{ required: false }}
                noLimit
                defaultValue={store?.cmsCurrentLegislative?.end_date || ''}
              />
            </InputsBlock>
            <InputsBlock style={{ marginBottom: 32 }} >
              <div>
                <Input
                  style={{ marginBottom: 22 }}
                  control={control}
                  name='file'
                  type='file'
                  handleChange={(e) => setFile(e.target.files[0])}
                  showFile
                  label='Upload Document (.pdf only)'
                  smallLabel
                  validation={{ required: !editMode }}
                  error={errors.file && 'This field is required'}
                  accept='application/pdf'
                />
                {store?.cmsCurrentLegislative?.reference_document_name && <Document fileName={store?.cmsCurrentLegislative?.reference_document_name} />}
              </div>
            </InputsBlock>
            <InputsBlock style={{ marginBottom: 32 }} >
              <Input
                smallLabel
                name={'reference_source'}
                label={'Reference Source'}
                type={'text'}
                control={control}
                validation={{ required: !isEditMode }}
                transparent
                defaultValue={store?.cmsCurrentReport?.reference_source || ''}
              />
            </InputsBlock>
            <InputsBlock style={{ marginBottom: 32 }} >
              <Input
                style={{ marginBottom: 32 }}
                smallLabel
                name={'survey_number'}
                label={'Survey Number'}
                options={store?.cms_options?.survey}
                type={'select'}
                control={control}
                validation={{ required: !isEditMode }}
                transparent
                defaultValue={store?.cmsCurrentLegislative?.survey_number || ''}
              />
            </InputsBlock>
            <InputsBlock style={{ width: '50%' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <StyledSwitch
                  defaultChecked={store?.cmsCurrentLegislative?.is_published || false}
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  onChange={(checked) => { setIsPublished(checked) }}
                />
                <div style={{ marginLeft: 10 }} >
                  <SwitchTitle>Is Published</SwitchTitle>
                </div>
              </div >
            </InputsBlock>
            <div style={{ width: '50%', margin: '32px 0 32px 0 ', border: '0.5px solid #8B9394' }} />

            {isEditMode &&
              !loading &&
              <GetReadyList
                isReportSource={false}
                reportId={params.id || store?.cmsCurrentLegislative?.id}
                initialTasks={store?.cmsCurrentLegislative?.applies_to || []}
                title={''}
              />
            }
            < ButtonContainer editMode={isEditMode}>
              {isEditMode &&
                <RemoveButton onClick={() => setIsRemoveModalOpen(true)}>
                  <img src={bin} alt='delete-icon' />
                  <span>Remove this Compliance Item</span>
                </RemoveButton>
              }
              <Button
                text={isEditMode ? 'Save and Go Back' : 'Save'}
                onClick={handleSubmit(onSubmit)}
                type={'main'}
                style={{
                  padding: 15,
                  marginRight: 0,
                  marginLeft: '.5em',
                  marginTop: '2.3em',
                }}
              />
            </ButtonContainer>
          </NavPageWrap >
        </Wraper>
      }
    </>

  );
};

export default CompliancePage;
