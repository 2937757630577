import React, { useEffect, useState } from 'react'
import notAvailable from '../../assets/images/notAvailable.png'
import reportSourceImage from '../../assets/images/rsImage.svg'
import resourceResourceImage from '../../assets/images/resource-benefits.svg'
import proposedSourceImage from '../../assets/images/proposed-benefits.svg'
import { Wrap, Title, Text } from './styles'
import { useStore } from '../../mobx-store/context'
import FullScreenLoader from '../../components/common/FullScreenLoader'

const NoSubscription = ({ serviceName }) => {
  const [loading, setLoading] = useState(true)
  const [userRole, setUserRole] = useState('')
  const store = useStore()

  function getUserRole(serviceName) {
    let userRole;
    switch (serviceName) {
      case 'ReportSource':
        userRole = store.user[0].report_source_role;
        break;
      case 'ReSource':
        userRole = store.user[0].resource_role;
        break;
      case 'ProposedSource':
        userRole = 'admin';
        break;
      default:
        userRole = store.user[0].member_type;
    }
    setUserRole(userRole);
  }

  let serviceImage;
  switch (serviceName) {
    case 'ReportSource':
      serviceImage = reportSourceImage;
      break;
    case 'ReSource':
      serviceImage = resourceResourceImage;
      break;
    case 'ProposedSource':
      serviceImage = proposedSourceImage;
      break;
    default:
      serviceImage = notAvailable;
  }

  useEffect(() => {
    store.getUser(() => store.getCompany(() => {
      getUserRole(serviceImage)
      setLoading(false)
    }))
  }, [])

  if (loading) return <FullScreenLoader />

  return (
    <Wrap>
      <Title serviceName={serviceName}>
        Sign up for <strong>{serviceName}</strong>
      </Title>
      <img src={serviceImage} alt="not-available" />
      {(userRole !== 'admin')
        ? <Text>
          Your current account doesn’t have a subscription to {serviceName}, ask your account admin to add it.
          OR if you have questions or need a demo, please <a href='mailto:contact@pbmsource.com'>contact us</a> .
        </Text>
        : <Text>
          Your current account doesn’t have a subscription to {serviceName}.
          {/* <a
            href={
              store.user[0].member_type === 'admin' ? (
                serviceName === 'ReportSource'
                  ? '/addReportMember/'
                  : serviceName === 'ProposedSource'
                    ? '/addProposedMember/'
                    : serviceName === 'ReSource'
                      ? '/resource/options'
                      : '/topicsAndJur/')
                : '/edit-profile'
            }
          >
            Sign up here
          </a>  */}
           If you have questions or need a demo, please <a href='mailto:contact@pbmsource.com'>contact us</a> .
        </Text>
      }
    </Wrap >
  )
}

export default NoSubscription